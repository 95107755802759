import Modal from 'react-responsive-modal';
import React, {Component} from 'react';
import validate from "./validateCogs";
import WizardTabsForm from "../TabsContainer/WizardTabsForm";
import cloneDeep from 'lodash/cloneDeep';
// Using an ES6 transpiler like Babel
// To include the default styles
import 'react-rangeslider/lib/index.css';
import {reset} from "redux-form";
import LoadMask from "../../Utils/LoadMask/LoadMask";


class ModalProductos extends Component {

    state = {
        page: 0,
        reglaMutiMoneda: true,
        reglaMutiYearsInflation: true,
        values: {
            min: 2,
            max: 10,
        },
    };

    nextPage = () => {
        // eslint-disable-next-line react/destructuring-assignment,react/no-access-state-in-setstate
        this.setState({ page: this.state.page + 1 });
    };

    previousPage = () => {
        // eslint-disable-next-line react/destructuring-assignment,react/no-access-state-in-setstate
        this.setState({ page: this.state.page - 1 });
    };

    setPage = (page) => {
        // eslint-disable-next-line radix
        this.setState({ page: Number.parseInt(page) });
    };

    // eslint-disable-next-line class-methods-use-this
    onSubmitsss = (valForm) => {
        const data_cogs = cloneDeep(valForm);
        data_cogs.id = valForm.id || null;
        delete data_cogs.product.label;
        delete data_cogs.product.value;
        delete data_cogs.segment;
        delete data_cogs.territory;
        data_cogs.product.details = cloneDeep(data_cogs.product.detail || data_cogs.product.details);
        delete data_cogs.product.detail;

        data_cogs.direct_shipping_ubc = data_cogs.direct_shipping_ubc === "" ? 0 : data_cogs.direct_shipping_ubc;
        data_cogs.direct_shipping_acg = data_cogs.direct_shipping_acg === "" ? 0 : data_cogs.direct_shipping_acg;
        data_cogs.warehousing_ubc = data_cogs.warehousing_ubc === "" ? 0 : data_cogs.warehousing_ubc;
        data_cogs.warehousing_acg = data_cogs.warehousing_acg === "" ? 0 : data_cogs.warehousing_acg;
        data_cogs.overhead_ubc = data_cogs.overhead_ubc === "" ? 0 : data_cogs.overhead_ubc;
        data_cogs.overhead_acg = data_cogs.overhead_acg === "" ? 0 : data_cogs.overhead_acg;
        data_cogs.contractual_fees_ubc = data_cogs.contractual_fees_ubc === "" ? 0 : data_cogs.contractual_fees_ubc;
        data_cogs.contractual_fees_acg = data_cogs.contractual_fees_acg === "" ? 0 : data_cogs.contractual_fees_acg;
        data_cogs.cost_funds_ubc = data_cogs.cost_funds_ubc === "" ? 0 : data_cogs.cost_funds_ubc;
        data_cogs.cost_funds_acg = data_cogs.cost_funds_acg === "" ? 0 : data_cogs.cost_funds_acg;
        data_cogs.other_ubc = data_cogs.other_ubc === "" ? 0 : data_cogs.other_ubc;
        data_cogs.other_acg = data_cogs.other_acg === "" ? 0 : data_cogs.other_acg;

        for (let i = 0; i < data_cogs.employee_cogs.length; i++) {
            delete data_cogs.employee_cogs[i].level.label;
            delete data_cogs.employee_cogs[i].level.value;
            delete data_cogs.employee_cogs[i].ediMode;
            data_cogs.employee_cogs[i].level_id = `${data_cogs.employee_cogs[i].level.id}`;
            data_cogs.employee_cogs[i].variable_salary = 0;
        }

        this.props.crear("cogs/create_update_cogs/", {data_cogs: data_cogs});
        this.props.leer("cogs/get_cogs", this.props.idProject);
        this.props.onCloseModal();
        this.props.dispatch(reset('formCogs'));
        this.setPage(0);
    };

    render() {
        const { value, reverseValue } = this.state;
        const styleModal = { modal: { borderRadius: 25, maxWidth: "75%", margin: "50px auto" , padding: "0px" } };
        const styleLinea = { width: "100%" };
        const { open, onCloseModal, onSubmitsss } = this.props;
        return (
            <Modal
                open={open}
                animationDuration={1000}
                onClose={onCloseModal}
                center
                styles={styleModal}
                closeIconSize={0}
            >
                <div className="mx-5 my-3">
                    <div className="font-weight-bold">{`NEW COGS / ${this.props.arrayTabs[this.state.page].nombre}`}</div>
                </div>
                <LoadMask loading={this.props.loaderMoneda} blur>
                    <div className="lineaAzul" style={{ ...styleLinea, height: 20 }} />
                    <div className="mx-5 my-3">
                        <WizardTabsForm
                            {...this.props}
                            nameForm="formCogs"
                            initialValue={this.props.initialValue}
                            page={this.state.page}
                            arrayTabs={this.props.arrayTabs}
                            nextPage={this.nextPage}
                            previousPage={this.previousPage}
                            setPage={this.setPage}
                            onSubmitsss={this.onSubmitsss}
                            errorForm={this.props.formulario || {}}
                            validateForm={validate}
                            enableReinitialize={true}
                        />
                    </div>
                </LoadMask>
            </Modal>
        );
    }
};

export default (ModalProductos);
