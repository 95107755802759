export const validate = (values) => {
    const errors = {};
    if (!values.name) { errors.name = 'Required'; }
    if (!values.segment) { errors.segment = 'Required'; }


    if (!values.initial_customer_stock && values.initial_customer_stock > 0) { errors.initial_customer_stock = 'Required'; }
    if (!values.new_customers && values.new_customers > 0) { errors.new_customers = 'Required'; }
    if (!values.customer_growth && values.customer_growth > 0) { errors.customer_growth = 'Required'; }
    if (!values.annual_attrition && values.annual_attrition > 0) { errors.annual_attrition = 'Required'; }
    if (!values.active_customers && values.active_customers > 0) { errors.active_customers = 'Required'; }
    if (!values.monthlydistribution || !values.monthlydistribution.length) {
        errors.monthlydistribution = { _error: 'Required' };
    } else {
        let total = 0;
        values.monthlydistribution.forEach((value) => { total += Number(value.value); });
        if (Number(total) !== 100) {
            errors.monthlydistribution = { _error: 'The total must be equal to 100%.' };
        }
    }

    if (!values.base_price && values.base_price > 0) { errors.base_price = 'Required'; }
    if (!values.annual_growthp && values.annual_growthp > 0) { errors.annual_growthp = 'Required'; }
    if (!values.annual_discount && values.annual_discount > 0) { errors.annual_discount = 'Required'; }

    if (!values.base_volume && values.base_volume > 0) { errors.base_volume = 'Required'; }
    if (!values.annual_growthv && values.annual_growthv > 0) { errors.annual_growthv = 'Required'; }
    if (!values.monthlysales || !values.monthlysales.length) {
        errors.monthlysales = { _error: 'Required' };
    } else {
        let total = 0;
        values.monthlysales.forEach((value) => { total += Number(value.value); });
        if (Number(total) !== 100) {
            errors.monthlysales = { _error: 'The total must be equal to 100%.' };
        }
    }

    if (!values.salescollection || !values.salescollection.length) {
        errors.salescollection = { _error: 'Required' };
    } else {
        let total = 0;
        values.salescollection.forEach((value) => { total += Number(value.value) || 0; });
        if (Number(total) !== 100) {
            errors.salescollection = { _error: 'The total must be equal to 100%.' };
        }
    }

    if ((values.initial_customer_stock === 0) && (values.new_customers === 0)) {
        errors.new_customers = 'If New customers = 0 BOP must be > 0';
        errors.initial_customer_stock = 'If BOP = 0 New customers must be > 0';
    }
    if (!values.initial_customer_stock && !values.new_customers) {
        errors.initial_customer_stock = 'Required';
        errors.new_customers = 'Required';
    }
    return errors;
};


export default validate;
