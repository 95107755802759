import { connect } from 'react-redux';
import { actions } from '../../../../redux/modules/Cogs/Cogs';
import WizardCogs from './WizardCogs';

const ms2p = (state) => {
    return {
        ...state.cogs,
        formly: state.formlyAll.item,
        setUpGeneral: state.setUpGeneral,
        formulario: state.form.formCogs,
        login: state.login,
    };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(WizardCogs);
