import React from 'react';
import {Field, reduxForm, formValueSelector, submit} from 'redux-form';
import { connect } from 'react-redux';
import { renderField } from "../../../Utils/renderField";
import {renderDatePicker} from "../../../Utils/renderField/renderField";

let FilterForm = (props) => {
    const { handleSubmit } = props;
    return (
        <form onSubmit={handleSubmit}>
            <div className="form-row align-items-end">
                <div className={`form-group col-md-3`}>
                    <label htmlFor="start_date">Filter Payments From</label>
                    <Field
                        name="start_date"
                        type="text"
                        component={renderDatePicker}
                        label="start_date"
                    />
                </div>
                <div className={`form-group col-md-3`}>
                    <label htmlFor="end_date">To</label>
                    <Field
                        name="end_date"
                        type="text"
                        component={renderDatePicker}
                        label="end_date"
                    />
                </div>
                <div className={`form-group col-md-2`}>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <button type="submit" className="btn btn-optional">Filter</button>
                    <div style={{ visibility: "hidden" }}> dataa </div>
                </div>
            </div>
        </form>
    );
};

// eslint-disable-next-line no-unused-vars
export const matchPassword = (pass, confirm) => validatorFromFunction((value) => {
    return pass === confirm;
});

FilterForm = reduxForm({
    form: 'updatePass', // a unique identifier for this form
    validate: (data) => {
        const errors = {};
        if (!data.start_date) errors.start_date = 'This field is required';
        if (!data.end_date) errors.end_date = 'This field is required';
        return errors;
    },
})(FilterForm);

const selector = formValueSelector('updatePass');

FilterForm = connect((state) => {
    const new_password = selector(state, "new_password");

    return {
        new_password,
    };
})(FilterForm);

export default FilterForm;
