import { connect } from 'react-redux';
import { actions } from '../../../../redux/modules/Geography/geography';
import { actions as actionsSetUp } from '../../../../redux/modules/ProjectSetUp/formGeneral';
import WizardGeography from './WizardGeography';

const ms2p = (state) => {
    return {
        ...state.geogrephy,
        setUpGeneral: state.setUpGeneral,
        formly: state.formlyAll.item,
        formulario: state.form,
        login: state.login,
    };
};

const md2p = { ...actions, setGeographyList: actionsSetUp.setGeographyList, leerLabel: actionsSetUp.leer };

export default connect(ms2p, md2p)(WizardGeography);
