import { connect } from 'react-redux';
import { actions } from '../../../../redux/modules/Products/Products';
import WizardProducts from './WizardProducts';

const ms2p = (state) => {
    return {
        ...state.products,
        formly: state.formlyAll.item,
        setUpGeneral: state.setUpGeneral,
        formulario: state.form.formProduct,
        login: state.login,
    };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(WizardProducts);
