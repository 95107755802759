import { validateCurren, validateGeneral, validateOther, validateRateAnSen } from "../ProjectSetUp/validateGenerarl";
import {CURRENCY_ITEMS} from "../../../../utility/contry";

import imgAgregar from "../../../../../assets/img/agregar.png";
import imgElimiar from "../../../../../assets/img/cancelar.png";
import imgEditar from "../../../../../assets/img/editar.png";

const meses = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec",];
const sales = ["Cash", "30", "60", "85", "120", "150", "180", "210", "240", "270", "300", "330", "360",];

const ppenamesitems = [
    { value: 1, label: 'Buildings' },
    { value: 2, label: 'Computer Equipment' },
    { value: 3, label: 'Computer Software' },
    { value: 4, label: 'Furniture' },
    { value: 5, label: 'Land' },
    { value: 6, label: 'Lasehold Improvements' },
    { value: 7, label: 'Machinery' },
    { value: 8, label: 'Premises' },
    { value: 9, label: 'Vehicles' },
    { value: 10, label: 'Other' },
];

const ianamesitems = [
    { value: 1, label: 'Trademarks' },
    { value: 2, label: 'Goodwill' },
    { value: 3, label: 'Copyrights' },
    { value: 4, label: 'Patents' },
    { value: 5, label: 'Brands' },
    { value: 6, label: 'Internet Domains'},
    { value: 7, label: 'Other' },
];

const arrayTabs = [
    {
        nombre: "Capital investment",
        help: "Capital is the total amount of money raised by the company. This could be done either by personal investment and/or debt",
        inputs: [
            { name: "personal", value: "", label: "Personal <USD>", type: "inputNumber" },
            { name: "short_term_debt", value: "", label: "Short term debt <USD>", type: "inputNumber" },
            { name: "long_term_debt", value: "", label: "Long term debt <USD>", type: "inputNumber" },
            { name: "term_debt_base_cost", value: "", label: "Term debt monthly payment <USD>", type: "inputNumber" },
            { name: "term_debt_annual_growth", value: "", label: "Annual cost growth (%)", type: "inputNumber" },
        ],
        columInput: 2,
        validate: validateRateAnSen,
    },
    {
        nombre: "Fixed asset investment - Property, plant and equipment - PP&E",
        help: "Assets are physical and tangible elements. They are expected to generate economic benefits for a company.",
        inputs: [
            {
                name: "ppe", value: "", label: "PP&E", type: "multiInputInvestments", data: ppenamesitems,
                columnNum: "12", moneda: "Q", imgElimiar, imgAgregar, imgEditar,
            },
        ],
        columInput: 2,
        validate: validateRateAnSen,
    },
    {
        nombre: "Intangible assets - IA",
        help: "An intangible asset is an asset that is not physical in nature patents, trademarks, franchises, goodwill, and copyrights among others",
        inputs: [
            {
                name: "ia", value: "", label: "IA", type: "multiInputInvestments", data: ianamesitems,
                columnNum: "12", moneda: "Q", imgElimiar, imgAgregar, imgEditar,
            },
        ],
        columInput: 2,
        validate: validateRateAnSen,
    },
];
export default (arrayTabs);
