export const validateGeneral = (values) => {
    const errors = {};
    if (!values.name) { errors.name = 'Required'; }
    if (!values.horizon) { errors.horizon = 'Required'; }
    if (!values.inflation_base) { errors.inflation_base = 'Required'; }
    if (!values.inflation || !values.inflation.length) {
        errors.inflation = { _error: 'Required' };
    } else {
        const detalleArray = [];
        values.inflation.forEach((value, index) => {
            let detErrors = {};
            let repetidos = [];
            if (!value.id) {
                detErrors.id = "Required";
            }
            if (detErrors) {
                detalleArray[index] = detErrors;
            }
        });
        if (detalleArray.length) {
            errors.inflation = detalleArray;
        }
    }
    if (!values.analisis_units) { errors.analisis_units = 'Required'; }
    return errors;
};


export const validateOther = (values) => {
    const errors = {};
    if (!values.color) { errors.color = 'Required'; }
    return errors;
};

export const validateCurren = (values) => {
    const errors = {};
    if (!values.main_currency) { errors.main_currency = 'Required'; }
    if (!values.sales_currency) { errors.sales_currency = 'Required'; }
    if (!values.cogs_currency) { errors.cogs_currency = 'Required'; }
    if (!values.sga_currency) { errors.sga_currency = 'Required'; }
    if (!values.pl_currency) { errors.pl_currency = 'Required'; }
    return errors;
};


export const validateRateAnSen = (values) => {
    const errors = {};
    if (!values.annual_rate1) { errors.annual_rate1 = 'Required'; }
    if (!values.annual_rate2) { errors.annual_rate2 = 'Required'; }
    if (!values.annual_rate3) { errors.annual_rate3 = 'Required'; }
    if (!values.increase) { errors.increase = 'Required'; }
    if (!values.annual_rate_base) { errors.annual_rate_base = 'Required'; }
    return errors;
};
