import React, { Component } from 'react';
import { ProgressBar, Step } from "react-step-progress-bar";
import "react-step-progress-bar/styles.css";


class ProgresBar extends Component {
    render() {
        const { array } = this.props;
        const { valido } = this.props;
        const { invalido } = this.props;
        const { setPage } = this.props;

        const imagenes = array[1];
        const textos = array[0];
        const stepss = [];
        // eslint-disable-next-line react/destructuring-assignment
        const step = (100 / imagenes.length) * this.props.step;

        // eslint-disable-next-line no-plusplus
        for (let i = 1; i <= imagenes.length; i++) {
            const porcentaje = (100 / imagenes.length) * i;
            stepss.push(porcentaje);
        }
        return (
            <div>
                <ProgressBar
                    percent={step}
                    filledBackground="rgba(0, 0, 0, 0)"
                    unfilledBackground="rgba(0, 0, 0, 0)"
                    backgroun="rgba(0, 0, 0, 0)"
                    hasStepZero={false}
                    stepPositions={stepss}
                    width="90%"
                >
                    {imagenes.map((value, index) => {
                        return (
                            // eslint-disable-next-line react/no-array-index-key
                            <Step key={index} transition="scale">
                                {({ accomplished, position }) => {
                                    if (position === step) {
                                        return (
                                            <div className="div-imagen" onClick={() => (setPage(index + 1))}>
                                                <img src={imagenes[index]} className="icon-bar focus" alt="icon" />
                                                <span className="focusTexto" >{ textos[index] }</span>
                                            </div>
                                        );
                                    }
                                    if (accomplished) {
                                        return (
                                            <div className="div-imagen" onClick={() => (setPage(index + 1))}>
                                                <img src={valido} className="icon-bar" alt="icon" />
                                                <span>{ textos[index] }</span>
                                            </div>
                                        );
                                    }
                                    return (
                                        <div className="div-imagen" onClick={() => (setPage(index + 1))}>
                                            <img src={invalido} className="icon-bar" alt="icon" />
                                            <span>{ textos[index] }</span>
                                        </div>
                                    );
                                }}
                            </Step>
                        );
                    })}
                </ProgressBar>
            </div>
        );
    }
}

export default ProgresBar;
