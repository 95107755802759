import React from 'react';

export const Terms = () => {
    return (
        <div
            style={{
                height: "100vh",
                overflow: "auto",
                marginTop: "10px",
                marginLeft: "5px",
                padding: "20px",
                backgroundColor: "white"
            }}
        >
            <h1
                style={
                    { textAlign: "center", outline: "0px" }
                }
                tabIndex="-1"
            >
                Terms & Conditions
            </h1>
            <br />
            <p className="texto-negrita borde-texto" align="justify">
                IMPORTANT:
                PLEASE READ THIS DOCUMENT CAREFULLY. THIS DOCUMENT GOVERNS YOUR RELATIONSHIP WITH ONEPAGE.BUSINESS
                (“ONEPAGE“). SET FORTH BELOW ARE THE TERMS AND CONDITIONS UNDER WHICH ONEPAGE MAKES AVAILABLE ITS
                INTERNET WEBSITES, INCLUDING WWW.ONEPAGE.BUSINESS (THE “SITE“), AND THE SERVICES AVAILABLE THROUGH
                THE SITE OR OTHERWISE PROVIDED BY ONEPAGE (THE “SERVICES“), TO EACH PERSON OR ENTITY (EACH A “USER“)
                ACCESSING OR USING THE SITE OR THE SERVICES. BY ENTERING THIS AGREEMENT, YOU EXPRESSLY ACKNOWLEDGE
                THAT YOU HAVE READ AND UNDERSTAND ALL OF THE TERMS OF THIS AGREEMENT AND HAVE TAKEN TIME TO CONSIDER
                THE CONSEQUENCES OF THIS IMPORTANT DECISION.
            </p>
            <p align="justify">
                These terms and conditions, together with any and all other terms, conditions, rules, policies or
                procedures (“Additional Terms“) published on the Site from time to time and related to User’s use of
                the Site or the Services (including, without limitation, all country-specific terms attached hereto)
                (together, this “Agreement“), each as updated from time to time by Onepage, constitute a binding,
                legal agreement between Onepage and the User.
            </p>
            <p align="justify">
                By clicking “I Agree” (or any other button indicating your acceptance of this Agreement) you hereby
                agree to, and are bound by, this Agreement. If you do not agree with any of the terms of this Agreement,
                do not click such a button or otherwise access or use this Site or the Services or any information
                contained on this Site.
            </p>
            <p align="justify">
                Onepage may make changes to the materials and services offered on this Site at any time. Onepage can
                change the terms of this Agreement (including any Additional Terms) at any time. If Onepage changes
                the terms of this Agreement, Onepage will post an updated set of terms and conditions of use on this
                Site and post a change notice and/or send registered users an email notice of the changes in advance
                of implementing the changes.
            </p>
            <p align="justify">
                If any modification is unacceptable to you, you shall cease using this Site and the Services and be
                able to terminate your registration and/or subscription (if any). If you do not cease using this Site
                and the Services, you will be conclusively deemed to have accepted the change.
            </p>
            <h2>Legal Status</h2>
            <p align="justify">
                Each User hereby warrants that if it is a corporation or other legal entity, User is validly formed
                and existing under the laws of its jurisdiction and has duly authorized its agent or agents to enter
                into this Agreement and, if an individual, User is of the age of majority in his or her place of
                residence.
            </p>
            <h2>Subscription and Payment</h2>
            <p align="justify">
                If User purchases a paid subscription to the Services (a “Subscription“), User shall, unless otherwise
                specified, pay all fees to Onepage, including for any additional Services purchased by User or usage
                that exceeds the thresholds at the rates posted, as well as VAT and any other applicable taxes,
                applicable to such Subscription, as published on the Site at https://www.Onepage.com/pricing/ from
                time to time.
            </p>
            <p align="justify">
                Onepage may change such fees at any time and in its sole discretion, provided that any such change shall
                become effective at the end of the then-current term of User’s Subscription. Onepage will provide User
                with prior notice of any change in fees to allow User to cancel User’s Subscription prior to the end of
                the then-current term of User’s Subscription.
            </p>
            <p align="justify">
                Subscription membership in the Services is on a continuous service basis. This means that, subject to
                the terms and conditions of this Agreement, Onepage shall automatically renew User’s Subscription at
                the end of its term unless User’s Subscription is canceled using the tool in User’s account page on the
                Site at any time prior to the end of the then-current Subscription. User will be charged a renewal rate
                equal to the rate for the immediately preceding Subscription period, based upon the Subscription program
                (annual, quarterly, monthly, etc.) that User has chosen, unless Onepage has notified User of any new
                rate in advance. A cancellation of a Subscription requested by User through User’s account page will
                become effective on the date that the then-current Subscription period expires.
            </p>
            <p align="justify">
                If User has elected to pay the fees by credit card, User hereby warrants that the credit card
                information provided by User is correct, and User shall promptly notify Onepage of any changes to such
                credit card information. User acknowledges and agrees that if User’s credit card payment cannot be
                processed for any reason, Onepage may suspend or cancel User’s Subscription.
            </p>
            <h2>Registration</h2>
            <p>
                If User has registered for an account to use the Services, User hereby warrants that the information
                provided by User for the purposes of such registration is complete and accurate. User shall ensure that
                such information is kept up to date. Upon registering for an account, each User will receive a password
                and an account designation. User acknowledges and agrees that User, and not Onepage, is responsible for
                User’s account and all activities occurring in connection with the use of that account, whether or not
                User authorizes such activities.
            </p>
            <h2>Onepage content and material</h2>
            <p>
                Certain content and information provided on and through this Site and Services, including, without
                limitation, documents, graphics and images that are not User Content (as defined below) are provided
                to you by Onepage and are the copyrighted and/or trademarked work of Onepage or Onepage’s contributors.
                Onepage grants you a limited, personal, non-exclusive and non-transferable license to use and to display
                the Onepage Materials solely for your personal use in connection with the Site and Services.
            </p>
            <p>
                Except as expressly permitted in this Agreement, you have no right to modify, edit, copy, reproduce,
                create derivative works of, reverse engineer, alter, enhance or in any way exploit any of the Onepage
                Materials in any manner. This limited license terminates automatically, without prior notice to you,
                if you breach any term of this Agreement. Except for the limited rights granted hereunder, you
                acknowledge that you have no right, title or interest in or to any Onepage Materials.
            </p>
            <h2>User Content</h2>
            <p>
                User acknowledges and agrees that:
            </p>
            <ol type="a" align="justify">
                <li>
                    all questions, information, data, text, software, music, sound, photographs, images, video,
                    responses, messages or other materials communicated, submitted or transmitted by User through
                    the Site (“User Content“), whether publicly posted or privately transmitted, are the sole
                    responsibility of the person from whom such User Content originated,
                </li>
                <br />
                <li>
                    User, and not Onepage, is responsible for all User Content that the User upload, post, email,
                    distribute, communicate, transmit, or otherwise make available using the Services or that is
                    otherwise made available through the use of User’s account (if User has one), whether or not
                    authorized by User,
                </li>
                <br />
                <li>
                    By communicating or transmitting any User Content using the Site or the Services, User grants
                    to Onepage an irrevocable, non-exclusive, royalty-free and worldwide license to use such User
                    Content for the purpose of operating the Site and supplying the Services, and User warrants to
                    Onepage that it has the right to grant such license for such purposes.
                </li>
                <br />
                <li>
                    User further acknowledges and agrees that Onepage does not control the User Content originating
                    from User, or other users of the Site or the Services, and does not guarantee the accuracy,
                    integrity or quality of such User Content. Notwithstanding the foregoing, Onepage may, but is
                    not obligated to, review all User Content and block, modify, terminate access to, or remove any
                    such User Content that Onepage, in its sole discretion, considers being non-compliant with any of
                    the requirements of this Agreement. In abundant, since the user / tenant provides and/or inputs
                    all data and information into the model, Onepage does not guarantee the accuracy, authenticity,
                    timeliness, reliability, appropriateness, correct sequencing, or completeness on its results.
                </li>
                <br />
                <li>
                    Onepage reserves the right to purge User Content from its databases at any time and from time
                    to time without notice. User acknowledges and agrees that User is solely responsible for backing
                    up any User Content uploaded to the Site by User or received by User through the use of the
                    Services. Onepage shall not be liable for any purging, deletion or failure to retain any such User
                    Content.
                </li>
                <li>
                    In the event of User’s non-compliance with any provision of this Agreement, Onepage may suspend or
                    disable User’s account and User’s access to use the Site and/or the Services, as well as recover
                    from User any losses, damages, costs or expenses incurred by Onepage resulting from or arising
                    out of such non-compliance.
                </li>
                <br />
            </ol>
            <h2>Account Management</h2>
            <ol type="a" align="justify">
                <li>
                    Keep Your Password Secure. If you have been issued an account by Onepage in connection with your use
                    of the Services, you are responsible for safeguarding your password and any other credentials used
                    to access that account. You, and not Onepage, are responsible for any activity occurring in your
                    account (other than activity that Onepage.business is directly responsible for which is not
                    performed in accordance with your instructions), whether or not you authorized that activity. If
                    you become aware of any unauthorized access to your account, you should notify Onepage.business
                    immediately. Accounts may not be shared and may only be used by one individual per account.
                </li>
                <li>
                    Keep Your Details Accurate. Onepage occasionally sends notices to the email address registered with
                    your account. You must keep your email address and, where applicable, your contact details and
                    payment details associated with your account current and accurate. Accounts are controlled by the
                    entity whose email address is registered with the account.
                </li>
                <li>
                    Remember to Backup. You are responsible for maintaining, protecting, and making backups of your
                    Content. To the extent permitted by applicable law, Onepage will not be liable for any failure to
                    store, or for loss or corruption of, your Content.
                </li>
                <li>
                    Account Inactivity. Onepage may terminate your account and delete any content contained in it if
                    there is no account activity (such as a log in event or payment) for over six (6) months. However,
                    we will attempt to warn you by email before terminating your account to provide you with an
                    opportunity to log in to your account so that it remains active.
                </li>
            </ol>
            <h2>Code of Conduct</h2>
            <p>
                In connection with User’s use of the Site and the Services, and without limiting any of User’s other
                obligations under this Agreement or applicable law, User:
            </p>
            <ol type="a" align="justify">
                <li>
                    must don’t do anything illegal;
                </li>
                <li>
                    shall comply with: (i) this Agreement, and all other policies as published on the Site from time to
                    time, (ii) all local and international laws applicable to User, and all other laws related to
                    unsolicited commercial email messages, defamation, privacy, obscenity, intellectual property, data
                    protection, and child protective email address registries, (iii) all other rules or regulations
                    applicable to User, including, among other things, securities regulations, and (iv) all privacy
                    policies or similar policies or procedures to which User may be bound that are related to User’s
                    use of the Services;
                </li>
                <li>
                    shall not upload, post, email, distribute, communicate, transmit or otherwise make available any
                    User Content: (i) that is unlawful, harmful, threatening, abusive, harassing, tortious, defamatory,
                    libelous, vulgar, obscene, offensive, indecent, invasive of another’s privacy, hateful, or racially,
                    ethnically or otherwise objectionable, (ii) that infringes any patent, trademark, trade secret,
                    copyright, or other intellectual property right of any party, (iii) that User does not have the
                    right to make available by reason of any law or contractual or fiduciary relationship (including
                    inside information, and proprietary or confidential information obtained or disclosed in connection
                    with an employment relationship or pursuant to a confidentiality agreement), or (iv) that comprises
                    or includes any “junk mail”, “spam”, “chain letters”, “pyramid schemes”, or any similar form of
                    solicitation;
                </li>
                <li>
                    shall not use the Site or the Services to  harm minors in any way, and shall not send materials to
                    minors that would subject Onepage to any local or international law, rule or regulation governing
                    children’s privacy, rights of personality or otherwise related to protecting minors;
                </li>
                <li>
                    shall not impersonate any other person or entity, including Onepage, or a Onepage official, forum
                    leader, guide or host, or falsely state or otherwise misrepresent User’s affiliation with any other
                    person or entity;
                </li>
                <li>
                    shall not circumvent any restrictions on access nor interfere with or disrupt the Site or the
                    Services or servers or networks connected to the Site or the Service, or disobey any requirements,
                    procedures, policies, or regulations of networks connected to the Site or the Services;
                </li>
                <li>
                    shall not engage in excessive usage of the Site or the Services, as determined by Onepage in its
                    sole discretion, including usage that adversely affects the speed, responsiveness, or functionality
                    of the Site, or disrupts the availability of the Site and the Services for other users;
                </li>
                <li>
                    shall not attempt to damage, deny service to, hack, crack, reverse engineer, or otherwise interfere
                    with the Site or the Services in any manner (except to the extent the foregoing prohibition is not
                    permitted by applicable law, including, without limitation, as set forth with respect to Users in
                    France in the attached country-specific terms);
                </li>
                <li>
                    shall not upload, post, email, distribute, communicate, transmit, or otherwise make available any
                    viruses or similar malicious software that may damage the operation of a computer, the Site, or the
                    Services;
                </li>
                <li>
                    shall not infringe upon the rights of others and/or  engage in activity that violates the privacy
                    of others, and
                </li>
                <li>
                    shall not help others break these rules.
                </li>
            </ol>
            <h2>Enforcement</h2>
            <p align="justify">
                User further acknowledges and agrees that if you violate these Terms, we may stop providing Services
                to you or we may close your Onepage account. We may also block delivery of a communication to or from
                the Services in an effort to enforce these Terms.
                <br />
                <br />
                When investigating alleged violations of these Terms, Onepage may cooperate with any governmental
                authority in connection with any investigation into User’s use of the Site or the Services, including
                use in contravention of applicable laws, and may, in accordance with applicable laws, disclose any User
                Content, and any other information pertaining to the User or to User’s use of the Site or the Services,
                to such governmental authority in connection with any such investigation.
            </p>
            <h2>Indemnity</h2>
            <p align="justify">
                Onepage its affiliates, and their respective general partners, directors, officers, employees, servants
                and agents shall not be liable from and against all claims, demands, damages, liabilities and costs
                (including legal fees on a full indemnity basis) arising out of or in connection with:
            </p>
            <ol type="a" align="justify">
                <li>
                    any loss or damage of whatever nature (direct, indirect, consequential, or other) whether arising in
                    contract, tort or otherwise, which may arise because of your use of (or inability to use) the
                    platform,
                </li>
                <li>
                    any other use of the Services by a third party using User’s account (whether or not authorized
                    by User),
                </li>
                <li>
                    user’s breach of this Agreement, including any of User’s representations and warranties under this
                    Agreement,
                </li>
                <li>
                    user’s contravention of any law applicable to User, or
                </li>
                <li>
                    your use of (or failure to use) the information of this document.
                </li>
            </ol>
            <h2>Limitation of Liability</h2>
            <p align="justify">
                If you have any basis for recovering damages (including breach of these Terms), you agree that your
                exclusive remedy is to recover, from Onepage and any other third party, direct damages up to an amount
                equal to your Services fee for the month during which the breach occurred (or up to $10.00 if the
                Services are free).
                <br />
                <br />
                You cannot recover any other damages or losses, including direct, consequential, lost profits, special,
                indirect, incidental, or punitive. These limitations and exclusions apply even if this remedy does
                not fully compensate you for any losses or fails of its essential purpose or if we knew or should
                have known about the possibility of the damages. To the maximum extent permitted by law, these
                limitations and exclusions, apply to anything or any claims related to these Terms, the Services,
                or the software related to the Services.
            </p>
            <h2>Provision of the Site and the Services</h2>
            <ol type="a" align="justify">
                <li>
                    Onepage reserves the right at any time and from time to time to modify, suspend, or discontinue,
                    temporarily or permanently, the Services or any part thereof, or User’s access thereto, and to
                    modify, suspend or delete the Site or any part thereof, and Onepage will use commercially reasonable
                    efforts to provide reasonable advance notice of changes that significantly impact the Services in a
                    negative manner.
                </li>
                <li>
                    In the case of changes that significantly influence the Services in a negative manner, User is
                    entitled to cancel User’s Subscription, if any. User acknowledges and agrees that Onepage shall
                    not be liable to User, or to any third party, for any modification, suspension or discontinuance
                    of the Services or any modification, suspension or deletion of the Site.
                </li>
                <li>
                    All the information is provided "as is", without warranty of any kind. Onepage does not accept any
                    responsibility or liability for the accuracy, content, completeness, legality, or reliability of the
                    information contained on its reports. User acknowledges and agrees that the information, software,
                    products, and services contained in or available through the site or the services, including
                    information, software, products and services made available by other users of the site or the
                    services, may include inaccuracies or errors.
                </li>
                <li>
                    Onepage, or our affiliates, make no warranties, express or implied, guarantees or conditions with
                    respect to your use of the services. You understand that use of the services is at your own risk
                    and that we provide the services on an "as is" basis "with all faults" and "as available." you bear
                    the entire risk of using the services.
                </li>
                <li>
                    User specifically agrees that Onepage, its affiliates, and their respective directors, officers,
                    employees, servants and agents shall not be liable for unauthorized access to or alteration of any
                    transmissions or data, any material or data sent or received or not sent or not received, or any
                    transactions entered into through the site or the services or in reliance upon any information
                    obtained through the use of the site or the services,
                </li>
                <li>
                    User further acknowledges and agrees that user, not Onepage, is responsible for evaluating the
                    accuracy, reliability, completeness, and usefulness of any information obtained using the site
                    or the services.
                </li>
                <li>
                    You acknowledge that computer and telecommunications systems are not fault-free and occasional
                    periods of downtime occur. We do not guarantee the services will be uninterrupted, timely, secure,
                    or error-free or that content loss will not occur, nor do we guarantee any connection to or
                    transmission from the computer networks.
                </li>
                <li>
                    Outputs, reports, calculations, provided by Onepage are solely as a guidance to you and for general
                    informational purposes only. The underlying intellectual property, calculation, algorithms and
                    methodologies used by Onepage are neither Financial Accounting Standards Board (FASB) nor Generally
                    Accepted Accounting Principles (GAAP) compliance.
                </li>
                <li>
                    Outputs, reports, calculations, provided by Onepage are not a substitute for professional advice.
                    Before presenting, entering, implementing or executing any investment project, we recommend to
                    review in full details, specially whatever nature the assumptions are (implicit, explicit, direct,
                    indirect, consequential, or other), with your board, financial, accountant, legal and/or tax
                    advisors.
                </li>
                <li>
                    Onepage makes no representations or warranties regarding the suitability, reliability, availability,
                    timeliness, quality, or lack of viruses, or other harmful components of the site or the services, or
                    the accuracy of such information, software, products and services.
                </li>
                <li>
                    To the fullest extent permitted by applicable law, Onepage, its affiliates, and their respective
                    directors, officers, employees, servants and agents hereby disclaim all warranties, express or
                    implied, with regard to such information, software, products or services, including any warranty of
                    non-infringement of third party rights, and the implied warranties of merchantability and fitness
                    for purpose.
                </li>
            </ol>
            <h2>Intellectual Property Rights</h2>
            <p align="justify">
                The Site and the Services and all algorithms, information and screens appearing on this Site, including
                documents, services, site design, text, graphics, logos, images and icons, as well as the arrangement
                thereof, are the sole property of Onepage Inc., its affiliates, or its third party licensors.
                <br />
                <br />
                Except as otherwise required or limited by applicable law, any reproduction, distribution, modification,
                retransmission, or publication of any copyrighted material is strictly prohibited without the express
                written consent of the copyright owner or license.
                <br />
                <br />
                Onepage reserves all rights in the Site and the Services that are not expressly granted. Onepage is a
                registered trademark. Other trademarks, names, and logos on this Site are the property of their
                respective owners.
                <br />
                <br />
                Nothing in this Agreement shall be deemed to assign or transfer to User any rights to any such
                intellectual property. User further acknowledges and agrees that Onepage Materials and other content
                made available to User through the Services may be subject to the intellectual property rights of third
                parties.
            </p>
            <h2>Privacy, Confidentiality & Security</h2>
            <p align="justify">
                Privacy.
                <br />
                In the course of using the Services, you may submit content to Onepage (including your personal data and
                the personal data of others) or third parties may submit content to you through the Services (your
                “Content”). We know that by giving us your Content, you trust us to treat it appropriately. Onepage
                Privacy Policy, together with any Service-specific data use policies, privacy statements and privacy
                notices (collectively, “privacy policies”), detail how we treat your Content and personal data and we
                agree to adhere to those privacy policies. You in turn agree that Onepage may use and share your Content
                in accordance with our privacy policies.
                <br />
                <br />
                Confidentiality
                <br />
                Onepage will treat your Content as confidential information and only use and disclose it in accordance
                with these Terms (including our privacy policies). However, your Content is not regarded as confidential
                information if such content:
            </p>
            <ol type="a" align="justify">
                <li>
                    is or becomes public (other than through breach of these Terms by Onepage;
                </li>
                <li>
                    was lawfully known to Onepage before receiving it from you;
                </li>
                <li>
                    is received by Onepage from a third party without knowledge of breach of any obligation owed to
                    you; or (d) was independently developed by Onepage. without reference to your Content. Onepage may
                    disclose your Content when required by law or legal process, but only after Onepage, if permitted by
                    law, uses commercially reasonable efforts to notify you to give you the opportunity to challenge the
                    requirement to disclose.
                </li>
            </ol>
            <p align="justify">
                Security
                <br />
                Onepage will store and process your Content in a manner consistent with industry security standards.
                Onepage has implemented appropriate technical, organizational, and administrative systems, policies, and
                procedures designed to help ensure the security, integrity, and confidentiality of your Content and to
                mitigate the risk of unauthorized access to or use of your Content.
            </p>
            <h2>
                Suspension and Termination of Services
            </h2>
            <ol type="a" align="justify">
                <li>
                    By You; If you terminate a Subscription in the middle of a billing cycle, you will not receive a
                    refund for any period of time you did not use in that billing cycle unless you are terminating these
                    Terms for any of the following reasons: (a) we have materially breached these Terms and failed to
                    cure that breach within 30 days after you have so notified us in writing; or (b) a refund is
                    required by law.
                </li>
                <li>
                    By Onepage; Onepage may terminate your Subscription at the end of a billing cycle by providing at
                    least 30 days’ prior written notice to you. Onepage.business may terminate your Subscription for any
                    reason by providing at least 90 days’ written notice to you and will provide a pro rata refund for
                    any period of time you did not use in that billing cycle. Onepage.business may suspend performance
                    or terminate your Subscription for any of the following reasons: (a) you have materially breached
                    these Terms and failed to cure that breach within 30 days after Onepage.business has so notified
                    you in writing; (b) you cease your business operations or become subject to insolvency proceeding
                    and the proceedings are not dismissed within 90 days; or (c) you fail to pay fees for 30 days past
                    the due date. Additionally, Onepage.business may limit or suspend the Services to you if you fail
                    to comply with these Terms, or if you use the Services in a way that causes legal liability to us
                    or disrupts others’ use of the Services. Onepage.business may also suspend providing the Services to
                    you if we are investigating suspected misconduct by you. If we limit, suspend, or terminate the
                    Services you receive, we will endeavor to give you advance notice and an opportunity to export a
                    copy of your Content from that Service. However, there may be time sensitive situations where
                    Onepage.business may decide that we need to take immediate action without notice. Onepage.business
                    will use commercially reasonable efforts to narrow the scope and duration of any limitation or
                    suspension under this Section as is needed to resolve the issue that prompted such action.
                    Onepage.business has no obligation to retain your Content upon termination of the applicable
                    Service.
                </li>
                <li>
                    Further Measures; If Onepage stops providing the Services to you because you repeatedly or
                    egregiously breach these Terms, Onepage may take measures to prevent the further use of
                    the Services by you
                </li>
            </ol>
            <h2>
                Changes and Updates
            </h2>
            <ol type="a" align="justify">
                <li>
                    Changes to Terms; Onepage may change these Terms at any time for a variety of reasons, such as to
                    reflect changes in applicable law or updates to Services, and to account for new Services or
                    functionality. The most current version will always be posted on the Onepage website. If an
                    amendment is material, as determined in Onepage sole discretion, Onepage will notify you by email.
                    Notice of amendments may also be posted to Onepage blog or upon your login to your account. Changes
                    will be effective the day they are publicly posted. In order for certain changes to become
                    effective, applicable law may require Onepage to obtain your consent to such changes, or to provide
                    you with sufficient advance notice of them. If you do not want to agree to any changes made to
                    the terms for a Service, you should stop using that Service, because by continuing to use the
                    Services you indicate your agreement to be bound by the updated terms.
                </li>
                <li>
                    Changes to Services. Onepage constantly changes and improves the Services. Onepage may add, alter,
                    or remove functionality from a Service at any time without prior notice. Onepage may also limit,
                    suspend, or discontinue a Service at its discretion. If Onepage discontinues a Service, we will give
                    you reasonable advance notice to provide you with an opportunity to export a copy of your Content
                    from that Service. Onepage may remove content from the Services at any time in our sole discretion,
                    although we will endeavor to notify you before we do that if it materially affects you and if
                    practicable under the circumstances.
                </li>
            </ol>
            <h2>Other Terms</h2>
            <ol type="a" align="justify">
                <li>
                    Entire Agreement. These Terms & Conditions (including the Additional Terms) constitute the entire
                    agreement between you (“User”) and Onepage, and they supersede any other prior or contemporaneous
                    agreements, terms and conditions, written or oral concerning its subject matter. Any terms and
                    conditions appearing on a purchase order or similar document issued by you do not apply to the
                    Services, do not override or form a part of these Terms, and are void.
                </li>
                <li>
                    Independent Contractors. The relationship between you and Onepage.business is that of independent
                    contractors, and not legal partners, employees, or agents of each other.
                </li>
                <li>
                    Interpretation. The use of the terms “includes”, “including”, “such as”, and similar terms, will be
                    deemed not to limit what else might be included.
                </li>
                <li>
                    Precedence. To the extent any conflict exists, the Additional Terms prevail over this Terms &
                    Conditions with respect to the Services to which the Additional Terms apply.
                </li>
            </ol>
        </div>
    );
};
