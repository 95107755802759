import { connect } from 'react-redux';
import { actions } from '../../../../redux/modules/SG&A/SG&A';
import WizardSG from './WizardSG&A';

const ms2p = (state) => {
    return {
        ...state.sags,
        formly: state.formlyAll.item,
        setUpGeneral: state.setUpGeneral,
        formulario: null,
        login: state.login,
    };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(WizardSG);
