import React, { Component } from 'react';
import Loader from 'react-loader-spinner';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import iconLoader from '../../../../../assets/img/icon-loader.gif';


require('./LoadMask.css');

class LoadMask extends Component {
    static propTypes = {
        radius: PropTypes.bool,
        loading: PropTypes.bool.isRequired,
        dark: PropTypes.bool,
        blur: PropTypes.bool,
        light: PropTypes.bool,
    };

    static defaultProps = {
        radius: false,
        dark: false,
        blur: false,
        light: false,
    };

    render() {
        const {
            children, radius, dark, light, blur, loading, positionM = "relative", type = "Triangle",
        } = this.props;

        return (
            <div
                className="load-mask"
                style={{ position: positionM }}
            >
                {loading && (
                    <div
                        className={classnames('loader-container', {
                            radius,
                            dark,
                            light,
                        })}
                    >
                        <img src={iconLoader}/>
                        {/* <p>Cargando...</p> */}
                    </div>
                )}
                <div
                    className={classnames('load-mask-content', {
                        loading,
                        blur,
                    })}
                >
                    {children}
                </div>
            </div>
        );
    }
}

export default LoadMask;
