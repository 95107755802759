import PropTypes from 'prop-types';
import React, { Component } from 'react';
import {Link, Redirect} from 'react-router-dom';
import RegisterForm from './RegisterForm';
import LoadMask from "../../Utils/LoadMask/LoadMask";
import './register.css';
import logo from "../../../../../assets/img/logo.png";
import arrow_rigth from "../../../../../assets/img/arrow-rigth.png";

class Registro extends Component {
    static propTypes = {
        onSubmit: PropTypes.func.isRequired,
    };

    componentDidMount(props) {
        this.state = { prueba: true };
        // eslint-disable-next-line react/destructuring-assignment
        this.props.loadOptions();
    }

    render() {
        const { onSubmit, loader, suscriptions } = this.props;
        if (localStorage.getItem('token')) {
            return (<Redirect to="/" />);
        }
        return (
            <div className="blue-gradient-bg-register div-center">
                <div className="card card-login-register">
                    <div className="d-flex justify-content-start aling-items-center">
                        <img src={arrow_rigth} alt="logo" width={10} style={{ height: '15px' }}/>
                        <Link className="ml-2" to="/login">Back</Link>
                    </div>
                    <div className="d-flex justify-content-center">
                        <img src={logo} alt="logo" width={225} />
                    </div>
                    <LoadMask loading={loader} light>
                        <RegisterForm suscriptions={suscriptions} onSubmit={onSubmit} />
                    </LoadMask>
                </div>
            </div>
        );
    }
}

export default Registro;
