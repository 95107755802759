/* Official capitalized country names. */
// eslint-disable-next-line no-undef
export const OFFICIAL_COUNTRIES = [
    { value: 'AF', label: 'Afghanistan' },
    { value: 'AX', label: '\xc5land Islands' },
    { value: 'AL', label: 'Albania' },
    { value: 'DZ', label: 'Algeria' },
    { value: 'AS', label: 'American Samoa' },
    { value: 'AD', label: 'Andorra' },
    { value: 'AO', label: 'Angola' },
    { value: 'AI', label: 'Anguilla' },
    { value: 'AQ', label: 'Antarctica' },
    { value: 'AG', label: 'Antigua and Barbuda' },
    { value: 'AR', label: 'Argentina' },
    { value: 'AM', label: 'Armenia' },
    { value: 'AW', label: 'Aruba' },
    { value: 'AU', label: 'Australia' },
    { value: 'AT', label: 'Austria' },
    { value: 'AZ', label: 'Azerbaijan' },
    { value: 'BS', label: 'Bahamas' },
    { value: 'BH', label: 'Bahrain' },
    { value: 'BD', label: 'Bangladesh' },
    { value: 'BB', label: 'Barbados' },
    { value: 'BY', label: 'Belarus' },
    { value: 'BE', label: 'Belgium' },
    { value: 'BZ', label: 'Belize' },
    { value: 'BJ', label: 'Benin' },
    { value: 'BM', label: 'Bermuda' },
    { value: 'BT', label: 'Bhutan' },
    { value: 'BO', label: 'Bolivia' },
    { value: 'BQ', label: 'Bonaire, Sint Eustatius And Saba' },
    { value: 'BA', label: 'Bosnia And Herzegovina' },
    { value: 'BW', label: 'Botswana' },
    { value: 'BV', label: 'Bouvet Island' },
    { value: 'BR', label: 'Brazil' },
    { value: 'IO', label: 'British Indian Ocean Territory' },
    { value: 'BN', label: 'Brunei Darussalam' },
    { value: 'BG', label: 'Bulgaria' },
    { value: 'BF', label: 'Burkina Faso' },
    { value: 'BI', label: 'Burundi' },
    { value: 'KH', label: 'Cambodia' },
    { value: 'CM', label: 'Cameroon' },
    { value: 'CA', label: 'Canada' },
    { value: 'CV', label: 'Cape Verde' },
    { value: 'KY', label: 'Cayman Islands' },
    { value: 'CF', label: 'Central African Republic' },
    { value: 'TD', label: 'Chad' },
    { value: 'CL', label: 'Chile' },
    { value: 'CN', label: 'China' },
    { value: 'CX', label: 'Christmas Island' },
    { value: 'CC', label: 'Cocos (Keeling) Islands' },
    { value: 'CO', label: 'Colombia' },
    { value: 'KM', label: 'Comoros' },
    { value: 'CG', label: 'Congo' },
    { value: 'CD', label: 'Congo, The Democratic Republic of the' },
    { value: 'CK', label: 'Cook Islands' },
    { value: 'CR', label: 'Costa Rica' },
    { value: 'CI', label: "Cote D'ivoire" },
    { value: 'HR', label: 'Croatia' },
    { value: 'CU', label: 'Cuba' },
    { value: 'CW', label: 'Cura \xc7ao' },
    { value: 'CY', label: 'Cyprus' },
    { value: 'CZ', label: 'Czech Republic' },
    { value: 'DK', label: 'Denmark' },
    { value: 'DJ', label: 'Djibouti' },
    { value: 'DM', label: 'Dominica' },
    { value: 'DO', label: 'Dominican Republic' },
    { value: 'EC', label: 'Ecuador' },
    { value: 'EG', label: 'Egypt' },
    { value: 'SV', label: 'El Salvador' },
    { value: 'GQ', label: 'Equatorial Guinea' },
    { value: 'ER', label: 'Eritrea' },
    { value: 'EE', label: 'Estonia' },
    { value: 'ET', label: 'Ethiopia' },
    { value: 'FK', label: 'Falkland Islands (Malvinas)' },
    { value: 'FO', label: 'Faroe Islands' },
    { value: 'FJ', label: 'Fiji' },
    { value: 'FI', label: 'Finland' },
    { value: 'FR', label: 'France' },
    { value: 'GF', label: 'French Guiana' },
    { value: 'PF', label: 'French Polynesia' },
    { value: 'TF', label: 'French Southern Territories' },
    { value: 'GA', label: 'Gabon' },
    { value: 'GM', label: 'Gambia' },
    { value: 'GE', label: 'Georgia' },
    { value: 'DE', label: 'Germany' },
    { value: 'GH', label: 'Ghana' },
    { value: 'GI', label: 'Gibraltar' },
    { value: 'GR', label: 'Greece' },
    { value: 'GL', label: 'Greenland' },
    { value: 'GD', label: 'Grenada' },
    { value: 'GP', label: 'Guadeloupe' },
    { value: 'GU', label: 'Guam' },
    { value: 'GT', label: 'Guatemala' },
    { value: 'GG', label: 'Guernsey' },
    { value: 'GN', label: 'Guinea' },
    { value: 'GW', label: 'Guinea-Bissau' },
    { value: 'GY', label: 'Guyana' },
    { value: 'HT', label: 'Haiti' },
    { value: 'HM', label: 'Heard Island and Mcdonald Islands' },
    { value: 'VA', label: 'Holy See (Vatican City State)' },
    { value: 'HN', label: 'Honduras' },
    { value: 'HK', label: 'Hong Kong' },
    { value: 'HU', label: 'Hungary' },
    { value: 'IS', label: 'Iceland' },
    { value: 'IN', label: 'India' },
    { value: 'ID', label: 'Indonesia' },
    { value: 'IR', label: 'Iran, Islamic Republic Of' },
    { value: 'IQ', label: 'Iraq' },
    { value: 'IE', label: 'Ireland' },
    { value: 'IM', label: 'Isle of man' },
    { value: 'IL', label: 'Israel' },
    { value: 'IT', label: 'Italy' },
    { value: 'JM', label: 'Jamaica' },
    { value: 'JP', label: 'Japan' },
    { value: 'JE', label: 'Jersey' },
    { value: 'JO', label: 'Jordan' },
    { value: 'KZ', label: 'Kazakhstan' },
    { value: 'KE', label: 'Kenya' },
    { value: 'KI', label: 'Kiribati' },
    { value: 'KP', label: "Korea, Democratic People's Republic of" },
    { value: 'KR', label: 'Korea, Republic of' },
    { value: 'KW', label: 'Kuwait' },
    { value: 'KG', label: 'Kyrgyzstan' },
    { value: 'LA', label: "Lao People's Democratic Republic" },
    { value: 'LV', label: 'Latvia' },
    { value: 'LB', label: 'Lebanon' },
    { value: 'LS', label: 'Lesotho' },
    { value: 'LR', label: 'Liberia' },
    { value: 'LY', label: 'Libya' },
    { value: 'LI', label: 'Liechtenstein' },
    { value: 'LT', label: 'Lithuania' },
    { value: 'LU', label: 'Luxembourg' },
    { value: 'MO', label: 'Macao' },
    { value: 'MK', label: 'Macedonia, The Former Yugoslav Republic of' },
    { value: 'MG', label: 'Madagascar' },
    { value: 'MW', label: 'Malawi' },
    { value: 'MY', label: 'Malaysia' },
    { value: 'MV', label: 'Maldives' },
    { value: 'ML', label: 'Mali' },
    { value: 'MT', label: 'Malta' },
    { value: 'MH', label: 'Marshall Islands' },
    { value: 'MQ', label: 'Martinique' },
    { value: 'MR', label: 'Mauritania' },
    { value: 'MU', label: 'Mauritius' },
    { value: 'YT', label: 'Mayotte' },
    { value: 'MX', label: 'Mexico' },
    { value: 'FM', label: 'Micronesia, Federated States of' },
    { value: 'MD', label: 'Moldova, Republic of' },
    { value: 'MC', label: 'Monaco' },
    { value: 'MN', label: 'Mongolia' },
    { value: 'ME', label: 'Montenegro' },
    { value: 'MS', label: 'Montserrat' },
    { value: 'MA', label: 'Morocco' },
    { value: 'MZ', label: 'Mozambique' },
    { value: 'MM', label: 'Myanmar' },
    { value: 'NA', label: 'Namibia' },
    { value: 'NR', label: 'Nauru' },
    { value: 'NP', label: 'Nepal' },
    { value: 'NL', label: 'Netherlands' },
    { value: 'NC', label: 'New Caledonia' },
    { value: 'NZ', label: 'New Zealand' },
    { value: 'NI', label: 'Nicaragua' },
    { value: 'NE', label: 'Niger' },
    { value: 'NG', label: 'Nigeria' },
    { value: 'NU', label: 'Niue' },
    { value: 'NF', label: 'Norfolk Island' },
    { value: 'MP', label: 'Northern Mariana Islands' },
    { value: 'NO', label: 'Norway' },
    { value: 'OM', label: 'Oman' },
    { value: 'PK', label: 'Pakistan' },
    { value: 'PW', label: 'Palau' },
    { value: 'PS', label: 'Palestinian Territory, Occupied' },
    { value: 'PA', label: 'Panama' },
    { value: 'PG', label: 'Papua New Guinea' },
    { value: 'PY', label: 'Paraguay' },
    { value: 'PE', label: 'Peru' },
    { value: 'PH', label: 'Philippines' },
    { value: 'PN', label: 'Pitcairn' },
    { value: 'PL', label: 'Poland' },
    { value: 'PT', label: 'Portugal' },
    { value: 'PR', label: 'Puerto Rico' },
    { value: 'QA', label: 'Qatar' },
    { value: 'RE', label: 'Reunion' },
    { value: 'RO', label: 'Romania' },
    { value: 'RU', label: 'Russian Federation' },
    { value: 'RW', label: 'Rwanda' },
    { value: 'BL', label: 'Saint Barthlemy' },
    { value: 'SH', label: 'Saint Helena, Ascension And TRistan Da Cunha' },
    { value: 'KN', label: 'Saint Kitts and Nevis' },
    { value: 'LC', label: 'Saint Lucia' },
    { value: 'MF', label: 'Saint Martin (French Part)' },
    { value: 'PM', label: 'Saint Pierre And Miquelon' },
    { value: 'VC', label: 'Saint Vincent And The Grenadines' },
    { value: 'WS', label: 'Samoa' },
    { value: 'SM', label: 'San Marino' },
    { value: 'ST', label: 'Sao Tome and Principe' },
    { value: 'SA', label: 'Saudi Arabia' },
    { value: 'SN', label: 'Senegal' },
    { value: 'RS', label: 'Serbia' },
    { value: 'SC', label: 'Seychelles' },
    { value: 'SL', label: 'Sierra Leone' },
    { value: 'SG', label: 'Singapore' },
    { value: 'SX', label: 'Sint Maarten (Dutch Part)' },
    { value: 'SK', label: 'Slovakia' },
    { value: 'SI', label: 'Slovenia' },
    { value: 'SB', label: 'Solomon Islands' },
    { value: 'SO', label: 'Somalia' },
    { value: 'ZA', label: 'South Africa' },
    { value: 'GS', label: 'South Georgia and the South Sandwich Islands' },
    { value: 'SS', label: 'South Sudan' },
    { value: 'ES', label: 'Spain' },
    { value: 'LK', label: 'Sri Lanka' },
    { value: 'SD', label: 'Sudan' },
    { value: 'SR', label: 'Suriname' },
    { value: 'SJ', label: 'Svalbard and Jan Mayen' },
    { value: 'SZ', label: 'Swaziland' },
    { value: 'SE', label: 'Sweden' },
    { value: 'CH', label: 'Switzerland' },
    { value: 'SY', label: 'Syrian Arab Republic' },
    { value: 'TW', label: 'Taiwan, Province of China' },
    { value: 'TJ', label: 'Tajikistan' },
    { value: 'TZ', label: 'Tanzania, United Republic of' },
    { value: 'TH', label: 'Thailand' },
    { value: 'TL', label: 'Timor-Leste' },
    { value: 'TG', label: 'Togo' },
    { value: 'TK', label: 'Tokelau' },
    { value: 'TO', label: 'Tonga' },
    { value: 'TT', label: 'Trinidad and Tobago' },
    { value: 'TN', label: 'Tunisia' },
    { value: 'TR', label: 'Turkey' },
    { value: 'TM', label: 'Turkmenistan' },
    { value: 'TC', label: 'Turks and Caicos Islands' },
    { value: 'TV', label: 'Tuvalu' },
    { value: 'UG', label: 'Uganda' },
    { value: 'UA', label: 'Ukraine' },
    { value: 'AE', label: 'United Arab Emirates' },
    { value: 'GB', label: 'United Kingdom' },
    { value: 'US', label: 'United States' },
    { value: 'UM', label: 'United States Minor Outlying Islands' },
    { value: 'UY', label: 'Uruguay' },
    { value: 'UZ', label: 'Uzbekistan' },
    { value: 'VU', label: 'Vanuatu' },
    { value: 'VE', label: 'Venezuela' },
    { value: 'VN', label: 'Vietnam' },
    { value: 'VG', label: 'Virgin Islands, British' },
    { value: 'VI', label: 'Virgin Islands, U.S.' },
    { value: 'WF', label: 'Wallis and Futuna' },
    { value: 'EH', label: 'Western Sahara' },
    { value: 'YE', label: 'Yemen' },
    { value: 'ZM', label: 'Zambia' },
    { value: 'ZW', label: 'Zimbabwe' },
];

export const PERMSSIONS = [
    { id: 1, value: 'Read only' },
    { id: 2, value: 'Update' },
    { id: 3, value: 'Admin' },
];

export const CURRENCY_ITEMS = [
    {
        symbol: "AU$",
        label: "AUD - Australian Dollar",
        symbol_native: "$",
        decimal_digits: 2,
        rounding: 0,
        value: "AUD",
        name_plural: "Australian dollars",
    },
    {
        symbol: "R$",
        label: "BRL - Brazilian Real",
        symbol_native: "R$",
        decimal_digits: 2,
        rounding: 0,
        value: "BRL",
        name_plural: "Brazilian reals",
    },
    {
        symbol: "CA$",
        label: "CAD - Canadian Dollar",
        symbol_native: "$",
        decimal_digits: 2,
        rounding: 0,
        value: "CAD",
        name_plural: "Canadian dollars",
    },
    {
        symbol: "CHF",
        label: "CHF - Swiss Franc",
        symbol_native: "CHF",
        decimal_digits: 2,
        rounding: 0.05,
        value: "CHF",
        name_plural: "Swiss francs",
    },
    {
        symbol: "CL$",
        label: "CLP - Chilean Peso",
        symbol_native: "$",
        decimal_digits: 0,
        rounding: 0,
        value: "CLP",
        name_plural: "Chilean pesos",
    },
    {
        symbol: "CN¥",
        label: "CNY - Chinese Yuan",
        symbol_native: "CN¥",
        decimal_digits: 2,
        rounding: 0,
        value: "CNY",
        name_plural: "Chinese yuan",
    },
    {
        symbol: "€",
        label: "EUR - European Euro",
        symbol_native: "€",
        decimal_digits: 2,
        rounding: 0,
        value: "EUR",
        name_plural: "euros",
    },
    {
        symbol: "£",
        label: "GBP - British Pound Sterling",
        symbol_native: "£",
        decimal_digits: 2,
        rounding: 0,
        value: "GBP",
        name_plural: "British pounds sterling",
    },
    {
        symbol: "HK$",
        label: "HKD - Hong Kong Dollar",
        symbol_native: "$",
        decimal_digits: 2,
        rounding: 0,
        value: "HKD",
        name_plural: "Hong Kong dollars",
    },
    {
        symbol: "Rs",
        label: "INR - Indian Rupee",
        symbol_native: "টকা",
        decimal_digits: 2,
        rounding: 0,
        value: "INR",
        name_plural: "Indian rupees",
    },
    {
        symbol: "¥",
        label: "JPY - Japanese Yen",
        symbol_native: "￥",
        decimal_digits: 0,
        rounding: 0,
        value: "JPY",
        name_plural: "Japanese yen",
    },
    {
        symbol: "₩",
        label: "KRW - South Korean Won",
        symbol_native: "₩",
        decimal_digits: 0,
        rounding: 0,
        value: "KRW",
        name_plural: "South Korean won",
    },
    {
        symbol: "MX$",
        label: "MXN - Mexican Peso",
        symbol_native: "$",
        decimal_digits: 2,
        rounding: 0,
        value: "MXN",
        name_plural: "Mexican pesos",
    },
    {
        symbol: "Nkr",
        label: "NOK - Norwegian Krone",
        symbol_native: "kr",
        decimal_digits: 2,
        rounding: 0,
        value: "NOK",
        name_plural: "Norwegian kroner",
    },
    {
        symbol: "NZ$",
        label: "NZD - New Zealand Dollar",
        symbol_native: "$",
        decimal_digits: 2,
        rounding: 0,
        value: "NZD",
        name_plural: "New Zealand dollars",
    },
    {
        symbol: "RUB",
        label: "RUB - Russian Ruble",
        symbol_native: "руб.",
        decimal_digits: 2,
        rounding: 0,
        value: "RUB",
        name_plural: "Russian rubles",
    },
    {
        symbol: "Skr",
        label: "SEK - Swedish Krona",
        symbol_native: "kr",
        decimal_digits: 2,
        rounding: 0,
        value: "SEK",
        name_plural: "Swedish kronor",
    },
    {
        symbol: "S$",
        label: "SGD - Singapore Dollar",
        symbol_native: "$",
        decimal_digits: 2,
        rounding: 0,
        value: "SGD",
        name_plural: "Singapore dollars",
    },
    {
        symbol: "TL",
        label: "TRY - Turkish Lira",
        symbol_native: "TL",
        decimal_digits: 2,
        rounding: 0,
        value: "TRY",
        name_plural: "Turkish Lira",
    },
    {
        symbol: "$",
        label: " USD - US dollar",
        symbol_native: "$",
        decimal_digits: 2,
        rounding: 0,
        value: "USD",
        name_plural: "US dollars",
    },
    {
        symbol: "R",
        label: "ZAR - South African Rand",
        symbol_native: "R",
        decimal_digits: 2,
        rounding: 0,
        value: "ZAR",
        name_plural: "South African rand",
    },
];


export const ARREGLO_COLORES = [
    { label: "Red", value: "#F44336" },
    { label: "Pink", value: "#E91E63" },
    { label: "Purple", value: "#9C27B0" },
    { label: "Deep purple", value: "#673AB7" },
    { label: "Indigo", value: "#3F51B5" },
    { label: "Blue", value: "#2196F3" },
    { label: "Light blue", value: "#03A9F4" },
    { label: "Cyan", value: "#00BCD4" },
    { label: "Teal", value: "#009688" },
    { label: "Green", value: "#4CAF50" },
    { label: "Light green", value: "#8BC34A" },
    { label: "Lime", value: "#CDDC39" },
    { label: "Yellow", value: "#FFEB3B" },
    { label: "Amber", value: "#FFC107" },
    { label: "Orange", value: "#FF9800" },
    { label: "Deep orange", value: "#FF5722" },
    { label: "Brown", value: "#795548" },
    { label: "Gray", value: "#9E9E9E" },
    { label: "Blue gray", value: "#607D8B" },
];

export const VAL_ITEMS = [
    { id: 1, value: "Years (Y)", label: 'Years (Y)' },
    { id: 2, value: "Quarters (Q)", label: 'Quarters (Q)' },
    { id: 3, value: "Months (M)", label: 'Months (M)' },
];

export const VAL_ORDER = [
    { value: 1, label: "By Name" },
    { value: 2, label: "By Sales" },
    { value: 3, label: "By Finished" },
    { value: 4, label: "By User" },
    { value: 5, label: "By IRR" },
];

export const LEVELITEMS = [
    { id: 1, value: 1, label: 'Administration/Other', name: 'Administration/Other'},
    { id: 2, value: 2, label: 'Branch Manager', name: 'Branch Manager',},
    { id: 3, value: 3, label: 'Business Manager', name: 'Business Manager',},
    { id: 4, value: 4, label: 'CEO/General Manager', name: 'CEO/General Manager' },
    { id: 5, value: 5, label: 'Department Manager', name: 'Department Manager'},
    { id: 6, value: 6, label: 'Engineering', name: 'Engineering'},
    { id: 7, value: 7, label: 'Finance/Other', name: 'Finance/Other'},
    { id: 8, value: 8, label: 'Head of Department / Supervisor', name: 'Head of Department / Superviso' },
    { id: 9, value: 9, label: 'Information Technology', name: 'Information Technology' },
    { id: 10, value: 10, label: 'Managers/Directors', name: 'Managers/Directors' },
    { id: 11, value: 11, label: 'Office Supervisor', name: 'Office Supervisor'},
    { id: 12, value: 12, label: 'Operations / Plant', name: 'Operations / Plant' },
    { id: 13, value: 13, label: 'Personel / Human Resources', name: 'Personel / Human Resources' },
    { id: 14, value: 14, label: 'Professional', name: 'Professional' },
    { id: 15, value: 15, label: 'Sales/Marketing', name: 'Sales/Marketing' },
    { id: 16, value: 16, label: 'Staff/Assistants', name: 'Staff/Assistants' },
    { id: 17, value: 17, label: 'Systems Analysts/Specialists', name: 'Systems Analysts/Specialists' },
    { id: 18, value: 18, label: 'Technical', name: 'Technical' },
    { id: 19, value: 19, label: 'Vice President', name: 'Vice President' },
];
