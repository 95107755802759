import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import WizardReport from "../ProjectContainer/Report";

class ProjectReport extends Component {
    render() {
        const { idProject } = this.props.location;
        return (
            <div className="div-border-round m-4">
                {idProject ? (
                    <WizardReport previousPage={() => {}} onSubmit={() => {}} idProject={idProject} nombreV={true} />
                ) : (<Redirect to="/" />)}
            </div>
        );
    }
}
export default ProjectReport;
