import React, { PureComponent } from 'react';
import moment from "moment";
import dashReportV from "../../../../assets/img/dashReportV.png";
import dashFinishA from "../../../../assets/img/dashFinishA.png";
import dashUpdateA from "../../../../assets/img/dashUpdateA.png";
import dashDeletA from "../../../../assets/img/dashDeletA.png";
import dashView from "../../../../assets/img/dashView.png";
import dashReopen from "../../../../assets/img/dashReopen.png";
import { Link } from "react-router-dom";
import { ARREGLO_COLORES } from "../../../utility/contry";
import Select from "react-select";
import classNames from "classnames";

const typoForm = (e) => {
    localStorage.setItem('typeForm', e);
};


const monedaFormat = (num) => parseFloat(num).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');

    const dot = (color = '#ccc') => ({
        alignItems: 'center',
        display: 'flex',

        ':before': {
            backgroundColor: color,
            borderRadius: 0,
            content: '" "',
            display: 'block',
            marginRight: 8,
            height: 10,
            width: 10,
        },
    });

    const colourStyles = {
        option: (styles, { data, isDisabled, isFocused, isSelected, invalid }) => {
            const color = data.value;
            return {
                ...styles,
                control: styles => ({ ...styles, border: !invalid ? '1px solid #959595': '1px solid #ff0000' }),
                backgroundColor: isDisabled ? null : isSelected ? data.value : isFocused ? color : null,
                color: isDisabled ? 'white' : isSelected ? 'white' : isFocused ? 'black' : data.value,
                cursor: isDisabled ? 'not-allowed' : 'default',
                ':active': {
                    ...styles[':active'],
                    backgroundColor: !isDisabled && (isSelected ? data.value : color),
                },
                borderRadius: "0",
            };
        },
        menu: styles => ({ ...styles, borderRadius: "0", width: "125px" }),
    };

class CardContainer extends React.Component {
    items = [
        {
            text: 'Display Settings'
        },
        {
            text: 'System Settings'
        },
        {
            text: 'Additional Settings'
        }
    ];

    data = (val) => {
        this.props.setEditForm(val);
        this.props.setItem(val.cogs_list);
    };

    render() {
        const { data, permission, deletProject, updateProject, me } = this.props;
        return (
            <div className="row" >
                {
                    //data.permission
                    data && data.map((val, index) => (
                        <React.Fragment key={index}>
                            <div className="col-xs-12 col-md-12 col-lg-6 col-xl-4 min-div" >
                                <div className="div-project border-card mt-4 min-div-h" >
                                    <div style={{ backgroundColor: `${val.color}`}} className="card-name py-4 px-4 form-row delet-m">
                                        <div className="form-group col-md-6 my-auto" >
                                            <div className="texto-negrita t-name">{val.name}</div>
                                        </div>
                                        <div className="form-group col-md-6 my-auto" >
                                            <div><span className="texto-negrita">Investment: </span>{val.dashboard ? `${val.dashboard.total_investment}`: "$ ----------"}</div>
                                            <div><span className="texto-negrita">Owner: </span>{val.owner ? val.owner: "----------"}</div>
                                        </div>
                                        <Select
                                            className={classNames('select-containerS')}
                                            classNamePrefix={classNames('select-containerS')}
                                            menuPlacement="auto"
                                            isSearchable={false}
                                            backspaceRemovesValue={false}
                                            options={ARREGLO_COLORES}
                                            placeholder=""
                                            onChange={(e) => {
                                                updateProject("proyectos/editproycolor/", { idproy: val.id, color: e.value }, me.id);
                                            }}
                                            value=""
                                            styles={colourStyles}
                                            components={{ DropdownIndicator:() => null }}
                                        />
                                    </div>
                                    <div className="pt-3 px-4 form-row delet-m">
                                        <div className="form-group col-6" >
                                            <div className="texto-negrita text-marino">Project Info</div><br/>
                                            <div>NPV: {val.dashboard ? `${monedaFormat(val.dashboard.project.npv)}`: "$ ----------"}</div>
                                            <div>IRR: {monedaFormat(val.annual_rate_base)}%</div>
                                        </div>
                                        <div className="form-group col-6" >
                                            <br/><br/>
                                            <div>Created: {moment(val.created).format("DD/MM/YY")}</div>
                                            <div>Currency: {val.main_currency}</div>
                                        </div>
                                    </div>
                                    <div className="linea-gris" />
                                    <div className="px-4 form-row delet-m">
                                        <div className="form-group col-6" >
                                            <div className="texto-negrita text-marino">Sales</div>
                                            <div>Average: {val.dashboard ? `$ ${monedaFormat(val.dashboard.sales.avg)}`: "$ ----------"}</div>
                                            <div>Horizon: {val.horizon} year</div>
                                            <div>CAGR: {val.dashboard ? `${monedaFormat(val.dashboard.sales.cagr_value)} (%)`: "----------"}</div>
                                            <div>Min. value: {val.dashboard ? `${monedaFormat(val.dashboard.sales.min_value)}`: "----------"}</div>
                                            <div>Max value: {val.dashboard ? `${monedaFormat(val.dashboard.sales.max_value)}`: "----------"}</div>
                                        </div>
                                        <div className="form-group col-6" >
                                            <div className="texto-negrita text-marino">Gross profit</div>
                                            <div>Average: {val.dashboard ? `$ ${monedaFormat(val.dashboard.g_profit.avg)}`: "$ ----------"}</div>
                                            <div>Horizon: {val.horizon} year</div>
                                            <div>CAGR: {val.dashboard ? `${monedaFormat(val.dashboard.g_profit.cagr_value)} (%)`: "----------"}</div>
                                            <div>Min. value: {val.dashboard ? `${monedaFormat(val.dashboard.g_profit.min_value)}`: "----------"}</div>
                                            <div>Max value: {val.dashboard ? `${monedaFormat(val.dashboard.g_profit.max_value)}`: "----------"}</div>
                                        </div>
                                    </div>
                                    <div className="linea-gris" />
                                    <div className="pb-3 px-4 form-row delet-m">
                                        <div className="form-group col-6" >
                                            <div className="texto-negrita text-marino">EBITDA</div>
                                            <div>Average: {val.dashboard ? `$ ${monedaFormat(val.dashboard.ebitda.avg)}`: "$ ----------"}</div>
                                            <div>Horizon: {val.horizon} year</div>
                                            <div>CAGR: {val.dashboard ? `${monedaFormat(val.dashboard.ebitda.cagr_value)} (%)`: "----------"}</div>
                                            <div>Min. value: {val.dashboard ? `${monedaFormat(val.dashboard.ebitda.min_value)}`: "----------"}</div>
                                            <div>Max value: {val.dashboard ? `${monedaFormat(val.dashboard.ebitda.max_value)}`: "----------"}</div>
                                        </div>
                                        <div className="form-group col-6" >
                                            <div className="texto-negrita text-marino">Gross margin</div>
                                            <div>Average: {val.dashboard ? `$ ${monedaFormat(val.dashboard.g_margin.avg)} (%)`: "$ ----------"}</div>
                                            <div>Horizon: {val.horizon} year</div>
                                            <div>CAGR: {val.dashboard ? `${monedaFormat(val.dashboard.g_margin.cagr_value)} (%)`: "----------"}</div>
                                            <div>Min. value: {val.dashboard ? `${monedaFormat(val.dashboard.g_margin.min_value)} (%)`: "----------"}</div>
                                            <div>Max value: {val.dashboard ? `${monedaFormat(val.dashboard.g_margin.max_value)} (%)`: "----------"}</div>
                                        </div>
                                    </div>
                                    <div style={{ backgroundColor: `#D5DBDB`}} className="card-botom py-3 px-4 form-row delet-m div-center">
                                        <div className="car-actions" >
                                            <Link onClick={() => (this.data(val))} to={{pathname: `/project-report`, idProject: val.id, estado: val.id }} >
                                                <img src={dashReportV} alt="dashReportV" className="imagenDash" width="40%" /><br/>Report
                                            </Link>
                                        </div>
                                        {
                                            (me.profile || permission == 3) && (
                                                <React.Fragment>
                                                    <div className="linea-gris-vertical" />
                                                    <div className="car-actions" onClick={() => updateProject("proyectos/editfinish/", { idproy: val.id, finish: !val.finish, finish_on: new Date() }, me.id)}>
                                                        <img src={val.finish ? dashReopen: dashFinishA} alt="dashReportV" className="imagenDash" width="40%" />
                                                        <br/>{ val.finish ? "Reopen": "Finish" }
                                                    </div>
                                                </React.Fragment>
                                            )
                                        }
                                        {
                                            me.profile ? (
                                                <React.Fragment>
                                                    <div className="linea-gris-vertical" />
                                                    <div className="car-actions" >
                                                        <Link onClick={() => (typoForm(val.type))} to={`/project-create/${val.id}/${val.type}`}>
                                                            <img src={(me.profile.subscription !== val.subscription || val.finish || data.permission === 1) ? dashView: dashUpdateA} alt="dashReportV" className="imagenDash" width="40%" />
                                                            <br/>{ (me.profile.subscription !== val.subscription || val.finish || data.permission === 1) ? "View": "Update" }
                                                        </Link>
                                                    </div>
                                                </React.Fragment>
                                            ) : (
                                                <React.Fragment>
                                                    <div className="linea-gris-vertical" />
                                                    <div className="car-actions" >
                                                        <Link onClick={() => (typoForm(val.type))} to={`/project-create/${val.id}/${val.type}`}>
                                                            <img src={(val.finish || permission == 1) ? dashView: dashUpdateA} alt="dashReportV" className="imagenDash" width="40%" />
                                                            <br/>{ (val.finish  || permission == 1) ? "View": "Update" }
                                                        </Link>
                                                    </div>
                                                </React.Fragment>
                                            )
                                        }
                                        {
                                            (me.profile || permission == 3) && (
                                                <React.Fragment>
                                                    <div className="linea-gris-vertical" />
                                                    <div className="car-actions" onClick={() => deletProject(val.id, me.id)} >
                                                        <img src={dashDeletA} alt="dashReportV" className="imagenDash" width="40%" /><br/>Delete
                                                    </div>
                                                </React.Fragment>
                                            )
                                        }
                                    </div>
                                </div>
                            </div>
                        </React.Fragment>
                    ))
                }
            </div>
        );
    }
}
export default (CardContainer);
