import React from 'react';
import {
    Route,
    Switch,
} from 'react-router-dom';
import { NotificationContainer } from 'react-notifications';
import { Login, Profile, Registro, Readytogo } from './common/components/LoginRegister';
import ResetPassword from "./common/components/LoginRegister/ResetPassword";
import ConfirmResetPassword from "./common/components/LoginRegister/ConfirmResetPassword";
import Inicio from './common/components/Inicio';
import ProjectContainer from './common/components/ProjectContainer';
import ProjectSelectContainer from './common/components/ProjectSelectContainer';
import ProjectReport from "./common/components/Inicio/Report";
import ProtectedRoute from './ProtectedRoute';
import NotFound from './common/components/layout/NotFound/NotFound';
import '../assets/fonts/fonts.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Policy } from "./common/components/layout/Policy";
import { Terms } from "./common/components/layout/Terms";

require('../../node_modules/font-awesome/css/font-awesome.css');
require('../../node_modules/bootstrap/dist/css/bootstrap.css');
require('../style/index.css');

module.exports = (
    <div>
        <div className="container__content">
            <Switch>
                <Route exact path="/login" component={Login} />
                <Route exact path="/login/:token" component={Login} />
                <Route exact path="/reset-password" component={ResetPassword} />
                <Route exact path="/reset/:uid/:token" component={ConfirmResetPassword} />
                <Route exact path="/register" component={Registro} />
                <Route exact path="/readytogo" component={Readytogo} />
                <Route exact path="/agreement" component={Terms} />
                <ProtectedRoute exact path="/policy" component={Policy} />
                <ProtectedRoute exact path="/terms" component={Terms} />
                <ProtectedRoute exact path="/" component={Inicio} />
                <ProtectedRoute exact path="/project-select" component={ProjectSelectContainer} />
                <ProtectedRoute exact path="/project-report" component={ProjectReport} />
                <ProtectedRoute exact path="/project-create/:tipo" component={ProjectContainer} />
                <ProtectedRoute exact path="/project-create/:idProject/:tipo" component={ProjectContainer} />
                <ProtectedRoute exact path="/user-profile" component={Profile} />
                <ProtectedRoute exact path="/user-profile/payment" component={Profile} />
                <ProtectedRoute exact path="/user_confirm" component={Profile} />
                <ProtectedRoute exact path="/user_cancel" component={Profile} />
                <Route component={NotFound} />
            </Switch>
        </div>
        <NotificationContainer />
    </div>
);
