import React, { Component } from 'react';
import { connect } from 'react-redux';
import { TableHeaderColumn } from "react-bootstrap-table";
import icono from "../../../../../assets/img/product-C.png";
import Grid from "../../Utils/Grid";
import ModalProductos from "./ModalProducts";
import ModalTotalSales from "./ModalTotalSales";
import ModalTotalSalesProduct from  "./ModalTotalSalesProduct"
import { standardActions } from "../../Utils/Grid/StandardActions";
import "./productos.css";
import arrayTabs from "./arryTabs";
import ReactTooltip from "react-tooltip";
import felchaI from "../../../../../assets/img/flecha-izquierda.png";
import felchaD from "../../../../../assets/img/flecha-derecha.png";
import {RenderNumberGrid} from  "../../Utils/renderField/renderReadField";
import Swal from "sweetalert2";

const initialValue = {
    monthlydistribution: [{value: 8}, {value: 8}, {value: 8}, {value: 8}, {value: 8}, {value: 8}, {value: 8}, {value: 8}, {value: 8}, {value: 8}, {value: 10}, {value: 10}],
    monthlysales: [{value: 8}, {value: 8}, {value: 8}, {value: 8}, {value: 8}, {value: 8}, {value: 8}, {value: 8}, {value: 8}, {value: 8}, {value: 10}, {value: 10}],
    salescollection: [{value: 100}, {value: 0}, {value: 0}, {value: 0}, {value: 0}, {value: 0}, {value: 0}, {value: 0}, {value: 0}, {value: 0}, {value: 0}, {value: 0}, {value: 0}],
    // initial_customer_stock: 0.00,
    // new_customers: 0.00,
    // customer_growth: 0.00,
    // annual_attrition: 0.00,
    // active_customers: 0.00,
    // base_price: 0.00,
    // annual_growthp: 0.00,
    // annual_discount: 0.00,
    // base_volume: 0.00,
    // annual_growthv: 0.00,
};

class WizardGeography extends Component {

    state = {
        open: false,
        openM: false,
        openTotalSales: false,
        openTotalSalesProduct: false,
        idProject: 0,
        inflation: 0,
        analysis_unit: 0,
        salesGrowthRate: false,
        custAttrition: false,
        activeCustomer: false,
        priceDiscount: false,
        moneda: "GTQ",
        monedaMainCur: "GTQ",
        data: {
            count: 0,
            results: [],
        },
        arrayTabsModal: arrayTabs,
        initialValues: initialValue,
        finish: false,
        nProducts: 0,
    };

    dataForm=[];

    componentDidMount() {
        const idProject = this.props.setUpGeneral.idForm;
        const formSaveData = this.props.setUpGeneral.formDataSave;
        const geographyList = this.props.setUpGeneral.geographyList;
        const inflation = formSaveData ? formSaveData.inflation: 0;
        const analysis_unit = formSaveData ? formSaveData.proyinfo.analisis_units: 0;
        this.setState({ analysis_unit });
        this.setState({ inflation });
        this.setState({ idProject });
        this.props.leer("products/get_productos", idProject);

        this.setState({ monedaMainCur: formSaveData && formSaveData.proyinfo.main_currency })
        this.setState({ moneda: formSaveData && formSaveData.proyinfo.sales_currency }, () => {
            const arrayTabsModal = arrayTabs;
            arrayTabsModal[0].inputs[1].data= geographyList || [];
            arrayTabsModal[2].inputs[0].label= `Base price <${this.state.moneda}>`;
            this.setState({ arrayTabsModal });
        });
        const context = this;
        window.onclick = function(event) {
            if (!event.target.matches('.btnM')) context.setState({ openM: false})
        }
    }

    componentDidUpdate() {
        ReactTooltip.rebuild();
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.setUpGeneral && nextProps.setUpGeneral.geographyList) {
            const arrayTabsModal = this.state.arrayTabsModal;
            arrayTabsModal[0].inputs[1].data= nextProps.setUpGeneral.geographyList || [];
            this.setState({ arrayTabsModal });
        }

        if (nextProps.item && nextProps.item.count > 0) {
            const data = {
                count: nextProps.item.count,
                results: [],
            };

            const datA = nextProps.item.results;
            _.forEach(datA, (itemT) => {
                _.forEach(itemT.segment, (itemSeg) => {
                    _.forEach(itemSeg.psegment, (itemProd) => {
                        const itemData = itemProd;
                        itemData.monthlydistribution = [];
                        itemData.monthlydistribution.push(itemProd.monthlyvalues[0]);
                        itemData.monthlydistribution.push(itemProd.monthlyvalues[1]);
                        itemData.monthlydistribution.push(itemProd.monthlyvalues[2]);
                        itemData.monthlydistribution.push(itemProd.monthlyvalues[3]);
                        itemData.monthlydistribution.push(itemProd.monthlyvalues[4]);
                        itemData.monthlydistribution.push(itemProd.monthlyvalues[5]);
                        itemData.monthlydistribution.push(itemProd.monthlyvalues[6]);
                        itemData.monthlydistribution.push(itemProd.monthlyvalues[7]);
                        itemData.monthlydistribution.push(itemProd.monthlyvalues[8]);
                        itemData.monthlydistribution.push(itemProd.monthlyvalues[9]);
                        itemData.monthlydistribution.push(itemProd.monthlyvalues[10]);
                        itemData.monthlydistribution.push(itemProd.monthlyvalues[11]);

                        itemData.monthlysales = [];
                        itemData.monthlysales.push(itemProd.monthlyvalues[12]);
                        itemData.monthlysales.push(itemProd.monthlyvalues[13]);
                        itemData.monthlysales.push(itemProd.monthlyvalues[14]);
                        itemData.monthlysales.push(itemProd.monthlyvalues[15]);
                        itemData.monthlysales.push(itemProd.monthlyvalues[16]);
                        itemData.monthlysales.push(itemProd.monthlyvalues[17]);
                        itemData.monthlysales.push(itemProd.monthlyvalues[18]);
                        itemData.monthlysales.push(itemProd.monthlyvalues[19]);
                        itemData.monthlysales.push(itemProd.monthlyvalues[20]);
                        itemData.monthlysales.push(itemProd.monthlyvalues[21]);
                        itemData.monthlysales.push(itemProd.monthlyvalues[22]);
                        itemData.monthlysales.push(itemProd.monthlyvalues[23]);


                        itemData.salescollection = itemProd.salescollection;
                        delete itemData.salescollection[13];
                        itemData.segment = itemSeg.id;
                        this.dataForm[itemProd.id] = itemData;
                        this.dataForm[itemProd.id].segment = _.find(arrayTabs[0].inputs[1].data, { id: this.dataForm[itemProd.id].segment } );
                        data.results.push({
                            id: itemProd.id,
                            name: itemProd.name,
                            territory: { segment: itemSeg.name, territory: itemT.name },
                            customer_growth: itemProd.customer_growth,
                            annual_attrition: itemProd.annual_attrition,
                            active_customers: itemProd.active_customers,
                            base_price: itemProd.base_price,
                            annual_growthp: itemProd.annual_growthp,
                            annual_discount: itemProd.annual_discount,
                            base_volume: itemProd.base_volume,
                            annual_growthv: itemProd.annual_growthv,
                            attrition: itemProd.attrition,
                            new_customer: itemProd.new_customers,
                            new_customers: {
                                new_customers: itemProd.new_customers,
                                initial_customer_stock: itemProd.initial_customer_stock,
                            },
                            totalsales: {
                                totalsales: itemProd.totalsales,
                                unitarysales: itemProd.unitarysales,
                                salesbycustomer: itemProd.salesbycustomer,
                            },
                        });
                    });
                });
            });

            data.count = data.results.length;
            this.setState({ data });
        } else this.setState({ data: { count: 0, results: [],} });


        if (nextProps !== nextContext) {
            if (nextProps.formly && nextProps.formly.length > 0) {
                nextProps.formly.forEach((val) => {
                    if (val.key === "SALES_GROWTH_RATE") {
                        this.setState({ salesGrowthRate: val.value === "true" });
                    } else if (val.key === "CUSTOMER_ATTRITION") {
                        this.setState({ custAttrition: val.value === "true" });
                    } else if (val.key === "ACTIVE_CUSTOMER") {
                        this.setState({ activeCustomer: val.value === "true" });
                    } else if (val.key === "PRICE_DISCOUNT") {
                        this.setState({ priceDiscount: val.value === "true" });
                    } else if (val.key === "N_PRODUCTS_BY_PROJECT") {
                        this.setState({ nProducts: val.value });
                    }
                });
            }
        }
        if (nextProps.setUpGeneral && nextProps.setUpGeneral.formDataSave && nextProps.setUpGeneral.formDataSave.proyinfo) {
            let finish = nextProps.setUpGeneral.formDataSave.proyinfo.finish;
            if (nextProps.login.me.user_profile && nextProps.login.me.user_profile.permission === 1) finish = true;
            this.setState({ finish: finish });
        }
    }

    render() {
        const onOpenModal = () => this.setState({ initialValues: initialValue }, () => this.setState({ open: true }));

        const onOpenModalN = () => {
            const { state: { nProducts, data: { count } } } = this;
            if (count >= nProducts) {
                Swal.fire({
                    title: 'You can not add more products, you must update your subscription!',
                    text: `You have ${count} of ${nProducts} allowed products by project.`,
                    type: 'warning',
                    showCancelButton: false,
                    confirmButtonText: 'Ok!',
                    cancelButtonText: 'Cancel',
                    reverseButtons: true,
                }).then((result) => {});
            } else onOpenModal();
        };

        const onCloseModal = () => this.setState({ open: false });
        const editarModal = (e) => this.setState({ initialValues: this.dataForm[e] }, () => this.setState({ open: true }));

        const totalizadosales = () => {
            const { analisis_units, main_currency  } = this.props.setUpGeneral.formDataSave.proyinfo;
            let data = { id_project: this.state.idProject, infla: this.props.setUpGeneral.formDataSave.inflation, analysis_unit: analisis_units };
            this.props.leerTotalizadoresSales("products/totalizadosales/", data, main_currency);
        };

        const onOpenModalTotalSales = () => {totalizadosales(); this.setState({ openTotalSales: true })};
        const onCloseModalTotalSales = () => this.setState({ openTotalSales: false });

        const onOpenModalTotalSalesProduct = () => {totalizadosales(); this.setState({ openTotalSalesProduct: true })};
        const onCloseModalTotalSalesProduct = () => this.setState({ openTotalSalesProduct: false });
        const opneMenu = () => { this.setState({ openM: !this.state.openM });};


        const eliminar = (e) => {
            const url = `products/destroyproduct/`;
            const urlLeer = `products/get_productos`;
            this.props.eliminar(url, urlLeer, e, this.state.idProject);
        };
        const { open, openTotalSales, openTotalSalesProduct, finish } = this.state;
        return (
            <div>
                <div className="header-wizard-form">
                    <div className="columna-1-Ge columna-1 my-auto">
                        <table>
                            <tr>
                                <td>
                                    <img
                                        className="flecha-pagina pag-div"
                                        src={felchaI}
                                        alt="regresar"
                                        style={this.props.previousPage ? { display: "inline-block" } : { display: "none" }}
                                        onClick={this.props.previousPage}
                                    />
                                </td>
                                <td>
                                    <img src={icono} alt="icono" className="icono-step" />
                                </td>
                                <td><span>Customer & Products</span></td>
                                <td>
                                    <img
                                        className="flecha-pagina pag-div"
                                        src={felchaD}
                                        alt="regresar"
                                        style={this.props.nextPage ? { display: "inline-block" } : { display: "none" }}
                                        onClick={this.props.nextPage}
                                    />
                                </td>
                            </tr>
                        </table>
                    </div>
                    <div className="columna-2-Ge columna-2 my-auto">
                        Define sales (revenue) drivers by Territories and Segments. Revenue is the total amount
                        of cash generated by the sale of products or services associated with the company's
                        primary operations. You can define key variables that will affect sales, its volume, growth,
                        attrition and monthly distribution among others.
                    </div>
                </div>
                <div className="div-center" />
                <br/>
                <div className="content-wizarform">
                    <button type="button" onClick={onOpenModalN} disabled={finish} className="btn btn-primary btn-sm mr-1">Add Product</button>
                    <div className="block-div"/>
                    <div className="float-right">
                        <button type="button" disabled={this.state.data.results.length <= 0} onClick={onOpenModalTotalSalesProduct} className="btn-Movil btn btn-optional btn-sm mr-1">Sales by product</button>
                        <button type="button" disabled={this.state.data.results.length <= 0} onClick={onOpenModalTotalSales} className="btn-Movil btn btn-optional btn-sm mr-1">Total Sales</button>
                        <div className="dropdown menu-Movil" style={ { margin: "auto" } }>
                            <a className="btnM" onClick={opneMenu} style={ { cursor: "pointer" } } >
                                <i style={ { color: "black", margin: "auto" } } className="fa fa-ellipsis-v fa-2x btnM" />
                            </a>
                            <div
                                id="myDropdown"
                                className={`dropdown-content ${this.state.openM===true && "show"}`}
                                style={ { right: "0px" } }
                            >
                                <a onClick={onOpenModalTotalSalesProduct} style={ { cursor: "pointer" } } >Sales by product</a>
                                <a onClick={onOpenModalTotalSales} style={ { cursor: "pointer" } } >Total Sales</a>
                            </div>
                        </div>
                    </div>
                    <div className="block-div"/>
                    <br />
                    <br />
                    <Grid
                        page={1}
                        hover
                        pagination={false}
                        data={this.state.data}
                        loading={false}
                        //loading={this.props.loader}
                        // onPageChange={onPageChange}
                        // onSortChange={onSortChange}
                    >
                        <TableHeaderColumn
                            dataField="id"
                            dataAlign="center"
                            isKey
                            dataFormat={
                                standardActions( finish===true ? {  } : { editarClick: editarModal, eliminar: eliminar } )
                            }
                            style={{ fontSize: "10px" }}
                        >ACTIONS</TableHeaderColumn>
                        <TableHeaderColumn
                            tdStyle={{ whiteSpace: 'normal' }}
                            thStyle={{ whiteSpace: 'normal' }}
                            dataAlign="left" dataField="name" dataSort>NAME</TableHeaderColumn>
                        <TableHeaderColumn
                            dataAlign="left"
                            dataField="territory"
                            dataSort
                            tdStyle={{ whiteSpace: 'normal' }}
                            thStyle={{ whiteSpace: 'normal' }}
                            dataFormat={
                                cell => (
                                    <div> {cell.territory} <br /> { cell.segment } </div>
                                )
                            }
                        >
                            GEOGRAPHY
                        </TableHeaderColumn>
                        { this.state.salesGrowthRate
                            // eslint-disable-next-line max-len
                            && <TableHeaderColumn
                                tdStyle={{ whiteSpace: 'normal' }}
                                thStyle={{ whiteSpace: 'normal' }}
                                dataAlign="left" dataField="customer_growth"
                                dataFormat={RenderNumberGrid}
                                dataSort>CUSTOMER GROWTH (%)</TableHeaderColumn>
                        }
                        { this.state.custAttrition
                            // eslint-disable-next-line max-len
                            && <TableHeaderColumn
                                tdStyle={{ whiteSpace: 'normal' }}
                                thStyle={{ whiteSpace: 'normal' }}
                                dataFormat={RenderNumberGrid}
                                dataAlign="left" dataField="annual_attrition" dataSort>ANNUAL ATTRITION (%)</TableHeaderColumn>
                        }
                        { this.state.activeCustomer
                            // eslint-disable-next-line max-len
                            && <TableHeaderColumn
                                tdStyle={{ whiteSpace: 'normal' }}
                                thStyle={{ whiteSpace: 'normal' }}
                                dataFormat={RenderNumberGrid}
                                dataAlign="left" dataField="active_customers" dataSort>ACTIVE CUSTOMERS (%)</TableHeaderColumn>
                        }
                        <TableHeaderColumn
                                tdStyle={{ whiteSpace: 'normal' }}
                                thStyle={{ whiteSpace: 'normal' }}
                                dataFormat={RenderNumberGrid}
                                dataAlign="left" dataField="base_price" dataSort>BASE PRICE({this.state.moneda})
                        </TableHeaderColumn>
                        { this.state.salesGrowthRate
                            && <TableHeaderColumn
                                tdStyle={{ whiteSpace: 'normal' }}
                                thStyle={{ whiteSpace: 'normal' }}
                                dataFormat={RenderNumberGrid}
                                dataAlign="left" dataField="annual_growthp" dataSort>PRICING ANNUAL GROWTH (%)</TableHeaderColumn>
                        }
                        { this.state.priceDiscount
                            && <TableHeaderColumn
                                tdStyle={{ whiteSpace: 'normal' }}
                                thStyle={{ whiteSpace: 'normal' }}
                                dataFormat={RenderNumberGrid}
                                dataAlign="left" dataField="annual_discount" dataSort>ANNUAL DISCOUNT (%)</TableHeaderColumn>
                        }
                        <TableHeaderColumn
                            tdStyle={{ whiteSpace: 'normal' }}
                            thStyle={{ whiteSpace: 'normal' }}
                            dataFormat={RenderNumberGrid}
                            dataAlign="left" dataField="base_volume" dataSort>BASE VOLUME (QTY)</TableHeaderColumn>
                        { this.state.salesGrowthRate
                            // eslint-disable-next-line max-len
                            && <TableHeaderColumn
                                tdStyle={{ whiteSpace: 'normal' }}
                                thStyle={{ whiteSpace: 'normal' }}
                                dataFormat={RenderNumberGrid}
                                dataAlign="left" dataField="annual_growthv" dataSort>VOLUME ANNUAL GROWTH (%)</TableHeaderColumn>
                        }
                        { this.state.custAttrition
                            // eslint-disable-next-line max-len
                            && <TableHeaderColumn
                                tdStyle={{ whiteSpace: 'normal' }}
                                thStyle={{ whiteSpace: 'normal' }}
                                dataFormat={RenderNumberGrid}
                                dataAlign="left" dataField="attrition" dataSort>ATTRITION (QTY)</TableHeaderColumn>
                        }
                        {/* eslint-disable-next-line max-len */}
                        <TableHeaderColumn
                            tdStyle={{ whiteSpace: 'normal' }}
                            thStyle={{ whiteSpace: 'normal' }}
                            dataFormat={RenderNumberGrid}
                            dataAlign="left" dataField="new_customer" dataSort>ANNUAL ACTIVE CUSTOMERS (%)</TableHeaderColumn>
                        {/* eslint-disable-next-line max-len */}
                        <TableHeaderColumn
                            dataAlign="left" dataField="new_customers" dataSort
                            tdStyle={{ whiteSpace: 'normal' }}
                            thStyle={{ whiteSpace: 'normal' }}
                            dataFormat={
                                cell => (
                                    <div>
                                        {cell.initial_customer_stock} <b>(BOP)</b> <br/> {cell.new_customers} <b>(BOP)</b>
                                    </div>
                                )
                            }
                        >
                            CUSTOMER
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataAlign="left" dataField="totalsales" dataSort
                            tdStyle={{ whiteSpace: 'normal' }}
                            thStyle={{ whiteSpace: 'normal' }}
                            dataFormat={
                                cell => (
                                    <div>{cell.totalsales} <b>(Total)</b><br/>
                                    {cell.unitarysales} <b>(Unitary)</b><br/>
                                    {cell.salesbycustomer} <b>(Customer)</b>
                                    </div>
                                )
                            }
                        >
                            SALES({this.state.moneda})
                        </TableHeaderColumn>
                    </Grid>
                </div>

                <ModalProductos
                    {...this.props}
                    arrayTabs={this.state.arrayTabsModal}
                    open={open}
                    idProject={this.state.idProject}
                    onSubmitsss={this.props.onSubmit}
                    onCloseModal={onCloseModal}
                    inflation={this.state.inflation}
                    analysis_unit={this.state.analysis_unit}
                    initialValues={this.state.initialValues}
                />

                <ModalTotalSales
                    {...this.props}
                    moneda={this.state.moneda}
                    monedaMainCur={this.state.monedaMainCur}
                    open={openTotalSales}
                    idProject={this.state.idProject}
                    onCloseModal={onCloseModalTotalSales}
                    salesGrowthRate={this.state.salesGrowthRate}
                    custAttrition={this.state.custAttrition}
                    activeCustomer={this.state.activeCustomer}
                    priceDiscount={this.state.priceDiscount}
                />

                <ModalTotalSalesProduct
                    {...this.props}
                    moneda={this.state.moneda}
                    monedaMainCur={this.state.monedaMainCur}
                    open={openTotalSalesProduct}
                    idProject={this.state.idProject}
                    onCloseModal={onCloseModalTotalSalesProduct}
                    salesGrowthRate={this.state.salesGrowthRate}
                    custAttrition={this.state.custAttrition}
                    activeCustomer={this.state.activeCustomer}
                    priceDiscount={this.state.priceDiscount}
                />

            </div>
        );
    }
}

export default connect()(WizardGeography);
