import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import './login.css';
import LoadMask from "Utils/LoadMask/LoadMask";
import logo from "../../../../../assets/img/logo.png";
import arrow_rigth from "../../../../../assets/img/arrow-rigth.png";
import ResetForm from "./ResetForm";

class ResetPassword extends Component {
    state = {
        // eslint-disable-next-line react/no-unused-state
        animate: "",
        animateFondo: "",
        mystyle: {
            height: window.innerHeight,
            width: window.innerWidth,
        },
    };

    componentDidMount(props) {
        this.state = { prueba: true };
        setTimeout(() => {
            this.setState({animate: "animate"})
        }, 550);
        setTimeout(() => {
            this.setState({animateFondo: "animate"})
        }, 1000);

        window.addEventListener("resize", function() {
            if (window.innerWidth<=850) {
                const mystyle = {
                    height: window.innerHeight,
                    width: window.innerWidth,
                };
                this.setState({mystyle: mystyle});
            }
        }.bind(this), false);
    }

    render() {
        const { loader, resetPass } = this.props;
        if (localStorage.getItem('token')) {
            return (<Redirect to="/" />);
        }

        const onSubmit = (data) => {
            resetPass(data);
        };

        return (
            <div className="blue-gradient-bg-register div-center">
                <div className="card card-login-register">
                    <div className="d-flex justify-content-start mb-3">
                        <img src={arrow_rigth} alt="logo" width={10} style={
                            {
                                height: '15px'
                            }
                        }/>
                        <Link className="ml-2" to="/login">Back</Link>
                    </div>
                    <div className="d-flex justify-content-center mb-3">
                        <img src={logo} alt="logo" height={67} />
                    </div>
                    <p>Please submit your email and we'll send you the instructions to reset it</p>
                    <LoadMask loading={loader} light>
                        <ResetForm name="restPass" onSubmit={onSubmit} />
                    </LoadMask>
                </div>
            </div>
        );
    }
}

export default ResetPassword;
