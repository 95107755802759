import { handleActions } from 'redux-actions';
import { push } from "react-router-redux";
import { AlertManager } from "alerts";
import { api } from "api";
import { initialize as initializeForm } from "redux-form";
import { setMe } from "./login";

const LOADER = 'LOGIN_LOADER';
const COMPANYINDUSTRY = 'LOGIN_COMPANYINDUSTRY';
const COMPANYREVENUE = 'LOGIN_COMPANYREVENUE';
const COMPANYSIZE = 'LOGIN_COMPANYSIZE';
const FUNCTIONALAREA = 'LOGIN_FUNCTIONALAREA';
const SECURITYQUESTION = 'LOGIN_SECURITYQUESTION';
const SUBSCRIPTION = 'LOGIN_SUBSCRIPTION';
const USERS = 'LOGIN_USERS';
const PAYMENTS = 'LOGIN_PAYMENTS';
const PLAN = 'LOGIN_PLAN';
const ISFREE = 'LOGIN_ISFREE';
const HASPLAN = 'LOGIN_HASPLAN';
const PROJECTS = 'LOGIN_PROJECTS';

export const constants = {
};

// ------------------------------------
// Pure Actions
// ------------------------------------

export const setLoader = loader => ({
    type: LOADER,
    loader,
});
export const setcompanyIndustry = companyIndustry => ({
    type: COMPANYINDUSTRY,
    companyIndustry,
});
export const setcompanyRevenue = companyRevenue => ({
    type: COMPANYREVENUE,
    companyRevenue,
});
export const setcompanySize = companySize => ({
    type: COMPANYSIZE,
    companySize,
});
export const setfunctionalArea = functionalArea => ({
    type: FUNCTIONALAREA,
    functionalArea,
});
export const setsecurityQuestion = securityQuestion => ({
    type: SECURITYQUESTION,
    securityQuestion,
});
export const setsubscription = subscription => ({
    type: SUBSCRIPTION,
    subscription,
});
export const setUsers = users => ({
    type: USERS,
    users,
});
export const setPayments = payments => ({
    type: PAYMENTS,
    payments,
});
export const setPlan = plan => ({
    type: PLAN,
    plan,
});
export const setIsFree = isfree => ({
    type: ISFREE,
    isfree,
});
export const setHasPlan = hasPlan => ({
    type: HASPLAN,
    hasPlan,
});
export const setProjects = projects => ({
    type: PROJECTS,
    projects,
});
// ------------------------------------
// Actions
// ------------------------------------

export const getData = (id, pagina)=> (dispatch) => {
    // eslint-disable-next-line no-use-before-define
    const limI = (5 * pagina) - 5;
    let limS = limI;
    limS--;
    for (let i = 0; i <= 5; i++) {
        limS++;
    }
    dispatch(getUsers(`users_profiles/users/?id=${id}&lim_i=${limI}&lim_s=${limS}`));
    api.get(`proyectos/my_projects/?id=${id}`).then((dataP) => {
        dispatch(setProjects(dataP));
    }).catch(() => {}).finally(() => {});
    api.get('company_industries/all/').then((dataI) => {
        dispatch(setcompanyIndustry(dataI));
        api.get('company_revenues/all/').then((dataR) => {
            dispatch(setcompanyRevenue(dataR));
            api.get('company_sizes/all/').then((dataS) => {
                dispatch(setcompanySize(dataS));
                api.get('functional_areas/all/').then((dataA) => {
                    dispatch(setfunctionalArea(dataA));
                    api.get('security_questions/all/').then((dataQ) => {
                        dispatch(setsecurityQuestion(dataQ));
                        api.get('subscriptions/all/').then((dataSub) => {
                            dispatch(setsubscription(dataSub));
                        }).catch(() => {}).finally(() => {});
                    }).catch(() => {}).finally(() => {});
                }).catch(() => {}).finally(() => {});
            }).catch(() => {}).finally(() => {});
        }).catch(() => {}).finally(() => {});
    }).catch(() => {}).finally(() => {});
};

export const getUsers = url => (dispatch) => {
    api.get(url).then((dataSub) => {
        dispatch(setUsers(dataSub));
    }).catch(() => {}).finally(() => {});
};

export const getFilter = url => (dispatch) => {
    dispatch(setLoader(true));
    api.get(url).then((data) => {
        dispatch(setPayments(data.payment_list));
        dispatch(setPlan(data.plan));
        dispatch(setIsFree(data.isfree));
        dispatch(setHasPlan(data.has_plan));
    }).catch(() => {}).finally(() => dispatch(setLoader(false)));
};

export const update = (id, data = {}, attachments=[]) => (dispatch, getStore) => {
    dispatch(setLoader(true));
    api.putAttachments(`profiles/${id}/`, data, attachments).then((response) => {
        dispatch(setMe(response));
        AlertManager.success('Data updated successfully', 'Success', 1000);
    }).catch(() => {
        AlertManager.error('Incorrect credentials, please try again', 'ERROR', 0);
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const updatePerfil = (endpoint, data, redirection= false) => (dispatch) => {
    dispatch(setLoader(true));
    api.post(endpoint, data).then((response) => {
        AlertManager.success('Information updated', 'Success', 3000);
        if (response.freeplan === false && redirection === true) {
            window.location = response.redirect;
        }
    }).catch((e) => {
        if (e[0]) AlertManager.error(e[0], 'ERROR');
        else AlertManager.error('Error on the creation', 'ERROR');
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const leer = (endpoint, id) => (dispatch) => {
    dispatch(setLoader(true));
    api.get(`${endpoint}?id_project=${id}`).then((response) => {
        dispatch(setItem(response));
        dispatch(setGeographyData(response));
        if (!!formName)
            dispatch(initializeForm(formName, response));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const crear = (endpoint, data, funcionc) => (dispatch) => {
    dispatch(setLoader(true));
    api.post(endpoint, data).then(() => {
        AlertManager.success('Register created', 'Success', 3000);
        dispatch(getUsers(`users_profiles/users/?id=${data.profile}&lim_i=0&lim_s=5`));
        if (funcionc) funcionc();
    }).catch((e) => {
        AlertManager.error(e && e.message ? e.message : 'Error on the creation', 'ERROR');
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const eliminar = (item, idUser) => (dispatch) => {
    dispatch(setLoader(true));
    api.eliminar(`users_profiles/${item.id}`).then(() => {
        dispatch(getUsers(`users_profiles/users/?id=${idUser}&lim_i=0&lim_s=5`));

        api.eliminar(`users/${item.user.id}`).then(() => {
            dispatch(getUsers(`users_profiles/users/?id=${idUser}&lim_i=0&lim_s=5`));
            AlertManager.success('Register deleted', 'Success', 3000);
        }).catch(() => { AlertManager.error('Error on the transaction', 'ERROR', 3000); }).finally(() => {
            dispatch(setLoader(false));
        });
    }).catch(() => { AlertManager.error('Error on the transaction', 'ERROR', 3000); }).finally(() => {});
};

const confirSubs = data => (dispatch) => {
    dispatch(setLoader(true));
    api.post("profiles/confirm_subscription/", data).then(() => {
        AlertManager.success('Subscription created', 'Success', 3000);
        dispatch(push("user-profile/payment/"));
    }).catch((e) => {
        AlertManager.error('Error on the creation', 'ERROR');
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const actions = {
    update,
    updatePerfil,
    leer,
    getData,
    getUsers,
    getFilter,
    crear,
    eliminar,
    confirSubs,
};

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [COMPANYINDUSTRY]: (state, { companyIndustry }) => {
        return {
            ...state,
            companyIndustry,
        };
    },
    [COMPANYREVENUE]: (state, { companyRevenue }) => {
        return {
            ...state,
            companyRevenue,
        };
    },
    [COMPANYSIZE]: (state, { companySize }) => {
        return {
            ...state,
            companySize,
        };
    },
    [FUNCTIONALAREA]: (state, { functionalArea }) => {
        return {
            ...state,
            functionalArea,
        };
    },
    [SECURITYQUESTION]: (state, { securityQuestion }) => {
        return {
            ...state,
            securityQuestion,
        };
    },
    [SUBSCRIPTION]: (state, { subscription }) => {
        return {
            ...state,
            subscription,
        };
    },
    [USERS]: (state, { users }) => {
        return {
            ...state,
            users,
        };
    },
    [PAYMENTS]: (state, { payments }) => {
        return {
            ...state,
            payments,
        };
    },
    [PLAN]: (state, { plan }) => {
        return {
            ...state,
            plan,
        };
    },
    [ISFREE]: (state, { isfree }) => {
        return {
            ...state,
            isfree,
        };
    },
    [HASPLAN]: (state, { hasPlan }) => {
        return {
            ...state,
            hasPlan,
        };
    },
    [PROJECTS]: (state, { projects }) => {
        return {
            ...state,
            projects,
        };
    },
};

export const initialState = {
    loader: false,
    companyIndustry: [],
    companyRevenue: [],
    companySize: [],
    functionalArea: [],
    securityQuestion: [],
    subscription: [],
    users: [],
    payments: [],
    projects: [],
    plan: {},
    isfree: false,
    hasPlan: false,
};

export default handleActions(reducers, initialState);
