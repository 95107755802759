import React, { Component } from 'react';
import { Field, reduxForm, FieldArray, formValueSelector } from 'redux-form';
import { connect } from "react-redux";
import {
    renderField,
    renderNumber,
    renderFieldCheck,
    renderFieldRadio,
    SelectField,
    SelectFieldColor,
    renderSlideRanger, renderDatePicker, renderFieldLabel,
} from "../../Utils/renderField/renderField";
import NumberFormat from "react-number-format";
import classNames from "classnames";
import Select from "react-select";
import _ from "lodash";
import { LEVELITEMS } from "./../../../../utility/contry";
import imgEditar from "../../../../../assets/img/editar-C.png";
import imgQuestion from "../../../../../assets/img/question.png";
import ReactTooltip from "react-tooltip";
import Swal from "sweetalert2";

export const fieldArray = ({ fields, columInput, indexParent, meta: { error }, disabled }) => {
    return (
        <div className="form-row align-items-end" key={indexParent}>
            {
                fields.map((field, index) => {
                    const indice = index + 1;
                    const label = `Year ${indice} (%)`;
                    return (
                        <div className={`form-group col-md-${columInput}`} key={index}>
                            <label htmlFor={label} key={`label${index}`}>{label}</label>
                            <Field
                                key={`muliInput${index}`}
                                name={`${field}.value`}
                                type="text"
                                disable={disabled}
                                component={renderNumber}
                                placeholder="0"
                                label={label}
                                decimalScale={2}
                            />
                            {error && (
                                <div className="invalid-feedback">{error}</div>
                            )}
                        </div>
                    );
                })
            }
        </div>
    );
};

export const fieldArraySlide = ({ fields, help, nameChildr, indexParent, labelTitle, labelData,  valueInput, meta: { error } }) => {
    let valorPercent = 0;
    valueInput ? valueInput.forEach((val) => {
        valorPercent += Number(val[nameChildr] || 0);
    }) : valorPercent=0;
    return (
        <React.Fragment>
            <div className="pull-left">{labelTitle}</div>
            &nbsp;{help && (<img src={imgQuestion} style={{ width: '15px', height: '15px'}} data-tip={help}/>)}
            <ReactTooltip backgroundColor="white" className="tooltipHelp" multiline={true} />
            <div className={`pull-right ${error && "textRed"}`}>{error}&nbsp;&nbsp;&nbsp;&nbsp;Total: <span className="textPercent">{valorPercent}%</span></div>
            <br/>
            <br/>
            <div className="div-center" style={{overflow: "auto"}}>
                <table className="tableSlider" style={{margin: "auto"}}>
                    <tbody>
                        <tr>
                        {
                            fields.map((field, index) => {
                                return (
                                    <td key={index}>
                                        <Field
                                            key={`muliSlider${index}`}
                                            name={`${field}.${nameChildr}`}
                                            label={labelData[index]}
                                            component={renderSlideRanger}
                                        />
                                        {error && (
                                            <div className="invalid-feedback">{error}</div>
                                        )}
                                    </td>
                                );
                            })
                        }
                        </tr>
                    </tbody>
                </table>
                <div className="div-lin-bottom" />
            </div>
            <br/>
        </React.Fragment>
    );
};

export class fieldArrayTable extends Component {

    state = {
        input: "",
        invalid: "",
    };

    render() {
        const { fields, imgElimiar, imgAgregar, nombreCampo, nTerritories } = this.props;
        const error = this.props.meta.error;

        return (
            <table className="table table-hover table-bordered">
                <thead>
                    <tr>
                        <th className="sort-column text-left" title="Usuario">
                            ACTIONS
                        </th>
                        <th className="sort-column text-left" title="Usuario">
                            SEGMENT NAME
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {
                        fields.map((field, index) => {
                            return (
                                <tr key={index}>
                                    <td>
                                        <img
                                            className="divEliminar"
                                            src={imgElimiar}
                                            alt="eliminar"
                                            onClick={() => fields.remove(index)}
                                        />
                                    </td>
                                    <td>
                                        <Field
                                            key={index}
                                            name={`${field}.${nombreCampo}`}
                                            type="text"
                                            disabled={true}
                                            component={renderField}
                                        />
                                    </td>
                                </tr>
                            );
                        })
                    }
                    <tr>
                        <td>
                            <img
                                className="divAgregar"
                                src={imgAgregar}
                                alt="agregar"
                                onClick={() => {
                                    if (this.state.input && this.state.input !== "") {
                                        let context = this.state;
                                        let a = _.find(fields.getAll(), (data) => { return data[nombreCampo] === context.input; });
                                        if (fields.length < nTerritories) {
                                            if (!a) {
                                                let objecttt = {}; objecttt[nombreCampo] = context.input;
                                                fields.push(objecttt);
                                                this.setState({ input: "" });
                                                this.setState({ invalid : ""})
                                            }
                                            else this.setState({ invalid : "The segment must be unique in the territory."});
                                        } else {
                                            Swal.fire({
                                                title: 'You can not add more segments, you must update your subscription!',
                                                text: `You have ${fields.length} of ${nTerritories} allowed segments by territory.`,
                                                type: 'warning',
                                                showCancelButton: false,
                                                confirmButtonText: 'Ok!',
                                                cancelButtonText: 'Cancel',
                                                reverseButtons: true,
                                            }).then((result) => {});
                                        }
                                    }
                                }}
                            />
                        </td>
                        <td>
                            <input
                                className="form-control"
                                value={this.state.input}
                                onChange={(e) => this.setState({ input: e.target.value})}
                                type="text"
                            />
                            {this.state.invalid !== "" ? (
                                <div style={{ display: "block" }} className="invalid-feedback">{this.state.invalid}</div>
                            ) : (<div> </div>)}
                            {error ? (
                                <div style={{ display: "block" }} className="invalid-feedback">{error}</div>
                            ) : (<div> </div>)}
                        </td>
                    </tr>
                </tbody>
            </table>
        );
    };
}

export class fieldArrayDirecMaterials extends Component {
    state = {
        input: "",
        material_name: "",
        unitary_cost: "",
        annual_growth: "",
        required_material: "",
        efficiency: "",
        invalidMaterial_name: "",
        invalid: "",
    };

    render() {
        const fields = this.props.fields;
        const imgElimiar = this.props.imgElimiar;
        const imgAgregar = this.props.imgAgregar;
        const imgEditar = this.props.imgEditar;
        const moneda = this.props.moneda;
        const error = this.props.meta.error;

        const agregar = (fields, index) => {
            if (
                this.state.material_name !== "" && this.state.unitary_cost !== "" &&
                this.state.annual_growth !== "" && this.state.required_material !== "" &&
                this.state.efficiency !== ""
            ) {
                let context = this.state;
                let a = _.find(fields.getAll(), (data) => { return data.material_name === context.material_name; });
                if (!a) {
                    let objecttt = {
                        material_name: context.material_name,
                        unitary_cost: context.unitary_cost,
                        annual_growth: context.annual_growth,
                        required_material: context.required_material,
                        efficiency: context.efficiency,
                        ediMode: "false",
                    };
                    index ? fields.splice(index, 1, objecttt) : fields.push(objecttt);
                    this.setState({
                        material_name: "",
                        unitary_cost: "",
                        annual_growth: "",
                        required_material: "",
                        efficiency: "",
                        invalid : ""
                    });
                    this.forceUpdate();
                }
                else {
                    this.setState({ invalid : `The material name: ${this.state.material_name}, already exist!`});
                }
            } else { this.setState({ invalid : `The fields are incomplete!`}); }
        };

        return (
            <div className="div-center">
                <table className="table table-hover table-bordered">
                    <thead>
                        <tr>
                            <th className="sort-column text-left nowrap">ACTIONS</th>
                            <th className="sort-column text-left nowrap">Material name</th>
                            <th className="sort-column text-left nowrap">{`Unitary base cost <${moneda}>`}</th>
                            <th className="sort-column text-left nowrap">Annual cost growth (%)</th>
                            <th className="sort-column text-left nowrap">Material required (Qty)</th>
                            <th className="sort-column text-left nowrap">Material efficiency (%)</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            fields.map((field, index) => {
                                if (fields.get(index).ediMode === true) {
                                    return (
                                        <tr>
                                            <td className="nowrap">
                                                <img
                                                    className="divAgregar"
                                                    src={imgAgregar}
                                                    alt="agregar"
                                                    onClick={() => {
                                                         let item = fields.get(index);
                                                         item.ediMode = false;
                                                         fields.splice(index, 1, item);
                                                         this.forceUpdate();
                                                    }}
                                                />
                                            </td>
                                            <td>
                                                <Field
                                                    key={index} name={`${field}.material_name`}
                                                    type="text" component={renderField}
                                                />
                                            </td>
                                            <td>
                                                <Field
                                                    key={index} name={`${field}.unitary_cost`}
                                                    type="text" component={renderNumber} decimalScale={2}
                                                />
                                            </td>
                                            <td>
                                                <Field
                                                    key={index} name={`${field}.annual_growth`}
                                                    type="text" component={renderNumber} decimalScale={2}
                                                />
                                            </td>
                                            <td>
                                                <Field
                                                    key={index} name={`${field}.required_material`}
                                                    type="text" component={renderNumber} decimalScale={2}
                                                />
                                            </td>
                                            <td>
                                                <Field
                                                    key={index} name={`${field}.efficiency`}
                                                    type="text" component={renderNumber} decimalScale={2}
                                                />
                                            </td>
                                        </tr>
                                    );
                                } else {
                                    return (
                                        <tr key={index}>
                                            <td className="nowrap">
                                                <img className="divEditar"
                                                     src={imgEditar} alt="editar" onClick={() => {
                                                         let item = fields.get(index);
                                                         item.ediMode = true;
                                                         fields.splice(index, 1, item);
                                                         this.forceUpdate();
                                                }}
                                                />&nbsp;&nbsp;
                                                <img className="divEliminar"
                                                     src={imgElimiar} alt="eliminar" onClick={() => fields.remove(index)}
                                                />
                                            </td>
                                            <td>
                                                <Field key={index} name={`${field}.material_name`}
                                                    type="text" disabled={true} component={renderField}
                                                />
                                            </td>
                                            <td>
                                                <Field key={index} name={`${field}.unitary_cost`} decimalScale={2}
                                                    type="text" disable={true} component={renderNumber}
                                                />
                                            </td>
                                            <td>
                                                <Field key={index} name={`${field}.annual_growth`} decimalScale={2}
                                                    type="text" disable={true} component={renderNumber}
                                                />
                                            </td>
                                            <td>
                                                <Field key={index} name={`${field}.required_material`} decimalScale={2}
                                                    type="text" disable={true} component={renderNumber}
                                                />
                                            </td>
                                            <td>
                                                <Field key={index} name={`${field}.efficiency`} decimalScale={2}
                                                    type="text" disable={true} component={renderNumber}
                                                />
                                            </td>
                                        </tr>
                                    );
                                }
                            })
                        }
                        <tr>
                            <td>
                                <img
                                    className="divAgregar"
                                    src={imgAgregar}
                                    alt="agregar"
                                    onClick={() => agregar(fields)}
                                />
                            </td>
                            <td>
                                <input
                                    className="form-control" value={this.state.material_name} type="text"
                                    onChange={(e) => this.setState({ material_name: e.target.value})}
                                />
                            </td>
                            <td>
                                <NumberFormat displayType="input" placeholder="0.0"
                                              className={classNames('form-control')} decimalScale={2}
                                              value={this.state.unitary_cost} onValueChange={(e) => this.setState({ unitary_cost: e.floatValue})}
                                />
                            </td>
                            <td>
                                <NumberFormat displayType="input" placeholder="0.0"
                                              className={classNames('form-control')} decimalScale={2}
                                              value={this.state.annual_growth} onValueChange={(e) => this.setState({ annual_growth: e.floatValue})}
                                />
                            </td>
                            <td>
                                <NumberFormat displayType="input" placeholder="0.0"
                                              className={classNames('form-control')} decimalScale={2}
                                              value={this.state.required_material} onValueChange={(e) => this.setState({ required_material: e.floatValue})}
                                />
                            </td>
                            <td>
                                <NumberFormat displayType="input" placeholder="0.0"
                                              className={classNames('form-control')} decimalScale={2}
                                              value={this.state.efficiency} onValueChange={(e) => this.setState({ efficiency: e.floatValue})}
                                />
                            </td>
                        </tr>
                    </tbody>
                </table>
                {this.state.invalid !== "" ? (
                    <div style={{ display: "block" }} className="invalid-feedback">{this.state.invalid}</div>
                ) : (<div> </div>)}
                {error ? (
                    <div style={{ display: "block" }} className="invalid-feedback">{error}</div>
                ) : (<div> </div>)}
            </div>
        );
    };
}

export class fieldArrayEmployee extends Component {
    state = {
        no_of_employees: "",
        level: {},
        base_salary: "",
        salary_growth: "",
        annual_bonus: "",
        invalidMaterial_name: "",
        invalid: "",
    };

    render() {
        const fields = this.props.fields;
        const imgElimiar = this.props.imgElimiar;
        const imgAgregar = this.props.imgAgregar;
        const imgEditar = this.props.imgEditar;
        const moneda = this.props.moneda;
        const error = this.props.meta.error;

        const agregar = (fields, index) => {
            if (
                this.state.no_of_employees !== "" && this.state.level.value &&
                this.state.base_salary !== "" && this.state.salary_growth !== "" &&
                this.state.annual_bonus !== ""
            ) {
                let context = this.state;
                let objecttt = {
                    no_of_employees: context.no_of_employees,
                    level: context.level,
                    base_salary: context.base_salary,
                    salary_growth: context.salary_growth,
                    annual_bonus: context.annual_bonus,
                    ediMode: "false",
                };
                index ? fields.splice(index, 1, objecttt) : fields.push(objecttt);
                this.setState({
                    no_of_employees: "",
                    level: {},
                    base_salary: "",
                    salary_growth: "",
                    annual_bonus: "",
                    invalid : ""
                });
                this.forceUpdate();
            } else { this.setState({ invalid : `The fields are incomplete!`}); }
        };

        return (
            <div className="div-center">
                <table className="table table-hover table-bordered react-bs-table">
                    <thead>
                        <tr>
                            <th className="sort-column text-left nowrap">ACTIONS</th>
                            <th className="sort-column text-left nowrap">Employees (Nª)</th>
                            <th className="sort-column text-left nowrap">Employee level</th>
                            <th className="sort-column text-left nowrap">{`Base salary (Monthly) <${moneda}>`}</th>
                            <th className="sort-column text-left nowrap">Base salary growth (%)</th>
                            <th className="sort-column text-left nowrap">{`Annual bonus <${moneda}>`}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            fields.map((field, index) => {
                                if (fields.get(index).ediMode === true) {
                                    return (
                                        <tr>
                                            <td className="nowrap">
                                                <img
                                                    className="divAgregar"
                                                    src={imgAgregar}
                                                    alt="agregar"
                                                    onClick={() => {
                                                         let item = fields.get(index);
                                                         item.ediMode = false;
                                                         fields.splice(index, 1, item);
                                                         this.forceUpdate();
                                                    }}
                                                />
                                            </td>
                                            <td>
                                                <Field
                                                    key={index} name={`${field}.no_of_employees`}
                                                    type="text" component={renderNumber}
                                                />
                                            </td>
                                            <td>
                                                <Field
                                                    name={`${field}.level`}
                                                    type="text"
                                                    component={SelectField}
                                                    menuPosition="fixed"
                                                    labelKeyObject={true}
                                                    options={LEVELITEMS}
                                                    valueKey="value"
                                                />
                                            </td>
                                            <td>
                                                <Field
                                                    key={index} name={`${field}.base_salary`}
                                                    type="text" component={renderNumber} decimalScale={2}
                                                />
                                            </td>
                                            <td>
                                                <Field
                                                    key={index} name={`${field}.salary_growth`}
                                                    type="text" component={renderNumber} decimalScale={2}
                                                />
                                            </td>
                                            <td>
                                                <Field
                                                    key={index} name={`${field}.annual_bonus`}
                                                    type="text" component={renderNumber} decimalScale={2}
                                                />
                                            </td>
                                        </tr>
                                    );
                                } else {
                                    return (
                                        <tr key={index}>
                                            <td className="nowrap">
                                                <img className="divEditar"
                                                     src={imgEditar} alt="editar" onClick={() => {
                                                         let item = fields.get(index);
                                                         item.ediMode = true;
                                                         fields.splice(index, 1, item);
                                                         this.forceUpdate();
                                                }}
                                                />&nbsp;&nbsp;
                                                <img className="divEliminar"
                                                     src={imgElimiar} alt="eliminar" onClick={() => fields.remove(index)}
                                                />
                                            </td>
                                            <td>
                                                <Field key={index} name={`${field}.no_of_employees`}
                                                    type="text" disable={true} component={renderNumber}
                                                />
                                            </td>
                                            <td>
                                                <Field
                                                    name={`${field}.level`}
                                                    type="text"
                                                    disabled={true}
                                                    component={SelectField}
                                                    menuPosition="fixed"
                                                    labelKeyObject={true}
                                                    options={LEVELITEMS}
                                                    valueKey="value"
                                                />
                                            </td>
                                            <td>
                                                <Field key={index} name={`${field}.base_salary`} decimalScale={2}
                                                    type="text" disable={true} component={renderNumber}
                                                />
                                            </td>
                                            <td>
                                                <Field key={index} name={`${field}.salary_growth`} decimalScale={2}
                                                    type="text" disable={true} component={renderNumber}
                                                />
                                            </td>
                                            <td>
                                                <Field key={index} name={`${field}.annual_bonus`} decimalScale={2}
                                                    type="text" disable={true} component={renderNumber}
                                                />
                                            </td>
                                        </tr>
                                    );
                                }
                            })
                        }
                        <tr>
                            <td>
                                <img
                                    className="divAgregar"
                                    src={imgAgregar}
                                    alt="agregar"
                                    onClick={() => agregar(fields)}
                                />
                            </td>
                            <td>
                                <NumberFormat displayType="input" placeholder="0"
                                              decimalPrecision={0}
                                              decimalSeparator="false"
                                              className={classNames('form-control')}
                                              value={this.state.no_of_employees} onValueChange={(e) => this.setState({ no_of_employees: e.floatValue})}
                                />
                            </td>
                            <td>
                                <Select
                                    className={classNames('react-select-container')}
                                    menuPlacement="auto"
                                    menuPortalTarget={document.body}
                                    backspaceRemovesValue={false}
                                    options={LEVELITEMS}
                                    onChange={(e) => {
                                        this.setState({ level: e} );
                                    }}
                                    value={this.state.level}
                                    styles={{
                                        menuPortal: base => ({ ...base, zIndex: 999999999 }),
                                        menu: base => ({ ...base, borderRadius: 15 }),
                                        menuList: base => ({ ...base, borderRadius: 15, padding: 0 })
                                    }}
                                />
                            </td>
                            <td>
                                <NumberFormat displayType="input" placeholder="0.0"
                                              className={classNames('form-control')}  decimalScale={2}
                                              value={this.state.base_salary} onValueChange={(e) => this.setState({ base_salary: e.floatValue})}
                                />
                            </td>
                            <td>
                                <NumberFormat displayType="input" placeholder="0.0"
                                              className={classNames('form-control')} decimalScale={2}
                                              value={this.state.salary_growth} onValueChange={(e) => this.setState({ salary_growth: e.floatValue})}
                                />
                            </td>
                            <td>
                                <NumberFormat displayType="input" placeholder="0.0"
                                              className={classNames('form-control')} decimalScale={2}
                                              value={Number(this.state.annual_bonus)} onValueChange={(e) => this.setState({ annual_bonus: e.floatValue})}
                                />
                            </td>
                        </tr>
                    </tbody>
                </table>
                {this.state.invalid !== "" ? (
                    <div style={{ display: "block" }} className="invalid-feedback">{this.state.invalid}</div>
                ) : (<div> </div>)}
                {error ? (
                    <div style={{ display: "block" }} className="invalid-feedback">{error}</div>
                ) : (<div> </div>)}
            </div>
        );
    };
}

export class fieldArrayCompesation extends Component {
    state = {
        no_of_employees: "",
        level: {},
        base_salary: "",
        salary_growth: "",
        benefits: "",
        sales_commission: "",
        other: "",
        annual_bonus: "",
        invalid: "",
    };

    render() {
        const fields = this.props.fields;
        const imgElimiar = this.props.imgElimiar;
        const imgAgregar = this.props.imgAgregar;
        const imgEditar = this.props.imgEditar;
        const moneda = this.props.moneda;
        const error = this.props.meta.error;

        const agregar = (fields, index) => {
            if (
                this.state.no_of_employees !== "" && this.state.level.value &&
                this.state.base_salary !== "" && this.state.salary_growth !== "" &&
                this.state.benefits !== "" && this.state.sales_commission !== "" && this.state.other !== "" &&
                this.state.annual_bonus !== ""
            ) {
                let context = this.state;
                let objecttt = {
                    no_of_employees: context.no_of_employees,
                    level: context.level,
                    base_salary: context.base_salary,
                    salary_growth: context.salary_growth,
                    benefits: context.benefits,
                    sales_commission: context.sales_commission,
                    other: context.other,
                    annual_bonus: context.annual_bonus,
                    ediMode: "false",
                };
                index ? fields.splice(index, 1, objecttt) : fields.push(objecttt);
                this.setState({
                    no_of_employees: "",
                    level: {},
                    base_salary: "",
                    salary_growth: "",
                    benefits: "",
                    sales_commission: "",
                    other: "",
                    annual_bonus: "",
                    invalid : ""
                });
                this.forceUpdate();
            } else { this.setState({ invalid : `The fields are incomplete!`}); }
        };

        return (
            <div className="div-center">
                <table className="table table-hover table-bordered react-bs-table">
                    <thead>
                        <tr>
                            <th className="sort-column text-left nowrap">ACTIONS</th>
                            <th className="sort-column text-left nowrap">Employees (Nª)</th>
                            <th className="sort-column text-left nowrap">Employee level</th>
                            <th className="sort-column text-left nowrap">{`Base salary (Monthly) <${moneda}>`}</th>
                            <th className="sort-column text-left nowrap">Base salary growth (%)</th>
                            <th className="sort-column text-left nowrap">{`Benefits <${moneda}>`}</th>
                            <th className="sort-column text-left nowrap">{`Sales Commission <${moneda}>`}</th>
                            <th className="sort-column text-left nowrap">{`Other <${moneda}>`}</th>
                            <th className="sort-column text-left nowrap">{`Annual bonus <${moneda}>`}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            fields.map((field, index) => {
                                if (fields.get(index).ediMode === true) {
                                    return (
                                        <tr>
                                            <td className="nowrap">
                                                <img
                                                    className="divAgregar"
                                                    src={imgAgregar}
                                                    alt="agregar"
                                                    onClick={() => {
                                                         let item = fields.get(index);
                                                        if (
                                                            item.no_of_employees !== "" && item.level.value
                                                            && item.base_salary !== "" && item.salary_growth !== ""
                                                            && item.benefits !== "" && item.sales_commission !== ""
                                                            && item.other !== "" && item.annual_bonus !== ""
                                                        ) {
                                                             item.ediMode = false;
                                                             fields.splice(index, 1, item);
                                                             this.forceUpdate();
                                                        } else { this.setState({ invalid : `The fields are incomplete!`}); }
                                                    }}
                                                />
                                            </td>
                                            <td>
                                                <Field
                                                    key={index} name={`${field}.no_of_employees`}
                                                    type="text" component={renderNumber}
                                                />
                                            </td>
                                            <td>
                                                <Field
                                                    name={`${field}.level`}
                                                    type="text"
                                                    component={SelectField}
                                                    menuPosition="fixed"
                                                    labelKeyObject={true}
                                                    options={LEVELITEMS}
                                                    valueKey="value"
                                                />
                                            </td>
                                            <td>
                                                <Field
                                                    key={index} name={`${field}.base_salary`}
                                                    type="text" component={renderNumber} decimalScale={2}
                                                />
                                            </td>
                                            <td>
                                                <Field
                                                    key={index} name={`${field}.salary_growth`}
                                                    type="text" component={renderNumber} decimalScale={2}
                                                />
                                            </td>
                                            <td>
                                                <Field
                                                    key={index} name={`${field}.benefits`}
                                                    type="text" component={renderNumber} decimalScale={2}
                                                />
                                            </td>
                                            <td>
                                                <Field
                                                    key={index} name={`${field}.sales_commission`}
                                                    type="text" component={renderNumber} decimalScale={2}
                                                />
                                            </td>
                                            <td>
                                                <Field
                                                    key={index} name={`${field}.other`}
                                                    type="text" component={renderNumber} decimalScale={2}
                                                />
                                            </td>
                                            <td>
                                                <Field
                                                    key={index} name={`${field}.annual_bonus`}
                                                    type="text" component={renderNumber} decimalScale={2}
                                                />
                                            </td>
                                        </tr>
                                    );
                                } else {
                                    return (
                                        <tr key={index}>
                                            <td className="nowrap">
                                                <img className="divEditar"
                                                     src={imgEditar} alt="editar" onClick={() => {
                                                         let item = fields.get(index);
                                                         item.ediMode = true;
                                                         fields.splice(index, 1, item);
                                                         this.forceUpdate();
                                                }}
                                                />&nbsp;&nbsp;
                                                <img className="divEliminar"
                                                     src={imgElimiar} alt="eliminar" onClick={() => fields.remove(index)}
                                                />
                                            </td>
                                            <td>
                                                <Field key={index} name={`${field}.no_of_employees`}
                                                    type="text" disable={true} component={renderNumber}
                                                />
                                            </td>
                                            <td>
                                                <Field
                                                    name={`${field}.level`}
                                                    type="text"
                                                    disabled={true}
                                                    component={SelectField}
                                                    menuPosition="fixed"
                                                    labelKeyObject={true}
                                                    options={LEVELITEMS}
                                                    valueKey="value"
                                                />
                                            </td>
                                            <td>
                                                <Field key={index} name={`${field}.base_salary`} decimalScale={2}
                                                    type="text" disable={true} component={renderNumber}
                                                />
                                            </td>
                                            <td>
                                                <Field key={index} name={`${field}.salary_growth`} decimalScale={2}
                                                    type="text" disable={true} component={renderNumber}
                                                />
                                            </td>
                                            <td>
                                                <Field key={index} name={`${field}.benefits`} decimalScale={2}
                                                    type="text" disable={true} component={renderNumber}
                                                />
                                            </td>
                                            <td>
                                                <Field key={index} name={`${field}.sales_commission`} decimalScale={2}
                                                    type="text" disable={true} component={renderNumber}
                                                />
                                            </td>
                                            <td>
                                                <Field key={index} name={`${field}.other`} decimalScale={2}
                                                    type="text" disable={true} component={renderNumber}
                                                />
                                            </td>
                                            <td>
                                                <Field key={index} name={`${field}.annual_bonus`} decimalScale={2}
                                                    type="text" disable={true} component={renderNumber}
                                                />
                                            </td>
                                        </tr>
                                    );
                                }
                            })
                        }
                        <tr>
                            <td>
                                <img
                                    className="divAgregar"
                                    src={imgAgregar}
                                    alt="agregar"
                                    onClick={() => agregar(fields)}
                                />
                            </td>
                            <td>
                                <NumberFormat displayType="input" placeholder="0"
                                              decimalPrecision={0}
                                              decimalSeparator="false"
                                              className={classNames('form-control')}
                                              value={this.state.no_of_employees} onValueChange={(e) => this.setState({ no_of_employees: e.floatValue})}
                                />
                            </td>
                            <td>
                                <Select
                                    className={classNames('react-select-container')}
                                    menuPlacement="auto"
                                    menuPortalTarget={document.body}
                                    styles={{
                                        menuPortal: base => ({ ...base, zIndex: 999999999 }),
                                        menu: base => ({ ...base, borderRadius: 15 }),
                                        menuList: base => ({ ...base, borderRadius: 15, padding: 0 })
                                    }}
                                    backspaceRemovesValue={false}
                                    options={LEVELITEMS}
                                    onChange={(e) => {
                                        this.setState({ level: e} );
                                    }}
                                    value={this.state.level}
                                />
                            </td>
                            <td>
                                <NumberFormat displayType="input" placeholder="0.0"
                                              className={classNames('form-control')} decimalScale={2}
                                              value={this.state.base_salary} onValueChange={(e) => this.setState({ base_salary: e.floatValue})}
                                />
                            </td>
                            <td>
                                <NumberFormat displayType="input" placeholder="0.0"
                                              className={classNames('form-control')} decimalScale={2}
                                              value={this.state.salary_growth} onValueChange={(e) => this.setState({ salary_growth: e.floatValue})}
                                />
                            </td>
                            <td>
                                <NumberFormat displayType="input" placeholder="0.0"
                                              className={classNames('form-control')} decimalScale={2}
                                              value={this.state.benefits} onValueChange={(e) => this.setState({ benefits: e.floatValue})}
                                />
                            </td>
                            <td>
                                <NumberFormat displayType="input" placeholder="0.0"
                                              className={classNames('form-control')} decimalScale={2}
                                              value={this.state.sales_commission} onValueChange={(e) => this.setState({ sales_commission: e.floatValue})}
                                />
                            </td>
                            <td>
                                <NumberFormat displayType="input" placeholder="0.0"
                                              className={classNames('form-control')} decimalScale={2}
                                              value={this.state.other} onValueChange={(e) => this.setState({ other: e.floatValue})}
                                />
                            </td>
                            <td>
                                <NumberFormat displayType="input" placeholder="0.0"
                                              className={classNames('form-control')} decimalScale={2}
                                              value={this.state.annual_bonus} onValueChange={(e) => this.setState({ annual_bonus: e.floatValue})}
                                />
                            </td>
                        </tr>
                    </tbody>
                </table>
                {this.state.invalid !== "" ? (
                    <div style={{ display: "block" }} className="invalid-feedback">{this.state.invalid}</div>
                ) : (<div> </div>)}
                {error ? (
                    <div style={{ display: "block" }} className="invalid-feedback">{error}</div>
                ) : (<div> </div>)}
            </div>
        );
    };
}

export class FieldArrayOther extends React.Component {
    state = {
        invalid: "",
        editMode1: false,
        editMode2: false,
        editMode3: false,
        editMode4: false,
        editMode5: false,
        editMode6: false,
    };

    render() {
        const { imgAgregar, imgEditar, moneda, meta, help } = this.props;

        return (
            <div className="div-center">
                <table className="table table-hover table-bordered react-bs-table">
                    <thead>
                        <tr>
                            {/*<th className="sort-column text-left nowrap">ACTIONS</th>*/}
                            <th className="sort-column text-left nowrap">NAME</th>
                            <th className="sort-column text-left nowrap">{`Unitary monthly base cost <${moneda}>`}</th>
                            <th className="sort-column text-left nowrap">Annual cost growth (%)</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            {/*<td>
                                <img  onClick={() => this.setState({ editMode1: !this.state.editMode1 }) } alt="agregar"
                                      src={this.state.editMode1 ? imgEditar : imgAgregar }
                                      className={this.state.editMode1 ? "divEditar" : "divAgregar" } />
                            </td>*/}
                            <td className="text-left">Direct shipping / freight&nbsp;{help && (<img src={imgQuestion} style={{ width: '15px', height: '15px'}} data-tip={help[0]}/>)}</td>
                            <td>
                                <Field placeholder="0.0" name={`direct_shipping_ubc`} disable={this.state.editMode1} type="text" component={renderNumber} decimalScale={2}/>
                            </td>
                            <td>
                                <Field placeholder="0.0" name={`direct_shipping_acg`} disable={this.state.editMode1} type="text" component={renderNumber} decimalScale={2}/>
                            </td>
                        </tr>
                        <tr>
                            {/*<td>
                                <img  onClick={() => this.setState({ editMode2: !this.state.editMode2 }) } alt="agregar"
                                      src={this.state.editMode2 ? imgEditar : imgAgregar }
                                      className={this.state.editMode2 ? "divEditar" : "divAgregar" } />

                            </td>*/}
                            <td className="text-left">Warehousing&nbsp;{help && (<img src={imgQuestion} style={{ width: '15px', height: '15px'}} data-tip={help[1]}/>)}</td>
                            <td>
                                <Field placeholder="0.0" name={`warehousing_ubc`} disable={this.state.editMode2} type="text" component={renderNumber} decimalScale={2}/>
                            </td>
                            <td>
                                <Field placeholder="0.0" name={`warehousing_acg`} disable={this.state.editMode2} type="text" component={renderNumber} decimalScale={2}/>
                            </td>
                        </tr>
                        <tr>
                            {/*<td>
                                <img  onClick={() => this.setState({ editMode3: !this.state.editMode3 }) } alt="agregar"
                                      src={this.state.editMode3 ? imgEditar : imgAgregar }
                                      className={this.state.editMode3 ? "divEditar" : "divAgregar" } />
                            </td>*/}
                            <td className="text-left">Overhead&nbsp;{help && (<img src={imgQuestion} style={{ width: '15px', height: '15px'}} data-tip={help[2]}/>)}</td>
                            <td>
                                <Field placeholder="0.0" name={`overhead_ubc`} disable={this.state.editMode3} type="text" component={renderNumber} decimalScale={2}/>
                            </td>
                            <td>
                                <Field placeholder="0.0" name={`overhead_acg`} disable={this.state.editMode3} type="text" component={renderNumber} decimalScale={2}/>
                            </td>
                        </tr>
                        <tr>
                            {/*<td>
                                <img  onClick={() => this.setState({ editMode4: !this.state.editMode4 }) } alt="agregar"
                                      src={this.state.editMode4 ? imgEditar : imgAgregar }
                                      className={this.state.editMode4 ? "divEditar" : "divAgregar" } />
                            </td>*/}
                            <td className="text-left">Contractual fees&nbsp;{help && (<img src={imgQuestion} style={{ width: '15px', height: '15px'}} data-tip={help[3]}/>)}</td>
                            <td>
                                <Field placeholder="0.0" name={`contractual_fees_ubc`} disable={this.state.editMode4} type="text" component={renderNumber} decimalScale={2}/>
                            </td>
                            <td>
                                <Field placeholder="0.0" name={`contractual_fees_acg`} disable={this.state.editMode4} type="text" component={renderNumber} decimalScale={2}/>
                            </td>
                        </tr>
                        <tr>
                            {/*<td>
                                <img  onClick={() => this.setState({ editMode5: !this.state.editMode5 }) } alt="agregar"
                                      src={this.state.editMode5 ? imgEditar : imgAgregar }
                                      className={this.state.editMode5 ? "divEditar" : "divAgregar" } />
                            </td>*/}
                            <td className="text-left">Cost of founds&nbsp;{help && (<img src={imgQuestion} style={{ width: '15px', height: '15px'}} data-tip={help[4]}/>)}</td>
                            <td>
                                <Field placeholder="0.0" name={`cost_funds_ubc`} disable={this.state.editMode5} type="text" component={renderNumber} decimalScale={2}/>
                            </td>
                            <td>
                                <Field placeholder="0.0" name={`cost_funds_acg`} disable={this.state.editMode5} type="text" component={renderNumber} decimalScale={2}/>
                            </td>
                        </tr>
                        <tr>
                            {/*<td>
                                <img  onClick={() => this.setState({ editMode6: !this.state.editMode6 }) } alt="agregar"
                                      src={this.state.editMode6 ? imgEditar : imgAgregar }
                                      className={this.state.editMode6 ? "divEditar" : "divAgregar" } />
                            </td>*/}
                            <td className="text-left">Other&nbsp;{help && (<img src={imgQuestion} style={{ width: '15px', height: '15px'}} data-tip={help[5]}/>)}</td>
                            <td>
                                <Field placeholder="0.0" name={`other_ubc`} disable={this.state.editMode6} type="text" component={renderNumber} decimalScale={2}/>
                            </td>
                            <td>
                                <Field placeholder="0.0" name={`other_acg`} disable={this.state.editMode6} type="text" component={renderNumber} decimalScale={2}/>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <ReactTooltip backgroundColor="white" className="tooltipHelp" multiline={true} />
            </div>
        );
    };
}

export class fieldArrayTableStatic extends React.Component {
    state = {
        invalid: "",
    };

    render() {
        const { imgAgregar, imgEditar, moneda, data, fields, help } = this.props;
        return (
            <div className="div-center">
                <table className="table table-hover table-bordered react-bs-table">
                    <thead>
                        <tr>
                            {/*<th className="sort-column text-left nowrap">ACTIONS</th>*/}
                            <th className="sort-column text-left nowrap">NAME</th>
                            <th className="sort-column text-left nowrap">{`Unitary monthly base cost <${moneda}>`}</th>
                            <th className="sort-column text-left nowrap">Annual cost growth (%)</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            fields.map((field, index) => {
                                return (
                                    <tr key={`filaInputs${index}`}>
                                        {/*<td>
                                            <img
                                                onClick={() => {
                                                    let item = fields.get(index);
                                                    item.editMode = !item.editMode;
                                                    fields.splice(index, 1, item);
                                                    this.forceUpdate();
                                                }} alt="agregar" src={!fields.get(index).editMode ? imgEditar : imgAgregar }
                                                className={!fields.get(index).editMode ? "divEditar" : "divAgregar" } />
                                        </td>*/}
                                        <td>
                                            <Field placeholder="0.0" name={`${field}.name`} disabled={true} type="text" component={renderFieldLabel} decimalScale={2} help={help && help[index]}/>
                                        </td>
                                        <td>
                                            <Field placeholder="0.0" name={`${field}.base_cost`} disable={!fields.get(index).editMode} type="text" component={renderNumber} decimalScale={2}/>
                                        </td>
                                        <td>
                                            <Field placeholder="0.0" name={`${field}.annual_growth`} disable={!fields.get(index).editMode} type="text" component={renderNumber} decimalScale={2}/>
                                        </td>
                                    </tr>
                                );
                            })
                        }
                    </tbody>
                </table>
            </div>
        );
    };
}

export class fieldArrayTableInvestments extends React.Component {
    state = {
        invalid: "",
        name: "",
        value: "",
        year: "",
    };

    render() {
        const { imgAgregar, imgEditar, moneda, imgElimiar, data, fields, nombreCampo, label, meta: { error }} = this.props;

        const agregar = (fields) => {
            if (
                this.state.name !== "" && this.state.value  !== "" &&
                this.state.year  !== ""
            ) {
                let context = this.state;
                let a = _.find(fields.getAll(), (data) => { return data[`${nombreCampo}_name`] === context.name; });
                if (!a) {
                    let objecttt = { ediMode: "false", };
                    objecttt[`${nombreCampo}_name`] = context.name;
                    objecttt[`${nombreCampo}_value`] = context.value;
                    objecttt[`${nombreCampo}_year`] = context.year;
                    fields.push(objecttt);
                    this.setState({
                        name: {},
                        value: "",
                        year: "",
                        invalid: "",
                    });
                    this.forceUpdate();
                }
                else {
                    this.setState({invalid: `${context.name.label} already exist!`});
                }
            } else { this.setState({ invalid : `The fields are incomplete!`}); }
        };

        return (
            <div className="div-center">
                <table className="table table-hover table-bordered react-bs-table">
                    <thead>
                        <tr>
                            <th className="sort-column text-left nowrap">ACTIONS</th>
                            <th className="sort-column text-left nowrap">{`${label} NAME`}</th>
                            <th className="sort-column text-left nowrap">{`${label} <${moneda}>`}</th>
                            <th className="sort-column text-left nowrap">{`${label} YEAR`}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            fields.map((field, index) => {
                                return (
                                    <tr key={`filaInputs${index}`}>
                                        <td>
                                            <img
                                                onClick={() => {
                                                    let item = fields.get(index);
                                                    item.editMode = !item.editMode;
                                                    fields.splice(index, 1, item);
                                                    this.forceUpdate();
                                                }} alt="agregar" src={!fields.get(index).editMode ? imgEditar : imgAgregar }
                                                className={!fields.get(index).editMode ? "divEditar" : "divAgregar" } />
                                                &nbsp;&nbsp;
                                                <img className="divEliminar"
                                                     src={imgElimiar} alt="eliminar" onClick={() => fields.remove(index)}
                                                />
                                        </td>
                                        <td className="minInputWidth">
                                            <Field
                                                name={`${field}.${nombreCampo}_name`}
                                                type="text"
                                                disabled={!fields.get(index).editMode}
                                                component={SelectField}
                                                menuPosition="fixed"
                                                labelKeyObject={true}
                                                options={data}
                                                valueKey="value"
                                                key={`${nombreCampo}select${index}`}
                                            />
                                        </td>
                                        <td className="minInputWidth">
                                            <Field name={`${field}.${nombreCampo}_value`} disable={!fields.get(index).editMode} type="text" component={renderNumber} decimalScale={2}/>
                                        </td>
                                        <td className="minInputWidth">
                                            <Field name={`${field}.${nombreCampo}_year`} disable={!fields.get(index).editMode} type="text" component={renderNumber} decimalScale={2}/>
                                        </td>
                                    </tr>
                                );
                            })
                        }
                        <tr>
                            <td>
                                <img
                                    className="divAgregar"
                                    src={imgAgregar}
                                    alt="agregar"
                                    onClick={() => agregar(fields)}
                                />
                            </td>
                            <td className="minInputWidth">
                                <Select
                                    className="react-select-container"
                                    menuPlacement="auto"
                                    menuPortalTarget={document.body}
                                    backspaceRemovesValue={false}
                                    options={data}
                                    onChange={(e) => {
                                        this.setState({ name: e} );
                                    }}
                                    value={this.state.name}
                                    styles={{
                                        menuPortal: base => ({ ...base, zIndex: 999999999 }),
                                        menu: base => ({ ...base, borderRadius: 15 }),
                                        menuList: base => ({ ...base, borderRadius: 15, padding: 0 })
                                    }}
                                />
                            </td>
                            <td className="minInputWidth">
                                <NumberFormat displayType="input" placeholder="0.0"
                                              className={classNames('form-control')} decimalScale={2}
                                              value={this.state.value} onValueChange={(e) => this.setState({ value: e.floatValue})}
                                />
                            </td>
                            <td className="minInputWidth">
                                <NumberFormat displayType="input" placeholder="0.0"
                                              className={classNames('form-control')} decimalScale={2}
                                              value={this.state.year} onValueChange={(e) => this.setState({ year: e.floatValue})}
                                />
                            </td>
                        </tr>
                    </tbody>
                </table>
                {this.state.invalid !== "" ? (
                    <div style={{ display: "block" }} className="invalid-feedback">{this.state.invalid}</div>
                ) : (<div> </div>)}
                {error ? (
                    <div style={{ display: "block" }} className="invalid-feedback">{error}</div>
                ) : (<div> </div>)}
            </div>
        );
    };
}

class FormTabs extends Component {
    render () {
        const { props: {handleSubmit, arrayInput, buttonSubmit = true, nameForm, help, form}, props } = this;
        const columInput = 12 / this.props.columInput;
        return (
            <form onSubmit={handleSubmit}>
                {help && (
                    <div>
                        <br />
                        <div className="div-center">{help}</div>
                        <br />
                    </div>
                )}
                <div className="form-row align-items-end">
                    {/* eslint-disable-next-line array-callback-return,consistent-return */}
                    {arrayInput.map((value, index) => {
                        if (value.type === "input" && !value.oculto) {
                            return (
                                // eslint-disable-next-line react/no-array-index-key
                                <div className={`form-group col-md-${columInput} ${value.oculto ? "d-none" : ""}`} key={index}>
                                    <label htmlFor={value.name}>
                                        {value.label}
                                        &nbsp;&nbsp;{value.edit && (<img src={imgEditar} style={{ width: '15px', height: '15px'}} onClick={value.edit}/>)}
                                        &nbsp;{value.help && (<img src={imgQuestion} style={{ width: '15px', height: '15px'}} data-tip={value.help}/>)}
                                        <ReactTooltip backgroundColor="white" className="tooltipHelp" multiline={true} />
                                    </label>
                                    <Field
                                        key={`${form}input${index}`}
                                        name={value.name}
                                        disabled={value.disabled}
                                        type={value.inputType || "text"}
                                        component={renderField}
                                        label={value.name}
                                    />
                                </div>
                            );
                        } else if (value.type === "inputDate") {
                            return (
                                // eslint-disable-next-line react/no-array-index-key
                                <div className={`form-group col-md-${columInput} sinborde`} key={index}>
                                    <label htmlFor={value.name}>
                                        {value.label}
                                        &nbsp;&nbsp;{value.edit && (<img src={imgEditar} style={{ width: '15px', height: '15px'}} onClick={value.edit}/>)}
                                        &nbsp;{value.help && (<img src={imgQuestion} style={{ width: '15px', height: '15px'}} data-tip={value.help}/>)}
                                        <ReactTooltip backgroundColor="white" className="tooltipHelp" multiline={true} />
                                    </label>
                                    <Field
                                        key={`${form}labelInput${index}`}
                                        name={value.name}
                                        disabled={value.disabled}
                                        type="text"
                                        component={renderDatePicker}
                                        label={value.name}
                                        suffix={value.suffix}
                                    />
                                </div>
                            );
                        }  else if (value.type === "inputLabe") {
                            return (
                                // eslint-disable-next-line react/no-array-index-key
                                <div className={`form-group col-md-${columInput} sinborde`} key={index}>
                                    <label htmlFor={value.name}>
                                        {value.label}
                                        &nbsp;&nbsp;{value.edit && (<img src={imgEditar} style={{ width: '15px', height: '15px'}} onClick={value.edit}/>)}
                                        &nbsp;{value.help && (<img src={imgQuestion} style={{ width: '15px', height: '15px'}} data-tip={value.help}/>)}
                                        <ReactTooltip backgroundColor="white" className="tooltipHelp" multiline={true} />
                                    </label>
                                    <Field
                                        key={`${form}labelInput${index}`}
                                        name={value.name}
                                        type="text"
                                        disabled={true}
                                        component={renderField}
                                        label={value.name}
                                        suffix={value.suffix}
                                    />
                                </div>
                            );
                        } else if (value.type === "inputNumber") {
                            return (
                                // eslint-disable-next-line react/no-array-index-key
                                <div className={`form-group col-md-${columInput} ${ value.disable ? 'sinborde' : ''}`} key={index}>
                                    <label htmlFor={value.name}>
                                        {value.label}
                                        &nbsp;&nbsp;{value.edit && (<img src={imgEditar} style={{ width: '15px', height: '15px'}} onClick={value.edit}/>)}
                                        &nbsp;{value.help && (<img src={imgQuestion} style={{ width: '15px', height: '15px'}} data-tip={value.help}/>)}
                                        <ReactTooltip backgroundColor="white" className="tooltipHelp" multiline={true} />
                                    </label>
                                    <Field
                                        key={`${form}inputNumber${index}`}
                                        name={value.name}
                                        type="text"
                                        disable={value.disabled}
                                        component={renderNumber}
                                        label={value.name}
                                        suffix={value.suffix}
                                        decimalScale={2}
                                        placeholder="0"
                                        onChangeEspecial={(e) => {
                                            if (value.dataHijosAsociados) {
                                                value.dataHijosAsociados.map((valueItem, index) => {
                                                    const val = e;
                                                    if (eval(valueItem.condicion)) {
                                                        if (eval(valueItem.condicion) === "true") {
                                                            let evalData = eval(valueItem.eval);
                                                            props.change(valueItem.name, evalData);
                                                        } else {
                                                            let evalDataFalse = eval(valueItem.evalFale);
                                                            props.change(valueItem.name, evalDataFalse);
                                                        }
                                                    } else {
                                                        if (valueItem.eval) {
                                                            let evalData = eval(valueItem.eval);
                                                            props.change(valueItem.name, evalData);
                                                        }
                                                    }
                                                });
                                            }
                                        }}
                                    />
                                </div>
                            );
                        } else if (value.type === "multiSlide") {
                            const items = [];
                            for (let i = 0; i < value.numImput; i++) { items.push({}); }
                            return (
                                <div className={`form-group col-md-${value.columnNum}`} key={index}>
                                    <FieldArray
                                        key={`${form}multslide${index}`}
                                        name={value.name}
                                        numImput={value.numImput}
                                        labelTitle={value.label}
                                        nameChildr={value.nameChildr}
                                        component={fieldArraySlide}
                                        labelData={value.labelData}
                                        columInput={columInput}
                                        indexParent={index}
                                        help={value.help}
                                        valueInput={props[value.name]}
                                    />
                                </div>
                            );
                        } else if (value.type === "multiInput") {
                            return (
                                <div className={`form-group col-md-${value.columnNum}`} key={index}>
                                    <label htmlFor={value.name}>
                                        {value.label}
                                        &nbsp;&nbsp;{value.edit && (<img src={imgEditar} style={{ width: '15px', height: '15px'}} onClick={value.edit}/>)}
                                        &nbsp;{value.help && (<img src={imgQuestion} style={{ width: '15px', height: '15px'}} data-tip={value.help}/>)}
                                        <ReactTooltip backgroundColor="white" className="tooltipHelp" multiline={true} />
                                    </label>
                                    <FieldArray
                                        key={`${form}multinput${index}`}
                                        name={value.name}
                                        disabled={value.disabled}
                                        component={fieldArray}
                                        columInput={columInput}
                                        indexParent={index}
                                    />
                                </div>
                            );
                        } else if (value.type === "multiInputTable") {
                            return (
                                <div className={`form-group col-md-${value.columnNum}`} key={index}>
                                    <label htmlFor={value.name}>
                                        {value.label}
                                        &nbsp;&nbsp;{value.edit && (<img src={imgEditar} style={{ width: '15px', height: '15px'}} onClick={value.edit}/>)}
                                        &nbsp;{value.help && (<img src={imgQuestion} style={{ width: '15px', height: '15px'}} data-tip={value.help}/>)}
                                        <ReactTooltip backgroundColor="white" className="tooltipHelp" multiline={true} />
                                    </label>
                                    <FieldArray
                                        key={`${form}multInpTable${index}`}
                                        name={value.name}
                                        nTerritories={value.nTerritories}
                                        component={fieldArrayTable}
                                        nombreCampo={value.nombreCampo}
                                        columInput={columInput}
                                        imgElimiar={value.imgElimiar}
                                        imgAgregar={value.imgAgregar}
                                        indexParent={index}
                                    />
                                </div>
                            );
                        } else if (value.type === "multiInputMaterials") {
                            return (
                                <div className={`form-group col-md-${value.columnNum}`} key={index}>
                                    <FieldArray
                                        key={`${form}multInpTable${index}`}
                                        name={value.name}
                                        component={fieldArrayDirecMaterials}
                                        nombreCampo={value.nombreCampo}
                                        moneda={value.moneda}
                                        columInput={columInput}
                                        imgElimiar={value.imgElimiar}
                                        imgAgregar={value.imgAgregar}
                                        imgEditar={value.imgEditar}
                                        indexParent={index}
                                    />
                                </div>
                            );
                        } else if (value.type === "multiInputEmployee") {
                            return (
                                <div className={`form-group col-md-${value.columnNum}`} key={index}>
                                    <FieldArray
                                        key={`${form}multInpTable${index}`}
                                        name={value.name}
                                        component={fieldArrayEmployee}
                                        nombreCampo={value.nombreCampo}
                                        moneda={value.moneda}
                                        columInput={columInput}
                                        imgElimiar={value.imgElimiar}
                                        imgAgregar={value.imgAgregar}
                                        imgEditar={value.imgEditar}
                                        indexParent={index}
                                    />
                                </div>
                            );
                        } else if (value.type === "multiInputOther") {
                            return (
                                <div className={`form-group col-md-${value.columnNum}`} key={index}>
                                    <FieldArrayOther
                                        key={`${form}multInputOther${index}`}
                                        field={value.name}
                                        nombreCampo={value.nombreCampo}
                                        help={value.help}
                                        moneda={value.moneda}
                                        columInput={columInput}
                                        imgElimiar={value.imgElimiar}
                                        imgAgregar={value.imgAgregar}
                                        imgEditar={value.imgEditar}
                                        indexParent={index}
                                    />
                                </div>
                            );
                        } else if (value.type === "multiInputCompesation") {
                            return (
                                <div className={`form-group col-md-${value.columnNum}`} key={index}>
                                    <FieldArray
                                        key={`${form}multInpTable${index}`}
                                        name={value.name}
                                        component={fieldArrayCompesation}
                                        nombreCampo={value.nombreCampo}
                                        moneda={value.moneda}
                                        columInput={columInput}
                                        imgElimiar={value.imgElimiar}
                                        imgAgregar={value.imgAgregar}
                                        imgEditar={value.imgEditar}
                                        indexParent={index}
                                    />
                                </div>
                            );
                        } else if (value.type === "multiInputOffice") {
                            return (
                                <div className={`form-group col-md-${value.columnNum}`} key={index}>
                                    <FieldArray
                                        key={`${form}multInpTable${index}`}
                                        name={value.name}
                                        help={value.help}
                                        component={fieldArrayTableStatic}
                                        nombreCampo={value.nombreCampo}
                                        moneda={value.moneda}
                                        columInput={columInput}
                                        imgElimiar={value.imgElimiar}
                                        imgAgregar={value.imgAgregar}
                                        imgEditar={value.imgEditar}
                                        indexParent={index}
                                    />
                                </div>
                            );
                        } else if (value.type === "multiInputInvestments") {
                            return (
                                <div className={`form-group col-md-${value.columnNum}`} key={index}>
                                    <FieldArray
                                        key={`${form}multInpInvestment${index}`}
                                        name={value.name}
                                        label={value.label}
                                        component={fieldArrayTableInvestments}
                                        data={value.data}
                                        nombreCampo={value.name}
                                        moneda={value.moneda}
                                        columInput={columInput}
                                        imgElimiar={value.imgElimiar}
                                        imgAgregar={value.imgAgregar}
                                        imgEditar={value.imgEditar}
                                        indexParent={index}
                                    />
                                </div>
                            );
                        } else if (value.type === "select") {
                            return (
                                // eslint-disable-next-line react/no-array-index-key
                                <div className={`form-group col-md-${columInput}`} key={index}>
                                    <label htmlFor={value.name}>
                                        {value.label}
                                        &nbsp;&nbsp;{value.edit && (<img src={imgEditar} style={{ width: '15px', height: '15px'}} onClick={value.edit}/>)}
                                        &nbsp;{value.help && (<img src={imgQuestion} style={{ width: '15px', height: '15px'}} data-tip={value.help}/>)}
                                        <ReactTooltip backgroundColor="white" className="tooltipHelp" multiline={true} />
                                    </label>
                                    <Field
                                        key={`${form}select${index}`}
                                        name={value.name}
                                        disabled={value.disabled}
                                        type="text"
                                        isMulti={value.isMulti}
                                        component={SelectField}
                                        labelKeyObject={value.labelKeyObject}
                                        options={value.data}
                                        labelKey={value.labelKey}
                                        valueKey={value.valueKey}
                                        onSelect={(e) => {
                                            const items = [];
                                            // eslint-disable-next-line no-plusplus
                                            for (let i = 0; i < e.value; i++) { items.push({}); }
                                            if (value.inputHijo) { props.change(value.inputHijo, items); }

                                            if (value.dataHijosAsociados) {
                                                value.dataHijosAsociados.map((valueItem, index) => {
                                                    const val = e;

                                                    if (eval(valueItem.condicion)) {
                                                        if (eval(valueItem.condicion) === "true") {
                                                            let evalData = eval(valueItem.eval);
                                                            props.change(valueItem.name, evalData);
                                                        } else {
                                                            let evalDataFalse = eval(valueItem.evalFale);
                                                            props.change(valueItem.name, evalDataFalse);
                                                        }
                                                    } else {
                                                        if (valueItem.eval) {
                                                            let evalData = eval(valueItem.eval);
                                                            props.change(valueItem.name, evalData);
                                                        }
                                                    }
                                                });
                                            }
                                        }}
                                    />
                                </div>
                            );
                        } else if (value.type === "selectTab") {
                            return (
                                // eslint-disable-next-line react/no-array-index-key
                                <div className={`form-group col-md-${columInput}`} key={index}>
                                    <label htmlFor={value.name}>
                                        {value.label}
                                        &nbsp;&nbsp;{value.edit && (<img src={imgEditar} style={{ width: '15px', height: '15px'}} onClick={value.edit}/>)}
                                        &nbsp;{value.help && (<img src={imgQuestion} style={{ width: '15px', height: '15px'}} data-tip={value.help}/>)}
                                        <ReactTooltip backgroundColor="white" className="tooltipHelp" multiline={true} />
                                    </label>
                                    <Field
                                        name={value.name}
                                        disabled={value.disabled}
                                        type="text"
                                        component={SelectFieldColor}
                                        options={value.data}
                                        label={value.name}
                                        value={value.value}
                                    />
                                </div>
                            );
                        } else if (value.type === "check") {
                            return (
                                // eslint-disable-next-line react/no-array-index-key
                                <div className={`form-group col-md-${columInput}`} key={index}>
                                    <label htmlFor={value.name}>
                                        {value.label}
                                        &nbsp;&nbsp;{value.edit && (<img src={imgEditar} style={{ width: '15px', height: '15px'}} onClick={value.edit}/>)}
                                        &nbsp;{value.help && (<img src={imgQuestion} style={{ width: '15px', height: '15px'}} data-tip={value.help}/>)}
                                        <ReactTooltip backgroundColor="white" className="tooltipHelp" multiline={true} />
                                    </label>
                                    <Field
                                        name={value.name}
                                        disabled={value.disabled}
                                        type="text"
                                        component={renderFieldCheck}
                                        label={value.name}
                                    />
                                </div>
                            );
                        } else if (value.type === "combox") {
                            return (
                                // eslint-disable-next-line react/no-array-index-key
                                <div className={`form-group col-md-${value.columnNum} align-items-end`} key={index}>
                                    <label htmlFor={value.name}>
                                        {value.label}
                                        &nbsp;&nbsp;{value.edit && (<img src={imgEditar} style={{ width: '15px', height: '15px'}} onClick={value.edit}/>)}
                                        &nbsp;{value.help && (<img src={imgQuestion} style={{ width: '15px', height: '15px'}} data-tip={value.help}/>)}
                                        <ReactTooltip backgroundColor="white" className="tooltipHelp" multiline={true} />
                                    </label>
                                    <div className="form-row align-items-end">
                                        {
                                            value.fields.map((field, index) => {
                                                return (
                                                    <div className={`form-group col-md-${columInput}`} key={index}>
                                                        <Field
                                                            type="radio"
                                                            name={value.name}
                                                            disabled={value.disabled}
                                                            label={`${field.label}`}
                                                            value={field.value}
                                                            component={renderFieldRadio}
                                                            onChangeEspecial={(e) => {
                                                                value.dataHijosAsociados.map((valueItem, index) => {
                                                                    const val = e;
                                                                    if (eval(valueItem.condicion)) {
                                                                        if (eval(valueItem.condicion) === "true") {
                                                                            let evalData = eval(valueItem.eval);
                                                                            props.change(valueItem.name, evalData);
                                                                        } else {
                                                                            let evalDataFalse = eval(valueItem.evalFale);
                                                                            props.change(valueItem.name, evalDataFalse);
                                                                        }
                                                                    } else {
                                                                        if (valueItem.eval) {
                                                                            let evalData = eval(valueItem.eval);
                                                                            props.change(valueItem.name, evalData);
                                                                        }
                                                                    }
                                                                });
                                                            }}
                                                        />
                                                    </div>
                                                );
                                            })
                                        }
                                    </div>
                                </div>
                            );
                        }
                    })}
                </div>
                { buttonSubmit === true
                    && (
                        <div className="div-center">
                            <button type="submit" className="btn btn-optional2">
                                Save
                            </button>
                        </div>)
                }
                <ReactTooltip backgroundColor="white" className="tooltipHelp" multiline={true} />
            </form>
        );
    }
};

FormTabs = reduxForm({
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
})(FormTabs);


FormTabs = connect((state, mapStateToProps) => {
    const form = state.form[mapStateToProps.form];
    const camposObject = {};
    if (form && form.registeredFields) {
        const selector = formValueSelector(mapStateToProps.form);
        Object.keys(form.registeredFields).forEach((val, index) => {
            camposObject[val] = selector(state, val);
        });
    }
    return camposObject
})(FormTabs);

export default FormTabs;
