import React, { Component } from 'react';
import PropTypes from 'prop-types';
import WizardProjectSetUp from './ProjectSetUp';
import WizardGeography from "./Geography";
import WizardProducts from "./Products";
import WizardCogs from "./Cogs";
import WizardSag from "./SG&A";
import WizardInvestments from "./Investments";
import WizardReport from "./Report";
import { Link } from 'react-router-dom';
import ReactTooltip from "react-tooltip";

class WizardForm extends Component {

    render() {
        const { nextPage, previousPage, onSubmit, page, setPage, tipo, idProject } = this.props;
        return tipo === "service" ? (
            <div className="div-border-round">
                {page === 1 && <WizardProjectSetUp onSubmit={nextPage} nextPage={nextPage} page={page} tipo={tipo} idProject={idProject} />}
                {page === 2 && (<WizardGeography previousPage={previousPage} nextPage={nextPage} page={page} idProject={idProject} />)}
                {page === 3 && (<WizardProducts previousPage={previousPage} nextPage={nextPage} page={page} idProject={idProject} />)}
                {page === 4 && (<WizardSag previousPage={previousPage} nextPage={nextPage} page={page} idProject={idProject} />)}
                {page === 5 && (<WizardInvestments previousPage={previousPage} nextPage={nextPage} page={page} idProject={idProject} />)}
                {page === 6 && (<WizardReport nextPage={nextPage} onSubmit={onSubmit} idProject={idProject} />)}
            </div>
        ) : (
            <div className="div-border-round">
                {page === 1 && <WizardProjectSetUp onSubmit={nextPage} nextPage={nextPage} page={page} tipo={tipo} idProject={idProject} />}
                {page === 2 && (<WizardGeography previousPage={previousPage} nextPage={nextPage} page={page} idProject={idProject} />)}
                {page === 3 && (<WizardProducts previousPage={previousPage} nextPage={nextPage} page={page} idProject={idProject} />)}
                {page === 4 && (<WizardSag previousPage={previousPage} nextPage={nextPage} page={page} idProject={idProject} />)}
                {page === 5 && (<WizardCogs previousPage={previousPage} nextPage={nextPage} page={page} idProject={idProject} />)}
                {page === 6 && (<WizardInvestments previousPage={previousPage} nextPage={nextPage} page={page} idProject={idProject} />)}
                {page === 7 && (<WizardReport nextPage={nextPage} onSubmit={onSubmit} idProject={idProject} />)}
            </div>
        );
    };
}


// eslint-disable-next-line react/no-unused-prop-types
WizardForm.propTypes = { onSubmit: PropTypes.func.isRequired };

export default WizardForm;
