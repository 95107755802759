import React, { Component } from 'react';
import { connect } from 'react-redux';
import icono from "../../../../../assets/img/report-C.png";
import { validateGeneral, validateCurren, validateOther, validateRateAnSen } from "./validateReport";
import LoadMask from "../../Utils/LoadMask/LoadMask";
import WizardTabsReport from "./TabsContainerReport/WizardTabsReport";
import {isNumber} from "recharts/lib/util/DataUtils";

const arrayTabs = [
    { nombre: "Graphic Analysis", type: "graphyc", data: [], columInput: 4, validate: validateGeneral },
    { nombre: "Project Detail", type: "table", data: [], columInput: 4, validate: validateCurren },
    { nombre: "Key ratios", type: "table", data: [], columInput: 4, validate: validateRateAnSen },
    { nombre: "Cash flow", type: "tableCash", data: [], columInput: 4, validate: validateOther },
    { nombre: "Collections distribution", type: "table", data: [], columInput: 4, validate: validateOther },
];

const format = (num) => parseFloat(num).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');

class WizardReport extends Component {
    state = {
        page: 0,
        arrayTabs: [],
        moneda: "GT",
        monedaCogs: "GT",
        monedaSag: "GT",
        projectType: "",
        annual_rate1: "",
        annual_rate2: "",
        annual_rate3: "",
        annual_rate_base: "",
        open: false,
        idProject: 0,
    };

    componentDidMount() {
        try {
            const {
                leer,
                leerEstado,
                idProject,
                cogs: { item: { results } },
                setUpGeneral: { typeForm, formDataSave: { inflation, proyinfo: {
                    analisis_units, sales_currency, cogs_currency, sga_currency, annual_rate_base, annual_rate1, annual_rate2, annual_rate3
                } } },
            } = this.props;
            const data = { id_project: idProject, infla: inflation, analysis_unit: analisis_units, cogs: results };
            this.setState({idProject});
            leer("final_report/total_final_report/", data);
            this.setState({ moneda: sales_currency });
            this.setState({ monedaCogs: cogs_currency });
            this.setState({ monedaSag: sga_currency });
            this.setState({ projectType: typeForm});
            this.setState({ annual_rate_base });
            this.setState({ annual_rate1 });
            this.setState({ annual_rate2 });
            this.setState({ annual_rate3 });
            this.setState({ arrayTabs });
        } catch (e) {
            console.log("errroooorrrr", e);
        }

        const context = this;
        window.onclick = function(event) {
          if (!event.target.matches('.dropbtn')) {
              context.setState({ open: false})
          }
        }
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps !== nextContext) {
            if (nextProps.item && nextProps.item.report_data) {
                const {
                    graphics_data,
                    cagr_data,
                    sales_cogs_data: { final_sums, products },
                    sga_investments_data,
                    key_financials_data,
                    key_ratios_data,
                    cash_flow_accrual_data,
                    cash_flow_basis_data,
                } = nextProps.item.report_data;
                if (graphics_data) {
                    /*-------------------------------------------- Tab Graficas --------------------------------------------*/
                    arrayTabs[0].data = [];
                    Object.entries(graphics_data).forEach(([key, value], index) => {
                        const dataResponse = value.data.labels ? value.data : value;
                        const item = {};
                        item.name = key.replace(/\_/g, " ").toUpperCase();
                        item.label = dataResponse.columns || dataResponse.data.columns;
                        item.label = item.label.map(val => val[0]);
                        if (key === "sales_by_collection") dataResponse.labels.splice(0, 1);
                        item.data = dataResponse.labels;
                        item.data = item.data.map((col, indexYear) => {
                            const itemCol = { name: col.toUpperCase() };
                            const columnItems = dataResponse.columns || dataResponse.data.columns;
                            columnItems.forEach((val) => {
                                itemCol[val[0]] = val[indexYear+1];
                            });
                            return itemCol;
                        });
                        arrayTabs[0].data.push(item);
                    });
                }

                const {
                    setUpGeneral: { formDataSave: { proyinfo, inflation } },
                } = this.props;
                let percentage = [];
                const analisisUnits = proyinfo && proyinfo.analisis_units || 0;

                let valdiv = 0;
                if (analisisUnits === "Years (Y)") {
                    valdiv = 1;
                    percentage = inflation;
                    for (let x = 0; x < percentage.length; x++) {percentage[x].label = `${percentage[x].horizon_value}`;}
                } else if (analisisUnits === "Quarters (Q)") {
                    valdiv = 4;
                    for (let y = 0; y < inflation.length; y++) {
                        for (let x = 0; x < valdiv; x++) {
                            percentage.push({ idq: Number(x + 1), idy: Number(y + 1), label: `Q${x + 1}-Y${y + 1}`,
                                value: parseFloat(`${inflation[y].value / valdiv}`).toFixed(2),
                            });
                        }
                    }
                } else if (analisisUnits === "Months (M)") {
                    valdiv = 12;
                    for (let y = 0; y < inflation.length; y++) {
                        for (let x = 0; x < valdiv; x++) {
                            percentage.push({ idm: Number(x + 1), idy: Number(y + 1), label: `M${x + 1}-Y${y + 1}`,
                                value: parseFloat(`${inflation[y].value / valdiv}`).toFixed(2)
                            });
                        }
                    }
                }

                const labels = (array, carg) => {
                    const item = [];
                    array.forEach((val, ind) => {
                        ind === 0 && (item[ind] = "ITEM");
                        item[ind+1] = val.label || val.value || val.val || val;
                        (carg === true && array.length-1 === ind) && (item[ind+2] = "CARGA (%)");
                    });
                    return item;
                };

                const itemS = (array, name, carg, labelItem, sufix, negrita=false) => {
                    const item = {};
                    array.forEach((val, ind) => {
                        ind === 0 && (item[`val${ind}`] = {val: name, negrita: negrita});
                        item[`val${ind+1}`] = {val: format(val[labelItem] || val)+sufix, negrita: negrita};
                        (isNumber(Number(carg)) && array.length-1 === ind && (item[`val${ind+2}`] = {val: format(`${carg || "0.0"}`)+"%", negrita: negrita}));
                    });
                    return item;
                };

                const itemCASH = (arrayT, arrayF, name, labelItem, sufix, negrita=false) => {
                    const item = {};
                    arrayT.forEach((val, ind) => {
                        const valF = arrayF[ind];
                        ind === 0 && (item[`val${ind}`] = {val: name, negrita: negrita});
                        item[`val${ind+1}`] = {val:
                                {
                                    true: format(val[labelItem] || val)+sufix,
                                    false: format(valF[labelItem] || valF)+sufix
                                },
                            negrita: negrita
                        };
                    });
                    return item;
                };

                const stock = final_sums.total_active_customer_stock;
                const stockC = cagr_data.cagr_total_active_customer_stock;
                const priceAvg = final_sums.effective_price_avg;
                const priceAvgC =cagr_data.cagr_effective_price_avg;
                const revenue = final_sums.revenue;
                const revenueC =cagr_data.cagr_revenue;
                const totalCogs = final_sums.total_cogs;
                const totalCogsC =cagr_data.cagr_total_cogs;
                const directL = final_sums.direct_labor;
                const directLC =cagr_data.cagr_direct_labor;
                const directLM = final_sums.direct_materials;
                const directLMC =cagr_data.cagr_direct_materials;
                const warehousing = final_sums.warehousing;
                const warehousingC =cagr_data.cagr_warehousing;
                const directS = final_sums.direct_shipping;
                const directSC =cagr_data.cagr_direct_shipping;
                const overhead = final_sums.overhead;
                const overheadC =cagr_data.cagr_overhead;
                const contractualF = final_sums.contractual_fees;
                const contractualFC =cagr_data.cagr_contractual_fees;
                const costF = final_sums.cost_funds;
                const costFC =cagr_data.cagr_cost_funds;
                const otherC = final_sums.other_cogs;
                const otherCC =cagr_data.cagr_other_cogs;
                const grossP = final_sums.gross_profit;
                const grossPC =cagr_data.cagr_gross_profit;
                const grossM = final_sums.gross_margin;
                const grossMC =cagr_data.cagr_gross_margin;
                const periods = sga_investments_data.periods;
                const periodsC =cagr_data.cagr_total_final_sga;
                const compensationC =cagr_data.cagr_total_compensation_employees;
                const totalOfC =cagr_data.cagr_total_office;
                const totalMC =cagr_data.cagr_total_marketing;
                const totalSC =cagr_data.cagr_total_sales;
                const totalITC =cagr_data.cagr_total_it;
                const totalRDC =cagr_data.cagr_total_rd;
                const totalFCC =cagr_data.cagr_total_finance;
                const cagrC =cagr_data.cagr_ebitda;

                /*-------------------------------------------- Project Detail --------------------------------------------*/
                arrayTabs[1].data = [];
                let item = {
                    name: "P&L",
                    label: labels(percentage, true),
                    data: { count: 0, results: [], },
                };
                const { annual_rate_base, annual_rate1, annual_rate2, annual_rate3, projectType } = this.state;
                item.data.results.push(itemS(percentage, "Inflation (%)", "0.0", "value", "%"));
                item.data.results.push(itemS(stock, "Quantity (EOP)", stockC, null, ""));
                item.data.results.push(itemS(priceAvg, "Avg. Price", priceAvgC, null, ""));
                item.data.results.push(itemS(revenue, `Total Sales <${this.state.moneda}>`, revenueC, null, "", true));
                if (projectType === "wholesale") {
                    item.data.results.push(itemS(totalCogs, `Total COGS <${this.state.monedaCogs}>`, totalCogsC, null, "", true))
                    item.data.results.push(itemS(directL, "Direct labor", directLC, null, ""));
                    item.data.results.push(itemS(directLM, "Direct material", directLMC, null, ""));
                    item.data.results.push(itemS(warehousing, "Warehousing/storing", warehousingC, null, ""));
                    item.data.results.push(itemS(directS, "Direct shipping & freight", directSC || "0.0", null, ""));
                    item.data.results.push(itemS(overhead, "Overhead", overheadC || "0.0", null, ""));
                    item.data.results.push(itemS(contractualF, "Contractual fees", contractualFC || "0.0", null, ""));
                    item.data.results.push(itemS(costF, "Cost of founds", costFC || "0.0", null, ""));
                    item.data.results.push(itemS(otherC, "Miscellaneous", otherCC || "0.0", null, ""));
                }
                item.data.results.push(itemS(grossP, "Total G. Profit", grossPC || "0.0", null, "", true));
                item.data.results.push(itemS(grossM, "Total G. Margin", grossMC || "0.0", null, "%", true));
                item.data.results.push(itemS(periods, `Total SG&A <${this.state.monedaSag}>`, periodsC || "0.0", "total_compensation_employees", "", true));
                item.data.results.push(itemS(periods, "Compensation expenses", compensationC, "total_compensation_employees", ""));
                item.data.results.push(itemS(periods, "Office expenses", totalOfC, "total_office", ""));
                item.data.results.push(itemS(periods, "Marketing expenses", totalMC, "total_marketing", ""));
                item.data.results.push(itemS(periods, "Sales expenses", totalSC, "total_sales", ""));
                item.data.results.push(itemS(periods, "IT & Comunication expenses", totalITC, "total_it", ""));
                item.data.results.push(itemS(periods, "R&D expenses", totalRDC, "total_rd", ""));
                item.data.results.push(itemS(periods, "Finance expenses", totalFCC, "total_finance", ""));
                item.data.results.push(itemS(periods, "Operation income (EBITDA)", cagrC, "ebitda", "", true));
                arrayTabs[1].data.push(item);

                const floattt = (num) => { return Number(num).toFixed(2)+"%" };
                item = {
                    name: "KEY FINANCIALS",
                    label: ["ITEM", floattt(annual_rate_base), floattt(annual_rate1), floattt(annual_rate2), floattt(annual_rate3)],
                    data: { count: 0, results: [], },
                };
                item.data.results.push(itemS(key_financials_data, "NPV", null, null, "%"));
                arrayTabs[1].data.push(item);


                const { avg_price_per_unit, avg_cogs_per_unit, avg_gprofit_per_unit, avg_gmargin_per_unit,
                    avg_sga_per_unit, avg_price_per_period, avg_cogs_per_period, avg_gprofit_per_period,
                    avg_gmargin_per_period, avg_sga_per_period, avg_price_per_employee, avg_cogs_per_employee,
                    avg_gprofit_per_employee, avg_gmargin_per_employee, avg_sga_per_employee,
                } = key_ratios_data;

                /*-------------------------------------------- Key Ratio --------------------------------------------*/
                arrayTabs[2].data = [];
                item = {
                    name: "KEY RATIOS",
                    label: labels(percentage, true),
                    data: { count: 0, results: [], },
                };
                item.data.results.push(itemS(avg_price_per_unit, "Avg. Price/unit", cagr_data.cagr_avg_price_per_unit, null, ""));
                projectType === "wholesale" && (item.data.results.push(itemS(avg_cogs_per_unit, "Avg. COGS/unit", cagr_data.cagr_avg_cogs_per_unit, null, "")));
                item.data.results.push(itemS(avg_gprofit_per_unit, "Avg. G. Profit/unit", cagr_data.cagr_avg_gprofit_per_unit, null, ""));
                item.data.results.push(itemS(avg_gmargin_per_unit, "Avg. G. Margin/unit", cagr_data.cagr_avg_gmargin_per_unit, null, ""));
                item.data.results.push(itemS(avg_sga_per_unit, "Avg. SG&A/unit", cagr_data.cagr_avg_sga_per_unit, null, ""));
                item.data.results.push(itemS(avg_price_per_period, "Avg. Price/period", cagr_data.cagr_avg_price_per_period, null, ""));
                projectType === "wholesale" && (item.data.results.push(itemS(avg_cogs_per_period, "Avg. COGS/period", cagr_data.cagr_avg_cogs_per_period, null, "")));
                item.data.results.push(itemS(avg_gprofit_per_period, "Avg. G. Profit/period", cagr_data.cagr_avg_gprofit_per_period, null, ""));
                item.data.results.push(itemS(avg_gmargin_per_period, "Avg. G. Margin/period", cagr_data.cagr_avg_gmargin_per_period, null, ""));
                item.data.results.push(itemS(avg_sga_per_period, "Avg. SG&A/period", cagr_data.cagr_avg_sga_per_period, null, ""));
                item.data.results.push(itemS(avg_price_per_employee, "Avg. Price/employees", cagr_data.cagr_avg_price_per_employee, null, ""));
                projectType === "wholesale" && (item.data.results.push(itemS(avg_cogs_per_employee, "Avg. COGS/employees", cagr_data.cagr_avg_cogs_per_employee, null, "")));
                item.data.results.push(itemS(avg_gprofit_per_employee, "Avg. G. Profit/employees", cagr_data.cagr_avg_gprofit_per_employee, null, ""));
                item.data.results.push(itemS(avg_gmargin_per_employee, "Avg. G. Margin/employees", cagr_data.cagr_avg_gmargin_per_employee, null, ""));
                item.data.results.push(itemS(avg_sga_per_employee, "Avg. SG&A/employees", cagr_data.cagr_avg_sga_per_employee, null, ""));
                arrayTabs[2].data.push(item);

                /*-------------------------------------------- Cash Flow --------------------------------------------*/
                arrayTabs[3].data = [];
                item = {
                    name: "Cash Flow",
                    label: labels(percentage, false),
                    data: { count: 0, results: [], },
                };
                item.data.results.push(itemS(cash_flow_accrual_data.investment, "Initial investment", null, "value", "%", true));
                item.data.results.push(itemCASH(cash_flow_accrual_data.cash_in, cash_flow_basis_data.cash_in, "Cash in (+)",  "value", "%"));
                item.data.results.push(itemCASH(cash_flow_accrual_data.cash_out, cash_flow_basis_data.cash_out, "Cash out (-)",  "value", "%"));
                item.data.results.push(itemCASH(cash_flow_accrual_data.balance, cash_flow_basis_data.balance, "Balance",  "value", "%", true));
                arrayTabs[3].data.push(item);

                /*-------------------------------------------- Colletion Distribution --------------------------------------------*/
                arrayTabs[4].data = [];
                item = {
                    name: "COLLECTIONS DISTRIBUTION (DAYS)",
                    label: ["ITEM", "CASH", "30", "60", "85", "120", "150", "180", "210", "240", "270", "300", "330", "360"],
                    data: { count: 0, results: [], },
                };
                Object.entries(products).forEach(([key, prod], index) => {
                    item.data.results.push(itemS(prod.sales_collection, prod.name, null, "value", "%", true));
                });
                arrayTabs[4].data.push(item);
            }
            this.setState({ arrayTabs });


        }
    }

    nextPage = () => {
        // eslint-disable-next-line react/destructuring-assignment,react/no-access-state-in-setstate
        this.setState({ page: this.state.page + 1 });
    };

    previousPage = () => {
        // eslint-disable-next-line react/destructuring-assignment,react/no-access-state-in-setstate
        this.setState({ page: this.state.page - 1 });
    };

    setPage = (page) => {
        // eslint-disable-next-line radix
        this.setState({ page: Number.parseInt(page) });
    };

    opneMenu = () => { this.setState({ open: !this.state.open });};

    openDOC = (type) => {
        let urlDOC = "";
        type==="E" && (urlDOC = '/api/proyectos/export_excel/?idproj=' + this.state.idProject + '&token=' + localStorage.getItem('token'));
        type==="P" && (urlDOC = '/api/proyectos/export_pdf/?idproj=' + this.state.idProject + '&token=' + localStorage.getItem('token'));
        window.open(urlDOC, '_blank');
    };

    render() {
        try {
            const { nombreV, loader, setUpGeneral: { formDataSave: { proyinfo: { name } } }, estado } = this.props;
            return (
                <div>

                    { estado && (
                        <div className="dropdown left-btn">
                            <button onClick={this.opneMenu} className="dropbtn">Export</button>
                            <div id="myDropdown" className={`dropdown-content ${this.state.open===true && "show"}`}>
                                <a onClick={() => this.openDOC("E")} >Excel</a>
                                <a onClick={() => this.openDOC("P")} >PDF</a>
                            </div>
                        </div>
                    )}

                    <div className="content-wizarform">
                        <LoadMask loading={false}>
                            { estado ? (
                                <div>
                                    <div className="header-wizard-form">
                                        {nombreV && (<div className="div-center"><span><h1>{name}</h1></span></div>)}
                                        <br />
                                        <div className="my-auto div-center">
                                            <img src={icono} alt="icono" className="icono-step" />
                                            <span><h3 className="div-center">This is how your idea looks like! (Hurray!!!)</h3></span>
                                        </div>
                                    </div>
                                    <br /> <br /> <br /> <br />
                                    <WizardTabsReport
                                        {...this.props}
                                        page={this.state.page}
                                        arrayTabs={this.state.arrayTabs}
                                        setPage={this.setPage}
                                    />
                                </div>
                            ) : (
                                <div className="div-center text-amarillo">
                                    <br/><br/><br/><br/>
                                    <i className="fa fa-exclamation-triangle fa-5x text-amarillo"/>
                                    <h2>The data of your project is still not complete, fill the missing information to see the reports.</h2>
                                    <br/><br/><br/><br/>
                                </div>
                            )}
                        </LoadMask>
                    </div>
                </div>
            );
        } catch (e) {
            return (
                <div>
                    <div className="header-wizard-form">

                        <br />
                        <div className="my-auto div-center">
                            <img src={icono} alt="icono" className="icono-step" />
                            <span><h3>This is how your idea looks like! (Hurray!!!)</h3></span>
                        </div>
                    </div>
                    <br /> <br /> <br /> <br />

                    <div className="content-wizarform">
                        <div className="div-center text-amarillo">
                            <i className="fa fa-exclamation-triangle fa-5x text-amarillo"/>
                            <h2>The data of your project is still not complete, fill the missing information to see the reports.</h2>
                            <br/><br/>
                        </div>
                    </div>
                </div>
            );
        }
    }
}

export default connect()(WizardReport);
