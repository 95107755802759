import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import './login.css';
import LoadMask from "Utils/LoadMask/LoadMask";
import logo from "../../../../../assets/img/logo.png";
import arrow_rigth from "../../../../../assets/img/arrow-rigth.png";
import ConfirmResetForm from "./ConfirmResetForm";

class ConfirmResetPassword extends Component {
    render() {
        const { loader, confirmResetPass } = this.props;
        if (localStorage.getItem('token')) {
            return (<Redirect to="/" />);
        }

        const onSubmit = (data) => {
            confirmResetPass({
                uid: this.props.match.params.uid,
                token: this.props.match.params.token,
                new_password: data.password,
                re_new_password: data.confirmPassword,
            });
        };

        return (
            <div className="blue-gradient-bg-register div-center">
                <div className="card card-login-register">
                    <Link className="card-regresar" to="/login">
                        <img src={arrow_rigth} alt="logo" width={10} />
                        Back
                    </Link>
                    <LoadMask loading={loader} light>
                        <img src={logo} alt="logo" width={225} />
                        <ConfirmResetForm name="restPass" onSubmit={onSubmit} />
                    </LoadMask>
                </div>
            </div>
        );
    }
}

export default ConfirmResetPassword;
