import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import LoginForm from './LoginForm';
import './login.css';
import LoadMask from "Utils/LoadMask/LoadMask";
import logo from "../../../../../assets/img/logo.png";

class Login extends Component {
    state = {
        // eslint-disable-next-line react/no-unused-state
        animate: "",
        animateFondo: "",
        mystyle: {
            height: window.innerHeight,
            width: window.innerWidth,
        },
    };

    static propTypes = {
        onSubmit: PropTypes.func.isRequired,
    };

    componentDidMount(props) {
        this.state = { prueba: true };
        setTimeout(() => {
            this.setState({animate: "animate"})
        }, 550);
        setTimeout(() => {
            this.setState({animateFondo: "animate"})
        }, 1000);

        window.addEventListener("resize", function() {
            if (window.innerWidth<=850) {
                const mystyle = {
                    height: window.innerHeight,
                    width: window.innerWidth,
                };
                this.setState({mystyle: mystyle});
            }
        }.bind(this), false);

        this.props.match.params.token  && localStorage.setItem('token', this.props.match.params.token);
    }

    render() {
        const { onSubmit, loader, errorLogin } = this.props;
        if (localStorage.getItem('token')) {
            return (<Redirect to="/" />);
        }

        return (
            <div className="blue-gradient">
                <LoadMask loading={loader} light>
                    <div className={`blue-gradient-bg ${this.state.animateFondo}`} style={this.state.mystyle} >
                        <div className={`card-login ${this.state.animate}`}>
                            <p>You are one step closer to your new bussiness, project or venture idea</p>
                            <img src={logo} alt="logo" height={75} />
                                <LoginForm onSubmit={onSubmit} errorLogin={errorLogin}/>
                                <div className="d-flex align-items-start flex-column foter">
                                    <Link to="/reset-password">Forgot your password?</Link>
                                    {/* <br /> */}
                                    <span className="mt-1">Need a Onepage.bussines account?&nbsp;
                                        <Link to="/register"> Create an account?</Link>
                                    </span>
                                </div>
                        </div>
                    </div>
                </LoadMask>
            </div>
        );
    }
}

export default Login;
