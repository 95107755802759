import Modal from 'react-responsive-modal';
import React, {Component} from 'react';
import validate from "./validateSG&A";
import WizardTabsForm from "../TabsContainer/WizardTabsForm";
import cloneDeep from 'lodash/cloneDeep';
import 'react-rangeslider/lib/index.css';
import LoadMask from "../../Utils/LoadMask/LoadMask";

class ModalProductos extends Component {

    state = {
        page: 0,
        reglaMutiMoneda: true,
        reglaMutiYearsInflation: true,
        values: {
            min: 2,
            max: 10,
        },
    };

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({ page: Number(nextProps.numTab) });
    }

    previousPage = () => {
        this.setState({ page: this.state.page - 1 });
    };

    enviadoExito = () => {
        this.props.leer("sga/sgabyprojectList", this.props.idProject)
        this.props.onCloseModal();
        //if (this.props.arrayTabs.length > 1) this.props.nextPage();
        //else this.props.onCloseModal();
        //this.props.onCloseModal();
        //this.props.dispatch(reset('formSgya'));
        //this.setPage(0);
    }

    // eslint-disable-next-line class-methods-use-this
    onSubmitsss = (valForm) => {
        const data = { idproy: this.props.idProject, sga: [] };
        const compesation = cloneDeep(valForm.compesation);

        if (compesation) {
            for (let i = 0; i < compesation.length; i++) {
                delete compesation[i].level.label;
                delete compesation[i].level.value;
                delete compesation[i].ediMode;
                compesation[i].variable_salary = 0;
            }
        }

        valForm.office.forEach((val, index) => {
            valForm.office[index].annual_growth = val.annual_growth === "" ? 0 : val.annual_growth;
            valForm.office[index].base_cost = val.base_cost === "" ? 0 : val.base_cost;
        });

        valForm.markCost.forEach((val, index) => {
            valForm.markCost[index].annual_growth = val.annual_growth === "" ? 0 : val.annual_growth;
            valForm.markCost[index].base_cost = val.base_cost === "" ? 0 : val.base_cost;
        });

        valForm.salesCost.forEach((val, index) => {
            valForm.salesCost[index].annual_growth = val.annual_growth === "" ? 0 : val.annual_growth;
            valForm.salesCost[index].base_cost = val.base_cost === "" ? 0 : val.base_cost;
        });

        valForm.itComunCost.forEach((val, index) => {
            valForm.itComunCost[index].annual_growth = val.annual_growth === "" ? 0 : val.annual_growth;
            valForm.itComunCost[index].base_cost = val.base_cost === "" ? 0 : val.base_cost;
        });

        valForm.rdCost.forEach((val, index) => {
            valForm.rdCost[index].annual_growth = val.annual_growth === "" ? 0 : val.annual_growth;
            valForm.rdCost[index].base_cost = val.base_cost === "" ? 0 : val.base_cost;
        });

        valForm.finCost.forEach((val, index) => {
            valForm.finCost[index].annual_growth = val.annual_growth === "" ? 0 : val.annual_growth;
            valForm.finCost[index].base_cost = val.base_cost === "" ? 0 : val.base_cost;
        });

        data.sga[0] = {id:1, name: "Office Cost", list: valForm.office, total: valForm.office_cost || 0 };
        data.sga[1] = {id:2, name: "Marketing Cost", list: valForm.markCost, total: valForm.marketing_cost || 0 };
        data.sga[2] = {id:3, name: "Sales Cost", list: valForm.salesCost, total: valForm.sales_cost || 0 };
        data.sga[3] = {id:4, name: "R&D Cost", list: valForm.itComunCost, total: valForm.it_communications_cost || 0 };
        data.sga[4] = {id:5, name: "IT & Communications Cost", list: valForm.rdCost, total: valForm.r_d_cost || 0 };
        data.sga[5] = {id:6, name: "Finance Cost", list: valForm.finCost, total: valForm.finance_cost || 0 };
        data.sga[6] = {id:7, name: "Compensation & benefits", list: compesation || [], total: valForm.compensation_benefits_cost || 0 };

        this.props.crear("proyectos/createsga/", data, this.enviadoExito);
    };

    render() {
        const { value, reverseValue } = this.state;
        const styleModal = { modal: { borderRadius: 25, maxWidth: "95%", margin: "50px auto" , padding: "0px" } };
        const styleLinea = { width: "100%" };
        const { open, onCloseModal, onSubmitsss } = this.props;
        return (
            <Modal
                open={open}
                animationDuration={1000}
                onClose={onCloseModal}
                center
                styles={styleModal}
                closeIconSize={0}
            >
                <div className="mx-5 my-3">
                    <div className="font-weight-bold">
                        {this.props.arrayTabs.length > 1 ? `${this.props.arrayTabs[this.state.page].nombre}`: "Selling, General and Administrative expenses (SG&A)"}
                    </div>
                </div>
                <LoadMask loading={this.props.loaderMoneda} blur>
                    <div className="lineaAzul" style={{ ...styleLinea, height: 20 }} />
                    <div className="mx-5 my-3">
                        <WizardTabsForm
                            {...this.props}
                            nameForm="formSgya"
                            initialValue={this.props.initialValue}
                            page={this.state.page}
                            arrayTabs={this.props.arrayTabs}
                            nextPage={this.props.nextPage}
                            previousPage={this.previousPage}
                            setPage={this.props.setPage}
                            onSubmitsss={this.onSubmitsss}
                            errorForm={{}}
                            validateForm={validate}
                            enableReinitialize={true}
                        />
                    </div>
                </LoadMask>
            </Modal>
        );
    }
}

export default (ModalProductos);
