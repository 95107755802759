import React, { PureComponent } from 'react';
import {BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer} from 'recharts';

const colores = [
    "#D1523D", "#AD4485",
    "#2E5389", "#BA7B36",
    "#ACB53C", "#CD7672",
    "#679BF9", "#3CBFA9",
    "#87CDF4", "#C98DC7",
    "#7D5791", "#7EC4C2",
];

const monedaFormat = (num) => parseFloat(num).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');

class ReportGraphyc extends PureComponent {
    render() {
        const { data } = this.props;
        return (
            <div className="form-row">
                {
                    data.map((value, index) =>
                        (
                            <div className="form-group col-xs-12 col-sm-12 col-lg-6 min-div" key={`graphy${index}`}>
                                <div className="min-div-h">
                                    <br />
                                    <br />
                                    <h6 className="div-center text-bold">{value.name.toUpperCase()}</h6>
                                    <ResponsiveContainer width="99%" height={300}>
                                        <BarChart key={`graphy${index}`}
                                            width={500}
                                            height={300}
                                            data={value.data}
                                            margin={{
                                                top: 5, right: 75, left: 75, bottom: 5,
                                            }}
                                        >
                                            <XAxis dataKey="name"/>
                                            <YAxis tickFormatter={monedaFormat} />
                                            <Tooltip cursor={{fill: '#fff'}}/>
                                            <Legend />
                                            {
                                                value.label && value.label.map((label, indColor) => {
                                                    return (
                                                        <Bar
                                                            dataKey={label}
                                                            fill={colores[indColor]}
                                                            key={indColor}
                                                            barSize={35}
                                                            margin={{right: 50, left: 50}}
                                                            radius={[8, 8, 0, 0]}/>
                                                    );
                                                })
                                            }
                                        </BarChart>
                                    </ResponsiveContainer>
                                </div>
                            </div>
                        )
                    )
                }
            </div>
        );
    }
}
export default (ReportGraphyc);
