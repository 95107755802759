import { connect } from 'react-redux';
import { actions } from '../../../../redux/modules/Report/report';
import WizardReport from "./WizardReport";

const ms2p = (state) => {
    return {
        ...state.report,
        cogs: state.cogs,
        formly: state.formlyAll.item,
        setUpGeneral: state.setUpGeneral,
        login: state.login,
    };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(WizardReport);
