import { handleActions } from 'redux-actions';
import { initialize as initializeForm } from 'redux-form';
import { AlertManager } from "alerts";
import { api } from "api";
import {push} from "react-router-redux";
import Swal from "sweetalert2";

const LOADER = 'INICIO_LOADER';
const PROJECTS = 'INICIO_PROJECTS';

export const constants = {
    LOADER,
    PROJECTS,
};

// ------------------------------------
// Pure Actions
// ------------------------------------

export const setProjects = projects => ({
    type: PROJECTS,
    projects,
});

const setLoader = loader => ({
    type: constants.LOADER,
    loader,
});

// ------------------------------------
// Actions
// ------------------------------------

export const getProject = (filtro, id, page, seach) => (dispatch) => {
    dispatch(setLoader(true));
    api.get(`proyectos/getproys/?filtro=${filtro}&id=${id}&page=${page}&searchname=${seach}`).then((data) => {
        dispatch(setProjects(data));
    }).catch(() => {}).finally(() => dispatch(setLoader(false)));
};

export const deletProject = (idPro, id) => (dispatch) => {
    Swal.fire({
        title: 'Are you sure?',
        text: 'Once deleted this action can not be undone',
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete',
        cancelButtonText: 'Cancel',
        reverseButtons: true,
    }).then((result) => {
        if (result.value) {
            dispatch(setLoader(true));
            api.post("proyectos/destroyproject/", { idproy: idPro }).then(() => {
                AlertManager.success('Project Deleted', 'Success', 3000);
                dispatch(getProject(0, id, 1, ""));
            }).catch((e) => {
                if (e[0]) AlertManager.error(e[0], 'ERROR', 0);
                else AlertManager.error('', 'ERROR', 0);
            }).finally(() => {
                dispatch(setLoader(false));
            });
        }
    });
};

export const updateProject = (url, data, id) => (dispatch) => {
    dispatch(setLoader(true));
    api.post(url, data).then(() => {
        AlertManager.success('Updated', 'Success', 3000);
        dispatch(getProject(0, id, 1, ""));
    }).catch((e) => {
        if (e[0]) AlertManager.error(e[0], 'ERROR', 0);
        else AlertManager.error('', 'ERROR', 0);
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

export const actions = {
    getProject,
    deletProject,
    updateProject,
};

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [PROJECTS]: (state, { projects }) => {
        return {
            ...state,
            projects,
        };
    },
};

export const initialState = {
    loader: false,
    projects: {},
};

export default handleActions(reducers, initialState);
