import React, {Component} from 'react';
import {connect} from 'react-redux';
import {TableHeaderColumn} from "react-bootstrap-table";
import icono from "../../../../../assets/img/cogs-C.png";
import Grid from "../../Utils/Grid";
import ModalCogs from "./ModalCogs";
import ModalTotalCogs from "./ModalTotalCogs";
import ModalTotalSalesCogs from "./ModalTotalSalesCogs"
import {standardActions} from "../../Utils/Grid/StandardActions";
import "./productos.css";
import arrayTabs from "./arryTabs";
import ReactTooltip from "react-tooltip";
import felchaI from "../../../../../assets/img/flecha-izquierda.png";
import felchaD from "../../../../../assets/img/flecha-derecha.png";
import {RenderNumberGrid} from  "../../Utils/renderField/renderReadField";

const initialValue = {
    direct_shipping_ubc: "",
    direct_shipping_acg: "",
    warehousing_ubc: "",
    warehousing_acg: "",
    overhead_ubc: "",
    overhead_acg: "",
    contractual_fees_ubc: "",
    contractual_fees_acg: "",
    cost_funds_ubc: "",
    cost_funds_acg: "",
    other_ubc: "",
    other_acg: "",
};

let arrayProduct = [];

const monedaFormat = (num) => parseFloat(num).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');

class WizardGeography extends Component {

    state = {
        open: false,
        openM: false,
        openTotalSales: false,
        openTotalSalesProduct: false,
        idProject: 0,
        inflation: 0,
        analysis_unit: 0,
        salesGrowthRate: false,
        custAttrition: false,
        activeCustomer: false,
        priceDiscount: false,
        detailedCOGS: false,
        moneda: "GTQ",
        monedaMainCur: "GTQ",
        data: {
            count: 0,
            results: [],
        },
        arrayTabsModal: arrayTabs,
        initialValues: initialValue,
        finish: false,
    };

    dataForm=[];

    componentDidUpdate() {
        ReactTooltip.rebuild();
    }

    componentDidMount() {
        const idProject = this.props.setUpGeneral.idForm;
        const formSaveData = this.props.setUpGeneral.formDataSave;
        const geographyList = this.props.setUpGeneral.geographyList;
        const inflation = formSaveData ? formSaveData.inflation: 0;
        const analysis_unit = formSaveData ? formSaveData.proyinfo.analisis_units: 0;
        this.setState({ analysis_unit });
        this.setState({ inflation });
        this.setState({ idProject });
        this.props.leer("cogs/get_cogs", idProject);

        this.setState({ monedaMainCur: formSaveData && formSaveData.proyinfo.main_currency })
        this.setState({ moneda: formSaveData && formSaveData.proyinfo.sales_currency }, () => {
            const arrayTabsModal = arrayTabs;
            arrayTabsModal[1].inputs[0].moneda= this.state.moneda;
            arrayTabsModal[2].inputs[0].moneda= this.state.moneda;
            arrayTabsModal[3].inputs[0].moneda= this.state.moneda;
            this.setState({ arrayTabsModal });
        });
        const context = this;
        window.onclick = function(event) {
            if (!event.target.matches('.btnM')) context.setState({ openM: false})
        }
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps !== nextContext) {
            if (nextProps.itemDataForm && nextProps.itemDataForm.count > 0) {

                arrayProduct = [];
                nextProps.itemDataForm.results.forEach((valTerritory, indexT) => {
                    valTerritory && valTerritory.segment.forEach((valSegment, indexS) => {
                        valSegment && valSegment.psegment.forEach((valPsemgent, indexS) => {
                            const product = {};
                            product.territory = valTerritory.name;
                            product.id_seg = valSegment.id;
                            product.segment = valSegment.name;
                            product.detail = valPsemgent;
                            product.value = valSegment.id;
                            product.label = valPsemgent.name;
                            arrayProduct.push(product);
                        })
                    })
                });

                const arrayTabsModal = arrayTabs;
                arrayTabsModal[0].inputs[0].data = arrayProduct;
                this.setState({ arrayTabsModal });
            }

            if (nextProps.item && nextProps.item.count > 0) {

                const data = {
                    count: nextProps.item.count,
                    results: [],
                };

                const datA = nextProps.item.results;
                _.forEach(datA, (itemCogs) => {
                    data.results.push({
                        id: itemCogs.id,
                        name: itemCogs.product.name,
                        geography: { segment: itemCogs.segment, territory: itemCogs.territory },
                        totalMaterial: monedaFormat(itemCogs.total_material),
                        totalEmployyes: monedaFormat(itemCogs.total_employees),
                        totalCogs: monedaFormat(itemCogs.total_cogs),
                    });

                    for (let i = 0; i < itemCogs.employee_cogs.length; i++) {
                        itemCogs.employee_cogs[i].level.value = itemCogs.employee_cogs[i].level_id;
                    }

                    this.dataForm[itemCogs.id] = {
                        id: itemCogs.id,
                        product: {
                                value: itemCogs.id_seg,
                                label: itemCogs.product.name,
                                territory: itemCogs.territory,
                                id_seg: itemCogs.id_seg,
                                segment: itemCogs.segment,
                                details: itemCogs.product,
                        },
                        segment: itemCogs.segment,
                        territory: itemCogs.territory,
                        employee_cogs: itemCogs.employee_cogs,
                        material: itemCogs.material_cogs,
                        direct_shipping_ubc: itemCogs.direct_shipping_ubc,
                        direct_shipping_acg: itemCogs.direct_shipping_acg,
                        warehousing_ubc: itemCogs.warehousing_ubc,
                        warehousing_acg: itemCogs.warehousing_acg,
                        overhead_ubc: itemCogs.overhead_ubc,
                        overhead_acg: itemCogs.overhead_acg,
                        contractual_fees_ubc: itemCogs.contractual_fees_ubc,
                        contractual_fees_acg: itemCogs.contractual_fees_acg,
                        cost_funds_ubc: itemCogs.cost_funds_ubc,
                        cost_funds_acg: itemCogs.cost_funds_acg,
                        other_ubc: itemCogs.other_ubc,
                        other_acg: itemCogs.other_acg,
                    };
                });
                this.setState({ data });
            } else this.setState({ data: { count: 0, results: [],} });

            if (nextProps.formly && nextProps.formly.length > 0) {
                nextProps.formly.forEach((val) => {
                    val.key === "SALES_GROWTH_RATE" &&  this.setState({ salesGrowthRate: val.value === "true" });
                    val.key === "CUSTOMER_ATTRITION" && this.setState({ custAttrition: val.value === "true" });
                    val.key === "ACTIVE_CUSTOMER" &&  this.setState({ activeCustomer: val.value === "true" });
                    val.key === "PRICE_DISCOUNT" && this.setState({ priceDiscount: val.value === "true" });
                    val.key === "DETAILED_COGS_BY_PRODUCT" && this.setState({ detailedCOGS: val.value === "true" });
                });
            }
            if (nextProps.setUpGeneral && nextProps.setUpGeneral.formDataSave && nextProps.setUpGeneral.formDataSave.proyinfo) {
                let finish = nextProps.setUpGeneral.formDataSave.proyinfo.finish;
                if (nextProps.login.me.user_profile && nextProps.login.me.user_profile.permission === 1) finish = true;
                this.setState({ finish: finish });
            }
        }
    }

    render() {
        const onOpenModal = () => {
            this.props.leerDataForm("products/get_productos", this.state.idProject);
            this.setState({ open: true })
        };
        const onCloseModal = () => {
            this.setState({ initialValues: initialValue });
            this.setState({ open: false });
        };
        const editarModal = (e) => {
            this.setState({ initialValues: this.dataForm[e] }, () => onOpenModal())
        };

        const totalizadosales = () => {
            const { analisis_units, main_currency  } = this.props.setUpGeneral.formDataSave.proyinfo;
            let data = { cogs: this.props.item.results, id_project: this.state.idProject, infla: this.props.setUpGeneral.formDataSave.inflation, analysis_unit: analisis_units };
            this.props.leerTotalizadoresSales("products/totalizadosales/", data, main_currency);
        };

        const onOpenModalTotalSales = () => {totalizadosales(); this.setState({ openTotalSales: true })};
        const onCloseModalTotalSales = () => this.setState({ openTotalSales: false });

        const onOpenModalTotalSalesProduct = () => {totalizadosales(); this.setState({ openTotalSalesProduct: true })};
        const onCloseModalTotalSalesProduct = () => this.setState({ openTotalSalesProduct: false });
        const opneMenu = () => { this.setState({ openM: !this.state.openM });};


        const eliminarTerry = (e) => {
            const url = `cogs/destroy_cogs/`;
            const urlLeer = `cogs/get_cogs`;
            this.props.eliminar(url, urlLeer, e, this.state.idProject);
        };
        const { open, openTotalSales, openTotalSalesProduct, finish } = this.state;
        return (
            <div>
                <div className="header-wizard-form">
                    <div className="columna-1-Ge columna-1 my-auto">
                        <table>
                            <tbody>
                                <tr>
                                    <td>
                                        <img
                                            className="flecha-pagina pag-div"
                                            src={felchaI}
                                            alt="regresar"
                                            style={this.props.previousPage ? { display: "inline-block" } : { display: "none" }}
                                            onClick={this.props.previousPage}
                                        />
                                    </td>
                                    <td>
                                        <img src={icono} alt="icono" className="icono-step" />
                                    </td>
                                    <td><span>Cost of Good Sold (COGS)</span></td>
                                    <td>
                                        <img
                                            className="flecha-pagina pag-div"
                                            src={felchaD}
                                            alt="regresar"
                                            style={this.props.nextPage ? { display: "inline-block" } : { display: "none" }}
                                            onClick={this.props.nextPage}
                                        />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="columna-2-Ge columna-2 my-auto">
                        Here you can define major direct costs attributable to the production of the goods sold.
                        Cost of goods sold (COGS) are the cost of acquiring or manufacturing the products (directly tied to the production)
                        that a company sells during a period.
                    </div>
                </div>
                <div className="div-center" />
                <br/>
                <div className="content-wizarform">
                    <button type="button" onClick={onOpenModal} disabled={finish} className="btn btn-primary btn-sm mr-1">Add COGS</button>
                    <div className="float-right">
                        <button type="button" disabled={this.state.data.results.length <= 0} onClick={onOpenModalTotalSalesProduct} className="btn-Movil btn btn-optional btn-sm mr-1">COGS by product</button>
                        <button type="button" disabled={this.state.data.results.length <= 0} onClick={onOpenModalTotalSales} className="btn-Movil btn btn-optional btn-sm mr-1">Total COGS</button>
                        <div className="dropdown menu-Movil" style={ { margin: "auto" } }>
                            <a className="btnM" onClick={opneMenu} style={ { cursor: "pointer" } } >
                                <i style={ { color: "black", margin: "auto" } } className="fa fa-ellipsis-v fa-2x btnM" />
                            </a>
                            <div
                                id="myDropdown"
                                className={`dropdown-content ${this.state.openM===true && "show"}`}
                                style={ { right: "0px" } }
                            >
                                <a onClick={onOpenModalTotalSalesProduct} style={ { cursor: "pointer" } } >COGS by product</a>
                                <a onClick={onOpenModalTotalSales} style={ { cursor: "pointer" } } >Total COGS</a>
                            </div>
                        </div>
                    </div>
                    <br />
                    <br />
                    <Grid
                        page={1}
                        hover
                        pagination={false}
                        data={this.state.data}
                        // loading={this.props.loader}
                        // onPageChange={onPageChange}
                        // onSortChange={onSortChange}
                    >
                        <TableHeaderColumn
                            dataField="id"
                            dataAlign="center"
                            isKey
                            dataFormat={
                                standardActions( finish===true ? {  } : { editarClick: editarModal, eliminar: eliminarTerry } )
                            }
                            style={{ fontSize: "10px" }}
                        >ACTIONS</TableHeaderColumn>
                        <TableHeaderColumn
                            tdStyle={{ whiteSpace: 'normal' }}
                            thStyle={{ whiteSpace: 'normal' }}
                            dataAlign="left" dataField="name" dataSort>PRODUCT NAME</TableHeaderColumn>
                        { this.state.detailedCOGS
                            // eslint-disable-next-line max-len
                            && <TableHeaderColumn
                                tdStyle={{ whiteSpace: 'normal' }}
                                thStyle={{ whiteSpace: 'normal' }}
                                dataAlign="left" dataField="geography"
                                dataFormat={cell => (<div> {cell.territory} <br /> { cell.segment } </div>)}
                                dataSort>GEOGRAPHY</TableHeaderColumn>
                        }
                        { this.state.detailedCOGS
                            // eslint-disable-next-line max-len
                            && <TableHeaderColumn
                                tdStyle={{ whiteSpace: 'normal' }}
                                thStyle={{ whiteSpace: 'normal' }}
                                dataAlign="left" dataField="totalMaterial"
                                dataFormat={RenderNumberGrid}
                                dataSort>TOTAL MATERIAL</TableHeaderColumn>
                        }
                        { this.state.detailedCOGS
                            // eslint-disable-next-line max-len
                            && <TableHeaderColumn
                                tdStyle={{ whiteSpace: 'normal' }}
                                thStyle={{ whiteSpace: 'normal' }}
                                dataAlign="left" dataField="totalEmployyes"
                                dataFormat={RenderNumberGrid}
                                dataSort>TOTAL EMPLOYEES</TableHeaderColumn>
                        }
                        <TableHeaderColumn
                            tdStyle={{ whiteSpace: 'normal' }}
                            thStyle={{ whiteSpace: 'normal' }}
                            dataAlign="left" dataField="totalCogs"
                            dataFormat={RenderNumberGrid}
                            dataSort>TOTAL COGS</TableHeaderColumn>
                    </Grid>
                </div>

                <ModalCogs
                    {...this.props}
                    arrayTabs={this.state.arrayTabsModal}
                    open={open}
                    idProject={this.state.idProject}
                    onSubmitsss={this.props.onSubmit}
                    onCloseModal={onCloseModal}
                    inflation={this.state.inflation}
                    analysis_unit={this.state.analysis_unit}
                    initialValue={this.state.initialValues}
                />

                <ModalTotalCogs
                    {...this.props}
                    moneda={this.state.moneda}
                    monedaMainCur={this.state.monedaMainCur}
                    open={openTotalSales}
                    idProject={this.state.idProject}
                    onCloseModal={onCloseModalTotalSales}
                    salesGrowthRate={this.state.salesGrowthRate}
                    custAttrition={this.state.custAttrition}
                    activeCustomer={this.state.activeCustomer}
                    priceDiscount={this.state.priceDiscount}
                    detailedCOGS={this.state.detailedCOGS}
                />

                <ModalTotalSalesCogs
                    {...this.props}
                    moneda={this.state.moneda}
                    monedaMainCur={this.state.monedaMainCur}
                    open={openTotalSalesProduct}
                    idProject={this.state.idProject}
                    onCloseModal={onCloseModalTotalSalesProduct}
                    salesGrowthRate={this.state.salesGrowthRate}
                    custAttrition={this.state.custAttrition}
                    activeCustomer={this.state.activeCustomer}
                    priceDiscount={this.state.priceDiscount}
                    detailedCOGS={this.state.detailedCOGS}
                />

            </div>
        );
    }
}

export default connect()(WizardGeography);
