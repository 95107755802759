

export const validate = (values) => {
    const errors = {};
    if (!values.personal && values.personal > 0) { errors.personal = 'Required'; }
    if (!values.short_term_debt && values.short_term_debt > 0) { errors.short_term_debt = 'Required'; }
    if (!values.long_term_debt && values.long_term_debt > 0) { errors.long_term_debt = 'Required'; }
    // eslint-disable-next-line max-len
    if ((values.short_term_debt && values.short_term_debt > 0) || (values.long_term_debt && values.long_term_debt > 0)) {
        // eslint-disable-next-line max-len
        if (!values.term_debt_base_cost) errors.term_debt_base_cost = 'The system has detected that the monthly payment of the debt is not enough to pay the debt amount (short + long term) during the established period of time.';
    }
    // eslint-disable-next-line max-len
    if (!values.term_debt_annual_growth && values.term_debt_annual_growth > 0) errors.term_debt_annual_growth = 'Required';


    if (!values.ppe || !values.ppe.length) errors.ppe = { _error: 'Required' };
    else {
        const detalleArray = [];
        let repetidos = [];
        values.ppe.forEach((value, index) => {
            const detErrors = {};
            if (typeof value.ppe_value == "undefined" || value.ppe_value === "") detErrors.ppe_value = "Required";
            if (typeof value.ppe_year == "undefined" || value.ppe_year === "") detErrors.ppe_year = "Required";
            if (!value.ppe_name) detErrors.ppe_name = "Required";
            else {
                repetidos = _.filter(values.ppe, (x) => {
                    return x.ppe_name.value ? x.ppe_name.value === value.ppe_name.value : false;
                });
                // eslint-disable-next-line max-len
                if (repetidos.length >= 2) detErrors.ppe_name = `${value.ppe_name.label}, already exist!`;
            }
            if (detErrors.ppe_name || detErrors.ppe_value
                || detErrors.ppe_year) detalleArray[index] = detErrors;
        });
        if (detalleArray.length) errors.ppe = detalleArray;
    }
    if (!values.ia || !values.ia.length) errors.ia = { _error: 'Required' };
    else {
        const detalleArray = [];
        let repetidos = [];
        values.ia.forEach((value, index) => {
            const detErrors = {};
            if (typeof value.ia_value == "undefined" || value.ia_value === "") detErrors.ia_value = "Required";
            if (typeof value.ia_year == "undefined" || value.ia_year === "") detErrors.ia_year = "Required";
            if (!value.ia_name) detErrors.ia_name = "Required";
            else {
                repetidos = _.filter(values.ia, (x) => {
                    return x.ia_name.value ? x.ia_name.value === value.ia_name.value : false;
                });
                // eslint-disable-next-line max-len
                if (repetidos.length >= 2) detErrors.ia_name = `${value.ia_name.label}, already exist!`;
            }
            if (detErrors.ia_name || detErrors.ia_value
                || detErrors.ia_year) detalleArray[index] = detErrors;
        });
        if (detalleArray.length) errors.ia = detalleArray;
    }
    return errors;
};


export default validate;
