import { handleActions } from 'redux-actions';
import { push } from "react-router-redux";
import { AlertManager } from "alerts";
import { api } from "api";

const SUBMIT = 'REGISTER_SUBMIT';
const LOADER = 'REGISTER_LOADER';
const SUSCRIPCION = 'REGISTER_SUSCRIPCION';


export const constants = {
    SUBMIT,
    SUSCRIPTIONS_REGISTER: 'SUCRIPTIONS_REGISTER'
};

// ------------------------------------
// Pure Actions
// ------------------------------------

export const setLoader = loader => ({
    type: LOADER,
    loader,
});

export const setSuscripcion = suscriptions => ({
    type: constants.SUSCRIPTIONS_REGISTER,
    suscriptions,
});

// ------------------------------------
// Actions
// ------------------------------------

export const onSubmit = (data = {}) => (dispatch) => {
    dispatch(setLoader(true));
    // eslint-disable-next-line no-param-reassign
    data.email = data.username;
    api.post('users/profile/', data).then(() => {
        dispatch(push("/readytogo"));
        AlertManager.success('Account created successfully, you can login', 'Success', 3000);
    }).catch((e) => {
        if (e.username) {
            AlertManager.error(e.username, 'Error', 3000);
        } else {
            AlertManager.error("An error has occurred, please verify the data", 'Error', 3000);
        }
    }).finally(() => {
        dispatch(setLoader(false));
    });
};


export const loadOptions = () => (dispatch) => {

    dispatch(setLoader(true));
    api.get("subscriptions/all", { }).
        then((data) => {
            if (data) {
                const array = data.map((item) => {
                    return { value: item.id, label: `${item.type  } - ${  item.annual}`};
                });
                dispatch(setSuscripcion(array));
                dispatch(setLoader(false));
            }
        }).catch(() => {
            dispatch(setSuscripcion([]));
            dispatch(setLoader(false));
        });
};


export const logOut = () => (dispatch) => {
    localStorage.removeItem('token');
};


export const actions = {
    onSubmit,
    logOut,
    loadOptions,
};

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [constants.SUSCRIPTIONS_REGISTER]: (state, { suscriptions }) => {
        return {
            ...state,
            suscriptions,
        };
    },
};

export const initialState = {
    loader: false,
    suscriptions: []
};

export default handleActions(reducers, initialState);
