import { handleActions } from 'redux-actions';
import { push } from "react-router-redux";
import { initialize as initializeForm, reset as resetForm } from 'redux-form';
import { api } from "api";
import { AlertManager } from "alerts";
import {reduceTreeBySelector} from "enzyme/src/selectors";


/*export const { reducers, initialState, actions } = createReducer(
    "setUpGeneral",
    "jsons/me",
    "formGeneral",
    "/formGeneral",
);

*/

// ------------------------------------
// Constants
// ------------------------------------

//  storeId, endpoint, formName=undefined, resourceList=undefined

const storeId = "report";
const resourceList=undefined;

const constants = {
    LOADER: `${storeId.toUpperCase()}_LOADER`,
    DATA: `${storeId.toUpperCase()}_DATA`,
    ITEM: `${storeId.toUpperCase()}_ITEM`,
    PAGE: `${storeId.toUpperCase()}_PAGE`,
    ORDERING: `${storeId.toUpperCase()}_ORDERING`,
    SEARCH: `${storeId.toUpperCase()}_SEARCH`,
    IDFORM: `${storeId.toUpperCase()}_IDFORM`,
    FORMDATASAVE: `${storeId.toUpperCase()}_FORMDATASAVE`,
    GEOGRAPHYLIST: `${storeId.toUpperCase()}_GEOGRAPHYLIST`,
    ESTADO: `${storeId.toUpperCase()}_ESTADO`,
};

// -----------------------------------
// Pure Actions
// -----------------------------------

const setLoader = loader => ({
    type: constants.LOADER,
    loader,
});

const setData = data => ({
    type: constants.DATA,
    data,
});

const setItem = item => ({
    type: constants.ITEM,
    item,
});

const setPage = page => ({
    type: constants.PAGE,
    page,
});

const setOrdering = ordering => ({
    type: constants.ORDERING,
    ordering,
});

const setSearch = search => ({
    type: constants.SEARCH,
    search,
});

const setIdForm = idForm => ({
    type: constants.IDFORM,
    idForm,
});

const setFormDataSave = formDataSave => ({
    type: constants.FORMDATASAVE,
    formDataSave,
});

const setGeographyList = geographyList => ({
    type: constants.GEOGRAPHYLIST,
    geographyList,
});

const setEstado = estado => ({
    type: constants.ESTADO,
    estado,
});

// -----------------------------------
// Actions
// -----------------------------------

const listar = (page = 1) => (dispatch, getStore) => {
    const resource = getStore()[storeId];
    const params = { page };
    params.ordering = resource.ordering;
    params.search = resource.search;
    dispatch(setLoader(true));
    api.get(endpoint, params).then((response) => {
        dispatch(setData(response));
        dispatch(setPage(page));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const leer = (endpoint, data) => (dispatch) => {
    dispatch(setLoader(true));
    api.post(endpoint, data).then((response) => {
        dispatch(setItem(response));
        dispatch(setEstado(true));
    }).catch((e) => {
        dispatch(setEstado(false));
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const leerEditForm = (endpoint, id) => (dispatch) => {
    dispatch(setLoader(true));
    api.get(`${endpoint}/?id=${id}`).then((response) => {
        response.inflation = response.inflationproject;
        let data = {} ;
        data.proyinfo = response;
        data.inflation = response.inflation;
        dispatch(setFormDataSave(data));
        dispatch(initializeForm("formGeneral", response));
    }).catch(() => {}).finally(() => {
        dispatch(setLoader(false));
    });
};

const crear = (endpoint, data, onSubmit) => (dispatch) => {
    dispatch(setLoader(true));
    api.post(endpoint, data).then((response) => {
        AlertManager.success('Register created', 'Success', 3000);
        response.idproy && response.idproy.id && dispatch(setIdForm(response.idproy.id));
        response.idproy && response.idproy.id && dispatch(push(`/project-create/${response.idproy.id}/${response.idproy.type}`));
        data && dispatch(setFormDataSave(data));
        onSubmit && onSubmit();
    }).catch((e) => {
        AlertManager.error('Error on the creation', 'ERROR');
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const editar = (endpoint, data) => (dispatch) => {
    dispatch(setLoader(true));
    api.put(endpoint, data).then(() => {
        AlertManager.success('Register updated', 'Success', 3000);
        if (!!resourceList)
            dispatch(push(resourceList));
    }).catch(() => {
        AlertManager.error('Error updating', 'ERROR', 0);
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const eliminar = (endpoint, id) => (dispatch) => {
    dispatch(setLoader(true));
    api.eliminar(`${endpoint}/${id}`).then(() => {
        dispatch(listar());
        AlertManager.success('Register deleted', 'Success', 3000);
    }).catch(() => {
        AlertManager.success('Error on the transaction', 'Error', 3000);
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const searchChange = search => (dispatch) => {
    dispatch(setSearch(search));
    dispatch(listar());
};

const onSortChange = ordering => (dispatch, getStore) => {
    const sort = getStore()[storeId].ordering;
    if (ordering === sort) {
        dispatch(setOrdering(`-${ordering}`));
    } else {
        dispatch(setOrdering(ordering));
    }
    dispatch(listar());
};

export const actions = {
    listar,
    leer,
    leerEditForm,
    crear,
    editar,
    eliminar,
    searchChange,
    onSortChange,
    setGeographyList,
    setIdForm,
};

// -----------------------------------
// Reducers
// -----------------------------------

export const reducers = {
    [constants.LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [constants.DATA]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [constants.ITEM]: (state, { item }) => {
        return {
            ...state,
            item,
        };
    },
    [constants.PAGE]: (state, { page }) => {
        return {
            ...state,
            page,
        };
    },
    [constants.ORDERING]: (state, { ordering }) => {
        return {
            ...state,
            ordering,
        };
    },
    [constants.SEARCH]: (state, { search }) => {
        return {
            ...state,
            search,
        };
    },
    [constants.IDFORM]: (state, { idForm }) => {
        return {
            ...state,
            idForm,
        };
    },
    [constants.FORMDATASAVE]: (state, { formDataSave }) => {
        return {
            ...state,
            formDataSave,
        };
    },
    [constants.GEOGRAPHYLIST]: (state, { geographyList }) => {
        return {
            ...state,
            geographyList,
        };
    },
    [constants.ESTADO]: (state, { estado }) => {
        return {
            ...state,
            estado,
        };
    },
};

export const initialState = {
    loader: false,
    data: {
        results: [],
        count: 0,
    },
    item: [],
    page: 1,
    ordering: '',
    search: '',
    idForm: '',
    formDataSave: '',
    geographyList: [],
    estado: true,
};

export default handleActions(reducers, initialState, actions);
