export const validate = (values) => {
    const errors = {};
    if (!values.territory) { errors.territory = 'Required'; }

    if (!values.segments || !values.segments.length) {
        errors.segments = { _error: 'Required' };
    }

    return errors;
};


export default validate;
