import { connect } from 'react-redux';
import { actions } from '../../../../redux/modules/ProjectSetUp/formGeneral';
import { actions as actionsFrmly } from '../../../../redux/modules/ProjectSetUp/formlyAll';
import WizardProjectSetUp from './WizardProjectSetUp';
import { actions as acctionsLeer } from "../../../../redux/modules/Geography/geography";
import { actions as actionsCogs } from '../../../../redux/modules/Cogs/Cogs';

const ms2p = (state) => {
    return {
        ...state.setUpGeneral,
        formulario: state.form,
        formly: state.formlyAll.item,
        formlyloader: state.setUpGeneral.loader,
        login: state.login,
    };
};

const md2p = { ...actions, formlyAll: actionsFrmly.leer, acctionsLeer: acctionsLeer.leer, leerCogs: actionsCogs.leer };

export default connect(ms2p, md2p)(WizardProjectSetUp);
