import React, { Component } from 'react';
import "react-step-progress-bar/styles.css";
import { Link } from "react-router-dom";
import imgSercice from "../../../../assets/img/serviceC.png";
import imgManufac from "../../../../assets/img/manufacturing.png";
import FooterDisclaimer from '../Utils/FooterDisclaimer/FooterDisclaimer';


const typoForm = (e) => {
    localStorage.setItem('typeForm', e);
};


class ProjectSelectContainer extends Component {
    render() {
        return (
            <div className="h-100 m-5">
                <div className="div-border-round" style={{ minHeight: "45vh" }}>
                    <div className="">
                        <h3
                            className="textoAzul div-center"
                            style={{ width: "70%" }}
                        >
                            Please select the type of project you want to work on
                        </h3>
                        <br />
                        <div className="form-row align-items-end">
                            <div className="orm-group col-md-6 paddingDiv">
                                <Link onClick={() => (typoForm("service"))} to="/project-create/service">
                                    <div className="divButon divService">
                                        <div className="centerVertical">
                                            <div className="div-center">
                                                <img src={imgSercice} alt="data" />
                                                <br />Service /
                                                <br />Consulting
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                            <div className="orm-group col-md-6 paddingDiv">
                                <Link onClick={() => (typoForm("wholesale"))} to="/project-create/wholesale">
                                    <div className="divButon divManufact">
                                        <div className="centerVertical">
                                            <div className="div-center">
                                                <img src={imgManufac} alt="data" />
                                                <br />Manufacturing /
                                                <br />Wholesale
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
                <br />
                <FooterDisclaimer disclaimer={false}/>
            </div>
        );
    }
}

export default ProjectSelectContainer;
