import React, { Component } from 'react';
import {Link} from "react-router-dom";
import "./inicio.css";
import classNames from "classnames";
import Select from "react-select";
import {CURRENCY_ITEMS, VAL_ITEMS, VAL_ORDER} from "../../../utility/contry";
import CardContainer from "./CardContainer";
import LoadMask from "../Utils/LoadMask/LoadMask";
import ReactPaginate from "react-paginate";
import FooterDisclaimer from "../Utils/FooterDisclaimer/FooterDisclaimer";
import Swal from "sweetalert2";
import dashView from "../../../../assets/img/dashView.png";
import dashUpdateA from "../../../../assets/img/dashUpdateA.png";


class Inicio extends Component {

    state = {
        numberProject: 0,
        filtro: 0,
        id: null,
        page: 1,
        seach: "",
    };

    getData = () => {
        const { filtro, id, page, seach } = this.state;
        this.props.getProject(filtro, id, page, seach);
        //proyectos/getproys
    };

    componentDidMount() {
        const { me, getMe, formlyAll } = this.props;
        formlyAll(me.id);
        this.setState({ id: me.id }, () => this.getData());
        getMe();
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps !== nextContext) {

            if (nextProps.item.length > 0) {
                nextProps.item.forEach((val) => {
                    val.key === "NUMBER_OF_PROJECTS" && this.setState({ numberProject: val.value });
                });
            }
            if (nextProps.hasSubs === false) {
                Swal.fire({
                    title: 'You don\'t have an active subscription!',
                    text: 'In order to work with OnePage you must have an active subscription, we are redirecting you to the subscription options, please choose one and subscribe',
                    type: 'warning',
                    showCancelButton: false,
                    confirmButtonText: 'Ok!',
                    cancelButtonText: 'Cancel',
                    reverseButtons: true,
                }).then((result) => {
                    this.props.history.push(`/user-profile/payment`);
                });
            }
        }
    }

    handlePageClick = (eee) => {
        const { getData, me } = this.props;
        this.setState({ page: eee.selected + 1 }, () => {
            this.getData();
        })
    };

    enterKeyPress = (event) => {
        if(event.key === 'Enter'){
            this.getData();
        }
    };

    verificarNumProj = e => {
        const { props: { projects: { count } }, state: { numberProject } } = this;
        if (count >= numberProject) {
            e.preventDefault();
            Swal.fire({
                title: 'To add more projects you must update your subscription!',
                text: `You have ${count} of ${numberProject} allowed projects.`,
                type: 'warning',
                showCancelButton: false,
                confirmButtonText: 'Ok!',
                cancelButtonText: 'Cancel',
                reverseButtons: true,
            }).then((result) => {});
        }
    };

    render() {
        const { props: { me, projects: { results, count }, loader }, state: { numberProject } } = this;
        return (
            <div className="m-4 round-movil">
                <LoadMask loading={loader} blur positionM="inherit">
                    <div className="div-border-round round-m">
                        <div className="form-row align-items-end header-div-card">
                            <div className={`form-group input-group col-md-12 col-lg-8 text-left`}>
                                <div className="header-h">
                                    {/* eslint-disable-next-line react/button-has-type */}
                                    { results && results.permission !== 1 && results.permission !== 2 ? (
                                        <Link onClick={this.verificarNumProj} to="/project-select" className="btn btn-primary btn-sm mr-1">
                                            <span>New Project</span>
                                        </Link>
                                    ): ("") }
                                    &nbsp;&nbsp;&nbsp;
                                    {/* eslint-disable-next-line react/button-has-type */}
                                    {me.profile && (
                                        <Link to="/user-profile/payment" className="btn btn-optional2 btn-sm mr-1">
                                            <span>Upgrade my plan</span>
                                        </Link>
                                    )}
                                </div>
                                <div className="header-h text-header texto-negrita">
                                    <span>{count}&nbsp;</span>
                                    <span>of&nbsp;</span>
                                    <span>{numberProject==10000 ? "unlimited": numberProject}&nbsp;</span>
                                    <span>PROJECTS</span>
                                </div>
                            </div>
                            <div className={`header-h form-group input-group col-md-12 col-lg-4 text-right sinbordeSelect`}>
                                <div className="form-row align-items-end" style={{ width: "100%" }}>
                                    <div className={`form-group input-group col-md-6`}>
                                        <Select
                                            className={classNames('react-select-containerS text-left')}
                                            classNamePrefix={classNames('react-select-containerS')}
                                            menuPlacement="auto"
                                            menuPortalTarget={document.body}
                                            placeholder="Order by"
                                            backspaceRemovesValue={false}
                                            isSearchable={false}
                                            options={VAL_ORDER}
                                            onChange={(e) => this.setState({ filtro: e.value }, () => this.getData())}
                                            styles={
                                                {
                                                    control: styles => ({ ...styles, width: "100%", borderRadius: 0, borderColor: 'white', backgroundColor: 'white' }),
                                                    menu: styles => ({ ...styles, borderRadius: 0, backgroundColor: 'white' }),
                                                    menuPortal: base => ({ ...base, zIndex: 999999999 }),
                                                    menuList: base => ({ ...base, padding: 0 })
                                                }
                                            }
                                        />
                                    </div>
                                    <div className={`form-group input-group col-md-6`}>
                                        <div className="inputWithIcon">
                                            <i style={{ cursor: "pointer" }} className="fa fa-search fa-lg" aria-hidden="true" onClick={() => this.getData()} />
                                            <input
                                                type="text"
                                                className="form-control"
                                                style={{ width: "100%" }}
                                                //value={this.state.seach}
                                                onKeyPress={ this.enterKeyPress }
                                                onChange={(val) => {
                                                    this.setState({ seach: val.target.value, filtro: 3 })
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div
                            className="lineaAzul div-center"
                            style={{
                                width: "100%",
                                borderRadius: 10,
                                padding: "5px",
                                color: "white",
                                fontWeight: "bold",
                                fontSize: "20px",
                            }}
                        >MY PROJECTS</div>
                            <CardContainer
                                {...this.props}
                                permission={results && results.permission ? results.permission: 1}
                                data={results && results.contenido ? results.contenido: []}
                            />
                        <br />
                        <br />
                        <div className="text-right">
                            <ReactPaginate
                                previousLabel={'<'}
                                nextLabel={'>'}
                                breakLabel="..."
                                breakClassName="break-me"
                                //pageCount={page}
                                pageCount={Math.ceil(count / 10)}
                                marginPagesDisplayed={2}
                                pageRangeDisplayed={5}
                                onPageChange={this.handlePageClick}
                                containerClassName="pagination"
                                subContainerClassName="pages pagination"
                                breakLinkClassName="page-link"
                                pageClassName="page-item"
                                pageLinkClassName="page-link"
                                previousClassName="page-item-arrow"
                                previousLinkClassName="page-link"
                                nextClassName="page-item-arrow"
                                nextLinkClassName="page-link"
                                activeClassName="active"
                            />
                        </div>
                    </div>
                </LoadMask>
                <FooterDisclaimer/>
            </div>
        );
    }
}

export default Inicio;
