import React, { Component } from 'react';
import {Link, NavLink} from "react-router-dom";
import logo from "../../../../../assets/img/logo.png"
import isotipo from "../../../../../assets/img/isotipo.png"
import minimizar from "../../../../../assets/img/minimizar.png"
import maximizar from "../../../../../assets/img/expandir.png"

import dashbord from "../../.././../../assets/img/dashboard.png"
import cuenta from "../../.././../../assets/img/cuenta.png"
import logout from "../../.././../../assets/img/logout.png"
import consultas from "../../.././../../assets/img/consultas.png"
import configuraciones from "../../.././../../assets/img/config.png";
import ReactHoverObserver from 'react-hover-observer';

class SideBar extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { toggleOpen, navToggle, navToggleClose, logOut, setNavBarClass, toggleOpenHover } = this.props;
        return (
            <ReactHoverObserver
                onMouseEnter={() => setNavBarClass(true)}
                onMouseLeave={() => setNavBarClass(false)}
            >
                <aside className={`main-sidebar ${toggleOpen || toggleOpenHover ?'':'cerrado'}`}>
                    <div className="main-navbar">
                        <ul className="nav">
                            <li className="nav-item-btn">
                                <div className="nav-link-btn">
                                    <img onClick={navToggle} src={`${toggleOpen || toggleOpenHover? minimizar: maximizar}`} alt="Logo" />
                                </div>
                            </li>
                            <li className="nav-item logo">
                                <img className="d-inline-block align-top mr-1" src={`${toggleOpen || toggleOpenHover? logo: isotipo}`} alt="Logo" />
                            </li>
                        </ul>
                    </div>
                    <div className="nav-wrapper">
                        <ul className="nav--no-borders flex-column nav">
                            <li className="nav-item">
                                <NavLink to="/" onClick={navToggleClose} exact className="nav-link " activeClassName={'active'}>
                                    <div className="d-inline-block item-icon-wrapper div-icon">
                                        <img className="icono" src={dashbord} alt="Logo" />
                                    </div>
                                    <span>&nbsp;&nbsp;Dashboard</span>
                                </NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink to="/user-profile" onClick={navToggleClose} className="nav-link" activeClassName={'active'}>
                                    <div className="d-inline-block item-icon-wrapper div-icon">
                                        <img className="icono" src={cuenta} alt="Logo" />
                                    </div>
                                    <span>&nbsp;&nbsp;Account</span>
                                </NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink to="#" onClick={logOut} className="nav-link" activeClassName={'active'}>
                                    <div className="d-inline-block item-icon-wrapper div-icon">
                                        <img className="icono" src={logout} alt="Logo" />
                                    </div>
                                    <span>&nbsp;&nbsp;Logout</span>
                                </NavLink>
                            </li>
                            {/*<li className="nav-item">
                                <NavLink to="/grids" onClick={navToggleClose} className="nav-link" activeClassName={'active'}>
                                    <div className="d-inline-block item-icon-wrapper div-icon">
                                        <img className="icono" src={consultas} alt="Logo" />
                                    </div>
                                    <span>Questions</span>
                                </NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink to="/notifications" onClick={navToggleClose} className="nav-link" activeClassName={'active'}>
                                    <div className="d-inline-block item-icon-wrapper div-icon">
                                        <img className="icono" src={configuraciones} alt="Logo" />
                                    </div>
                                    <span>Setting</span>
                                </NavLink>
                            </li>*/}
                        </ul>
                    </div>
                </aside>
            </ReactHoverObserver>
        )
    }
}

export default SideBar;
