import React, { Component } from 'react';
import {reset as resetForm, submit} from "redux-form";
import { connect } from 'react-redux';
import icono from "../../../../../assets/img/config-C.png";
import { CURRENCY_ITEMS, ARREGLO_COLORES, VAL_ITEMS } from "../../../../utility/contry";
import validate from "../validate";
import { validateGeneral, validateCurren, validateOther, validateRateAnSen } from "./validateGenerarl"
import LoadMask from "../../Utils/LoadMask/LoadMask";
import WizardTabsForm from "../TabsContainer/WizardTabsForm";
import ModalEditLabel from "./ModalEditLabel";
import felchaI from "../../../../../assets/img/flecha-izquierda.png";
import felchaD from "../../../../../assets/img/flecha-derecha.png";

let contexto = undefined;

class WizardProjectSetUp extends Component {

    state = {
        open: false,
        page: 0,
        arrayTabs: [],
        annual_rate1: 0,
        annual_rate2: 0,
        annual_rate3: 0,
        reglaMutiMoneda: true,
        reglaMutiYearsInflation: true,
        lablesArray: [],
        nItem: 0,
        finish: false,
        reinitialize: true,
        inflation_base: "",
    };

    onOpenModal = (campo) => {
        this.setState({ nItem: Number(campo) }, () => this.setState({ open: true }));
    };

    onCloseModal = () => {
        this.setState({ open: false });
        this.setState({ editandoURL: false });
    };

    saveLabel = (label) => {
        const { lablesArray, nItem } = this.state;
        const labels = _.cloneDeep(lablesArray);
        labels[nItem].label = label;
        this.setState({ lablesArray: labels }, () => {
            this.arrayTabs[0].inputs[0].label = labels[0].label;
            this.arrayTabs[0].inputs[1].label = labels[1].label;
            this.arrayTabs[0].inputs[2].label = labels[3].label;
            this.arrayTabs[0].inputs[3].label = labels[2].label;
            this.arrayTabs[1].inputs[0].label = labels[6].label;
            this.arrayTabs[1].inputs[1].label = labels[8].label;
            this.arrayTabs[1] && this.arrayTabs[1].inputs[2] && (this.arrayTabs[1].inputs[2].label = labels[9].label);
            this.arrayTabs[1].inputs[3].label = labels[10].label;
            this.arrayTabs[1].inputs[4].label = labels[11].label;
            this.arrayTabs[2].inputs[1].label = labels[13].label;
            this.arrayTabs[3].inputs[0].label = labels[12].label;
            this.setState({ arrayTabs: this.arrayTabs }, () => {
                const page = this.state.page;
                this.setState({ page: -1 }, () => {
                    this.setState({ page: Number.parseInt(page) });
                });
            });
        });
        this.onCloseModal();
    };

    aRBase = "props.annual_rate_base";
    increase = "props.increase";

    arrayTabs = [
        {
            nombre: "General",
            inputs: [
                { name: "name", value: "", label: "Project name", type: "input", help: "Indicate the name of your project", edit: () => this.onOpenModal(0) },
                { name: "horizon", value: "", label: "Project horizon (years)", type: "select", data: [], inputHijo: "inflation", help: "Overall horizon of the project (period / total length). Up to 20 years for any project", edit: () => this.onOpenModal(1) },
                { name: "analisis_units", value: "", label: "Analisis units", type: "select", data: [], getValue: "label", help: "Units in which the project will be evaluated throughout the period (monthly, quarterly or yearly)", edit: () => this.onOpenModal(3) },
                { name: "inflation", value: "", label: "Annual inflation (%)", type: "multiInput", columnNum: "12", help: "Inflation estimation thought the project lifetime", edit: () => this.onOpenModal(2) },
            ],
            columInput: 4,
            validate: validateGeneral,
        },
        {
            nombre: "Currencies",
            inputs: [
                { name: "main_currency", value: "", label: "Main currency", type: "select", data: CURRENCY_ITEMS, help: "Currency used to evaluate the project All numbers and final report will be presented in defined currency. By default is USD", edit: () => this.onOpenModal(6)},
                { name: "sales_currency", value: "", label: "Sales currency", type: "select", data: CURRENCY_ITEMS, help: "Currency in which sales occur. By default is USD. If different from main currency, automatic daily exchange rate provided by a 3rd party", edit: () => this.onOpenModal(8)},
                { name: "cogs_currency", value: "", label: "COGS currency", type: "select", data: CURRENCY_ITEMS, help: "Currency in which the Cost of goods sold will be presented. By default is USD. If different from main currency, automatic daily exchange rate provided by a 3rd party.", edit: () => this.onOpenModal(9)},
                { name: "sga_currency", value: "", label: "SG&A currency", type: "select", data: CURRENCY_ITEMS, help: "Currency in which sales, general and administration expenses occur. By default is USD. If different from main currency, automatic daily exchange rate provided by a 3rd party.", edit: () => this.onOpenModal(10)},
                { name: "pl_currency", value: "", label: "P&L currency", type: "select", data: CURRENCY_ITEMS, help: "Currency in which the Profit and Loss will be presented. By default is USD. If different from main currency, automatic daily exchange rate provided by a 3rd party", edit: () => this.onOpenModal(11)},
            ],
            columInput: 4,
            validate: validateCurren,
        },
        {
            nombre: "Rate and Sensibility NPV",
            inputs: [
                {
                    name: "increase", value: "", label: "", type: "combox", columnNum: "10",
                    fields: [
                        { label: "Increase by 50%", value: "true" },
                        { label: "Decrease by 50%", value: "false" },
                    ],
                    dataHijosAsociados: [
                        // eslint-disable-next-line max-len
                        { name: "annual_rate1", evalFale: `(${this.aRBase}*0.50)`, eval: `(${this.aRBase}*1.5)`, condicion: "val" },
                        // eslint-disable-next-line max-len
                        { name: "annual_rate2", evalFale: `(${this.aRBase}*0.25)`, eval: `(${this.aRBase}*2.25)`, condicion: "val" },
                        // eslint-disable-next-line max-len
                        { name: "annual_rate3", evalFale: `(${this.aRBase}*0.125)`, eval: `((${this.aRBase}*3.375))`, condicion: "val" },
                    ],
                },
                { name: "annual_rate_base", value: 0, label: "NPV Rate (%)", type: "inputNumber", suffix: "%",
                    help: "Defines the annual discount rate used to calculate the Net Present Value (NPV) of the project. Sensitivity analysis provided by increasing/deceasing the rate by 50%",
                    edit: () => this.onOpenModal(13),
                    dataHijosAsociados: [
                        // eslint-disable-next-line max-len
                        { name: "annual_rate1", evalFale: "(val * 0.50)", eval: "(val * 1.5)", condicion: this.increase },
                        // eslint-disable-next-line max-len
                        { name: "annual_rate2", evalFale: "(val * 0.25)", eval: "(val * 2.25)", condicion: this.increase },
                        // eslint-disable-next-line max-len
                        { name: "annual_rate3", evalFale: "(val * 0.125)", eval: "(val * 3.375)", condicion: this.increase },
                    ],
                },
                { name: "annual_rate1", value: "", label: "", type: "inputNumber", disable: "true", suffix: "%" },
                { name: "annual_rate2", value: "", label: "", type: "inputNumber", disable: "true", suffix: "%" },
                { name: "annual_rate3", value: "", label: "", type: "inputNumber", disable: "true", suffix: "%" },
            ],
            columInput: 4,
            validate: validateRateAnSen,
        },
        {
            nombre: "Other",
            inputs: [
                { name: "color", value: "", label: "Tab color", type: "selectTab",
                    data: ARREGLO_COLORES, help: "Select the color of tab in which your project will be displayed on the dashboard", edit: () => this.onOpenModal(12), },
            ],
            columInput: 4,
            validate: validateOther,
        },
    ];

    componentDidMount() {
        this.setState({ lablesArray: [] }, () => {
            let id = this.props.login.me.id;
            this.props.formlyAll(id);
            this.props.leer("jsons/me", id);
            contexto = this;

            this.props.setTypeForm(this.props.tipo);
            if (this.props.idProject) {
                this.props.leerCogs("cogs/get_cogs", this.props.idProject);
                this.props.setIdForm(this.props.idProject);
                this.props.leerEditForm("proyectos/alldataproj", this.props.idProject);
                this.props.acctionsLeer("territories/get_territories", this.props.idProject);
                this.setState({ reinitialize: false});
            }
            else {
                const resetForms = async() => async() =>{
                    await this.props.dispatch(resetForm("formGeneral"));
                };
                resetForms().then(r => this.setState({ reinitialize: false}));
            }
        });
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps !== nextContext) {
            const data = nextProps.item;
            if (data.length > 0) {
                this.state.lablesArray.length === 0 && this.setState({ lablesArray: nextProps.item }, () =>{
                    const labels = this.state.lablesArray;
                    this.arrayTabs[0].inputs[0].label = labels[0].label;
                    this.arrayTabs[0].inputs[1].label = labels[1].label;
                    this.arrayTabs[0].inputs[2].label = labels[3].label;
                    this.arrayTabs[0].inputs[3].label = labels[2].label;
                    this.arrayTabs[1].inputs[0].label = labels[6].label;
                    this.arrayTabs[1].inputs[1].label = labels[8].label;
                    this.arrayTabs[1] && this.arrayTabs[1].inputs[2] && (this.arrayTabs[1].inputs[2].label = labels[9].label);
                    this.arrayTabs[1].inputs[3].label = labels[10].label;
                    this.arrayTabs[1].inputs[4].label = labels[11].label;
                    this.arrayTabs[2].inputs[1].label = labels[13].label;
                    this.arrayTabs[3].inputs[0].label = labels[12].label;
                    this.setState({ arrayTabs: this.arrayTabs });
                });

                if (this.props.tipo==="service") {
                    delete this.arrayTabs[1].inputs[2];
                }
                else {
                    this.arrayTabs[1].inputs[2] = { name: "cogs_currency", value: "", label: "", type: "select", data: CURRENCY_ITEMS, help: "Currency in which the Cost of goods sold will be presented. By default is USD. If different from main currency, automatic daily exchange rate provided by a 3rd party.", edit: () => this.onOpenModal(9) };
                }


                this.arrayTabs[2].inputs[2].value = this.state.annual_rate1;
                this.arrayTabs[2].inputs[3].value = this.state.annual_rate2;
                this.arrayTabs[2].inputs[4].value = this.state.annual_rate3;


                this.setState({ arrayTabs: this.arrayTabs });
            }

            if (nextProps.formly.length > 0) {

                nextProps.formly.forEach((val) => {
                    if (val.key === "NUMBER_OF_YEARS_IN_HORIZON") {
                        const cantHorizon = val;
                        if (cantHorizon && cantHorizon.value) {
                            const dataa = [];
                            for (let x=1; x <= cantHorizon.value; x++) {
                              dataa.push({ label: `${x} years`, value: x },)
                            }
                            this.arrayTabs[0].inputs[1].data = dataa;
                        }
                    } else if (val.key === "TYPE_OF_UNITS") {
                        let analisisUnits = [];
                        let res = val.value;
                        let array = res.split(',');
                        for (let x = 0; x < array.length; x++) {
                            let a = _.find(VAL_ITEMS, {'id': parseInt(array[x])});
                            analisisUnits.push(a);
                        }
                        this.arrayTabs[0].inputs[2].data = analisisUnits;
                    } else if (val.key === "MULTI_CURRENCY") {
                        let reglaMultiMoneda = val.value === "true";
                        this.setState({ reglaMultiMoneda });

                        let a = _.find(CURRENCY_ITEMS, {'value': "USD"});
                        this.arrayTabs[1].inputs.forEach((val) => {
                           val.data = reglaMultiMoneda ? CURRENCY_ITEMS: [a];
                        });
                    } else if (val.key === "VARIABLE_INFLATION_ON_MULTIPLE_YEARS") {
                        let reglaMutiYearsInflation = val.value === "true";
                        this.setState({ reglaMutiYearsInflation });

                        if (!reglaMutiYearsInflation) {
                            this.arrayTabs[0].inputs[1].inputHijo = undefined;
                            this.arrayTabs[0].inputs[3].type = "inputNumber";
                            this.arrayTabs[0].inputs[3].name = "inflation_base";
                        }
                    } else if (val.key === "CUSTOMIZED_PROJECT_NAME_LABELS" && val.value == "false") {
                        for (let i=0; i < this.arrayTabs.length; i++) {
                            for (let ii=0; ii < this.arrayTabs[i].inputs.length; ii++) {
                                if (this.arrayTabs[i].inputs[ii]) {
                                    delete this.arrayTabs[i].inputs[ii].edit;
                                }
                            }
                        }
                        this.arrayTabs[0].inputs[0].oculto = true;
                    }


                    this.setState({ arrayTabs: this.arrayTabs });
                });

            }


            if (nextProps.formDataSave && nextProps.formDataSave.proyinfo) {
                if (this.props.idProject) {
                    let finish = nextProps.formDataSave.proyinfo.finish;
                    this.arrayTabs = this.arrayTabs.map(array => {
                        return { ...array, inputs: array.inputs.map(val => ({ ...val, disabled: finish }))}
                    });
                    if (nextProps.login.me.user_profile && nextProps.login.me.user_profile.permission === 1) {
                        this.arrayTabs = this.arrayTabs.map(array => {
                            return { ...array, inputs: array.inputs.map(val => ({ ...val, disabled: true }))}
                        });
                        finish = true
                    }
                    this.setState({ finish: finish });
                    this.setState({ arrayTabs: this.arrayTabs });
                }
            }
        }
    }


    nextPage = () => {
        // eslint-disable-next-line react/destructuring-assignment,react/no-access-state-in-setstate
        this.setState({ page: this.state.page + 1 });
    };

    previousPage = () => {
        // eslint-disable-next-line react/destructuring-assignment,react/no-access-state-in-setstate
        this.setState({ page: this.state.page - 1 });
    };

    setPage = (page) => {
        // eslint-disable-next-line radix
        this.setState({ page: Number.parseInt(page) });
    };

    // eslint-disable-next-line class-methods-use-this
    onSubmitsss = (ee) => {
        const mePerfil = this.props.login.me;
        let data = {};
        data.idproy = this.props.idProject || null;
        data.iduser = mePerfil.id;
        data.proyinfo = _.cloneDeep(ee);
        if (this.state.reglaMutiYearsInflation) {
            let array = [];
            ee.inflation.forEach((val, index) => {
               array.push({ id: index+1, value: val.value == "" ? 0 : val.value });
            });
            data.inflation = _.cloneDeep(array);
            data.proyinfo.inflationproject = _.cloneDeep(array);

            data.proyinfo.inflation_base = 0;
        }
        else {
            data.inflation = [];
            data.proyinfo.inflation_base = ee.inflation_base == "" ? 0 : ee.inflation_base;
            // data.proyinfo.inflation_base = _.cloneDeep(ee.inflation);
        }
        delete data.proyinfo.inflation;
        data.proyinfo.bop = "January";
        data.proyinfo.eop = "December";
        data.proyinfo.inflation_calculations = null;
        data.proyinfo.secondary_currency = this.state.reglaMutiMoneda ? data.proyinfo.main_currency : "";
        data.proyinfo.subscription = mePerfil.profile ? mePerfil.profile.subscription: mePerfil.user_profile.subscription;
        data.proyinfo.increase = mePerfil.increase == "true" ? true: false;
        data.project_type = this.props.tipo;
        !data.proyinfo.name ? data.proyinfo.name = "Project": data.proyinfo.name;
        if (data.proyinfo.annual_rate_base == "") data.proyinfo.annual_rate_base = 0;
        const url = this.props.idProject ? "proyectos/editproy/" : "proyectos/SuperCreate/";

        if (this.props.idProject) {
            this.props.crear("proyectos/destroyinflation/", data);
        }
        this.props.crear(url, data, this.props.onSubmit);

        const labels = {};
        labels.user = mePerfil.id;
        labels.json = this.state.lablesArray;
        this.props.crear("jsons/savejson/", labels, () => this.props.leer("jsons/me", this.props.login.me.id));
    };

    render() {
        const { open, lablesArray, nItem, finish, reinitialize } = this.state;
        const initialValue = {
            annual_rate1: this.state.annual_rate1,
            annual_rate2: this.state.annual_rate2,
            annual_rate3: this.state.annual_rate3,
            increase: "false",
            main_currency: "USD",
            sales_currency: "USD",
            cogs_currency: "USD",
            sga_currency: "USD",
            pl_currency: "USD",
            annual_rate_base: 0.0,
            analisis_units: "Years (Y)",
        };
        return (
            <div>
                <div className="header-wizard-form">
                    <div className="columna-1 my-auto text-left">
                        <table>
                            <tbody>
                                <tr>
                                    <td>
                                        <img
                                            className="flecha-pagina pag-div"
                                            src={felchaI}
                                            alt="regresar"
                                            style={this.props.previousPage ? { display: "inline-block" } : { display: "none" }}
                                            onClick={this.props.previousPage}
                                        />
                                    </td>
                                    <td>
                                        <img src={icono} alt="icono" className="icono-step" />
                                    </td>
                                    <td><span>Project Set Up</span></td>
                                    <td>
                                        <img
                                            className="flecha-pagina pag-div"
                                            src={felchaD}
                                            alt="regresar"
                                            style={this.props.nextPage ? { display: "inline-block" } : { display: "none" }}
                                            onClick={this.props.nextPage}
                                        />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="columna-2 my-auto">
                        Define (adjust) major variables that will affect the overall project structure and evaluation.
                        It prepares the overall evaluation process to be ready to be used.
                    </div>
                </div>
                <div className="div-center" />
                <br/>
                <div className="content-wizarform">
                    {/*<LoadMask loading={this.props.formlyloader}>*/}
                    <LoadMask loading={false}>
                    <WizardTabsForm
                        {...this.props}
                        nameForm="formGeneral"
                        initialValue={initialValue}
                        page={this.state.page}
                        arrayTabs={this.state.arrayTabs}
                        nextPage={this.nextPage}
                        previousPage={this.previousPage}
                        setPage={this.setPage}
                        onSubmitsss={this.onSubmitsss}
                        errorForm={this.props.formulario.formGeneral || {}}
                        validateForm={validate}
                        buttonSubmit={!finish}
                        enableReinitialize={reinitialize}
                    />
                    </LoadMask>
                </div>
                <ModalEditLabel
                    open={open}
                    saveLabel={this.saveLabel}
                    input={lablesArray && lablesArray[nItem] && lablesArray[nItem].label}
                    onCloseModal={this.onCloseModal}
                />
            </div>
        );
    }
}

export default connect()(WizardProjectSetUp);
