import Swal from "sweetalert2";

export const AlertManager = {
    success: (cuerpo, titulo, timer) => {
        Swal.fire({
            type: 'success',
            title: titulo,
            text: cuerpo,
        }).then(() => {});
    },
    error: (cuerpo, titulo, timer) => {
        Swal.fire({
            type: 'error',
            title: titulo,
            text: cuerpo,
        }).then(() => {});
    },
    info: (cuerpo, titulo, timer) => {
        Swal.fire({
            type: 'info',
            title: titulo,
            text: cuerpo,
        }).then(() => {});
    },
};
