export const validateProfil = (values) => {
    const errors = {};
    if (!values.first_name) { errors.first_name = 'Required'; }
    if (!values.last_name) { errors.last_name = 'Required'; }
    if (!values.email) { errors.email = 'Required'; }
    // eslint-disable-next-line max-len
    if (values.email && !values.email.match("[!@#$%^&*()_+\\-=\\[\\]{};':\"\\\\|,.<>\\/?]")) { errors.email = 'Email invalid'; }
    if (!values.country) { errors.country = 'Required'; }

    if (!values.position_title) { errors.position_title = 'Required'; }
    if (!values.functional_area) { errors.functional_area = 'Required'; }
    if (!values.company_name) { errors.company_name = 'Required'; }
    if (!values.head_quarters) { errors.head_quarters = 'Required'; }
    if (!values.company_industry) { errors.company_industry = 'Required'; }
    if (!values.company_size) { errors.company_size = 'Required'; }
    if (!values.revenue) { errors.revenue = 'Required'; }

    return errors;
};


export default validateProfil;
