import Modal from 'react-responsive-modal';
import React, { Component } from 'react';
import 'react-rangeslider/lib/index.css';
import Grid from "../../Utils/Grid";
import {TableHeaderColumn} from "react-bootstrap-table";
import LoadMask from "../../Utils/LoadMask/LoadMask";

const mese = [
    "JANUARY",
    "FEBRUARY",
    "MARCH",
    "APRIL",
    "MAY",
    "JUNE",
    "JULY",
    "AUGUST",
    "SEPTEMBER",
    "OCTOBER",
    "NOVEMBER",
    "DECEMBER",
];

class ModalProductos extends Component {

    render() {
        const styleModal = { modal: { borderRadius: 25, maxWidth: "85%", margin: "50px auto" } };
        const {
            open,
            onCloseModal,
            moneda,
            monedaMainCur,
            setUpGeneral: { formDataSave: { proyinfo, inflation } },
            totalizadoresSales: {total_sales},
            tipoCambio,
            salesGrowthRate,
            custAttrition,
            activeCustomer,
            priceDiscount,
        } = this.props;
        const final_sums = total_sales && total_sales.final_sums || null;
        const products = total_sales && total_sales.products || null;
        let percentage = [];
        const variation = [];
        const analisisUnits = proyinfo && proyinfo.analisis_units || 0;

        let valdiv = 0;

        const data = {
            count: 0,
            results: [],
        };

        const dataTipoCambio = {
            count: 0,
            results: [],
        };

        const monedaFormat = (num) => parseFloat(num).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');

        const objeto = (nombre, data=[], negrita=false) => {
            const item = { item: {val: nombre, negrita: negrita} };
            data.map((items, index) => item[`val${index + 1}`] = {val: monedaFormat(items.value)+"%", negrita: negrita});
            return item;
        };

        const objetoVal = (nombre, data=[], negrita=false, simbol="") => {
            const item = { item: {val: nombre, negrita: negrita} };
            data.map((items, index) => {
                item[`val${index + 1}`] = {
                    val: `${monedaFormat(items)}${simbol}`,
                    negrita: negrita
                }
            });
            return item;
        };

        if (analisisUnits === "Years (Y)") {
            valdiv = 1;
            percentage = inflation;
            for (let x = 0; x < percentage.length; x++) {percentage[x].label = `${percentage[x].horizon_value}`;}
        } else if (analisisUnits === "Quarters (Q)") {
            valdiv = 4;
            for (let y = 0; y < inflation.length; y++) {
                for (let x = 0; x < valdiv; x++) {
                    percentage.push({ idq: Number(x + 1), idy: Number(y + 1), label: `Q${x + 1}-Y${y + 1}`,
                        value: parseFloat(`${inflation[y].value / valdiv}`).toFixed(2),
                    });
                }
            }
        } else if (analisisUnits === "Months (M)") {
            valdiv = 12;
            for (let y = 0; y < inflation.length; y++) {
                for (let x = 0; x < valdiv; x++) {
                    percentage.push({ idm: Number(x + 1), idy: Number(y + 1), label: `M${x + 1}-Y${y + 1}`,
                        value: parseFloat(`${inflation[y].value / valdiv}`).toFixed(2)
                    });
                }
            }
        }

        const item = { item: {val: "COGS main currency", negrita: false} };
        percentage.map((items, index) => {
            return item[`val${index + 1}`] = {
                val: moneda,
                negrita: false
            }
        });

        data.results=[];
        data.results.push(item);
        data.results.push(objeto("Annual inflation (%)", percentage));
        for (let i=1; i <= Object.keys(products || {}).length; i++) {
            if (products[i].periods[0].total_cogs) {
                const item = { item: {val: products[i].name, negrita: true} };
                products[i].periods.forEach((val, index) => {
                    item[`val${index + 1}`] = {
                        val: `${monedaFormat((val.total_cogs))}`,
                        negrita: true
                    };
                });
                data.results.push(item);
            }
        }
        data.results.push(objetoVal(`Total Sales<${moneda}>`, final_sums && final_sums.total_cogs || [], true));
        data.results.push(objetoVal(`Sales var (${moneda})`, final_sums && final_sums.cogs_variation || []));
        data.results.push(objetoVal(`Sales var (%)`, final_sums && final_sums.cogs_variation_avg || [], false,"%"));

        /*
        data.results.push(objetoVal("Initial customer stock (BOP)", final_sums && final_sums.initial_customer_stock || []));
        data.results.push(objetoVal("New customers adquisition", final_sums && final_sums.new_customer || []));
        custAttrition && data.results.push(objetoVal("Average customer attrition", final_sums && final_sums.attrition || []));
        activeCustomer && data.results.push(objetoVal("Average active customers (%)", final_sums && final_sums.active_customer || []));
        data.results.push(objetoVal("Total active customer stock (EOP)", final_sums && final_sums.total_active_customer_stock || []));
        data.results.push(objetoVal("Average price", final_sums && final_sums.effective_price_avg || []));
        salesGrowthRate && data.results.push(objetoVal("Average price growth (%)", final_sums && final_sums.price_growth_avg || []));
        priceDiscount && data.results.push(objetoVal("Average annual discount (%)", final_sums && final_sums.price_discount_avg || []));
        data.results.push(objetoVal("Volume (Qty)", final_sums && final_sums.volume || []));
        salesGrowthRate && data.results.push(objetoVal("Average volume annual growth (%)", final_sums && final_sums.volume_growth_avg || []));
        data.results.push(objetoVal(`Total Sales<${moneda}>`, final_sums && final_sums.revenue || [], true));
        data.results.push(objetoVal(`Sales var (${moneda})`, final_sums && final_sums.sales_variation || []));
        data.results.push(objetoVal(`Sales var (%)`, final_sums && final_sums.sales_variation_avg || []));
        data.results.push(objetoVal(`Unitary Sales<${moneda}>`, final_sums && final_sums.unitary_sales || [], true));
        data.results.push(objetoVal(`Sales by customer<${moneda}>`, final_sums && final_sums.sales_by_customer || [], true));
        */

        const cambioArray = {};
        tipoCambio.forEach((val, index) => {
            cambioArray[val.date] = val.rate;
        });
        dataTipoCambio.results = [cambioArray];

        return (
            <Modal
                open={open}
                animationDuration={1000}
                onClose={onCloseModal}
                center
                styles={styleModal}
                //closeIconSize={0}
            >
                <div className="font-weight-bold textBold">TOTAL COGS BY PRODUCT</div>
                <LoadMask loading={this.props.loaderMoneda} blur>
                    <br />
                    <div className="content-wizarform">
                        <Grid
                            page={1}
                            hover
                            pagination={false}
                            data={data}
                            // loading={this.props.loader}
                            // onPageChange={onPageChange}
                            // onSortChange={onSortChange}
                        >
                            <TableHeaderColumn dataField="id" hidden={true} isKey>ACTIONS</TableHeaderColumn>
                            <TableHeaderColumn
                                dataFormat={
                                    i => (<div className={`${i && i.negrita && "textBold"}`} >{i && i.val}</div>)
                                }
                                tdStyle={{ whiteSpace: 'nowrap' }}
                                thStyle={{ whiteSpace: 'nowrap' }}
                                dataAlign="left" dataField="item" dataSort>ITEM</TableHeaderColumn>
                            {(percentage.map((items, index) => {
                                return(
                                    <React.Fragment>
                                        <TableHeaderColumn
                                            dataFormat={
                                                i => (<div className={`${i && i.negrita && "textBold"}`} >{i && i.val}</div>)
                                            }
                                            dataAlign="right" dataField={`val${index + 1}`} dataSort>{items.label}</TableHeaderColumn>
                                    </React.Fragment>
                                )
                            }))}
                        </Grid>
                        <br />
                        <p className="textBold div-center">Exchange rates last 12 months ({moneda} vs {monedaMainCur}) {new Date().getFullYear()}</p>
                        <Grid
                            page={1}
                            hover
                            pagination={false}
                            data={dataTipoCambio}
                            // loading={this.props.loaderMoneda}
                            // onPageChange={onPageChange}
                            // onSortChange={onSortChange}
                        >
                            <TableHeaderColumn dataField="id" hidden={true} isKey>ACTIONS</TableHeaderColumn>
                            {(tipoCambio.map((items, index) => {
                                const date = new Date(items.date);
                                let mes = mese[date.getMonth()];

                                return(
                                    <React.Fragment>
                                        <TableHeaderColumn
                                            width = {'8.33333333333%'}
                                            dataAlign="right" dataField={items.date} dataSort>{mes}</TableHeaderColumn>
                                    </React.Fragment>
                                )
                            }))}
                        </Grid>
                        <br />
                        <br />
                    </div>
                </LoadMask>
            </Modal>
        );
    }
};

export default (ModalProductos);
