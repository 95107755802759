import React, { Component } from 'react';
import Modal from "react-responsive-modal";
import { TableHeaderColumn } from "react-bootstrap-table";
import { reset, submit } from "redux-form";
import { connect } from "react-redux";
import ReactPaginate from 'react-paginate';
import Grid from "../../../Utils/Grid";
import { standardActions } from "../../../Utils/Grid/StandardActions";
import FormTabs from "../../../ProjectContainer/TabsContainer/FormTabs";
import validateGuestUsers from "./validateGuestUser";
import { PERMSSIONS } from "../../../../../utility/contry";

class TabGuest extends Component {
    state = {
        open: false,
        data: {
            count: 0,
            results: [],
        },
        initialValues: {},
        editandoURL: false,
        passwordShow: true,
        page: 1,
    };

    dataForm = [];

    componentWillMount() {
        const { props: { me: { id }, getData } } = this;
        getData(id, 1);
    }

    onOpenModal = () => {
        this.setState({ open: true }, () => {
            this.props.dispatch(reset("guestForm"));
        });
    };

    onCloseModal = () => {
        this.setState({ open: false });
        this.setState({ editandoURL: false });
        this.props.dispatch(reset('guestForm'));
        this.setState({ initialValues: {} });
        this.setState({ passwordShow: true });
    };

    funcionAlFinalizar = () => {
        const { props: { me: { id }, dispatch, getData } } = this;
        this.onCloseModal();
        dispatch(reset('guestForm'));
        getData(id, 1);
    };

    // eslint-disable-next-line class-methods-use-this
    onSubmitsss = (ee) => {
        const { state, props: { me: { profile, id }, crear } } = this;
        const data = _.cloneDeep(ee);
        data.user = id;
        data.profile = profile.id;
        // eslint-disable-next-line max-len
        if (state.editandoURL === false) {
            crear(
                `users_profiles/?iduser=${id}&total=${this.state.data.count}`,
                data,
                this.funcionAlFinalizar
            );
        } else {
            crear(
                `users_profiles/editguest/?flagedit=${state.passwordShow}&iduser=${id}`,
                data,
                this.funcionAlFinalizar
            );
        }
    };

    editarModal = (e) => {
        this.setState({ passwordShow: false });
        this.setState({ editandoURL: true });
        this.setState({ initialValues: this.dataForm[e.id] }, () => this.onOpenModal());
    };


    eliminar = (e) => {
        this.props.eliminar(e, this.props.me.id);
    };

    onPageChange = (e) => {
        console.log("dataaaaa", e);
    };

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps !== nextContext) {
            let data = { count: 0, results: [] };
            this.setState({ data });
            if (nextProps.users) {
                const { users } = nextProps;
                data = {
                    count: users.total,
                    results: users.data.map((val) => {
                        this.dataForm[val.id] = {
                            id: val.user.id,
                            usersprofile: val.id,
                            first_name: val.user.first_name,
                            last_name: val.user.last_name,
                            username: val.user.username,
                            password: "",
                            password2: "",
                            permission: _.find(PERMSSIONS, { id: val.permission }),
                            projects: val.projects.split(",").map((valP) => {
                                const item = _.find(this.props.selectS.projects, { id: Number(valP) });
                                item !== undefined && (item.label = item.name);
                                item !== undefined && (item.value = item.id);
                                return item;
                            }),
                        };
                        // eslint-disable-next-line max-len
                        return { id: val, fName: val.user.first_name, lName: val.user.last_name, email: val.user.username };
                    }),
                };
                this.setState({ data });
                this.setState({ page: Math.ceil(data.count / 5) });
            }
        }
    }

    componentDidMount() {
        const { users } = this.props;
        const data = {
            count: users.total,
            results: users.data.map((val) => {
                this.dataForm[val.id] = {
                    id: val.user.id,
                    usersprofile: val.id,
                    first_name: val.user.first_name,
                    last_name: val.user.last_name,
                    username: val.user.username,
                    password: "",
                    password2: "",
                    permission: _.find(PERMSSIONS, { id: val.permission }),
                    projects: val.projects.split(",").map((valP) => {
                        const item = _.find(this.props.selectS.projects, { id: Number(valP) });
                        item !== undefined && (item.label = item.name);
                        item !== undefined && (item.value = item.id);
                        return item;
                    }),
                };
                return { id: val, fName: val.user.first_name, lName: val.user.last_name, email: val.user.username };
            }),
        };
        this.setState({ data });
        this.setState({ page: Math.ceil(data.count / 5) });
    }

    handlePageClick = (eee) => {
        const { getData, me } = this.props;
        getData(me.id, eee.selected + 1);
    };

    render() {
        const validation = localStorage.getItem('val') === "true";
        const { props: { selectS, loader, nUsers, me: { profile } }, state: { data, open, passwordShow, page } } = this;
        const styleModal = { modal: { borderRadius: 25, maxWidth: "70% !important", width: "100%", margin: "50px auto", padding: "0px" } };
        const styleLinea = { width: "100%" };
        const input = [
            { name: "first_name", value: "", label: "First name", type: "input" },
            { name: "last_name", value: "", label: "Last name", type: "input" },
            { name: "username", value: "", label: "Email", type: "input" },
            // eslint-disable-next-line max-len
            passwordShow && { name: "password", value: "", label: "Password", type: "input", inputType: "password" },
            // eslint-disable-next-line max-len
            passwordShow && { name: "password2", value: "", label: "Confirm Password", type: "input", inputType: "password" },
        ];

        const input2 = [
            // eslint-disable-next-line max-len
            { name: "permission", value: "", label: "Permission level", type: "select", data: PERMSSIONS, labelKeyObject: true, labelKey: "value", valueKey: "id" },
            // eslint-disable-next-line max-len
            { name: "projects", value: "", label: "Projects", type: "select", data: selectS.projects, labelKeyObject: true, isMulti: true, labelKey: "name", valueKey: "id" },
        ];

        return (
            <div className="content-wizarform">
                <br />
                <h5 className="text-azul">
                    Current Users |
                    {' '}
                    {data.count + 1}
                    {' '}
of
                    {' '}
                    { nUsers }
                </h5>
                {/* eslint-disable-next-line react/button-has-type */}
                {
                    Number(data.count + 1) !== Number(nUsers) && (
                        <button onClick={this.onOpenModal} className="btn btn-primary btn-sm mr-1">Invite user</button>
                    )
                }
                {
                    Number(data.count + 1) === Number(nUsers) && (
                        <h5>You have reached the maximum users limit allowed by your subscription plan.</h5>
                    )
                }
                <br />
                <br />
                <Grid
                    page={1}
                    hover
                    pagination={false}
                    data={data}
                    //loading={loader}
                    onPageChange={this.onPageChange}
                    onSortChange={() => {}}
                >
                    <TableHeaderColumn
                        dataField="id"
                        dataAlign="center"
                        dataSort
                        isKey
                        dataFormat={
                            standardActions({ editarClick: this.editarModal, eliminar: this.eliminar })
                        }
                    >
                        ACTIONS
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataAlign="left"
                        headerAlign="left"
                        dataField="fName"
                        dataSort
                    >
                        FRIST NAME
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataAlign="left"
                        headerAlign="left"
                        dataField="lName"
                        dataSort
                    >
                        LAST NAME
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataAlign="left"
                        headerAlign="left"
                        dataField="email"
                        dataSort
                    >
                        EMAIL
                    </TableHeaderColumn>
                </Grid>

                <br />
                <br />
                <div className="text-right">
                    <ReactPaginate
                        previousLabel={'<'}
                        nextLabel={'>'}
                        breakLabel="..."
                        breakClassName="break-me"
                        pageCount={page}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={5}
                        onPageChange={this.handlePageClick}
                        containerClassName="pagination"
                        subContainerClassName="pages pagination"
                        breakLinkClassName="page-link"
                        pageClassName="page-item"
                        pageLinkClassName="page-link"
                        previousClassName="page-item-arrow"
                        previousLinkClassName="page-link"
                        nextClassName="page-item-arrow"
                        nextLinkClassName="page-link"
                        activeClassName="active"
                    />
                </div>

                <Modal
                    open={open}
                    animationDuration={1000}
                    onClose={this.onCloseModal}
                    center
                    styles={styleModal}
                    closeIconSize={0}
                >
                    <div className="mx-5 my-3">
                        <h5 className="font-weight-bold ">NEW USER</h5>
                    </div>
                    <div className="lineaAzul" style={{ ...styleLinea, height: 20 }} />
                    <br />
                    <div className="mx-5 my-3">
                        <div className="font-weight-bold">PERSONAL INFORMATION</div>
                        <div className="lineaAzul" style={{ height: 3 }} />
                        <br />
                        <FormTabs
                            form="guestForm"
                            onSubmit={() => {}}
                            arrayInput={input}
                            columInput="2"
                            validate={validateGuestUsers}
                            buttonSubmit={false}
                            initialValues={this.state.initialValues}
                            enableReinitialize
                        />
                        {
                            this.state.passwordShow === false && (
                                <button
                                    className="btn btn-secondary"
                                    onClick={() => this.setState({ passwordShow: true })}
                                >Update password</button>
                            )
                        }
                        <br />
                        {' '}
                        <br />
                        <div className="font-weight-bold">PROJECT PERMISSIONS</div>
                        <div className="lineaAzul" style={{ height: 3 }} />
                        <br />
                        <FormTabs
                            form="guestForm"
                            onSubmit={this.onSubmitsss}
                            arrayInput={input2}
                            columInput="2"
                            validate={validateGuestUsers}
                            buttonSubmit={false}
                            initialValues={this.state.initialValues}
                            enableReinitialize
                        />
                        <div className="div-center">
                            <button className="btn btn-secondary" onClick={this.onCloseModal}> Cancel </button>
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <button type="submit" className="btn btn-optional2" onClick={() => this.props.dispatch(submit("guestForm"))}>
                                Save
                            </button>
                        </div>
                    </div>
                </Modal>
            </div>
        );
    }
}
export default connect()(TabGuest);
