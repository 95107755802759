import { validateCurren, validateGeneral, validateOther, validateRateAnSen } from "../ProjectSetUp/validateGenerarl";
import {CURRENCY_ITEMS} from "../../../../utility/contry";

const meses = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
];
const sales = [
    "Cash",
    "30",
    "60",
    "85",
    "120",
    "150",
    "180",
    "210",
    "240",
    "270",
    "300",
    "330",
    "360",
];

const arrayTabs = [
    {
        nombre: "Geography",
        inputs: [
            { name: "name", value: "", label: "Product name", type: "input", help: "Create the name of the product/service." },
            { name: "segment", value: "", label: "Geography", type: "select", data: [], labelKeyObject: true, help: "Indicates into which Geography &amp; Segment the specific product will be sold" },
        ],
        columInput: 2,
        validate: validateGeneral,
    },
    {
        nombre: "Costumer & Products",
        inputs: [
            { name: "initial_customer_stock", value: "", label: "Initial customer stock (BOP)", type: "inputNumber", help: "Total initial customer portfolio at the beginning of the period (BOP). By default is 0." },
            { name: "new_customers", value: "", label: "New customer (Qty)", type: "inputNumber", help: "Total quantity acquired (sold) customers / products during of each defined period of analysis (monthly, quarterly or yearly)." },
            { name: "customer_growth", value: "", label: "Customer growth (%)", type: "inputNumber", help: "Annual growth rate (%) of the new acquired customers. By default is 0." },
            { name: "annual_attrition", value: "", label: "Annual attrition (%)", type: "inputNumber", help: "Total annual lost customers (%) in your customer base. Used to provide project sensitivity in upon eventual lost customers." },
            { name: "active_customers", value: "", label: "Active Customers (%)", type: "inputNumber", help: "Total annual active customers (%) within your customer base. Used to provide project sensitivity upon active customers and sporadic ones. System applies the defined active rate on future periods" },
            {
                name: "monthlydistribution", nameChildr: "value", label: "Annual customer acquisition distribution (%)",
                type: "multiSlide", columnNum: "12", numImput: 12, labelData: meses, help: "Annual (yearly) customer acquisition distribution. System applies the defined distribution on future periods."
            },
        ],
        columInput: 2,
        validate: validateCurren,
    },
    {
        nombre: "Princing",
        inputs: [
            { name: "base_price", value: "", label: "Base price CLP", type: "inputNumber", help: "Indicates the base (list price) for each product. By default is USD." },
            { name: "annual_growthp", value: "", label: "Pricing annual growth (%)", type: "inputNumber", help: "Annual growth rate (%) of base price. By default is 0%." },
            { name: "annual_discount", value: "", label: "Annual discount(%)", type: "inputNumber", help: "User can enter a fixed discount for the price. Used to estimate a more realistic net price or effective price charged." },
        ],
        columInput: 2,
        validate: validateRateAnSen,
    },
    {
        nombre: "Volume",
        inputs: [
            { name: "base_volume", value: "", label: "Base volume (Qty)", type: "inputNumber", help: "Number of units sold by customer." },
            { name: "annual_growthv", value: "", label: "Volume annual growth (%)", type: "inputNumber", help: "Annual growth rate (%) of the sales base volume. By default is 0%." },
            {
                name: "monthlysales", nameChildr: "value", label: "Annual volume distribution (%)",
                type: "multiSlide", columnNum: "12", numImput: 12, labelData: meses, help: "Defines the annual volume (units) distribution. System applies the defined distribution on future periods."
            },
        ],
        columInput: 2,
        validate: validateOther,
    },
    {
        nombre: "Sales Collection",
        inputs: [
            {
                name: "salescollection", nameChildr: "value", label: "Annual sales collection (%)",
                type: "multiSlide", columnNum: "12", numImput: 13, labelData: sales, help: "Period in which sales are collected for each product. Ranges between Cash up to 360 days, on a 30 days increase."
            },
        ],
        columInput: 2,
        validate: validateOther,
    },
];

export default (arrayTabs);
