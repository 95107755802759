import Modal from 'react-responsive-modal';
import React, {Component} from 'react';
import validate from "./validateProducts";
import WizardTabsForm from "../TabsContainer/WizardTabsForm";
// Using an ES6 transpiler like Babel
// To include the default styles
import 'react-rangeslider/lib/index.css';
import {reset} from "redux-form";


class ModalProductos extends Component {

    state = {
        page: 0,
        reglaMutiMoneda: true,
        reglaMutiYearsInflation: true,
        values: {
            min: 2,
            max: 10,
        },
    };

    nextPage = () => {
        // eslint-disable-next-line react/destructuring-assignment,react/no-access-state-in-setstate
        this.setState({ page: this.state.page + 1 });
    };

    previousPage = () => {
        // eslint-disable-next-line react/destructuring-assignment,react/no-access-state-in-setstate
        this.setState({ page: this.state.page - 1 });
    };

    setPage = (page) => {
        // eslint-disable-next-line radix
        this.setState({ page: Number.parseInt(page) });
    };

    creadoExito = () => {
        this.props.leer("products/get_productos", this.props.idProject)
        this.props.onCloseModal();
        this.props.dispatch(reset('formProduct'));
        this.setPage(0);
    };

    // eslint-disable-next-line class-methods-use-this
    onSubmitsss = (ee) => {
        let data = {};
        data.infla = this.props.inflation;
        data.analysis_unit = this.props.analysis_unit;
        data.data_product = _.cloneDeep(ee);
        data.data_product.salescollection[13] = { value: 100 };
        try {
            delete data.data_product.segment.label;
            delete data.data_product.segment.value;
        } catch (e) {};

        data.data_product.initial_customer_stock = ee.initial_customer_stock === "" ? 0 : ee.initial_customer_stock;
        data.data_product.new_customers = ee.new_customers === "" ? 0 : ee.new_customers;
        data.data_product.customer_growth = ee.customer_growth === "" ? 0 : ee.customer_growth;
        data.data_product.annual_attrition = ee.annual_attrition === "" ? 0 : ee.annual_attrition;
        data.data_product.active_customers = ee.active_customers === "" ? 0 : ee.active_customers;
        data.data_product.base_price = ee.base_price === "" ? 0 : ee.base_price;
        data.data_product.annual_growthp = ee.annual_growthp === "" ? 0 : ee.annual_growthp;
        data.data_product.annual_discount = ee.annual_discount === "" ? 0 : ee.annual_discount;
        data.data_product.base_volume = ee.base_volume === "" ? 0 : ee.base_volume;
        data.data_product.annual_growthv = ee.annual_growthv === "" ? 0 : ee.annual_growthv;

        data.data_product.monthlydistribution = data.data_product.monthlydistribution.map((vall, index) => {
            return {value: vall.value, month: index + 1, type: 1}
        });

        data.data_product.monthlysales = data.data_product.monthlysales.map((vall, index) => {
            return {value: vall.value, month: index + 1, type: 1}
        });

        this.props.crear(
            "products/create_update_product/",
            data,
            this.creadoExito
        );

    };

    render() {
        const { value, reverseValue } = this.state;
        const styleModal = { modal: { borderRadius: 25,  width: "100%", minHeight: "500px", margin: "50px auto" , padding: "0px" } };
        const styleLinea = { width: "100%", margin: "auto", position: "absolute" };
        const { open, onCloseModal, onSubmitsss } = this.props;
        return (
            <Modal
                open={open}
                animationDuration={1000}
                onClose={onCloseModal}
                center
                styles={styleModal}
                closeIconSize={0}
            >
                <div className="mx-5 my-3">
                    <div className="font-weight-bold">{`NEW CUSTOMERS / ${this.props.arrayTabs[this.state.page].nombre}`}</div>
                </div>
                <div className="lineaAzul" style={{ ...styleLinea, height: 20 }} />
                <div className="mx-5 my-3">
                    <br /><br />
                    <WizardTabsForm
                        {...this.props}
                        nameForm="formProduct"
                        initialValue={this.props.initialValues}
                        page={this.state.page}
                        arrayTabs={this.props.arrayTabs}
                        nextPage={this.nextPage}
                        previousPage={this.previousPage}
                        setPage={this.setPage}
                        onSubmitsss={this.onSubmitsss}
                        errorForm={this.props.formulario || {}}
                        validateForm={validate}
                        enableReinitialize={true}
                    />
                </div>
            </Modal>
        );
    }
};

export default (ModalProductos);
