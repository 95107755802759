import React, { Component } from 'react';
import { connect } from 'react-redux';
import { submit } from "redux-form";
import _ from 'lodash';
import FormTabs from "../../../ProjectContainer/TabsContainer/FormTabs";
import validateProfil from "./validateProfiles";
import { OFFICIAL_COUNTRIES } from '../../../../../utility/contry';


class TabProfile extends Component {
    render() {
        const validation = localStorage.getItem('val') === "true";
        const { initialValues, dispatch, selectS } = this.props;
        const styleLinea = { width: "100%", borderRadius: "25px" };
        const input = [
            { name: "first_name", value: "", label: "First name", type: "input" },
            { name: "last_name", value: "", label: "Last name", type: "input" },
            { name: "email", value: "", label: "Email", type: "input" },
            // eslint-disable-next-line max-len
            validation === true && { name: "country", value: "", label: "Country", type: "select", data: OFFICIAL_COUNTRIES, labelKeyObject: true },
        ];

        const input2 = [
            // eslint-disable-next-line max-len
            validation === true && { name: "position_title", value: "", label: "What is your position title?", type: "input" },
            // eslint-disable-next-line max-len
            validation === true && { name: "functional_area", value: "", label: "In wich area do you work?", type: "select", data: selectS.functionalArea || [], labelKey: "area", valueKey: "id", labelKeyObject: true },
            // eslint-disable-next-line max-len
            validation === true && { name: "company_name", value: "", label: "What is your company name?", type: "input" },
            // eslint-disable-next-line max-len
            validation === true && { name: "head_quarters", value: "", label: "Where are the company head quarters?", type: "select", data: OFFICIAL_COUNTRIES, labelKeyObject: true },
            // eslint-disable-next-line max-len
            validation === true && { name: "company_industry", value: "", label: "In which sector does your company mostly do business?", type: "select", data: selectS.companyIndustry || [], labelKey: "sector", valueKey: "id", labelKeyObject: true },
            // eslint-disable-next-line max-len
            validation === true && { name: "company_size", value: "", label: "How many people are in your company?", type: "select", data: selectS.companySize || [], labelKey: "size", valueKey: "id", labelKeyObject: true },
            // eslint-disable-next-line max-len
            validation === true && { name: "revenue", value: "", label: "What is your annual revenue range?(USD)", type: "select", data: selectS.companyRevenue || [], labelKey: "revenue", valueKey: "id", labelKeyObject: true },
        ];

        const update = (data) => {
            const dataForm = _.cloneDeep(data);
            dataForm.country = { code: dataForm.country.value, name: dataForm.country.label };
            dataForm.head_quarters = { code: dataForm.head_quarters.value, name: dataForm.head_quarters.label };
            this.props.updatePerfil(`profiles/profile/?id=${data.id}`, dataForm);
        };

        return (
            <div className="content-wizarform">
                <h5 className="font-weight-bold ">PERSONAL INFORMATION</h5>
                <div className="lineaAzul" style={{ ...styleLinea, height: 5 }} />
                <br />
                <FormTabs
                    form="profiles"
                    arrayInput={input}
                    onSubmit={() => {}}
                    columInput="2"
                    validate={validateProfil}
                    buttonSubmit={false}
                    initialValues={initialValues}
                    enableReinitialize={true}
                />
                <br />
                {validation === true && (<h5 className="font-weight-bold ">ADDITIONAL INFORMATION</h5>)}
                {validation === true && (<div className="lineaAzul" style={{ height: 5 }} />)}
                {validation === true && (<br />)}
                <FormTabs
                    form="profiles"
                    arrayInput={input2}
                    onSubmit={update}
                    columInput="2"
                    validate={validateProfil}
                    buttonSubmit={false}
                    initialValues={initialValues}
                    enableReinitialize={true}
                />
                <div className="div-center">
                    <button type="submit" className="btn btn-optional2" onClick={() => dispatch(submit("profiles"))}>
                        Save
                    </button>
                </div>
            </div>
        );
    }
}

export default connect()(TabProfile);
