import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import WizardTabsProfile from "./TabsProfile/WizardTabsProfile";
import { OFFICIAL_COUNTRIES } from "../../../../utility/contry";
import { renderFilePicker } from '../../Utils/renderField/renderField';
import moment from "moment";
import LoadMask from "../../Utils/LoadMask/LoadMask";
import Swal from "sweetalert2";
import {NotificationManager} from "react-notifications";
import {getProject} from "../../../../redux/modules/inicio/inicio";

const initialValues = {
    country: "",
    functional_area: "",
    company_industry: "",
    company_size: "",
    revenue: "",
    head_quarters: "",
    question1: "",
    question2: "",
    password: "",
    new_password: "",
    confirm_password: "",
    billing_country: "",
    subscription: "",
};
const arrayTabs = [
    { nombre: "Profile", type: "profile" },
    { nombre: "Guest Users", type: "guest" },
    { nombre: "Security Information", type: "security" },
    { nombre: "Subscription and Payment", type: "subscription" },
];

class Profile extends Component {
    state = {
        page: 0,
        arrayTabs: [],
        avatar: null,
        initialValues: {},
        selectS: {},
        users: {},
        nUsers: 0,
    };

    initialValues = {
        country: "",
        functional_area: "",
        company_industry: "",
        company_size: "",
        revenue: "",
        head_quarters: "",
        question1: "",
        question2: "",
        password: "",
        new_password: "",
        confirm_password: "",
        billing_country: "",
        subscription: "",
    };

    componentDidMount() {
        // eslint-disable-next-line no-shadow
        const {getData, me, formlyAll} = this.props;
        if (window.location.href.includes("payment")) {
            this.setState({page: 3})
        }
        formlyAll(me.id);
        getData(me.id, 1);
        this.setState({arrayTabs});
        if (me.profile) this.initialValues = me.profile;
        this.initialValues.id = me.id;
        this.initialValues.first_name = me.first_name;
        this.initialValues.last_name = me.last_name;
        this.initialValues.email = me.email;
        if (me.profile) this.initialValues.country = _.filter(OFFICIAL_COUNTRIES, {value: me.profile.country})[0];
        this.setState({initialValues: this.initialValues});

        const f = new Date();
        const end_date = new Date(f.getFullYear(), f.getMonth(), f.getDate());
        const start_date = new Date(f.getFullYear(), f.getMonth() - 3, f.getDate());
        if (me.profile) this.props.getFilter(`profiles/plan/?end_date=${moment(end_date).format()}&id=${me.profile.user}&start_date=${moment(start_date).format()}`);

        const search = this.props.location.search;
        const params = new URLSearchParams(search);
        const token = params.get('token');
        const ba_token = params.get('ba_token');
        if (token != null && ba_token != null) {
            this.props.confirSubs({token: token, ba_token: ba_token});
            this.setState({page: 3})
        }

        if(this.props.location.pathname==="/user_cancel") {
            this.props.history.push("/user-profile/payment");
            this.setState({page: 3});
            Swal.fire({
                title: 'Cancelled',
                text: 'You canceled the payment of the subscription',
                type: 'warning',
                showCancelButton: false,
                confirmButtonText: 'OK!',
                cancelButtonText: 'Cancel',
                reverseButtons: true,
            }).then((result) => {
                if (result.value) {
                    dispatch(setLoader(true));
                    api.post("proyectos/destroyproject/", { idproy: idPro }).then(() => {
                        AlertManager.success('Project Deleted', 'Success', 3000);
                        dispatch(getProject(0, id, 1, ""));
                    }).catch((e) => {
                        if (e[0]) AlertManager.error(e[0], 'ERROR', 0);
                        else AlertManager.error('', 'ERROR', 0);
                    }).finally(() => {
                        dispatch(setLoader(false));
                    });
                }
            });
        }
    }


    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps !== nextContext) {

            if (nextProps.functionalArea && nextProps.functionalArea.length > 0) {
                // eslint-disable-next-line max-len
                this.initialValues.functional_area && (this.initialValues.functional_area = _.filter(nextProps.functionalArea, { id: this.initialValues.functional_area.id || this.initialValues.functional_area })[0]);
            }
            if (nextProps.companyIndustry && nextProps.companyIndustry.length > 0) {
                // eslint-disable-next-line max-len
                this.initialValues.company_industry && (this.initialValues.company_industry = _.filter(nextProps.companyIndustry, { id: this.initialValues.company_industry.id || this.initialValues.company_industry })[0]);
            }
            if (nextProps.companySize && nextProps.companySize.length > 0) {
                // eslint-disable-next-line max-len
                this.initialValues.company_size && (this.initialValues.company_size = _.filter(nextProps.companySize, { id: this.initialValues.company_size.id || this.initialValues.company_size })[0]);
            }
            if (nextProps.companyRevenue && nextProps.companyRevenue.length > 0) {
                // eslint-disable-next-line max-len
                this.initialValues.revenue && (this.initialValues.revenue = _.filter(nextProps.companyRevenue, { id: this.initialValues.revenue.id || this.initialValues.revenue })[0]);
            }
            if (nextProps.subscription && nextProps.subscription.length > 0) {
                // eslint-disable-next-line max-len
                this.initialValues.subscription && (this.initialValues.subscription = _.filter(nextProps.subscription, { id: this.initialValues.subscription.id || this.initialValues.subscription })[0]);
            }

            if (nextProps.securityQuestion && nextProps.securityQuestion.length > 0) {
                // eslint-disable-next-line max-len
                this.initialValues.question1 && (this.initialValues.question1 = _.filter(nextProps.securityQuestion, { id: this.initialValues.question1.id || this.initialValues.question1 })[0]);
                // eslint-disable-next-line max-len
                this.initialValues.question2 && (this.initialValues.question2 = _.filter(nextProps.securityQuestion, { id: this.initialValues.question2.id || this.initialValues.question2 })[0]);
            }

            //feature_item.feature.key === 'N_USERS' &&
            if (nextProps.formly && nextProps.formly.length > 0) {
                nextProps.formly.forEach((val) => {
                    if (val.key === "N_USERS") this.setState({ nUsers: val.value });
                    this.setState({ arrayTabs });
                });
            }

            this.setState({ selectS:
                {
                    ...this.state.selectS,
                    functionalArea: nextProps.functionalArea,
                    companyIndustry: nextProps.companyIndustry,
                    companySize: nextProps.companySize,
                    companyRevenue: nextProps.companyRevenue,
                    projects: nextProps.projects,
                    subscription: nextProps.subscription,
                    securityQuestion: nextProps.securityQuestion,
                    payments: nextProps.payments,
                },
            });
            // eslint-disable-next-line max-len
            if (this.initialValues.head_quarters) this.initialValues.head_quarters = _.filter(OFFICIAL_COUNTRIES, { value: this.initialValues.head_quarters.value || this.initialValues.head_quarters })[0];
            this.setState({ users: nextProps.users });
            this.setState({ initialValues: this.initialValues });

            if (!this.props.me.profile && arrayTabs.length[2] !== null) {
                delete arrayTabs[1];
                delete arrayTabs[3];
                this.setState({arrayTabs});
            }
        }
    }

    setAvatar = (avatar) => {
        this.setState({ avatar }, () => this.update());
    };

    update = () => {
        const { update, me } = this.props;
        const { avatar } = this.state;
        const datos = { id: me.profile.id, avatar, user: me.id };
        update(me.profile.id, {
            ...datos, id: me.profile.id, avatar: null, user: me.id,
        }, [{ file: avatar, name: "avatar" }]);
    };

    setPage = page => this.setState({ page: Number.parseInt(page) });

    render() {
        const { me, crear, getData, getUsers, getFilter, eliminar } = this.props;
        const { page, arrayTabs, initialValues, selectS, users, nUsers } = this.state;

        return (
            <div>
                <LoadMask loading={this.props.loader} blur positionM="inherit">
                <div className="header-azul">
                    <div className="content">
                        {me.profile && (<div className="row">
                            <div className="center div-img">
                                <Field
                                    photo={me.profile && me.profile.avatar ? me.profile.avatar : null}
                                    className="inp-imagen"
                                    setFile={this.setAvatar}
                                    name="avatar"
                                    component={renderFilePicker}
                                />
                            </div>
                            <div className="center">
                                <h3 className="name-text">{me.first_name} {me.last_name}</h3>
                                <br />
                                <br />
                            </div>
                        </div>)}
                    </div>
                </div>
                <br />
                <br />
                    <WizardTabsProfile
                        {...this.props}
                        page={page}
                        arrayTabs={arrayTabs}
                        setPage={this.setPage}
                        initialValues={initialValues}
                        selectS={selectS}
                        users={users}
                        crear={crear}
                        getData={getData}
                        getUsers={getUsers}
                        getFilter={getFilter}
                        eliminar={eliminar}
                        nUsers={nUsers}
                    />
                </LoadMask>
            </div>
        );
    }
}

export default reduxForm({
    form: 'profile', // a unique identifier for this form
})(Profile);
