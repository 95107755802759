import { handleActions } from 'redux-actions';
import { createReducer } from "../baseReducer/baseReducer";
// ------------------------------------
// Constants
// ------------------------------------
export const { reducers, initialState, actions } = createReducer(
    "formlyAll",
    "proyectos/formly",
    "formLyAll",
    "/formGener",
);

export default handleActions(reducers, initialState);
