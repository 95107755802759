import React from 'react';
import {Field, reduxForm, formValueSelector, submit} from 'redux-form';
import { connect } from 'react-redux';
import moment from "moment";
import { SelectField } from "../../../Utils/renderField/renderField";
import monthly from '../../../../../../assets/img/monthly.png';
import anually from '../../../../../../assets/img/anually.png';

let SubscriptionForm = (props) => {
    const { handleSubmit, plan, subscript, subscription, me, hasPlan, hasSubs } = props;

    const updateSubs = (rad, sub) => {
        if (subscription && (subscription !== undefined || subscription !== null)) {
            const profile = me;
            profile.subscription = subscription;
            const data = hasSubs ? { radio: rad, subscription: sub } : { radio: rad, profile };
            const url = hasSubs ? `profiles/changepaypal/?id=${me.id}` : `profiles/apipaypal/?id=${me.id}`;
            props.updatePerfil(url, data, true);
        }
    };

    return (
        <form onSubmit={handleSubmit}>
            {console.log(props)}
            <div className="form-row align-items-end">
                <div className={`form-group col-md-4 sinborde`}>
                    <label htmlFor="subPlan">Subscription Plan</label>
                    <input disabled type="text" value={plan ? plan.name : "----"} className="form-control" />
                </div>
                <div className={`form-group col-md-4 sinborde`}>
                    <label htmlFor="subPlan">Type</label>
                    <input disabled type="text" value={plan ? plan.type : "----"} className="form-control" />
                </div>
                <div className={`form-group col-md-4 sinborde`}>
                    <label htmlFor="subPlan">Last Payment</label>
                    {/* eslint-disable-next-line max-len */}
                    <input disabled type="text" value={plan ? moment(plan.last_payment).format("DD/MM/YY HH:mm") : "----"} className="form-control" />
                </div>
                <div className={`form-group col-md-4 sinborde`}>
                    <br/>
                    <label htmlFor="subscription">Select Plan</label>
                    <Field
                        name="subscription"
                        type="text"
                        component={SelectField}
                        labelKeyObject={true}
                        labelKey="type"
                        valueKey="id"
                        options={subscript}
                    />
                    <div className="form-row align-items-end">
                        <div className={`form-group col-md-6 sinborde text-right`}>
                            <label className="texto-negrita">Fee: </label>
                            <br/>
                            <label className="texto-negrita">Next Payment: </label>
                        </div>
                        <div className={`form-group col-md-6 sinborde`}>
                            <label className="texto-verde">${plan && plan.fee}</label>
                            <br/>
                            <label className="texto-verde">{plan ? moment(plan.next_payment).format("DD/MM/YY HH:mm") : (<br />)}</label>
                        </div>
                    </div>
                </div>
                <div className={`form-group col-md-4 sinborde`}>
                    <div className="form-row align-items-end card-yearly">
                        <div className={`form-group col-md-6`}>
                            <img src={anually} alt="report" height="150px" />
                        </div>
                        <div className={`form-group col-md-6 my-auto`}>
                            <div>Billed Anually</div>
                            <div className="font-weight-bold">${subscription ? subscription.annual : "00.00"}</div>
                            <div>{subscription ? subscription.discount : "00.00"}%</div>
                            <br />
                            <button type="submit" className="btn btn-optional" onClick={() => updateSubs("yearly", subscription)}>
                                Pay with &nbsp; <i className="fa fa-paypal fa-1x"></i>
                            </button>
                        </div>
                    </div>
                </div>
                <div className={`form-group col-md-4 sinborde`}>
                    <div className="form-row align-items-end card-monthly">
                        <div className={`form-group col-md-6`}>
                            <img src={monthly} alt="report" height="150px" />
                        </div>
                        <div className={`form-group col-md-6 my-auto`}>
                            <div>Billed Billed Monthly</div>
                            <div className="font-weight-bold">${subscription ? subscription.amount : "00.00"}</div>
                            <br />
                            <button type="submit" className="btn btn-optional" onClick={() => updateSubs("monthly", subscription)}>
                                Pay with &nbsp; <i className="fa fa-paypal fa-1x"></i>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    );
};

SubscriptionForm = reduxForm({
    validate: (data) => {
        const errors = {};
        if (!data.subscription) errors.subscription = 'This field is required';
        return errors;
    },
})(SubscriptionForm);

SubscriptionForm = connect((state, mapStateToProps) => {
    const form = state.form[mapStateToProps.form];
    const camposObject = {};
    if (form && form.registeredFields) {
        const selector = formValueSelector(mapStateToProps.form);
        Object.keys(form.registeredFields).forEach((val, index) => {
            camposObject[val] = selector(state, val);
        });
    }
    return camposObject
})(SubscriptionForm);

export default SubscriptionForm;
