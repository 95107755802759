import { validateCurren, validateGeneral, validateOther, validateRateAnSen } from "../ProjectSetUp/validateGenerarl";
import {CURRENCY_ITEMS} from "../../../../utility/contry";

import imgAgregar from "../../../../../assets/img/agregar.png";
import imgElimiar from "../../../../../assets/img/cancelar.png";
import imgEditar from "../../../../../assets/img/editar.png";

const meses = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec",];
const sales = ["Cash", "30", "60", "85", "120", "150", "180", "210", "240", "270", "300", "330", "360",];

const arrayTabs = [
    {
        nombre: "Products by Territory / Segments",
        help: "Select the specific product into which will be allocated direct costs attributable to the production of it",
        inputs: [
            {
                name: "product", value: "", label: "Product", type: "select", data: [], labelKeyObject: true,
                dataHijosAsociados: [
                    // eslint-disable-next-line max-len
                    { name: "segment", evalFale: `val.segment`, eval: `val.segment`, condicion: "true" },
                    // eslint-disable-next-line max-len
                    { name: "territory", evalFale: `val.territory`, eval: `val.territory`, condicion: "true" },
                ],
            },
            { name: "segment", value: "", label: "Segment", type: "inputLabe" },
            { name: "territory", value: "", label: "Territory", type: "inputLabe" },
        ],
        columInput: 2,
        validate: validateGeneral,
    },
    {
        nombre: "Direct material",
        help: "Cost of the specific materials used (material costs for the parts that go) in creating the final products (goods)",
        inputs: [
            {
                name: "material", value: "", label: "Territory name", type: "multiInputMaterials",
                columnNum: "12", moneda: "Q", nombreCampo: "name", imgElimiar, imgAgregar, imgEditar,
            }
        ],
        columInput: 2,
        validate: validateCurren,
    },
    {
        nombre: "Direct labor employees",
        help: "Cost of the specific labor in creating the final products (goods)",
        inputs: [
            {
                name: "employee_cogs", value: "", label: "Territory name", type: "multiInputEmployee",
                columnNum: "12", moneda: "Q", nombreCampo: "name", imgElimiar, imgAgregar, imgEditar,
            }
        ],
        columInput: 2,
        validate: validateRateAnSen,
    },
    {
        nombre: "Others",
        inputs: [
            {
                name: "otheer", value: "", label: "Territory name", type: "multiInputOther",
                columnNum: "12", moneda: "Q", nombreCampo: "name", imgElimiar, imgAgregar, imgEditar,
                validate: [ "direct_shipping_ubc", "direct_shipping_acg", "warehousing_ubc", "warehousing_acg",
                            "overhead_ubc", "overhead_acg", "contractual_fees_ubc", "contractual_fees_acg",
                            "cost_funds_ubc", "cost_funds_acg", "other_ubc", "other_acg",
                ],
                help: [
                    "Cost of sending out a product to a customer",
                    "Refers to expenditure on moving the goods into or out of the warehouse (receipt, storage, handling, dispatch, etc.)",
                    "General expense related to basic administration and general business operations. Applies to the company's operations as a whole i.e. time allocated from the accountants, receptionist, CEO",
                    "Specific contract feet associated in creating the final products (goods) i.e. certifications",
                    "Direct financial cost associated in creating the final products (goods)",
                    "Other direct cost directly associated in creating the final products (goods)",
                    "Other direct cost directly associated in creating the final products (goods)",

                ]
            }
        ],
        columInput: 2,
        validate: validateOther,
    },
];

export default (arrayTabs);
