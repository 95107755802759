import React, { Component } from 'react';
import Tabs, { TabPane } from 'rc-tabs';
import TabContent from 'rc-tabs/lib/TabContent';
import ScrollableInkTabBar from 'rc-tabs/lib/ScrollableInkTabBar';
import { connect } from 'react-redux';
import { submit } from "redux-form";
import FormTabs from "./FormTabs";

class WizardTabsForm extends Component {
    render() {
        const {
            nextPage,
            onSubmitsss,
            page,
            setPage,
            arrayTabs,
            initialValue,
            nameForm,
            validateForm,
            dispatch,
            errorForm,
            enableReinitialize,
            buttonSubmit,
        } = this.props;
        let formValidado = true;

        if (arrayTabs[page]) {
            formValidado = true;
            arrayTabs[page].inputs.forEach((value) => {
                // eslint-disable-next-line no-prototype-builtins
                if ((errorForm.syncErrors || {}).hasOwnProperty(value.name)) {
                    formValidado = false;
                }
                // eslint-disable-next-line no-unused-expressions
                value.validate && value.validate.forEach((val) => {
                    // eslint-disable-next-line no-prototype-builtins
                    if ((errorForm.syncErrors || {}).hasOwnProperty(val)) {
                        formValidado = false;
                    }
                });
            });
        }

        return (
            <div>
                <Tabs
                    defaultActiveKey={page.toString()}
                    activeKey={page.toString()}
                    tabBarPoition="top"
                    onchange={this.callback}
                    renderTabBar={
                        formValidado ? () => (
                            <ScrollableInkTabBar onTabClick={setPage} />
                        ) : () => (
                            <ScrollableInkTabBar onTabClick={() => dispatch(submit(nameForm))} />
                        )
                    }
                    renderTabContent={() => <TabContent />}
                >
                    {arrayTabs.map((value, index) => {
                        return arrayTabs.length === index + 1 ? (
                            <TabPane tab={value.nombre} key={index.toString()} />
                        ) : (
                            <TabPane tab={value.nombre} key={index.toString()} />
                        );
                    })}
                </Tabs>
                {arrayTabs[page] && (
                    <React.Fragment>
                        {arrayTabs.length === page + 1 ? (
                            <FormTabs
                                {...this.props}
                                form={nameForm}
                                onSubmit={onSubmitsss}
                                page={page}
                                buttonSubmit={buttonSubmit}
                                arrayInput={arrayTabs[page].inputs && arrayTabs[page].inputs}
                                columInput={arrayTabs[page].columInput && arrayTabs[page].columInput}
                                help={arrayTabs[page].help && arrayTabs[page].help}
                                initialValues={initialValue}
                                validate={validateForm}
                                enableReinitialize={enableReinitialize}
                            />
                        ) : (
                            <FormTabs
                                {...this.props}
                                form={nameForm}
                                onSubmit={nextPage}
                                page={page}
                                buttonSubmit={buttonSubmit}
                                arrayInput={arrayTabs[page].inputs && arrayTabs[page].inputs}
                                columInput={arrayTabs[page].columInput && arrayTabs[page].columInput}
                                help={arrayTabs[page].help && arrayTabs[page].help}
                                initialValues={initialValue}
                                validate={validateForm}
                                enableReinitialize={enableReinitialize}
                            />
                        )}
                    </React.Fragment>
                )}
            </div>
        );
    }
}

export default connect()(WizardTabsForm);
