import React from 'react';
import {Field, reduxForm, formValueSelector, submit} from 'redux-form';
import { connect } from 'react-redux';
import { renderField } from "../../../Utils/renderField";

let PasswordForm = (props) => {
    const { handleSubmit, password, new_password, confirm_password } = props;
    return (
        <form onSubmit={handleSubmit}>
            <div className="form-row align-items-end">
                <div className="form-group col-md-6">
                    <label htmlFor="password">Current password</label>
                    <Field
                        name="password"
                        label="Contraseña"
                        component={renderField}
                        type="password"
                        className="form-control"
                    />
                </div>
                <div className="form-group col-md-8">
                    <ul className="ul-tow">
                        {/* eslint-disable-next-line camelcase,max-len */}
                        <li className={new_password && new_password.match("[a-z]") ? 'validado' : ''}>One lowercase character</li>
                        {/* eslint-disable-next-line camelcase,max-len */}
                        <li className={new_password && new_password.match("[A-Z]") ? 'validado' : ''}>One uppercase character</li>
                        {/* eslint-disable-next-line camelcase */}
                        <li className={new_password && new_password.match("[0-9]") ? 'validado' : ''}>One number</li>
                        {/* eslint-disable-next-line camelcase,max-len */}
                        <li className={new_password && new_password.match("[!@#$%^&*()_+\\-=\\[\\]{};':\"\\\\|,.<>\\/?]") ? 'validado' : ''}>One special character</li>
                        {/* eslint-disable-next-line camelcase,max-len */}
                        <li className={new_password && new_password.length >= 8 ? 'validado' : ''}>8 characters minimumr</li>
                    </ul>
                </div>
                <div className="form-group col-md-6">
                    <label htmlFor="password">New password</label>
                    <Field
                        name="new_password"
                        label="Contraseña"
                        component={renderField}
                        type="password"
                        className="form-control"
                    />
                </div>
                <div className="form-group col-md-6">
                    <label htmlFor="confirm_password">Confirm new password</label>
                    <Field
                        name="confirm_password"
                        label="Confirmar Contraseña"
                        component={renderField}
                        type="password"
                        className="form-control"
                    />
                </div>
            </div>
            <br />
            <div className="buttons-box">
                <button type="submit" className="btn btn-optional2">Update password</button>
            </div>
        </form>
    );
};

// eslint-disable-next-line no-unused-vars
export const matchPassword = (pass, confirm) => validatorFromFunction((value) => {
    return pass === confirm;
});

PasswordForm = reduxForm({
    form: 'updatePass', // a unique identifier for this form
    validate: (data) => {
        const errors = {};
        if (!data.password) errors.password = 'This field is required';
        if (!data.confirm_password) errors.confirm_password = 'This field is required';
        if (data.new_password
            && data.new_password.match("[a-z]")
            && data.new_password.match("[A-Z]")
            && data.new_password.match("[0-9]")
            && data.new_password.match("[!@#$%^&*()_+\\-=\\[\\]{};':\"\\\\|,.<>\\/?]")
            && data.new_password.length >= 8
            // eslint-disable-next-line no-empty
        ) { } else errors.new_password = 'Invalid Password';
        // eslint-disable-next-line max-len,eqeqeq
        if (data.new_password && data.new_password !== data.confirm_password) errors.confirm_password = 'Passwords do not match';
        return errors;
    },
})(PasswordForm);

const selector = formValueSelector('updatePass');

PasswordForm = connect((state) => {
    const new_password = selector(state, "new_password");

    return {
        new_password,
    };
})(PasswordForm);

export default PasswordForm;
