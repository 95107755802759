import React, {Component} from 'react';
import {connect} from 'react-redux';
import {TableHeaderColumn} from "react-bootstrap-table";
import icono from "../../../../../assets/img/invest-C.png";
import Grid from "../../Utils/Grid";
import ModalSG from "./ModalInvestments";
import ModalTotalSga from "./ModalTotalInvestments";
import {standardActions} from "../../Utils/Grid/StandardActions";
import "./productos.css";
import arrayTabs from "./arryTabs";
import { LEVELITEMS } from "./../../../../utility/contry";
import _ from "lodash";
import cloneDeep from "lodash/cloneDeep";
import ReactTooltip from "react-tooltip";
import felchaI from "../../../../../assets/img/flecha-izquierda.png";
import felchaD from "../../../../../assets/img/flecha-derecha.png";
import {RenderNumberGrid} from  "../../Utils/renderField/renderReadField";

const initialValue = {
    personal: "",
    short_term_debt: "",
    long_term_debt: "",
    term_debt_base_cost: "",
    term_debt_annual_growth: "",
};

let arrayProduct = [];

const monedaFormat = (num) => parseFloat(num).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');

const data = {
    count: 0,
    results: [],
};


class WizardGeography extends Component {

    state = {
        numTab: 0,
        openM: false,
        open: false,
        openTotalSales: false,
        openTotalSalesProduct: false,
        idProject: 0,
        inflation: 0,
        analysis_unit: 0,
        salesGrowthRate: false,
        custAttrition: false,
        activeCustomer: false,
        priceDiscount: false,
        detailedCOGS: false,
        moneda: "GTQ",
        monedaMainCur: "GTQ",
        data: {
            count: 0,
            results: [],
        },
        arrayTabsModal: arrayTabs,
        initialValues: initialValue,
        finish: false,
    };

    dataForm=[];

    componentDidUpdate() {
        ReactTooltip.rebuild();
    }

    componentDidMount() {
        const idProject = this.props.setUpGeneral.idForm;
        const formSaveData = this.props.setUpGeneral.formDataSave;
        const geographyList = this.props.setUpGeneral.geographyList;
        const inflation = formSaveData ? formSaveData.inflation: 0;
        const analysis_unit = formSaveData ? formSaveData.proyinfo.analisis_units: 0;
        this.setState({ analysis_unit });
        this.setState({ inflation });
        this.setState({ idProject });
        this.props.leer("investments/get_investments", idProject);

        this.setState({ monedaMainCur: formSaveData && formSaveData.proyinfo.main_currency });
        this.setState({ moneda: formSaveData && formSaveData.proyinfo.sales_currency }, () => {
            const arrayTabsModal = arrayTabs;
            arrayTabsModal[1].inputs[0].moneda= this.state.moneda;
            arrayTabsModal[2].inputs[0].moneda= this.state.moneda;
            this.setState({ arrayTabsModal });
        });
        this.setState({ data });
        const context = this;
        window.onclick = function(event) {
            if (!event.target.matches('.btnM')) context.setState({ openM: false})
        }
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps !== nextContext) {
            data.count = 0;
            data.results = [];

            if (nextProps.itemComp && nextProps.itemComp.length > 0) {
                const compesation = cloneDeep(nextProps.itemComp);
                compesation.forEach((val, index) => {

                });
            }


            if (nextProps.item && nextProps.item.results && nextProps.item.results.length > 0) {
                const datA = nextProps.item.results;
                    datA.forEach((val, index) => {
                        data.results[index] = {
                            id: val.id,
                            personal: monedaFormat(val.personal),
                            short: monedaFormat(val.short_term_debt),
                            long: monedaFormat(val.long_term_debt),
                            totalP: monedaFormat(val.total_ppe),
                            totalI: monedaFormat(val.total_ia),
                        };
                        this.dataForm[val.id] = {
                            id: val.id,
                            personal: val.personal,
                            short_term_debt: val.short_term_debt,
                            long_term_debt: val.long_term_debt,
                            term_debt_base_cost: val.sga_termdebt_base_cost,
                            term_debt_annual_growth: val.sga_termdebt_annual_growth,
                            ppe: val.ppe_investments.map((val) => {
                                return {ppe_value: val.ppe_value, ppe_year: val.ppe_year, ppe_name: {value: val.ppe_name.id, label: val.ppe_name.name}}
                            }),
                            ia: val.ia_investments.map((val) => {
                                return {ia_value: val.ia_value, ia_year: val.ia_year, ia_name: {value: val.ia_name.id, label: val.ia_name.name}}
                            }),
                        };
                    });
            }

            if (nextProps.formly && nextProps.formly.length > 0) {
                nextProps.formly.forEach((val) => {
                    val.key === "SALES_GROWTH_RATE" &&  this.setState({ salesGrowthRate: val.value === "true" });
                    val.key === "CUSTOMER_ATTRITION" && this.setState({ custAttrition: val.value === "true" });
                    val.key === "ACTIVE_CUSTOMER" &&  this.setState({ activeCustomer: val.value === "true" });
                    val.key === "PRICE_DISCOUNT" && this.setState({ priceDiscount: val.value === "true" });
                    val.key === "DETAILED_COGS_BY_PRODUCT" && this.setState({ detailedCOGS: val.value === "true" });
                });

            }
            this.setState({ data });
            if (nextProps.setUpGeneral && nextProps.setUpGeneral.formDataSave && nextProps.setUpGeneral.formDataSave.proyinfo) {
                let finish = nextProps.setUpGeneral.formDataSave.proyinfo.finish;
                if (nextProps.login.me.user_profile && nextProps.login.me.user_profile.permission === 1) finish = true;
                this.setState({ finish: finish });
            }
        }
    }

    render() {
        const onOpenModal = () => {
            this.props.leerComp("sga/sgabyproject", this.state.idProject);
            this.setState({ open: true })
        };
        const onCloseModal = () => {
            this.setState({ initialValues: initialValue });
            this.setState({ open: false });
        };
        const editarModal = (e) => {
            this.setState({ initialValues: this.dataForm[e] }, () => onOpenModal())
        };

        const totalizadosales = () => {
            const { analisis_units, main_currency  } = this.props.setUpGeneral.formDataSave.proyinfo;
            let data = { id: this.state.idProject, infla: this.props.setUpGeneral.formDataSave.inflation, analysis_unit: analisis_units };
            this.props.leerTotalizadoresSales("investments/total_investments/", data, main_currency);
        };

        const onOpenModalTotalSales = () => {totalizadosales(); this.setState({ openTotalSales: true })};
        const onCloseModalTotalSales = () => this.setState({ openTotalSales: false });

        const eliminarTerry = (e) => {
            const url = `investments/destroy_investments/`;
            const urlLeer = `investments/get_investments`;
            this.props.eliminar(url, urlLeer, e, this.state.idProject);
        };
        const opneMenu = () => { this.setState({ openM: !this.state.openM });};

        const { open, openTotalSales, openTotalSalesProduct, finish } = this.state;
        return (
            <div>
                <div className="header-wizard-form">
                    <div className="columna-1 my-auto">
                        <table>
                            <tbody>
                                <tr>
                                    <td>
                                        <img
                                            className="flecha-pagina pag-div"
                                            src={felchaI}
                                            alt="regresar"
                                            style={this.props.previousPage ? { display: "inline-block" } : { display: "none" }}
                                            onClick={this.props.previousPage}
                                        />
                                    </td>
                                    <td>
                                        <img src={icono} alt="icono" className="icono-step" />
                                    </td>
                                    <td><span>Investments</span></td>
                                    <td>
                                        <img
                                            className="flecha-pagina pag-div"
                                            src={felchaD}
                                            alt="regresar"
                                            style={this.props.nextPage ? { display: "inline-block" } : { display: "none" }}
                                            onClick={this.props.nextPage}
                                        />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="columna-2 my-auto">
                        Here you can determine mayor key variables that affects the required investments that the project needs to start.
                        Type of investments includes capital, debt, physical, tangible assets or intangible assets like intellectual property.
                    </div>
                </div>
                <div className="div-center" />
                <br/>
                <div className="content-wizarform">
                    { data.results.length === 0 && (
                        <button type="button" onClick={onOpenModal} disabled={finish} className="btn btn-primary btn-sm mr-1">Add Investments</button>
                    )}
                    <div className="float-right">
                        <button type="button" disabled={this.state.data.results.length <= 0} onClick={onOpenModalTotalSales} className="btn-Movil btn btn-optional btn-sm mr-1">Total Investments</button>
                        <div className="dropdown menu-Movil" style={ { margin: "auto" } }>
                            <a className="btnM" onClick={opneMenu} style={ { cursor: "pointer" } } >
                                <i style={ { color: "black", margin: "auto" } } className="fa fa-ellipsis-v fa-2x btnM" />
                            </a>
                            <div
                                id="myDropdown"
                                className={`dropdown-content ${this.state.openM===true && "show"}`}
                                style={ { right: "0px" } }
                            >
                                <a onClick={onOpenModalTotalSales} style={ { cursor: "pointer" } } >Total Investments</a>
                            </div>
                        </div>
                    </div>
                    <br />
                    <br />
                    <Grid
                        page={1}
                        hover
                        pagination={false}
                        data={this.state.data}
                        // loading={this.props.loader}
                        // onPageChange={onPageChange}
                        // onSortChange={onSortChange}
                    >
                        <TableHeaderColumn
                            dataField="id"
                            dataAlign="center"
                            isKey
                            dataFormat={
                                standardActions( finish===true ? {  } : { editarClick: editarModal, eliminar: eliminarTerry } )
                            }
                            style={{ fontSize: "10px" }}
                        >ACTIONS</TableHeaderColumn>
                        <TableHeaderColumn tdStyle={{ whiteSpace: 'normal' }} thStyle={{ whiteSpace: 'normal' }} dataFormat={RenderNumberGrid}
                            dataAlign="left" dataField="personal" dataSort>{`PERSONAL <${this.state.moneda}>`}</TableHeaderColumn>
                        <TableHeaderColumn tdStyle={{ whiteSpace: 'normal' }} thStyle={{ whiteSpace: 'normal' }} dataFormat={RenderNumberGrid}
                            dataAlign="left" dataField="short" dataSort>{`SHORT TERM DEBT <${this.state.moneda}>`}</TableHeaderColumn>
                        <TableHeaderColumn tdStyle={{ whiteSpace: 'normal' }} thStyle={{ whiteSpace: 'normal' }} dataFormat={RenderNumberGrid}
                            dataAlign="left" dataField="long" dataSort>{`LONG TERM DBT <${this.state.moneda}>`}</TableHeaderColumn>
                        <TableHeaderColumn tdStyle={{ whiteSpace: 'normal' }} thStyle={{ whiteSpace: 'normal' }} dataFormat={RenderNumberGrid}
                            dataAlign="left" dataField="totalP" dataSort>{`TOTAL PP&E <${this.state.moneda}>`}</TableHeaderColumn>
                        <TableHeaderColumn tdStyle={{ whiteSpace: 'normal' }} thStyle={{ whiteSpace: 'normal' }} dataFormat={RenderNumberGrid}
                            dataAlign="left" dataField="totalI" dataSort>{`TOTAL IA <${this.state.moneda}>`}</TableHeaderColumn>
                    </Grid>
                </div>

                <ModalSG
                    {...this.props}
                    arrayTabs={this.state.arrayTabsModal}
                    open={open}
                    idProject={this.state.idProject}
                    onSubmitsss={this.props.onSubmit}
                    onCloseModal={onCloseModal}
                    inflation={this.state.inflation}
                    analysis_unit={this.state.analysis_unit}
                    initialValue={this.state.initialValues}
                />

                <ModalTotalSga
                    {...this.props}
                    moneda={this.state.moneda}
                    monedaMainCur={this.state.monedaMainCur}
                    open={openTotalSales}
                    idProject={this.state.idProject}
                    onCloseModal={onCloseModalTotalSales}
                    salesGrowthRate={this.state.salesGrowthRate}
                    custAttrition={this.state.custAttrition}
                    activeCustomer={this.state.activeCustomer}
                    priceDiscount={this.state.priceDiscount}
                    detailedCOGS={this.state.detailedCOGS}
                />

            </div>
        );
    }
}

export default connect()(WizardGeography);
