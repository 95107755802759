import { validateCurren, validateGeneral, validateOther, validateRateAnSen } from "../ProjectSetUp/validateGenerarl";
import {CURRENCY_ITEMS} from "../../../../utility/contry";

import imgAgregar from "../../../../../assets/img/agregar.png";
import imgElimiar from "../../../../../assets/img/cancelar.png";
import imgEditar from "../../../../../assets/img/editar.png";

const meses = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec",];
const sales = ["Cash", "30", "60", "85", "120", "150", "180", "210", "240", "270", "300", "330", "360",];

const arrayTabs = [
    {
        nombre: "Compensation & Benefits",
        inputs: [
            {
                name: "compesation", value: "", label: "Compensation & Benefits", type: "multiInputCompesation",
                columnNum: "12", moneda: "Q", nombreCampo: "name", imgElimiar, imgAgregar, imgEditar,
            },
        ],
        columInput: 2,
        validate: validateRateAnSen,
    },
    {
        nombre: "Office Cost",
        inputs: [
            {
                name: "office", value: "", label: "Office Cost", type: "multiInputOffice",
                columnNum: "12", moneda: "Q", imgElimiar, imgAgregar, imgEditar,
                help: [
                    "Monthly fee payed on office / facility / premises rented.",
                    "Monthly fee payed on several basic utilities related to the office / facility / premises operation",
                    "Monthly fee payed on insurance related to the office / facility / premises",
                    "Monthly fee payed on office supplies to maintain regular operation",
                    "Monthly fee payed on several insurances related to the office / facility / premises",
                    "Monthly fee payed on taxes related to the office / facility / premises",
                    "Pending",
                    "Monthly fee payed on general / regular / recurrent maintenance cost related to the office / facility / premises",
                ],
            },
        ],
        columInput: 2,
        validate: validateRateAnSen,
    },
    {
        nombre: "Marketing Cost",
        inputs: [
            {
                name: "markCost", value: "", label: "Office Cost", type: "multiInputOffice",
                columnNum: "12", moneda: "Q", imgElimiar, imgAgregar, imgEditar,
                help: [
                    "Monthly expense incurred on developing / maintaining your company web site",
                    "Monthly expense incurred on implementing ads / banners / offers on electronic media",
                    "Monthly expense incurred on implementing communications / offers sent by electronic means such as email",
                    "Monthly expense incurred on traditional advertising such as newspaper, magazines, catalogues, among others.",
                    "Monthly expense incurred on traditional advertising such as radio.",
                    "Monthly expense incurred on traditional advertising such as open air TV or cable TV",
                    "Monthly expense incurred on outdoor ads such as billboards, among others.",
                    "Monthly expense for implementing direct mailing sent by regular mail",
                    "Monthly expense for developing print material such as brochures, flyers, take away, coupons, newsletters, among others.",
                    "Monthly expenses for developing promotional gifts such as pencils, notebooks, caps, mugs, among others.",
                    "Monthly expense incurred on product launch / customer events.",
                    "Monthly expense for being / participating as a sponsor of an activity, event, sport, person, among others.",
                    "Monthly expense incurred on PR or community activities",
                    "Monthly donations made to major charity or nonprofit organizations",
                    "Monthly fee payed on associated licenses that might be used in your products, brand, among others",
                    "Monthly fee payed on trademark, patents, among others.",
                    "Monthly contractual fee payed to the agencies that provides the service.",
                    "Other monthly marketing expenses that might incur.",
                ],
            },
        ],
        columInput: 2,
        validate: validateRateAnSen,
    },
    {
        nombre: "Sales Cost",
        inputs: [
            {
                name: "salesCost", value: "", label: "Office Cost", type: "multiInputOffice",
                columnNum: "12", moneda: "Q", imgElimiar, imgAgregar, imgEditar,
                help: [
                    "Monthly expense incurred on travelling such as taxi, hotels, air fares, airport parking, among others.",
                    "Monthly expense incurred on customer appreciation activities, business lunches, among others.",
                    "Monthly expense incurred on company car / personal car gas.",
                    "Monthly expense incurred on transportation toll.",
                    "Monthly expense incurred on company car regular maintance.",
                    "Monthly expense incurred on company cell phone bill.",
                    "Other monthly sales expenses that might incur.",
                ],
            },
        ],
        columInput: 2,
        validate: validateRateAnSen,
    },
    {
        nombre: "IT & Communications Cost",
        inputs: [
            {
                name: "itComunCost", value: "", label: "Office Cost", type: "multiInputOffice",
                columnNum: "12", moneda: "Q", imgElimiar, imgAgregar, imgEditar,
                help: [
                    "Monthly expense incurred on Technology fees, such as service providers, external support, among other.",
                    "Monthly expense incurred on hardware fees, such as  equipment rent.",
                    "Monthly expense incurred on Software fees, such as licenses.",
                    "Monthly expense incurred on regular telecommunication such as line lines, cell phones, bill.",
                    "Monthly expense incurred on internet / cable providers.",
                    "Other monthly IT expenses that might incur.",
                ],
            },
        ],
        columInput: 2,
        validate: validateRateAnSen,
    },
    {
        nombre: "R&D Cost",
        inputs: [
            {
                name: "rdCost", value: "", label: "Office Cost", type: "multiInputOffice",
                columnNum: "12", moneda: "Q", imgElimiar, imgAgregar, imgEditar,
                help: [
                    "Monthly expense incurred on product / prototype design",
                    "Monthly expense incurred on a field day / testing facility, among other",
                    "Monthly expense incurred on intellectual property (IP)",
                    "Monthly expense incurred on process testing / developing / mapping, among others",
                    "Monthly expense incurred on product / prototype construction / development",
                    "Monthly expense incurred on product / prototype research / consultancy / advisory, among others.",
                    "Other monthly R&amp;D expenses that might incur",
                ],
            },
        ],
        columInput: 2,
        validate: validateRateAnSen,
    },
    {
        nombre: "Finance Cost",
        inputs: [
            {
                name: "finCost", value: "", label: "Office Cost", type: "multiInputOffice",
                columnNum: "12", moneda: "Q", imgElimiar, imgAgregar, imgEditar,
                help: [
                    "Monthly expense incurred on accountant / auditing fees.",
                    "Monthly expense incurred on legal advisory / legal retainer.",
                    "Monthly expense incurred on bank fees & commissions.",
                    "Other monthly Finance expenses that might incur.",
                ],
            },
        ],
        columInput: 2,
        validate: validateRateAnSen,
    },
];

const arrayTabsFalse = [
    {
        nombre: "Compensation & Benefits",
        inputs: [
            { name: "office_cost", value: "", label: "Office <>", type: "inputNumber" },
            { name: "marketing_cost", value: "", label: "Marketing <>", type: "inputNumber" },
            { name: "sales_cost", value: "", label: "Sales <>", type: "inputNumber" },
            { name: "it_communications_cost", value: "", label: "IT & Communications <>", type: "inputNumber" },
            { name: "r_d_cost", value: "", label: "R&D <>", type: "inputNumber" },
            { name: "finance_cost", value: "", label: "Finance <>", type: "inputNumber" },
            { name: "compensation_benefits_cost", value: "", label: "Compensation & benefits <>", type: "inputNumber" },
        ],
        columInput: 2,
        validate: validateRateAnSen,
    },
];


export {
    arrayTabs,
    arrayTabsFalse,
};
