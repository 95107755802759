export const validate = (values) => {
    const errors = {};
    if (!values.name) { errors.name = 'Required'; }
    if (!values.horizon) { errors.horizon = 'Required'; }
    // if (!values.inflation_base) { errors.inflation_base = 'Required'; }
    if (!values.inflation || !values.inflation.length) {
        errors.inflation = { _error: 'Required' };
    } else {
        const detalleArray = [];
        if (Array.isArray(values.inflation)) {
            values.inflation.forEach((value, index) => {
                const detErrors = {};
                //if (!value.value) detErrors.value = "Required";
                if (value.value > 100) {
                    detErrors.value = "The value must be less than or equal to 100\n";
                    detalleArray[index] = detErrors;
                }
            });
        }
        if (detalleArray.length && detalleArray.length > 0) {
            errors.inflation = detalleArray;
        }
    }
    if (!values.analisis_units) { errors.analisis_units = 'Required'; }

    if (!values.color) { errors.color = 'Required'; }
    if (!values.main_currency) { errors.main_currency = 'Required'; }
    if (!values.sales_currency) { errors.sales_currency = 'Required'; }
    if (!values.cogs_currency) { errors.cogs_currency = 'Required'; }
    if (!values.sga_currency) { errors.sga_currency = 'Required'; }
    if (!values.pl_currency) { errors.pl_currency = 'Required'; }

    if (!values.annual_rate1 && values.annual_rate1 > 0) { errors.annual_rate1 = 'Required'; }
    if (!values.annual_rate2 && values.annual_rate2 > 0) { errors.annual_rate2 = 'Required'; }
    if (!values.annual_rate3 && values.annual_rate3 > 0) { errors.annual_rate3 = 'Required'; }

    const anualRate = Number(values.annual_rate_base);
    if (!anualRate && anualRate > 0) { errors.annual_rate_base = 'Required'; }
    return errors;
};


export default validate;
