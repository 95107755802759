import React, { PureComponent } from 'react';
import {BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer} from 'recharts';
import Grid from "../../../Utils/Grid";
import {TableHeaderColumn} from "react-bootstrap-table";

const colores = [
    "#D1523D", "#AD4485",
    "#2E5389", "#BA7B36",
    "#ACB53C", "#CD7672",
    "#679BF9", "#3CBFA9",
    "#87CDF4", "#C98DC7",
    "#7D5791", "#7EC4C2",
];

const monedaFormat = (num) => parseFloat(num).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');

const datas = [
    {
        name: "P&L",
        lable: ["ITEM", "Y1", "Y2", "CARGA (%)"],
        data: {
            count: 0,
            results: [
                { val0: "nombree", val1: 1, val2: 2, val3: 45 },
                { val0: "nombree", val1: 1, val2: 2, val3: 45 },
                { val0: "nombree", val1: 1, val2: 2, val3: 45 },
                { val0: "nombree", val1: 1, val2: 2, val3: 45 },
            ],
        },
    },
];

class ReportTable extends PureComponent {
    render() {
        const { data } = this.props;
        return (
            <div className="content-wizarform">
                {
                    data && data.map((value, index) => (
                        <div>
                            <br/>
                            <h4 className="textBold div-center">{value.name}</h4>
                            <Grid
                                page={1}
                                hover
                                pagination={false}
                                data={value.data}
                                // loading={this.props.loaderMoneda}
                                // onPageChange={onPageChange}
                                // onSortChange={onSortChange}
                            >
                                <TableHeaderColumn dataField="id" hidden={true} isKey>ACTIONS</TableHeaderColumn>
                                {(value.label.map((items, indxItem) => {
                                    return (
                                        <React.Fragment>
                                            <TableHeaderColumn
                                                dataAlign={"left"}
                                                dataField={`val${indxItem}`}
                                                dataSort
                                                tdStyle={{ whiteSpace: 'normal' }}
                                                thStyle={{ whiteSpace: 'normal' }}
                                                //width="8.33333333333%"
                                                dataFormat={ i => (<div className={`${i && i.negrita && "textBold"}`} >{i && i.val}</div>) }
                                            >
                                                {items}
                                            </TableHeaderColumn>
                                        </React.Fragment>
                                    );
                                }))}
                            </Grid>
                        </div>
                    ))
                }
            </div>
        );
    }
}
export default (ReportTable);
