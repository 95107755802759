import Modal from 'react-responsive-modal';
import React, {Component} from 'react';
import validate from "./validateInvestments";
import WizardTabsForm from "../TabsContainer/WizardTabsForm";
import 'react-rangeslider/lib/index.css';
import {reset} from "redux-form";
import LoadMask from "../../Utils/LoadMask/LoadMask";


class ModalProductos extends Component {

    state = {
        page: 0,
        reglaMutiMoneda: true,
        reglaMutiYearsInflation: true,
        values: {
            min: 2,
            max: 10,
        },
    };

    nextPage = () => {
        // eslint-disable-next-line react/destructuring-assignment,react/no-access-state-in-setstate
        this.setState({ page: this.state.page + 1 });
    };

    previousPage = () => {
        // eslint-disable-next-line react/destructuring-assignment,react/no-access-state-in-setstate
        this.setState({ page: this.state.page - 1 });
    };

    setPage = (page) => {
        // eslint-disable-next-line radix
        this.setState({ page: Number.parseInt(page) });
    };

    // eslint-disable-next-line class-methods-use-this
    onSubmitsss = (dataFom) => {
        const data = { id_proy: this.props.idProject, data_investments: {} };
        data.data_investments.id = dataFom.id || null;
        data.data_investments.personal = dataFom.personal;
        data.data_investments.short_term_debt = dataFom.short_term_debt;
        data.data_investments.long_term_debt = dataFom.long_term_debt;
        data.data_investments.term_debt_base_cost = dataFom.term_debt_base_cost;
        data.data_investments.term_debt_annual_growth = dataFom.term_debt_annual_growth;
        data.data_investments.ppe = dataFom.ppe.map((val, index) => {
            return {ppe_value: val.ppe_value, ppe_year: val.ppe_year, ppe_name: {id: val.ppe_name.value, name: val.ppe_name.label}}
        });
        data.data_investments.ia = dataFom.ia.map((val, index) => {
            return {ia_value: val.ia_value, ia_year: val.ia_year, ia_name: {id: val.ia_name.value, name: val.ia_name.label}}
        });


        data.data_investments.personal = dataFom.personal === "" ? 0 : dataFom.personal;
        data.data_investments.short_term_debt = dataFom.short_term_debt === "" ? 0 : dataFom.short_term_debt;
        data.data_investments.long_term_debt = dataFom.long_term_debt === "" ? 0 : dataFom.long_term_debt;
        data.data_investments.term_debt_base_cost = dataFom.term_debt_base_cost === "" ? 0 : dataFom.term_debt_base_cost;
        data.data_investments.term_debt_annual_growth = dataFom.term_debt_annual_growth === "" ? 0 : dataFom.term_debt_annual_growth;


        this.props.crear("investments/create_update_investments/", data);
        this.props.leer("investments/get_investments", this.props.idProject);
        this.props.onCloseModal();
        this.props.dispatch(reset('formInvest'));
        this.setPage(0);
    };

    render() {
        const { value, reverseValue } = this.state;
        const styleModal = { modal: { borderRadius: 25, maxWidth: "65%", margin: "50px auto" , padding: "0px" } };
        const styleLinea = { width: "100%", margin: "auto", position: "absolute" };
        const { open, onCloseModal, onSubmitsss } = this.props;
        return (
            <Modal
                open={open}
                animationDuration={1000}
                onClose={onCloseModal}
                center
                styles={styleModal}
                closeIconSize={0}
            >
                <div className="mx-5 my-3">
                    <div className="font-weight-bold">{`NEW INVESTMENTS / ${this.props.arrayTabs[this.state.page].nombre}`}</div>
                </div>
                <LoadMask loading={this.props.loaderMoneda} blur>
                    <div className="lineaAzul" style={{ ...styleLinea, height: 20 }} />
                    <div className="mx-5 my-3">
                        <br/>
                        <WizardTabsForm
                            {...this.props}
                            nameForm="formInvest"
                            initialValue={this.props.initialValue}
                            page={this.state.page}
                            arrayTabs={this.props.arrayTabs}
                            nextPage={this.nextPage}
                            previousPage={this.previousPage}
                            setPage={this.setPage}
                            onSubmitsss={this.onSubmitsss}
                            errorForm={this.props.formulario || {}}
                            validateForm={validate}
                            enableReinitialize={true}
                        />
                    </div>
                </LoadMask>
            </Modal>
        );
    }
};

export default (ModalProductos);
