import React, { Component } from 'react';
import { connect } from 'react-redux';
import Modal from 'react-responsive-modal';
import icono from "../../../../../assets/img/geography-C.png";
import FormTabs from "../TabsContainer/FormTabs";
import validate from "./validateGeography";
import imgAgregar from "../../../../../assets/img/agregar.png";
import imgElimiar from "../../../../../assets/img/cancelar.png";
import Grid from "../../Utils/Grid";
import {TableHeaderColumn} from "react-bootstrap-table";
import { standardActions } from "../../Utils/Grid/StandardActions"
import {submit, reset} from "redux-form";
import imgQuestion from "../../../../../assets/img/question.png";
import ReactTooltip from "react-tooltip";
import ModalEditLabel from "../ProjectSetUp/ModalEditLabel";
import felchaI from "../../../../../assets/img/flecha-izquierda.png";
import felchaD from "../../../../../assets/img/flecha-derecha.png";
import {CURRENCY_ITEMS, VAL_ITEMS} from "../../../../utility/contry";
import Swal from "sweetalert2";

class WizardGeography extends Component {
    state = {
        open: false,
        openEdit: false,
        data: {
            count: 0,
            results: [],
        },
        idProject: 0,
        initialValues: {},
        editandoURL: false,
        lablesArray: [],
        nItem: 0,
        finish: false,
        nTerritories: 0,
    };

    dataForm=[];

    onOpenModalEdit = (campo) => {
        this.setState({ nItem: Number(campo) }, () => this.setState({ openEdit: true }));
    };

    onCloseModalEdit = () => {
        this.setState({ openEdit: false });
    };

    saveLabel = (label) => {
        const { lablesArray, nItem } = this.state;
        const labels = _.cloneDeep(lablesArray);
        labels[nItem].label = label;
        this.setState({ lablesArray: labels }, () => {
            this.input[0].label = labels[14].label;
            this.mulTnput[0].label = labels[15].label;
            this.onCloseModalEdit();
            this.onCloseModal();
            //setTimeout(() => {  this.onOpenModal(); }, 1000);
            //this.setState({ open: false }, () => this.setState({ open: true }));
            //this.setState({ open: false }, () => setTimeout(() => {  this.onOpenModal(); }, 1000));
        });
    };

    input = [
        { name: "territory", value: "", label: "Territory name", type: "input", help: "Main market territory where products / services are sold / provided", edit: () => this.onOpenModalEdit(14) },
    ];

    mulTnput = [{
        name: "segments",
        value: "",
        label: "Territory name",
        type: "multiInputTable",
        columnNum: "12",
        nombreCampo: "name",
        imgElimiar,
        imgAgregar,
        help: "Specific market segment in which products / services will sold within a territory",
        edit: () => this.onOpenModalEdit(15)
    }];

    componentDidMount() {
        const idProject = this.props.setUpGeneral.idForm;
        this.setState({ idProject });
        this.props.leer("territories/get_territories", idProject);
    }

    onOpenModal = () => this.setState({ open: true });

    onOpenModalN = () => {
        const { state: { nTerritories, data: { count } } } = this;
        if (count >= nTerritories) {
            Swal.fire({
                title: 'You can not add more terrytories, you must update your subscription!',
                text: `You have ${count} of ${nTerritories} allowed territories by project.`,
                type: 'warning',
                showCancelButton: false,
                confirmButtonText: 'Ok!',
                cancelButtonText: 'Cancel',
                reverseButtons: true,
            }).then((result) => {});
        } else this.onOpenModal();
    };

    onCloseModal = () => {
        this.setState( { initialValues: {} }, () => {
            this.setState({ open: false });
            this.setState({ editandoURL: false });
        });
    };

    componentDidUpdate() {
        ReactTooltip.rebuild();
    }

    componentWillReceiveProps(nextProps, nextContext) {

        if (nextProps.setUpGeneral && nextProps.setUpGeneral.item && nextProps.setUpGeneral.item.length>0){
            this.state.lablesArray.length === 0 && this.setState({ lablesArray: nextProps.setUpGeneral.item }, () =>{
                const labels = this.state.lablesArray;
                this.input[0].label = labels[14].label;
                this.mulTnput[0].label = labels[15].label;
                this.setState({ arrayTabs: this.arrayTabs });
            });
        }

        if (nextProps.item && nextProps.item.count > 0) {
            const data = {
                count: nextProps.item.count,
                results: [],
            };
            nextProps.item.results.forEach((val) => {
                let segment = "";
                const segmentData = [];
                val.segment.forEach((value, i) => {
                    segment+= i !== val.segment.length-1 ? `${value.name} / ` : `${value.name}`;
                    segmentData.push({ name:  value.name});
                });
                data.results.push({id: val.id, terry: val.name, segment: segment});
                this.dataForm[val.id] = {territory: val.name, segments: segmentData};
            });
            this.setState( { data: data });
        } else this.setState({ data: { count: 0, results: [],} });

        if (nextProps.setUpGeneral && nextProps.setUpGeneral.formDataSave && nextProps.setUpGeneral.formDataSave.proyinfo) {
            let finish = nextProps.setUpGeneral.formDataSave.proyinfo.finish;
            if (nextProps.login.me.user_profile && nextProps.login.me.user_profile.permission === 1) finish = true;
            this.setState({ finish: finish });
        }


            if (nextProps.formly.length > 0) {
                nextProps.formly.forEach((val) => {
                    if (val.key === "CUSTOMIZED_PROJECT_NAME_LABELS" && val.value == "false") {
                        this.input[0].edit = null;
                        this.mulTnput[0].edit = null;
                    } else if (val.key === "N_TERRITORIES") {
                        this.setState({ nTerritories: Number(val.value) });
                    } else if (val.key === "N_SEGMENTS_BY_TERRITORY") {
                        this.mulTnput[0].nTerritories = Number(val.value);
                    }
                    this.setState({ arrayTabs: this.arrayTabs });
                });
            }
    }

    onSubmitLabel = () => {
        const labels = {};
        labels.user = this.props.login.me.id;
        labels.json = this.state.lablesArray;
        this.props.crear("jsons/savejson/", labels, () => this.props.leerLabel("jsons/me", labels.user));
    };

    // eslint-disable-next-line class-methods-use-this
    onSubmitsss = (ee) => {
        this.onSubmitLabel();
        let data = { id_project: this.state.idProject, data_geography: _.cloneDeep(ee)};
        this.props.crear("territories/create_update_territory/", data, () => this.props.leer("territories/get_territories", this.state.idProject));
        this.onCloseModal();
        this.props.dispatch(reset('geographyForm'));
    };

    editarModal = (e) => {
        this.setState({ editandoURL: true });
        this.setState( { initialValues: this.dataForm[e] }, () => {
            this.onOpenModal();
        });
    };


    eliminarTerry = (e) => {
        let url = `territories/delete_territory/?id_project=${this.state.idProject}&id_territory=${e}`;
        let urlLeer = `territories/get_territories/`;
        this.props.eliminar(url, urlLeer, e, this.state.idProject);
    };

    render() {
        const { open, lablesArray, nItem, openEdit, finish } = this.state;
        const styleModal = { modal: { borderRadius: 25,  maxWidth: "70% !important",  width: "100%", margin: "50px auto" , padding: "0px" }, };
        const styleLinea = { width: "100%" };
        return (
            <div>
                <div className="header-wizard-form" style={{ width: "100%" }}>
                    <div className="columna-1-Ge columna-1 my-auto">
                        <table>
                            <tbody>
                                <tr>
                                    <td>
                                        <img
                                            className="flecha-pagina pag-div"
                                            src={felchaI}
                                            alt="regresar"
                                            style={this.props.previousPage ? { display: "inline-block" } : { display: "none" }}
                                            onClick={this.props.previousPage}
                                        />
                                    </td>
                                    <td>
                                        <img src={icono} alt="icono" className="icono-step" />
                                    </td>
                                    <td><span>Geography - Territory & segments</span></td>
                                    <td>
                                        <img
                                            className="flecha-pagina pag-div"
                                            src={felchaD}
                                            alt="regresar"
                                            style={this.props.nextPage ? { display: "inline-block" } : { display: "none" }}
                                            onClick={this.props.nextPage}
                                        />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="columna-2-Ge columna-2 my-auto">
                        Create your territories and its segments. A sales territory is a geographical area in which
                        sales occur and a particular sales team is responsible for it.
                    </div>
                </div>
                <div className="div-center" />
                <br/>
                <div className="content-wizarform">
                    <button onClick={this.onOpenModalN} disabled={finish} className="btn btn-primary btn-sm mr-1">Add Territory</button>
                    <br /><br />
                    {/*loading={this.props.loader}*/}
                    <Grid
                        page={1}
                        hover
                        pagination={false}
                        data={this.state.data}
                        loading={false}
                        //pagination={false}
                        //onPageChange={onPageChange}
                        //onSortChange={onSortChange}
                    >
                        <TableHeaderColumn
                            dataField="id" dataAlign="center" dataSort isKey
                            dataFormat={
                                standardActions( finish===true ? {  } : { editarClick: this.editarModal, eliminar: this.eliminarTerry } )
                            }
                        > ACTIONS </TableHeaderColumn>
                        <TableHeaderColumn dataAlign='left' headerAlign='left' dataField="terry" dataSort>ACTIVE TERRITORY</TableHeaderColumn>
                        <TableHeaderColumn dataAlign='left' headerAlign='left' dataField="segment" dataSort>ACTIVE SEGMENTS</TableHeaderColumn>
                    </Grid>


                    <Modal
                        open={open} animationDuration={1000} onClose={this.onCloseModal}
                        center styles={styleModal} closeIconSize={0}
                    >
                        <div className="mx-5 my-3">
                            <h5 className="font-weight-bold ">NEW TERRITORY</h5>
                        </div>
                        <div className="lineaAzul" style={{ ...styleLinea, height: 20 }} />
                        <div className="mx-5 my-3">
                            <br />
                            <FormTabs
                                {...this.props}
                                form="geographyForm"
                                onSubmit={this.onSubmitsss}
                                arrayInput={this.input}
                                columInput="2"
                                validate={validate}
                                buttonSubmit={false}
                                initialValues={this.state.initialValues}
                                enableReinitialize={true}
                            />
                            <div className="font-weight-bold">SEGMENTS</div>
                            <div className="lineaAzul" style={{ height: 3 }} />
                            <br />
                            <FormTabs
                                {...this.props}
                                form="geographyForm"
                                onSubmit={this.onSubmitsss}
                                arrayInput={this.mulTnput}
                                columInput="1"
                                validate={validate}
                                buttonSubmit={false}
                                initialValues={this.state.initialValues}
                                enableReinitialize={true}
                            />
                            <div className="div-center">
                                <button className="btn btn-secondary" onClick={this.onCloseModal}> Cancel </button>
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                <button type="submit" className="btn btn-optional2" onClick={() => this.props.dispatch(submit("geographyForm"))}>
                                    Save
                                </button>
                            </div>
                        </div>
                    </Modal>
                <ModalEditLabel
                    open={openEdit}
                    saveLabel={this.saveLabel}
                    input={lablesArray && lablesArray[nItem] && lablesArray[nItem].label}
                    onCloseModal={this.onCloseModalEdit}
                />
                </div>
            </div>
        );
    }
}

export default connect()(WizardGeography);
