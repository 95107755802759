import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import './login.css';
import LoadMask from "Utils/LoadMask/LoadMask";
import logo from "../../../../../assets/img/logo.png";
import { Field, reduxForm } from "redux-form";
import { renderField } from "../../Utils/renderField";
import arrow_rigth from "../../../../../assets/img/arrow-rigth.png";
import {onSubmit, resetPass} from "../../../../redux/modules/cuenta/login";

class ResetForm extends Component {
    render() {
        const { handleSubmit } = this.props;
        if (localStorage.getItem('token')) {
            return (<Redirect to="/" />);
        }
        return (
            <form className="form-validate mb-lg" onSubmit={handleSubmit}>
                <div className="form-group has-feedback">
                    <Field
                        name="email"
                        label="Username"
                        component={renderField}
                        type="text"
                        className="form-control"
                        placeholder="Email"
                    />
                </div>
                <div className="buttons-box">
                    <button type="submit" className="btn btn-primary m-1 align-self-center">Reset my password</button>
                </div>
            </form>
        );
    }
}

ResetForm = reduxForm({
    form: 'register', // a unique identifier for this form
    validate: (data) => {
        const errors = {};
        if (!data.email) errors.email = 'This field is required';
        if (data.email && !data.email.match("[!@#$%^&*()_+\\-=\\[\\]{};':\"\\\\|,.<>\\/?]")) { errors.email = 'Email invalid'; }
        return errors;
    },
})(ResetForm);

export default ResetForm;
