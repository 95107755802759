

export const validate = (values) => {
    const errors = {};

    if (!values.compesation || values.compesation.length <=0) {
        errors.compesation = { _error: 'Required' };
    } else {
        const detalleArray = [];
        values.compesation.forEach((value, index) => {
            const detErrors = {};
            if (value.no_of_employees === "") detErrors.no_of_employees = "Required";
            if (!value.level) detErrors.level = "Required";
            if (value.base_salary === "") detErrors.base_salary = "Required";
            if (value.salary_growth === "") detErrors.salary_growth = "Required";
            if (value.benefits === "") detErrors.benefits = "Required";
            if (value.sales_commission === "") detErrors.sales_commission = "Required";
            if (value.other === "") detErrors.other = "Required";
            if (value.annual_bonus === "") detErrors.annual_bonus = "Required";
            if (detErrors.no_of_employees || detErrors.material_name || detErrors.level
                || detErrors.base_salary || detErrors.salary_growth
                || detErrors.benefits || detErrors.sales_commission || detErrors.other
                || detErrors.annual_bonus) detalleArray[index] = detErrors;
        });
        if (detalleArray.length) errors.compesation = detalleArray;
    }
    if (!values.office || !values.office.length) { errors.office = { _error: 'Required' }; } else {
        const detalleArray = [];
        values.office.forEach((value, index) => {
            const detErrors = {};
            if (!value.annual_growth && values.annual_growth > 0) detErrors.annual_growth = "Required";
            if (!value.base_cost && values.base_cost > 0) detErrors.base_cost = "Required";
            if (detErrors.annual_growth || detErrors.base_cost) detalleArray[index] = detErrors;
        });
        if (detalleArray.length) errors.office = detalleArray;
    }
    if (!values.markCost || !values.markCost.length) { errors.markCost = { _error: 'Required' }; } else {
        const detalleArray = [];
        values.markCost.forEach((value, index) => {
            const detErrors = {};
            if (!value.annual_growth && values.annual_growth > 0) detErrors.annual_growth = "Required";
            if (!value.base_cost && values.base_cost > 0) detErrors.base_cost = "Required";
            if (detErrors.annual_growth || detErrors.base_cost) detalleArray[index] = detErrors;
        });
        if (detalleArray.length) errors.markCost = detalleArray;
    }
    if (!values.salesCost || !values.salesCost.length) { errors.salesCost = { _error: 'Required' }; } else {
        const detalleArray = [];
        values.salesCost.forEach((value, index) => {
            const detErrors = {};
            if (!value.annual_growth && values.annual_growth > 0) detErrors.annual_growth = "Required";
            if (!value.base_cost && values.base_cost > 0) detErrors.base_cost = "Required";
            if (detErrors.annual_growth || detErrors.base_cost) detalleArray[index] = detErrors;
        });
        if (detalleArray.length) errors.salesCost = detalleArray;
    }
    if (!values.itComunCost || !values.itComunCost.length) { errors.itComunCost = { _error: 'Required' }; } else {
        const detalleArray = [];
        values.itComunCost.forEach((value, index) => {
            const detErrors = {};
            if (!value.annual_growth && values.annual_growth > 0) detErrors.annual_growth = "Required";
            if (!value.base_cost && values.base_cost > 0) detErrors.base_cost = "Required";
            if (detErrors.annual_growth || detErrors.base_cost) detalleArray[index] = detErrors;
        });
        if (detalleArray.length) errors.itComunCost = detalleArray;
    }
    if (!values.rdCost || !values.rdCost.length) { errors.rdCost = { _error: 'Required' }; } else {
        const detalleArray = [];
        values.rdCost.forEach((value, index) => {
            const detErrors = {};
            if (!value.annual_growth && values.annual_growth > 0) detErrors.annual_growth = "Required";
            if (!value.base_cost && values.base_cost > 0) detErrors.base_cost = "Required";
            if (detErrors.annual_growth || detErrors.base_cost) detalleArray[index] = detErrors;
        });
        if (detalleArray.length) errors.rdCost = detalleArray;
    }
    if (!values.finCost || !values.finCost.length) { errors.finCost = { _error: 'Required' }; } else {
        const detalleArray = [];
        values.finCost.forEach((value, index) => {
            const detErrors = {};
            if (!value.annual_growth && values.annual_growth > 0) detErrors.annual_growth = "Required";
            if (!value.base_cost && values.base_cost > 0) detErrors.base_cost = "Required";
            if (detErrors.annual_growth || detErrors.base_cost) detalleArray[index] = detErrors;
        });
        if (detalleArray.length) errors.finCost = detalleArray;
    }
    return errors;
};


export default validate;
