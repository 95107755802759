import { handleActions } from 'redux-actions';
import { push } from "react-router-redux";
import { initialize as initializeForm, reset } from 'redux-form';
import { AlertManager } from "alerts";
import { api } from "api";
import Swal from "sweetalert2";
import {getProject} from "../inicio/inicio";

const SUBMIT = 'LOGIN_SUBMIT';
const LOADER = 'LOGIN_LOADER';
const ME = 'LOGIN_ME';
const HASSUBS = 'HASSUBS_ME';
const ERROR_LOGIN = 'ERROR_LOGIN';


export const constants = {
    SUBMIT,
};

// ------------------------------------
// Pure Actions
// ------------------------------------

export const setLoader = loader => ({
    type: LOADER,
    loader,
});

export const setMe = me => ({
    type: ME,
    me,
});

export const setHasSub = hasSubs => ({
    type: HASSUBS,
    hasSubs,
});


// ------------------------------------
// Actions
// ------------------------------------

export const onSubmit = (data = {}) => (dispatch, getStore) => {
    dispatch({ type: ERROR_LOGIN, errorLogin: ''});
    dispatch(setLoader(true));
    api.post('/token/', data).then((response) => {
        localStorage.setItem('token', response.token);
        localStorage.setItem('user', response.user);
        dispatch(initializeForm('profile', response.user));
        dispatch(setMe(response.user));
        dispatch(push("/"));
    }).catch(() => {
        dispatch({ type: ERROR_LOGIN, errorLogin: 'Sorry, your password was incorrect. Please double-check your password'});
        // AlertManager.error('Incorrect credentials, please try again', 'ERROR', 0);
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

export const getMe = () => (dispatch) => {
    api.get('/users/me').then((me) => {
        dispatch(initializeForm('profile', me));
        dispatch(setMe(me));
        api.get(`/users/haveprofile/?username=${me.username}`).then((dataHP) => {
            localStorage.setItem('val', dataHP.val);
        }).catch(() => {}).finally(() => {});
        api.get(`subscriptions/has_subscription/?user=${me.username}`).then((data) => {
            dispatch(setHasSub(data.has_subscription));
        }).catch(() => {}).finally(() => {});
    }).catch(() => {}).finally(() => {});
};

export const logOut = () => (dispatch) => {
    Swal.fire({
        title: 'Logout?',
        text: 'Are you sure you want to close the session and logout?',
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, logout',
        cancelButtonText: 'Cancel',
        reverseButtons: true,
    }).then((result) => {
        if (result.value) {
            localStorage.removeItem('token');
            dispatch(push("/login"));
        }
    });
};

export const resetPass = data => (dispatch) => {
    dispatch(setLoader(true));
    api.post('auth/users/reset_password/', data).then(() => {
        Swal.fire({
            type: 'success',
            title: 'Hello',
            text: 'If the email address that you entered is associated with a Onepage.business account, you will receive an email from us with link to reset your password. If you do not receive an email, please check your junk mail folder or contact us for further assistance',
        }).then(() => window.location.replace(" https://onepage.business/"));
    }).catch((e) => {
        AlertManager.error("Please enter a valid email and try again", 'ERROR', 0);
    }).finally(() => dispatch(setLoader(false)));
    localStorage.removeItem('token');
};

export const confirmResetPass = data => (dispatch) => {
    dispatch(setLoader(true));
    api.post('auth/users/reset_password_confirm/', data).then(() => {
        AlertManager.success('Sent', 'Success', 3000);
        dispatch(reset('register'));
    }).catch((e) => {
        e.token ? AlertManager.error(e.token[0], 'ERROR', 0) : AlertManager.error("Error", 'ERROR', 0);
    }).finally(() => dispatch(setLoader(false)));
    localStorage.removeItem('token');
};

export const actions = {
    onSubmit,
    logOut,
    resetPass,
    confirmResetPass,
    getMe,
};

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [ME]: (state, { me }) => {
        return {
            ...state,
            me,
        };
    },
    [HASSUBS]: (state, { hasSubs }) => {
        return {
            ...state,
            hasSubs,
        };
    },
    [ERROR_LOGIN]: (state, { errorLogin }) => {
        return {
            ...state,
            errorLogin
        }
    }
};

export const initialState = {
    loader: false,
    me: {},
    hasSubs: true,
    tipoForm: "",
    errorLogin: ''
};

export default handleActions(reducers, initialState);
