

export const validate = (values) => {
    const errors = {};
    if (!values.product) { errors.product = 'Required'; }
    if (!values.material || !values.material.length) {
        errors.material = { _error: 'Required' };
    } else {
        const detalleArray = [];
        values.material.forEach((value, index) => {
            const detErrors = {};
            let repetidos = [];
            if (!value.material_name) detErrors.material_name = "Required";
            else {
                repetidos = _.filter(values.material, (x) => {
                    return x.material_name ? x.material_name === value.material_name : false;
                });
                // eslint-disable-next-line max-len
                if (repetidos.length >= 2) detErrors.material_name = `The material name: ${value.material_name}, already exist!`;
            }
            if (typeof value.unitary_cost == "undefined") detErrors.unitary_cost = "Required";
            if (typeof value.annual_growth == "undefined") detErrors.annual_growth = "Required";
            if (typeof value.required_material == "undefined") detErrors.required_material = "Required";
            if (typeof value.efficiency == "undefined") detErrors.efficiency = "Required";
            if (
                detErrors.unitary_cost || detErrors.annual_growth
                || detErrors.required_material || detErrors.efficiency
                || detErrors.material_name
            ) {
                detalleArray[index] = detErrors;
            }
        });
        if (detalleArray.length) {
            errors.material = detalleArray;
        }
    }

    if (!values.employee_cogs || !values.employee_cogs.length) {
        errors.employee_cogs = { _error: 'Required' };
    } else {
        const detalleArray = [];
        values.employee_cogs.forEach((value, index) => {
            const detErrors = {};
            if (typeof value.no_of_employees == "undefined") detErrors.no_of_employees = "Required";
            if (!value.level && value.level > 0) detErrors.level = "Required";
            if (typeof value.base_salary == "undefined") detErrors.base_salary = "Required";
            if (typeof value.salary_growth == "undefined") detErrors.salary_growth = "Required";
            if (typeof value.annual_bonus == "undefined") detErrors.annual_bonus = "Required";
            if (detErrors.material_name || detErrors.level
                || detErrors.base_salary || detErrors.salary_growth
                || detErrors.annual_bonus) detalleArray[index] = detErrors;
        });
        if (detalleArray.length) errors.employee_cogs = detalleArray;
    }
    const detalleArray = [];
    // if (!values.direct_shipping_ubc && values.direct_shipping_ubc !== 0) { errors.direct_shipping_ubc = 'Required'; }
    // if (!values.direct_shipping_acg && values.direct_shipping_acg !== 0) { errors.direct_shipping_acg = 'Required'; }
    // if (!values.warehousing_ubc && values.warehousing_ubc !== 0) { errors.warehousing_ubc = 'Required'; }
    // if (!values.warehousing_acg && values.warehousing_acg !== 0) { errors.warehousing_acg = 'Required'; }
    // if (!values.overhead_ubc && values.overhead_ubc !== 0) { errors.overhead_ubc = 'Required'; }
    // if (!values.overhead_acg && values.overhead_acg !== 0) { errors.overhead_acg = 'Required'; }
    // if (!values.contractual_fees_ubc && values.contractual_fees_ubc !== 0) { errors.contractual_fees_ubc = 'Required'; }
    // if (!values.contractual_fees_acg && values.contractual_fees_acg !== 0) { errors.contractual_fees_acg = 'Required'; }
    // if (!values.cost_funds_ubc && values.cost_funds_ubc !== 0) { errors.cost_funds_ubc = 'Required'; }
    // if (!values.cost_funds_acg && values.cost_funds_acg !== 0) { errors.cost_funds_acg = 'Required'; }
    // if (!values.other_ubc && values.other_ubc !== 0) { errors.other_ubc = 'Required'; }
    // if (!values.other_acg && values.other_acg !== 0) { errors.other_acg = 'Required'; }
    if (detalleArray.length) errors.employee_cogs = detalleArray;
    return errors;
};


export default validate;
