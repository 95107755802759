import PropTypes from 'prop-types';
import React, { Component } from 'react';
import {Link, Redirect} from 'react-router-dom';
import RegisterForm from './RegisterForm';
import LoadMask from "../../Utils/LoadMask/LoadMask";
import './register.css';
import logo from "../../../../../assets/img/logo.png";
import arrowRigth from "../../../../../assets/img/arrow-rigth.png";
import check from "../../../../../assets/img/cheack-azul.png";


class Readytogo extends Component {


    render() {
        if (localStorage.getItem('token')) {
            return (<Redirect to="/" />);
        }
        return (
            <div className="blue-gradient-bg-register div-center">
                <div className="card card-login-register readytogo">
                    <Link className="card-regresar" to="/login">
                        <img src={arrowRigth} alt="logo" width={10} />
                        Back
                    </Link>
                    {/* <img src={logo} alt="logo" width={175} /> */}
                    <div className="mt-4">
                        <img src={logo} alt="logo" width={158} style={{"margin-left": "-25px"}} />
                    </div>
                    <div className="d-flex flex-column align-items-center mt-3 mb-2 w-100">
                        <img  className="" src={check} alt="logo" width={100} />
                        <p className="text-negro mt-2">ONE MORE STEP</p>
                        <p className="text-azul mt-2">Please check your email and activate your account and you'll be ready to go</p>
                    </div>
                </div>
            </div>
        );
    }
}

export default Readytogo;
