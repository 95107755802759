import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import './FooterDisclaimer.css'

class FooterDisclaimer extends Component {
    render() {
        const { disclaimer = true } = this.props;
        return (
            <div className="d-flex mt-5 align-items-start mb-0 text-muted flex-column footer-disclaimer">
                {
                    disclaimer && (
                        <span className="title">Disclaimer:</span>
                    )
                }
                <div className="content">
                    {
                        disclaimer && (
                            <p className="m-0">
                                Algorithms, calculations and outputs provided by Onepage.Business are solely as a guidance to you and for general informational purposes only. They are not a substitute for professional advice. Before, entering into an investment project, we recommend reviewing in full details, with your financial, accountant, legal and/or tax advisors.
                            </p>
                        )
                    }
                    <p className="m-0 mt-2">
                        View the <Link to="/terms">&nbsp;terms&nbsp;</Link>& <Link to="/policy">&nbsp;privacy policy.&nbsp;</Link>
                            Copyright © {(new Date().getFullYear())} Onepage.Business All Rights Reserved.
                    </p>
                </div>
            </div>
        )
    }
}

export default FooterDisclaimer;
