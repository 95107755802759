import React, {Component} from 'react';
import {connect} from 'react-redux';
import {TableHeaderColumn} from "react-bootstrap-table";
import icono from "../../../../../assets/img/sgya-C.png";
import Grid from "../../Utils/Grid";
import ModalSG from "./ModalSG&A";
import ModalTotalSga from "./ModalTotalSga";
import {standardActions} from "../../Utils/Grid/StandardActions";
import "./productos.css";
import { arrayTabs, arrayTabsFalse } from "./arryTabs";
import { LEVELITEMS } from "./../../../../utility/contry";
import _ from "lodash";
import cloneDeep from "lodash/cloneDeep";
import ReactTooltip from "react-tooltip";
import felchaD from "../../../../../assets/img/flecha-derecha.png";
import felchaI from "../../../../../assets/img/flecha-izquierda.png";
import {RenderNumberGrid} from  "../../Utils/renderField/renderReadField";

const initialValue = {
    office: [
        { id: 1, name: "Facility Rent", annual_growth: "", base_cost: "", editMode: true },
        { id: 2, name: "Utilities (Light / Water / Gas)", annual_growth: "", base_cost: "", editMode: true },
        { id: 3, name: "Insurance", annual_growth: "", base_cost: "", editMode: true },
        { id: 4, name: "Office Supplies", annual_growth: "", base_cost: "", editMode: true },
        { id: 5, name: "Premises Security", annual_growth: "", base_cost: "", editMode: true },
        { id: 6, name: "Property taxes", annual_growth: "", base_cost: "", editMode: true },
        { id: 7, name: "Premises cleaning / gardening", annual_growth: "", base_cost: "", editMode: true },
        { id: 8, name: "Repair / maintenance cost", annual_growth: "", base_cost: "", editMode: true },
    ],
    markCost: [
        { id: 1, name: "Web site", annual_growth: "", base_cost: "", editMode: true },
        { id: 2, name: "On line advertising", annual_growth: "", base_cost: "", editMode: true },
        { id: 3, name: "Email marketing", annual_growth: "", base_cost: "", editMode: true },
        { id: 4, name: "News Paper / Magazine Advertising", annual_growth: "", base_cost: "", editMode: true },
        { id: 5, name: "Radio Advertising", annual_growth: "", base_cost: "", editMode: true },
        { id: 6, name: "TV Advertising", annual_growth: "", base_cost: "", editMode: true },
        { id: 7, name: "Outdoor Advertising", annual_growth: "", base_cost: "", editMode: true },
        { id: 8, name: "Direct Marketing", annual_growth: "", base_cost: "", editMode: true },
        { id: 9, name: "Brochures / Print material", annual_growth: "", base_cost: "", editMode: true },
        { id: 10, name: " Promotional Material / Merchandising", annual_growth: "", base_cost: "", editMode: true },
        { id: 11, name: "Launch / Events", annual_growth: "", base_cost: "", editMode: true },
        { id: 12, name: "Sponsorships", annual_growth: "", base_cost: "", editMode: true },
        { id: 13, name: "Public Relations / Social Responsibility", annual_growth: "", base_cost: "", editMode: true },
        { id: 14, name: "Donations / Philanthropy", annual_growth: "", base_cost: "", editMode: true },
        { id: 15, name: "Licenses fees", annual_growth: "", base_cost: "", editMode: true },
        { id: 16, name: "Intellectual property fees", annual_growth: "", base_cost: "", editMode: true },
        { id: 17, name: "Advertising Agency fees", annual_growth: "", base_cost: "", editMode: true },
        { id: 18, name: "Other", annual_growth: 0, base_cost: 0, editMode: true },
    ],
    salesCost: [
        { id: 1, name: "Travel", annual_growth: "", base_cost: "", editMode: true },
        { id: 2, name: "Representation", annual_growth: "", base_cost: "", editMode: true },
        { id: 3, name: "Gas", annual_growth: "", base_cost: "", editMode: true },
        { id: 4, name: "Toll", annual_growth: "", base_cost: "", editMode: true },
        { id: 5, name: "Vehicle maintainace", annual_growth: "", base_cost: "", editMode: true },
        { id: 6, name: "Cell Phone", annual_growth: "", base_cost: "", editMode: true },
        { id: 7, name: "Other", annual_growth: "", base_cost: "", editMode: true },
    ],
    itComunCost: [
        { id: 1, name: "IT support fees", annual_growth: "", base_cost: "", editMode: true },
        { id: 2, name: "Hardware", annual_growth: "", base_cost: "", editMode: true },
        { id: 3, name: "Software", annual_growth: "", base_cost: "", editMode: true },
        { id: 4, name: "Telephone / cell phone", annual_growth: "", base_cost: "", editMode: true },
        { id: 5, name: "Internet", annual_growth: "", base_cost: "", editMode: true },
        { id: 6, name: "Other", annual_growth: "", base_cost: "", editMode: true },
    ],
    rdCost: [
       { id: 1, name: "Design", annual_growth: "", base_cost: "", editMode: true },
       { id: 2, name: "Field Test", annual_growth: "", base_cost: "", editMode: true },
       { id: 3, name: "Intellectual property fees", annual_growth: "", base_cost: "", editMode: true },
       { id: 4, name: "Process", annual_growth: "", base_cost: "", editMode: true },
       { id: 5, name: "Prototype", annual_growth: "", base_cost: "", editMode: true },
       { id: 6, name: "Research", annual_growth: "", base_cost: "", editMode: true },
       { id: 7, name: "Other", annual_growth: "", base_cost: "", editMode: true },
    ],
    finCost: [
        { id: 1, name: "Accounting fees ($)", annual_growth: "", base_cost: "", editMode: true },
        { id: 2, name: "Legal fees ($)", annual_growth: "", base_cost: "", editMode: true },
        { id: 3, name: "Bank fees / commissions ($)", annual_growth: "", base_cost: "", editMode: true },
        { id: 4, name: "Other ($)", annual_growth: "", base_cost: "", editMode: true },
    ],
};

let arrayProduct = [];

const monedaFormat = (num) => parseFloat(num).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');

const data = {
    count: 0,
    results: [
        { id: 0, costCategory: "Compensation & benefits", totalBase: "0.0" },
        { id: 1, costCategory: "Office Cost", totalBase: "0.0" },
        { id: 2, costCategory: "Marketing Cost", totalBase: "0.0" },
        { id: 3, costCategory: "Sales Cost", totalBase: "0.0" },
        { id: 4, costCategory: "IT & Communications Cost", totalBase: "0.0" },
        { id: 5, costCategory: "R&D Cost", totalBase: "0.0" },
        { id: 6, costCategory: "Finance Cost", totalBase: "0.0" },
    ],
};


class WizardGeography extends Component {

    state = {
        numTab: 0,
        openM: false,
        open: false,
        openTotalSales: false,
        openTotalSalesProduct: false,
        idProject: 0,
        inflation: 0,
        analysis_unit: 0,
        salesGrowthRate: false,
        custAttrition: false,
        activeCustomer: false,
        priceDiscount: false,
        detailedCOGS: false,
        moneda: "GTQ",
        monedaMainCur: "GTQ",
        data: {
            count: 0,
            results: [],
        },
        arrayTabsModal: arrayTabs,
        initialValues: initialValue,
        finish: false,
    };

    dataForm=[];

    componentDidUpdate() {
        ReactTooltip.rebuild();
    }

    componentDidMount() {
        const idProject = this.props.setUpGeneral.idForm;
        const formSaveData = this.props.setUpGeneral.formDataSave;
        const geographyList = this.props.setUpGeneral.geographyList;
        const inflation = formSaveData ? formSaveData.inflation: 0;
        const analysis_unit = formSaveData ? formSaveData.proyinfo.analisis_units: 0;
        this.setState({ analysis_unit });
        this.setState({ inflation });
        this.setState({ idProject });
        this.props.leer("sga/sgabyprojectList", idProject);

        this.setState({ monedaMainCur: formSaveData && formSaveData.proyinfo.main_currency });
        this.setState({ moneda: formSaveData && formSaveData.proyinfo.sales_currency }, () => {
            const arrayTabsModal = arrayTabs;
            arrayTabsModal[0].inputs[0].moneda= this.state.moneda;
            //arrayTabsModal[2].inputs[0].moneda= this.state.moneda;
            //arrayTabsModal[3].inputs[0].moneda= this.state.moneda;
            this.setState({ arrayTabsModal });
        });
        this.setState({ data });
        const context = this;
        window.onclick = function(event) {
            if (!event.target.matches('.btnM')) context.setState({ openM: false})
        }
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps !== nextContext) {
            if (nextProps.itemComp && nextProps.itemComp.length > 0) {
                const compesation = cloneDeep(nextProps.itemComp);
                compesation.forEach((val, index) => {
                    const office = initialValue.office[val.cost_type-1];
                    const markCost = initialValue.markCost[val.cost_type-1];
                    const salesCost = initialValue.salesCost[val.cost_type-1];
                    const itComunCost = initialValue.itComunCost[val.cost_type-1];
                    const rdCost = initialValue.rdCost[val.cost_type-1];
                    const finCost = initialValue.finCost[val.cost_type-1];

                    if (val.type === 7) {
                        val.sgaemployee.forEach((valItem, indexItem) => {
                            valItem.no_of_employees = Number(valItem.no_of_employees);
                            const value = valItem.level;
                            valItem.level =  _.find(LEVELITEMS, { value });
                            valItem.base_salary = Number(valItem.base_salary);
                            valItem.variable_salary = Number(valItem.variable_salary);
                            valItem.salary_growth = Number(valItem.salary_growth);
                            valItem.benefits = Number(valItem.benefits);
                            valItem.sales_commission = Number(valItem.sales_commission);
                            valItem.other = Number(valItem.other);
                            valItem.annual_bonus = Number(valItem.annual_bonus);
                        });
                        val.sgaemployee.length > 0 && (initialValue.compesation = val.sgaemployee);
                    } else if (val.type === 1) {
                        office && (office.base_cost = val.base_cost || 0);
                        office && (office.annual_growth = val.annual_growth || 0);
                    } else if (val.type === 2) {
                        markCost && (markCost.base_cost = val.base_cost || 0);
                        markCost && (markCost.annual_growth = val.annual_growth || 0);
                    } else if (val.type === 3) {
                        salesCost && (salesCost.base_cost = val.base_cost || 0);
                        salesCost && (salesCost.annual_growth = val.annual_growth || 0);
                    } else if (val.type === 4) {
                        itComunCost && (itComunCost.base_cost = val.base_cost || 0);
                        itComunCost && (itComunCost.annual_growth = val.annual_growth || 0);
                    } else if (val.type === 5) {
                        rdCost && (rdCost.base_cost = val.base_cost || 0);
                        rdCost && (rdCost.annual_growth = val.annual_growth || 0);
                    } else if (val.type === 6) {
                        finCost && (finCost.base_cost = val.base_cost || 0);
                        finCost && (finCost.annual_growth = val.annual_growth || 0);
                    }
                });
            }
            this.setState({ initialValues: initialValue });


            if (nextProps.item && nextProps.item.length > 0) {
                const datA = nextProps.item;
                    data.results[0].totalBase = monedaFormat(datA[0].compensation_benefits_cost);
                    data.results[1].totalBase = monedaFormat(datA[0].office_cost);
                    data.results[2].totalBase = monedaFormat(datA[0].marketing_cost);
                    data.results[3].totalBase = monedaFormat(datA[0].sales_cost);
                    data.results[4].totalBase = monedaFormat(datA[0].it_communications_cost);
                    data.results[5].totalBase = monedaFormat(datA[0].r_d_cost);
                    data.results[6].totalBase = monedaFormat(datA[0].finance_cost);
            }
            this.setState({ data });

            if (nextProps.formly && nextProps.formly.length > 0) {
                nextProps.formly.forEach((val) => {
                    val.key === "SALES_GROWTH_RATE" &&  this.setState({ salesGrowthRate: val.value === "true" });
                    val.key === "CUSTOMER_ATTRITION" && this.setState({ custAttrition: val.value === "true" });
                    val.key === "ACTIVE_CUSTOMER" &&  this.setState({ activeCustomer: val.value === "true" });
                    val.key === "PRICE_DISCOUNT" && this.setState({ priceDiscount: val.value === "true" });
                    val.key === "DETAILED_COGS_BY_PRODUCT" && this.setState({ detailedCOGS: val.value === "true" });
                    if (val.key === "DETAILED_SG_A_BY_PRODUCT" && val.value === "false") {
                        let defaultCurr = "";
                        nextProps.formly.forEach(val => {
                            if (val.key === "DEFAULT_CURRENCY_FOR_A_PROJECT") defaultCurr = val.value;
                        });
                        console.log("this.arrayTabsFalse", arrayTabsFalse);
                        arrayTabsFalse[0].inputs[0].label = `Office <${defaultCurr}>`;
                        arrayTabsFalse[0].inputs[1].label = `Marketing <${defaultCurr}>`;
                        arrayTabsFalse[0].inputs[2].label = `Sales <${defaultCurr}>`;
                        arrayTabsFalse[0].inputs[3].label = `IT & Communications <${defaultCurr}>`;
                        arrayTabsFalse[0].inputs[4].label = `R&D <${defaultCurr}>`;
                        arrayTabsFalse[0].inputs[5].label = `Finance <${defaultCurr}>`;
                        arrayTabsFalse[0].inputs[6].label = `Compensation & benefits <${defaultCurr}>`;

                        this.setState({ arrayTabsModal: arrayTabsFalse });
                        this.setState({ initialValues: {
                            ...this.state.initialValues,
                            office_cost: nextProps.item[0] && nextProps.item[0].office_cost,
                            marketing_cost: nextProps.item[0] && nextProps.item[0].marketing_cost,
                            sales_cost: nextProps.item[0] && nextProps.item[0].sales_cost,
                            it_communications_cost: nextProps.item[0] && nextProps.item[0].it_communications_cost,
                            r_d_cost: nextProps.item[0] && nextProps.item[0].r_d_cost,
                            finance_cost: nextProps.item[0] && nextProps.item[0].finance_cost,
                            compensation_benefits_cost: nextProps.item[0] && nextProps.item[0].compensation_benefits_cost,
                        }});
                    }
                });
            }
            if (nextProps.setUpGeneral && nextProps.setUpGeneral.formDataSave && nextProps.setUpGeneral.formDataSave.proyinfo) {
                let finish = nextProps.setUpGeneral.formDataSave.proyinfo.finish;
                if (nextProps.login.me.user_profile && nextProps.login.me.user_profile.permission === 1) finish = true;
                this.setState({ finish: finish });
            }
        }
    }

    render() {
        const onOpenModal = () => {
            this.props.leerComp("sga/sgabyproject", this.state.idProject);
            this.setState({ open: true })
        };
        const onCloseModal = () => this.setState({ open: false });
        const editarModal = (numTab) => {
            if (this.state.arrayTabsModal.length === 1) onOpenModal();
            else this.setState({ numTab }, () => onOpenModal());
        };

        const nextPage = () => {
            console.log("aaaaaaa 13212313123");
            this.forceUpdate();
            console.log("aaaaaaa 13212313123");
            if (this.state.numTab === 6) this.setState({ numTab: 0 });
            else this.setState({ numTab: this.state.numTab + 1 });
        };

        const setPage = (page) => {
            this.setState({ numTab: Number.parseInt(page) });
        };

        const totalizadosales = () => {
            const { analisis_units, main_currency  } = this.props.setUpGeneral.formDataSave.proyinfo;
            let data = { id: this.state.idProject, infla: this.props.setUpGeneral.formDataSave.inflation, analysis_unit: analisis_units };
            this.props.leerTotalizadoresSales("sga/sga_total_by_project/", data, main_currency);
        };

        const onOpenModalTotalSales = () => {totalizadosales(); this.setState({ openTotalSales: true })};
        const onCloseModalTotalSales = () => this.setState({ openTotalSales: false });

        const onOpenModalTotalSalesProduct = () => {totalizadosales(); this.setState({ openTotalSalesProduct: true })};
        const onCloseModalTotalSalesProduct = () => this.setState({ openTotalSalesProduct: false });
        const opneMenu = () => { this.setState({ openM: !this.state.openM });};


        const eliminarTerry = (e) => {
            const url = `cogs/destroy_cogs/`;
            const urlLeer = `cogs/get_cogs`;
            this.props.eliminar(url, urlLeer, e, this.state.idProject);
        };
        const { open, openTotalSales, openTotalSalesProduct, finish } = this.state;
        return (
            <div>
                <div className="header-wizard-form">
                    <div className="columna-1-Ge columna-1 my-auto">
                        <table>
                            <tr>
                                <td>
                                    <img
                                        className="flecha-pagina pag-div"
                                        src={felchaI}
                                        alt="regresar"
                                        style={this.props.previousPage ? { display: "inline-block" } : { display: "none" }}
                                        onClick={this.props.previousPage}
                                    />
                                </td>
                                <td>
                                    <img src={icono} alt="icono" className="icono-step" />
                                </td>
                                <td> <span>Selling, General and Administrative expenses (SG&A)</span> </td>
                                <td>
                                    <img
                                        className="flecha-pagina pag-div"
                                        src={felchaD}
                                        alt="regresar"
                                        style={this.props.nextPage ? { display: "inline-block" } : { display: "none" }}
                                        onClick={this.props.nextPage}
                                    />
                                </td>
                            </tr>
                        </table>
                    </div>
                    <div className="columna-2-Ge columna-2 my-auto">
                        Here you can define mayor key variables that affects selling, general and administrative expenses
                        (SG&A). These are the sum of all direct and indirect costs that occur during the daily
                        operations of a company and not directly related to the manufacturing of the product
                    </div>
                </div>
                <div className="div-center" />
                <br/>
                <div className="content-wizarform">
                    <button type="button" onClick={onOpenModal} disabled={finish} className="btn btn-primary btn-sm mr-1">Add SG&A</button>
                    <div className="float-right">
                        <button type="button" disabled={this.state.data.results.length <= 0} onClick={onOpenModalTotalSales} className="btn-Movil btn btn-optional btn-sm mr-1">Total SG&As</button>
                        <div className="dropdown menu-Movil" style={ { margin: "auto" } }>
                            <a className="btnM" onClick={opneMenu} style={ { cursor: "pointer" } } >
                                <i style={ { color: "black", margin: "auto" } } className="fa fa-ellipsis-v fa-2x btnM" />
                            </a>
                            <div
                                id="myDropdown"
                                className={`dropdown-content ${this.state.openM===true && "show"}`}
                                style={ { right: "0px" } }
                            >
                                <a onClick={onOpenModalTotalSales} style={ { cursor: "pointer" } } >Total SG&As</a>
                            </div>
                        </div>
                    </div>
                    <br />
                    <br />
                    <Grid
                        page={1}
                        hover
                        pagination={false}
                        data={this.state.data}
                        //loading={this.props.loader}
                        // onPageChange={onPageChange}
                        // onSortChange={onSortChange}
                    >
                        <TableHeaderColumn
                            dataField="id"
                            dataAlign="center"
                            isKey
                            dataFormat={
                                standardActions( finish===true ? {  } : { editarClick: editarModal } )
                            }
                            style={{ fontSize: "10px" }}
                        >ACTIONS</TableHeaderColumn>
                        <TableHeaderColumn
                            tdStyle={{ whiteSpace: 'normal' }}
                            thStyle={{ whiteSpace: 'normal' }}
                            dataAlign="left" dataField="costCategory" dataSort>COST CATEGORY</TableHeaderColumn>
                        <TableHeaderColumn
                            tdStyle={{ whiteSpace: 'normal' }}
                            thStyle={{ whiteSpace: 'normal' }}
                            dataAlign="left" dataField="totalBase" dataFormat={RenderNumberGrid} dataSort>{`TOTAL BASE COST <${this.state.moneda}>`}</TableHeaderColumn>
                    </Grid>
                </div>

                <ModalSG
                    {...this.props}
                    arrayTabs={this.state.arrayTabsModal}
                    open={open}
                    idProject={this.state.idProject}
                    onSubmitsss={this.props.onSubmit}
                    onCloseModal={onCloseModal}
                    inflation={this.state.inflation}
                    analysis_unit={this.state.analysis_unit}
                    numTab={this.state.numTab}
                    nextPage={nextPage}
                    setPage={setPage}
                    initialValue={this.state.initialValues}
                />

                <ModalTotalSga
                    {...this.props}
                    moneda={this.state.moneda}
                    monedaMainCur={this.state.monedaMainCur}
                    open={openTotalSales}
                    idProject={this.state.idProject}
                    onCloseModal={onCloseModalTotalSales}
                    salesGrowthRate={this.state.salesGrowthRate}
                    custAttrition={this.state.custAttrition}
                    activeCustomer={this.state.activeCustomer}
                    priceDiscount={this.state.priceDiscount}
                    detailedCOGS={this.state.detailedCOGS}
                />

            </div>
        );
    }
}

export default connect()(WizardGeography);
