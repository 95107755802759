export const validateGuestUsers = (values) => {
    const errors = {};
    if (!values.first_name) { errors.first_name = 'Required'; }
    if (!values.last_name) { errors.last_name = 'Required'; }
    if (!values.username) { errors.username = 'Required'; }
    // eslint-disable-next-line max-len
    if (values.username && !values.username.match("[!@#$%^&*()_+\\-=\\[\\]{};':\"\\\\|,.<>\\/?]")) { errors.username = 'Email invalid'; }
    if (!values.password) { errors.password = 'Required'; }
    if (!values.password2) { errors.password2 = 'Required'; }
    if (!values.permission) { errors.permission = 'Required'; }
    if (!values.projects) { errors.projects = 'Required'; }

    if (values.password && values.password2) {
        if (values.password !== values.password2) errors.password2 = { _error: 'Passwords do not match' };
    }

    return errors;
};


export default validateGuestUsers;
