import React, { PureComponent, Component } from 'react';
import Grid from "../../../Utils/Grid";
import {TableHeaderColumn} from "react-bootstrap-table";
import {Field} from "redux-form";
import {renderFieldRadio as RadioField } from "../../../Utils/renderField/renderField";
import classNames from "classnames";

const colores = [
    "#D1523D", "#AD4485",
    "#2E5389", "#BA7B36",
    "#ACB53C", "#CD7672",
    "#679BF9", "#3CBFA9",
    "#87CDF4", "#C98DC7",
    "#7D5791", "#7EC4C2",
];

const monedaFormat = (num) => parseFloat(num).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');

const datas = [
    {
        name: "P&L",
        lable: ["ITEM", "Y1", "Y2", "CARGA (%)"],
        data: {
            count: 0,
            results: [
                { val0: "nombree", val1: 1, val2: 2, val3: 45 },
                { val0: "nombree", val1: 1, val2: 2, val3: 45 },
                { val0: "nombree", val1: 1, val2: 2, val3: 45 },
                { val0: "nombree", val1: 1, val2: 2, val3: 45 },
            ],
        },
    },
];

class ReportTableCashFlow extends Component {

    state = {
        radio: true,
    };

    render() {
        const { data } = this.props;
        return (
            <div className="content-wizarform">
                {
                    data && data.map((value, index) => (
                        <div>
                            <br/>
                            <h4 className="textBold div-center">{value.name}</h4>
                            <div className="row">
                                <div className="col-md-9">
                                    <h4 className="textBold div-center">{value.name}</h4>
                                </div>
                                <div className="col-md-3">
                                    <div className="radio c-radio c-radio-nofont d-flex">
                                        <label className="negro font-weight-normal col-md--2">
                                            <input
                                                type="radio" name="estado" checked={this.state.radio} className={classNames('', { 'is-invalid': false })}
                                                onChange={(value) => this.setState({ radio: Boolean(value.target.value) })}
                                            />
                                            <span />&nbsp;Accrual Basic
                                        </label>
                                        &nbsp;&nbsp;&nbsp;&nbsp;
                                        <label className="negro font-weight-normal col-md--2">
                                            <input
                                                type="radio" name="estado" checked={!this.state.radio} className={classNames('', { 'is-invalid': false })}
                                                onChange={(value) => this.setState({ radio: Boolean(!value.target.value) })}
                                            />
                                            <span />&nbsp;Cash Basic
                                        </label>
                                    </div>
                                </div>
                            </div>

                            <Grid
                                page={1}
                                hover
                                pagination={false}
                                data={value.data}
                                loading={false}
                            >
                                <TableHeaderColumn dataField="id" hidden={true} isKey>ACTIONS</TableHeaderColumn>
                                {(value.label.map((items, indxItem) => {
                                    return (
                                        <React.Fragment>
                                            <TableHeaderColumn
                                                dataAlign={"left"}
                                                dataField={`val${indxItem}`}
                                                dataSort
                                                tdStyle={{ whiteSpace: 'normal' }}
                                                thStyle={{ whiteSpace: 'normal' }}

                                                dataFormat={ i => (<div className={`${i && i.negrita && "textBold"}`} >{i && (i.val[this.state.radio] ? i.val[this.state.radio]: i.val)}</div>) }
                                            >
                                                {items}
                                            </TableHeaderColumn>
                                        </React.Fragment>
                                    );
                                }))}
                            </Grid>
                        </div>
                    ))
                }
            </div>
        );
    }
}
export default (ReportTableCashFlow);
