import React, { Component } from 'react';
import Tabs, { TabPane } from 'rc-tabs';
import TabContent from 'rc-tabs/lib/TabContent';
import ScrollableInkTabBar from 'rc-tabs/lib/ScrollableInkTabBar';
import { connect } from 'react-redux';
import ReportGraphyc from "./ReportGraphyc";
import ReportTableCashFlow from "./ReportTableCashFlow";
import ReportTable from "./ReportTable";

class WizardTabsReport extends Component {
    render() {
        const {
            page,
            setPage,
            arrayTabs,
        } = this.props;

        return (
            <div>
                <Tabs
                    defaultActiveKey={page.toString()}
                    activeKey={page.toString()}
                    tabBarPoition="top"
                    onchange={this.callback}
                    renderTabBar={
                        () => (<ScrollableInkTabBar onTabClick={setPage} />)
                    }
                    renderTabContent={() => <TabContent />}
                >
                    {arrayTabs.map((value, index) => (<TabPane tab={value.nombre} key={index.toString()} />))}
                </Tabs>
                {arrayTabs[page] && (
                    <React.Fragment>
                        {arrayTabs[page].type === "graphyc" && (<ReportGraphyc data={arrayTabs[page].data} />)}
                        {arrayTabs[page].type === "tableCash" && (<ReportTableCashFlow data={arrayTabs[page].data} />)}
                        {arrayTabs[page].type === "table" && (<ReportTable data={arrayTabs[page].data} />)}
                    </React.Fragment>
                )}
            </div>
        );
    }
}

export default connect()(WizardTabsReport);
