import { connect } from 'react-redux';
import { actions } from '../../../redux/modules/cuenta/login';
import ProjectSetUp from './ProjectCreateContainer';
import geogrephy from "../../../redux/modules/Geography/geography";
import products from "../../../redux/modules/Products/Products";


const ms2p = (state) => {
    return {
        ...state.login,
        setup: state.setUpGeneral,
        mascaraLoading:
            state.setUpGeneral.loader
            || state.geogrephy.loader
            || state.products.loader
            || state.sags.loader
            || state.cogs.loader
            || state.invest.loader
            || state.report.loader,
    };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(ProjectSetUp);
