import React, { Component } from 'react';
import "react-step-progress-bar/styles.css";
import ProgresBar from './ProgresBar';
import WizardForm from "./WizardForm";
import config from '../../../../assets/img/config.png';
import geography from '../../../../assets/img/geography.png';
import product from '../../../../assets/img/product.png';
import cogs from '../../../../assets/img/cogs.png';
import sgya from '../../../../assets/img/sgya.png';
import invest from '../../../../assets/img/invest.png';
import report from '../../../../assets/img/report.png';
import valido from '../../../../assets/img/validado.png';
import invalido from '../../../../assets/img/invalido.png';
import regresar from '../../../../assets/img/regresar.png';
import felchaI from '../../../../assets/img/flecha-izquierda.png';
import felchaD from '../../../../assets/img/flecha-derecha.png';
import {NavLink} from "react-router-dom";
import cuenta from "../../../../assets/img/cuenta.png";
import FooterDisclaimer  from '../Utils/FooterDisclaimer/FooterDisclaimer';
import LoadMask from "../Utils/LoadMask/LoadMask";

let array = [];

class ProjectCreateContainer extends Component {

    state = {
        page: 1,
    };

    nextPage = () => {
        this.setState({ page: this.state.page + 1 });
    };

    previousPage = () => {
        this.setState({ page: this.state.page - 1 });
    };

    setPage = (page) => {
        this.setState({ page: Number.parseInt(page) });
    };

    // eslint-disable-next-line class-methods-use-this
    onSubmitsss = () => {
        // eslint-disable-next-line no-alert
        alert("asdlkasdkasldad");
    };

    render() {
        if (this.props.match.params.tipo==="service"){
            array = [
                ["Project Set Up", "Geography", "Products", "SG&A", "Investments", "Report"],
                [config, geography, product, sgya, invest, report],
            ];
        }
        else {
            array = [
                ["Project Set Up", "Geography", "Products", "SG&A", "COGS", "Investments", "Report"],
                [config, geography, product, sgya, cogs, invest, report],
            ];
        }
        const name = this.props.setup && this.props.setup.formDataSave && this.props.setup.formDataSave.proyinfo && this.props.setup.formDataSave.proyinfo.name;

        const { mascaraLoading } = this.props;

        return (
            <div className="m-4 movile">
                <LoadMask loading={mascaraLoading} blur positionM="inherit">
                    <div className="div-border-round">
                        <ProgresBar
                            array={array}
                            step={this.state.page}
                            valido={valido}
                            invalido={invalido}
                            setPage={this.setPage}
                        />
                    </div>
                    <div className="div-paginas">
                        <div className="div-regresar">
                            <NavLink to="/">
                                <img src={regresar} alt="regresar" />
                            </NavLink>
                            <span>PROJECT {this.props.match.params.idProject ? ` ${name}` : ""}</span>
                        </div>
                        <div className="div-paginacion">
                            <img
                                className="flecha-pagina"
                                src={felchaI}
                                alt="regresar"
                                style={this.state.page === 1 ? { display: "none" } : { display: "inline-block" }}
                                onClick={this.previousPage}
                            />
                            <span>{ `Step ${this.state.page} of ${array[0].length}` }</span>
                            <img
                                className="flecha-pagina"
                                src={felchaD}
                                alt="regresar"
                                style={this.state.page === array[0].length ? { display: "none" } : { display: "inline-block" }}
                                onClick={this.nextPage}
                            />
                        </div>
                    </div>
                    <WizardForm
                        nextPage={this.nextPage}
                        previousPage={this.previousPage}
                        page={this.state.page}
                        setPage={this.setPage}
                        onSubmit={this.onSubmitsss}
                        tipo={this.props.match.params.tipo}
                        idProject={this.props.match.params.idProject}
                    />
                    <FooterDisclaimer/>
                </LoadMask>
            </div>
        );
    }
}

export default ProjectCreateContainer;
