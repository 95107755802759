import React, { Component } from 'react';
import { connect } from 'react-redux';
import Modal from 'react-responsive-modal';

class ModalEditLabel extends Component {
    state = {
        input: "",
    };

    componentDidMount() {
        this.setState({ input: this.props.input });
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps !== nextContext) {
            nextProps.input && this.setState({ input: nextProps.input });
        }
    }

    render() {
        const { state: { input }, props: { open, onCloseModal, saveLabel } } = this;
        const styleModal = { modal: { borderRadius: 25,  maxWidth: "50% !important",  width: "100%", margin: "50px auto" } };
        return (
            <Modal
                open={open}
                animationDuration={1000}
                onClose={onCloseModal}
                center
                styles={styleModal}
                closeIconSize={25}
            >
                <h6>Label change</h6>
                Enter new label text.
                <br />
                <br />
                <input
                    className="form-control"
                    type="text"
                    value={input}
                    onChange={e => this.setState({ input: e.target.value })}
                />
                <br />
                <br />
                <button
                    onClick={() => saveLabel(input)}
                    className="btn btn-optional2 btn-sm mr-1"
                >
                    Save
                </button>
            </Modal>
        );
    }
}

export default connect()(ModalEditLabel);
